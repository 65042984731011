<template>
    <v-container  >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="tariffForm" v-model="valid">
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="[formRules.rules.required,formRules.rules.validString]"
                        :label="$store.getters.getTextMap().name"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.msg"
                        :rules="[formRules.rules.validString]"
                        :counter="45"
                        outlined
                        dense
                        :label="$store.getters.getTextMap().description_optional"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.default_tariff_val"
                        outlined
                        dense
                        :rules="[formRules.rules.required,formRules.rules.num]"
                        :label="$store.getters.getTextMap().default_tariff_val"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.tariff_on"
                        :items="tagTypeOptions"
                        :label="$store.getters.getTextMap().tag_type"
                        item-text="label"
                        item-value="value"
                        outlined
                        small-chips
                        dense
                        :rules="[formRules.rules.required,formRules.rules.required]"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.timezone"
                        :items="timezoneOptions"
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        outlined
                        dense
                        small-chips
                        :label="$store.getters.getTextMap().timezone"
                        item-text="label"
                        item-value="value"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'CreateTariff',
    props:['tariff'],
    components:{
        InfoAlert
    },
    mounted(){
        this.getTimezoneList()
        if(this.tariff && this.tariff.tariff_id){
            this.api='updateTariff'
            this.editMode=true
            this.form=Object.assign({},this.tariff) 
        }else{
            this.api='createTariff'
            this.editMode=false
        }
    },
    data(){
        return {
            api:'createTariff',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            timezoneOptions:[],
            form:{
                name:null,
                msg:null,
                meta:null,
                default_tariff_val:0,
                timezone:"Asia/Kolkata"
            },
            formRules: FormRules,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    computed:{
        tagTypeOptions(){
            let op=[]
            let g=this.$store.state.tagTypes
            for(let i of g){
                op.push( {value:i.tag_type, label: i.name  })
                // op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
            }
          return op
        },
    },
    methods:{
        close(){
            this.$emit("close")
        },
        getTimezoneList(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "getTimezones",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let timezones=response.data.data
            for(let i of timezones){
                op.push({value:i.timezone, label: i.timezone })
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
            this.timezoneOptions=op
        },
        onSubmit(){
            this.$refs.tariffForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['tariff_id']=this.tariff.tariff_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.$store.dispatch('refreshTariffs')
                        this.loading=false
                        this.$emit('update')
                        if(!this.editMode){
                            this.$refs.tariffForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    }
}
</script>