<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
        :dark="$store.getters.getColorPalette().isDark"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="paramEquations"
      item-key="eq_id"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
 :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
       :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{$store.getters.getTextMap().param_equations}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <CreateParamEquationSheet v-on:update="getParamEquations" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
        <v-icon small :color="$store.getters.getColorPalette().accentCode" @click="getParamEquations">mdi-refresh</v-icon>
      </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <paramEquation :paramEquation="item" v-on:deleteParamEquation="getParamEquations" v-on:update="getParamEquations"/>
       
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</template>

<script>
import paramEquation from '@/components/items/derived/ParamEquation'
import CreateParamEquationSheet from '@/components/modals/derived/CreateParamEquationSheet'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'ParamEquationTable',
    components:{
        paramEquation,
        CreateParamEquationSheet,
        InfoAlert
    },
    mounted(){
      this.getParamEquations()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            paramEquations:[],
            expanded: [],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'eq_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'label', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Unit', value: 'unit', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    methods:{
      getParamEquations(){
        this.loading=true
        //console.log('getParamEquations')
            axios.get(this.$store.state.api+'paramEquations',{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            
                            
                            this.paramEquations=response.data.data
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })


      }
    },
    computed:{
        calculatedParams(){
            return this.$store.state.calculatedParams;
        }
    }
}
</script>
<style scoped>


</style>
