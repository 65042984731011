<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col align="right">
                <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        color="blue"
        v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.betaAccessLevel"
        @click="editShow=!editShow"
      >
        mdi-pencil
      </v-icon>
      <DeleteConfirmation v-on:confirm="deleteCalculatedParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this calculated param?"> 
      <v-icon
        small
      :color="$store.getters.getColorPalette().deletebtnColor"
       
      >
        mdi-delete
           
      </v-icon>
      </DeleteConfirmation>
    </span>
            </v-col>
        </v-row>
                <v-row v-if="editShow">
          <v-col><CreateCalculatedParam v-on:close="editShow=false" v-on:update="$emit('update')" :param="calculatedParam" /> </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span class="h3">
               ( ( <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white">{{paramName1}}</v-chip> 
      {{calculatedParam.param1_operator}} {{calculatedParam.param1_operand}} )
      {{calculatedParam.operator}}
      (
      <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white">{{paramName2}}</v-chip>
      {{calculatedParam.param2_operator}} {{calculatedParam.param2_operand}} ) ) * {{calculatedParam.correction_factor}}
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
// import EditCalculatedParam from '@/components/crud_components/EditCalculatedParam'
import CreateCalculatedParam from '@/components/crud_components/derived/CreateCalculatedParam'

export default {
    name:'CalculatedParam',
    props:['calculatedParam'],
    components:{
        InfoAlert,
        DeleteConfirmation,
       CreateCalculatedParam
    },
    data(){
        return {
            showDialog:false,
            loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
                editShow:false,
        }
    },
    computed:{
        paramName1(){
            if(this.calculatedParam){
                if(this.calculatedParam.param1_type=='computed'){
                    let v=this.$store.getters.getComputedParameterById(this.calculatedParam.param1)
                    if(!v){
                        return this.calculatedParam.param1
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else if(this.calculatedParam.param1_type=='parameter'){
                    let v=this.$store.getters.getParameterById(this.calculatedParam.param1)
                    if(!v){
                        return this.calculatedParam.param1
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else if(this.calculatedParam.param1_type=='manual'){
                    let v=this.$store.getters.getManualParameterById(this.calculatedParam.param1)
                    if(!v){
                        return this.calculatedParam.param1
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else{
                    return "Unassigned Name"
                }
            }
            return null
        },
        paramName2(){
            if(this.calculatedParam){
                if(this.calculatedParam.param2_type=='computed'){
                    let v=this.$store.getters.getComputedParameterById(this.calculatedParam.param2)
                    if(!v){
                        return this.calculatedParam.param2
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else if(this.calculatedParam.param2_type=='parameter'){
                    let v=this.$store.getters.getParameterById(this.calculatedParam.param2)
                    if(!v){
                        return this.calculatedParam.param2
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else if(this.calculatedParam.param2_type=='manual'){
                    let v=this.$store.getters.getManualParameterById(this.calculatedParam.param2)
                    if(!v){
                        return this.calculatedParam.param2
                    }
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    let s=''
                    if(dev && dev.name){
                        s+=dev.name
                    }
                    if(v && v.name){
                        s+=' - '+v.name
                    }
                    return s
                }else{
                    return "Unassigned Name"
                }
            }
            return null
        },
    },
    methods:{
        deleteCalculatedParam(){
            axios.post(this.$store.state.api+'deleteCalculatedParam',this.calculatedParam,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshCalculatedParams')
                            this.loading=false
                            this.info='The Param has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
                    console.log(this.deleteCalculatedParam, "check")
        },
     onClose(){
            this.showDialog=false
            this.$emit('close')
        },
           cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
       
        }
       
    }
}
</script>