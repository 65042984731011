<template>

  <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"
    flat :dark="$store.getters.getColorPalette().isDark">
    <v-card-title>
      <v-text-field v-model="search" append-icon="mdi-magnify" single-line hide-details
        :dark="$store.getters.getColorPalette().isDark">
        {{ $store.getters.getTextMap().search }}
      </v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="words" item-key="word_id" :search="search" :single-expand="true"
      show-expand :expanded.sync="expanded" :loading="loading" loading-text="Loading... Please wait"
      :dark="$store.getters.getColorPalette().isDark">
      <template v-slot:top>
        <v-toolbar flat :dark="$store.getters.getColorPalette().isDark">
          <v-toolbar-title>{{$store.getters.getTextMap().words}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <CreateWordModal />
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <Word :word="item" />

        </td>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
//import CreateActionBottomSheet from '@/components/modals/CreateActionBottomSheet'
import Word from '@/components/items/Word'
import CreateWordModal from '@/components/modals/core/CreateWordModal.vue'
// import ColorUtility from '@/utillities/ColorUtility'
export default {
    name:'WordTable',
    components:{
        //CreateActionBottomSheet,
        Word,
        CreateWordModal
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'word_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    computed:{
        words(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getWords()
        }
    },
}
</script>
<style scoped>

.v-card__title{
  background-color:#BDBDBD00;
}
.theme--light.v-toolbar.v-sheet{
  background-color: #B2DFDB00;
}
.theme--light.v-data-table{
   background-color: #B2DFDB00;
}
.theme--light.v-tabs-items{
  background-color:#263238
}
</style>