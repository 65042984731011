<template>
    <span>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-btn :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" @click="ShowUsageByMachinePanel=!ShowUsageByMachinePanel" small text outlined  :color="$store.getters.getColorPalette().accentCode" v-if="!loading">
            {{$store.getters.getTextMap().energy_consumption_by_machines}}<v-icon right small :color="$store.getters.getColorPalette().btnborderColorCode">mdi-chart-donut</v-icon>
        </v-btn>
                <v-dialog v-model="ShowUsageByMachinePanel" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                        <v-toolbar flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="ShowUsageByMachinePanel = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{$store.getters.getTextMap().energy_consumption_by_machines}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="ShowUsageByMachinePanel = false">close</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <UsageByMachinePanel/>
                    </v-card>
                </v-dialog>
            
    </span>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import UsageByMachinePanel from '@/components/panels/UsageByMachinePanel'
export default {
    name:'UsageByMachineBottomSheet',
    components:{
        InfoAlert,
        UsageByMachinePanel
    },
    data(){
        return{
            showDismissibleAlert:false,
            loading:false,
            info:'',
            ShowUsageByMachinePanel:false
        }
    }
}
</script>