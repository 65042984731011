<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-divider></v-divider>
            
        <v-row>
            <v-col>
       <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white" v-for="param in report.list"
        :key="param">
        {{getParamName(param)}}
        </v-chip>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
                v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            :label="$store.getters.getTextMap().dates"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          range
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().view_report}}

          </v-btn>
            </v-col>
        </v-row>
        </v-form>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
        <v-row v-if="graphData>0">
            <v-col>
                <strong>{{report.name ?report.name : report.report_id}} - {{dateRangeText}}</strong>
            </v-col>
        </v-row>
        <v-row v-if="graphData">
            <v-col>
              <div ref="graph"></div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
        </v-container>
</template>

<script>
//import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export default {
    name:'ParameterReportDetails',
    props:['report'],
    components:{
        InfoAlert,
        //LineGraphByTimeStamp
    },
    data(){
        return {
            form:{
                date:null,
                device:null,
                parameter:null
            },
            //date: new Date().toISOString().substr(0, 10),
            label:null,
            unit:null,
                valid:false,
                menu:false,
            graphData:{},
            layout:{
              //title:'Compare',
              showlegend:true
            },
            showDismissibleAlert:false,
            loading:false,
            info:'',
             objectRules:[
        v => !!v || 'Required',
      ],
        }
    },
    computed:{
        dateRangeText () {
          if(this.form.date==null){
            return null
          }
        return this.form.date.join(' ~ ')
      },
      timeUnit(){
        if(this.form.date && this.form.date.length>1 ){
          return "day"
        }else{
          return 'hour'
        }
      },
      fromTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[0].toString()
            if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
              dt=this.form.date[1].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
          }
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[1].toString()
            if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
              dt=this.form.date[0].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
          }
        }else{
          return null
        }
      },
    },
    methods:{
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        createGraph(){
          if(this.graphData){
            Plotly.newPlot(this.$refs.graph, Object.values(this.graphData), this.layout,{displaylogo: false, displayModeBar:true});
          }
          
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              
    let endpoint='getNormalisedDataByParamsBetweenTimestamp'
    //console.log(fromTimeStamp)
    /*if(this.report.processing=='single'){
      endpoint='getNormalisedDataByParamsBetweenTimestamp'
      payload['params']=this.report.list
      this.label=this.$store.getters.getParameterById(this.report.list[0])['name']
      this.unit=this.$store.getters.getParameterById(this.report.list[0])['unit']
      //console.log(this.label)
    }else{
      this.label=null
      this.unit=null

    }*/
    this.graphData={}
    for(let param of this.report.list){
      endpoint='getNormalisedDataByParameterBetweenTimestamp'
      let payload={
                  report_id:this.report.report_id,
                  parameter:param,
                from_timestamp:this.fromTimeStamp,
                to_timestamp:this.toTimeStamp
              }
              this.graphData[param]={
        name:this.getParamName(param),
        x:[],
        y:[],
        type:'scatter'
      }
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      console.log("report data received")
      //console.log(response.data)
      if(response.data.status==='success'){
      //let data=[]
      for (let i of response.data.data){
        this.graphData[i.param_id]['x'].push(moment.unix(Number(i.timestamp)).format('YYYY-MM-DD HH:mm:ss'))
        this.graphData[i.param_id]['y'].push(i.data)
          //data.push(i)
        
      }
      
      //console.log(response.data.data)
      //this.report['parameter']=this.form.parameter
      //this.report['date']=this.form.date
      /*if(this.graphData.length==0){
          this.info = "No data to show";
            this.showDismissibleAlert = true;
      }*/
      this.createGraph()
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })
          }

        }
    },
    }
}
</script>