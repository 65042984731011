<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && activity && activity.activity_id">
            <v-col cols="auto" class="d-flex">
                <CreateActivityModal :activity="activity" />
                <CreateTaskModal  class="mx-1" :activity="activity"  v-on:success="getTask" v-on:update="getTask"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                    <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="getTask">
                        Refresh
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small>
                            mdi-refresh
                        </v-icon>
                    </v-btn>
                    <DeleteConfirmation class="mx-1" v-on:confirm="deleteArea()" title="Delete Confirmation" description="Are you sure you want to delete this Premise?">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>
                        Delete Activity
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                    </v-btn>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="tasks && tasks.length>0">
            <v-col>
                <TaskPanel :activity="activity" :tasks="tasks" v-on:update="getTask"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DeleteConfirmation from '@/components/DeleteConfirmation'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import CreateActivityModal from '@/components/modals/application/CreateActivityModal.vue'
import TaskPanel from '@/components/panels/application/taskmanagment/TaskPanel.vue'
import CreateTaskModal from '@/components/modals/application/taskmanagment/CreateTaskModal.vue'
export default {
    name:'ActivityPage',
    components:{
        InfoAlert,
        DeleteConfirmation,
        CreateActivityModal,
        CreateTaskModal,
        TaskPanel
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activity_id:null,
            activity:null,
            tasks:null
        }
    },
    mounted(){
        this.activity_id=this.$route.params.id
        if(this.activity_id){
            this.getActivity()
            this.getTask()
        }
    },
    computed:{
        
    },
    methods:{
        getActivity(){
            this.activity=null
            this.loading=true
            axios.post(this.$store.state.api+'getActivityById',{activity_id:this.activity_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.activity=response.data.activity
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        getTask(){
            this.tasks=null
            this.loading=true
            axios.post(this.$store.state.api+'getTaskByActivityId',{activity_id:this.activity_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.tasks=response.data.tasks
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteActivity(){
            let payload={activity_id:this.activity_id}
            this.loading=true
            axios.post(this.$store.state.api+'deleteActivity',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshActivities')
                if (this.$store.state.activities.length === 0) {
                    this.$router.push("/tasksmanagment");
                } else {
                    this.$router.push("/tasksmanagment/" + this.$store.state.activities[0].activity_id);
                }
            }
            })
            .catch(error=>{
                    this.loading=false
                    this.info=error
                    this.showDismissibleAlert=true
            });
        }
    },
    watch:{
        '$route.params.id':{
            handler(){
                this.activity_id=this.$route.params.id
                this.getActivity()
                this.getTask()
            },
            deep:true
        },
    }
}
</script>