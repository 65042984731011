<template>
    <span class="text-center">
      <v-btn  small fab :dark="$store.getters.getColorPalette().isDark"  color="teal accent-3" @click="dialog=!dialog" >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
           <v-bottom-sheet v-model="dialog">
        <v-sheet
        :dark="$store.getters.getColorPalette().isDark"
          class="text-center"
           :style="{background: $store.getters.getColorPalette().backgroundColorCode}"
        >
        <CreateWord  v-on:close="dialog=false"/>
        </v-sheet>
      </v-bottom-sheet>
  
    </span>
    
  </template>
  
  <script>
  import CreateWord from '@/components/crud_components/core/CreateWord'
   export default {
       name:'CreateWordModal',
       components:{
           CreateWord
       },
       data(){
           return {
               dialog:false
           }
       }
   }
   </script>