<template>
    <span class="text-center">
        <v-btn
            
        :color="$store.getters.getColorPalette().accentCode" small text outlined

            @click="dialog = !dialog"
        >
        {{$store.getters.getTextMap().day_wise_targets}}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor">mdi-target</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{$store.getters.getTextMap().day_wise_targets}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <TargetDayWiseTable :target="target" v-on:close="dialog=false" v-on:update="$emit('update')"/>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import TargetDayWiseTable from '@/components/lists/derived/TargetDayWiseTable'
export default {
    name:'TargetDayWiseSheet',
    props:{'target':{
        type:Object,
        required:true
    }},
    components:{
        TargetDayWiseTable  
    },
    data(){
        return {
            dialog:false
        }
      },
    methods:{
        update(){
            this.$emit('update')
        }
    }
  }
  </script>
  <style scoped>
  .devc{
    border:3px solid;
    border-radius:20px;
  }
  .theme--light.v-sheet {
     background-color: #263238;
  }
  .theme--light.v-card{
     background-color: #263238;
  }
  </style>