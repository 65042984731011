<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <div v-if="!refillRequest">
            <v-row 
                v-for="(item, index) in checklistFormDetails"
                :key="index"
            >
                <v-col v-if="!loading" class="ma-0 pa-1">
                    <v-card
                        flat
                        outlined
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().background2ColorCode"
                        style="border-radius: 12px;"
                    >
                        <div style="display: flex; justify-content: space-between;">
                            <v-card-title class="text-body-1 font-weight-bold" :style="{ color: $store.getters.getColorPalette().accentCode }">
                                {{ item.key_text }}
                            </v-card-title>
                            <v-card-title class="text-body-2" :style="{ color: $store.getters.getColorPalette().accentCode }">
                                {{ item.entry_type }}
                            </v-card-title>
                        </div>
                        <v-divider ></v-divider>
                        <v-card-subtitle class="text-subtitle-1" :style="{ color: $store.getters.getColorPalette().textColor }">
                            <div v-if="item.entry_type==='image'">
                                <v-img 
                                    :src="item.data" 
                                    :alt="item.key_text" 
                                    max-width="100px"
                                    max-height="100px"
                                    contain/>
                            </div>
                            <div v-else-if="item.entry_type==='check'">
                                <!-- {{ item.data }} -->
                                <v-icon v-if="item.data" :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-check-outline</v-icon>
                                <v-icon v-else :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-close-outline</v-icon>
                            </div>
                            <div v-else>
                                {{ item.data }}
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
            <div v-if="!(checklistFormDetails && checklistFormDetails.length>0) && !loading">
                <v-row>
                    <v-col  cols="12">
                        <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                            <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-row  v-if="!readOnly">
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
                    <!-- <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="moveStage(false)">{{$store.getters.getTextMap().accept}}</v-btn> -->
                    <v-btn class="mx-1" v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor" small @click="moveStage(true)">{{$store.getters.getTextMap().reject}}</v-btn>
                    <v-btn class="mx-1" v-if="!loading"  :color="$store.getters.getColorPalette().downloadbtnColor" small @click="refillRequest=true">{{$store.getters.getTextMap().refill_request}}</v-btn>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="moveStage(false)">{{$store.getters.getTextMap().accept}} And {{$store.getters.getTextMap().move_to_next_stage}}</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="$store.state.user && $store.getters.getAccessByLevels(['logs'])">
            <v-row>
                <v-col>
                    <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().refill_request }}</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form ref="refillForm" v-model="isFormValid">
                        <v-select  
                            v-if="!loading"
                            v-model="req_assignee"
                            :items="userList"
                            :label="$store.getters.getTextMap().assignee"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"            
                            :rules="[rules.required]"
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-form>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
                    <!-- <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="moveStage(false)">{{$store.getters.getTextMap().accept}}</v-btn> -->
                    <v-btn class="mx-1" v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor" small @click="refillRequest=false">{{$store.getters.getTextMap().cancel}}</v-btn>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="refillSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue'
import axios from 'axios';
import FormRules from '@/utillities/FormRules'
export default {
    name:"ChecklistFormDetails",
    props:['checklist','readOnly'],
    components: {
        InfoAlert
    },
    data() { 
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            refillRequest: false,
            isFormValid:false,
            req_assignee: false,
            rules: FormRules.rules,
            checklistFormDetails: [],
            userList:[],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.checklist && this.checklist.checklist_id) {
                this.getMakersList()
                this.getChecklistData()
            }
        },
        getChecklistData() {
            this.loading = true
            this.checklistFormDetails = []
            axios.post(this.$store.state.api + "getChecklistDataById",{checklist_id:this.checklist.checklist_id,timestamp:this.checklist.epoch_timestamp}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    let dbData = [...response.data.data]
                    this.checklistFormDetails=dbData
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        getMakersList() {
            this.loading = true
            this.checklistFormDetails = []
            let op=[]
            axios.get(this.$store.state.api + "checklistsMakersList",{headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    let dbData = [...response.data.data]
                    for (let i of dbData) {
                        op.push({label:i.user_label,value:i.userid})
                    }
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
            this.userList=op
        },
        moveStage(reject) {
            this.loading = true
            axios.post(this.$store.state.api + "moveChecklistToNextStage",{reject ,checklist_id:this.checklist.checklist_id,timestamp:this.checklist.epoch_timestamp }, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.$emit('submitted')
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        refillSubmit() {
            if (this.$refs.refillForm.validate()) {
                this.refillRequest = false
                this.loading = true
                axios.post(this.$store.state.api + "requestForChecklistRefill",{req_assignee:this.req_assignee,checklist_id:this.checklist.checklist_id,timestamp:this.checklist.epoch_timestamp}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.$emit('refillChecklist')
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
            }
        }
    },
    watch: {
        checklist: {
            handler() {
                this.init()
            },
            deep:true
        }
    }
}
</script>