<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>Consumption Based Sheet</v-card-title>
                </v-col>
                <v-col align="right" align-self="center" v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                        <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                        <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        dense
                        outlined
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().time_frame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col v-if="formType==='deviceForm'">
                    <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                </v-col>
                <v-col v-else>
                    <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                </v-col>
                <v-col class="mt-3">
                    <v-select  
                        v-if="!loading"
                        v-model="statisticDetails"
                        :items="statisticOptions"
                        :label="$store.getters.getTextMap().statistics"
                        dense
                        outlined
                        multiple
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
            </v-row>
            <v-row no-gutters dense>
                <v-col cols="auto" class="d-flex">
                    <v-btn class="mt-4 mx-5"  color="gray darken-1" small outlined @click="addMachineGroupToList">{{$store.getters.getTextMap().add_param_to_list}}</v-btn>
                    <v-btn class="mt-4 mx-5" :color="$store.getters.getColorPalette().deletebtnColor" outlined small @click="onProcessReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && paramTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().parameter}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.param_id" v-for="item in paramTableDetails">
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.param_id }}</td>
                                                <td>
                                                <DeleteConfirmation v-on:confirm="deleteFromParameterTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                                    <v-icon  small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                                </DeleteConfirmation>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card>   
                </v-col>
            </v-row>
            </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" outlined small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form> 
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import axios from 'axios'
export default {
    name:'ConsumptionBasedSheet',
    props: {
    "sheet": {
        type: Object,
    },
    },
    components:{
        InfoAlert,
        DeleteConfirmation,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm
    },
    mounted(){
        if(this.sheet && this.sheet.meta){
            this.paramTableDetails = this.sheet.meta.parameter_list
            this.statisticDetails = this.sheet.meta.statistics
            this.form.name = this.sheet.name
            this.api = 'updateSheets'
            this.form.sheet_id = this.sheet.sheet_id
            this.isTranspose=this.sheet.meta.isTranspose
        }
        this.form.sheet_type = 'consumptionBasedSheet'
    },
    data(){ 
        return {
            showDialog:false,
            api:'createSheets',
            selected:[],
            selectedInRow:[],
            selectedInColumn:[],
            rowContains:[],
            columnContains:[],
            statisticDetails:[],
            paramTableDetails:[],
            machine_group:null,
            addTagTypes:false,
            machine:null,
            tagType:null,
            parameter:null,
            parameter_label:null,
            machine_group_label:null,
            tagType_label:null,
            machineOptions:[],
            generalSheetValid:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            formType:'machineForm',
            isTranspose:false,
            timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
            ],
            statisticOptions:[
                {label:'Max', value:'max'},
                {label:'Min', value:'min'},
                {label:'Usage', value:'usage'},
                {label:'Start Reading', value:'start_reading'},
                {label:'End Reading', value:'end_reading'},
            ],
            form:{
                name:null,
                sheet_type:'general',
                meta:{
                    parameter_list:{},
                    timeframe:'mtd',
                    isTranspose:false
                }
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
            ],
            numberRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
            nonNegativeRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
        }
    },
    methods:{
        updateParamList(value){
            if(value && value.parameter && value.parameter.param_id){
                this.parameter=value.parameter.param_id
                this.parameter_label=value.parameter.name
            }
        },
        createTagTypeArray(value){
            this.statisticDetails = value
        },
        close(){
            this.$emit("close")
        },
        addMachineGroupToList(){
            if(!this.paramTableDetails){
                this.paramTableDetails = []
            }
            if(this.parameter){

                this.paramTableDetails.push(
                    {label:this.parameter_label,param_id:this.parameter,statistics:this.statisticDetails}
                )
            }else{
                this.loading=false
                this.info="Please select parameter"
                this.showDismissibleAlert=true
            }
            this.parameter=null
            this.parameter_label=null

        },
        remove(m){
            this.statisticDetails=this.statisticDetails.filter(x=>x.label!=m.label)
        },
        sheetSubmit(){
            this.$refs.generalSheetForm.validate()
            if(this.generalSheetValid && this.paramTableDetails && this.paramTableDetails.length>0){
                this.loading=true
                this.form.meta.parameter_list = this.paramTableDetails
                this.form.meta.isTranspose = this.isTranspose
                let d=Object.assign({}, this.form)
                // d.target=this.emails.replaceAll(' ','').split(',')
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$emit('update')
                            this.$store.dispatch('refreshSheets')
                            this.$refs.generalSheetForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }else{
                this.loading=false
                this.info="Please add parameter"
                this.showDismissibleAlert=true       
            }
        },
        onProcessReset(){
            this.machine_group=null
            this.machine_group_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
        onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
        deleteFromParameterTable(item){
            this.paramTableDetails=this.paramTableDetails.filter(x=>x.param_id!=item.param_id)
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        }
    },
    watch:{
        isTranspose(){
            if(this.isTranspose===null){
                this.isTranspose=false
            }
        }
}

}
</script>