<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().add_stock }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">
                <v-row dense>
                       
                        
    
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.quantity"
                        :counter="15"
                        :rules="[rules.required, rules.noSpace, rules.unum]"
                        :label="$store.getters.getTextMap().quantity"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.code"
                        :counter="15"
                        :label="$store.getters.getTextMap().code"
                        required
                        ></v-text-field>
                    </v-col> -->
                    <v-col v-if="!loading">
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{ $store.getters.getTextMap().add  }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
// import moment from 'moment'
export default {
    name:'AddItemStock',
    props:{
        item_code:{
            type:String,
            required:true
        }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
        }
    },
    mounted(){
        // if(this.quantity){
        //     this.form=Object.assign({},this.quantity)
        // }
    },
    computed:{
        // skuOptions(){
        //     let op=[]
        //     let g=this.$store.state.skus
        //     for(let i of g){
        //         if(this.showSKUID){
        //             op.push( {value:i.sku_id, label: i.sku_id })

        //         }else{

        //             op.push( {value:i.sku_id, label: i.sku_name })
        //         }
        //     }
        //   return op
        // },
    },
    methods:{
        getPayload(){
            // let timestamp;
            // if(this.timeForm.time){
            //     timestamp=Number(moment(this.timeForm.date.toString()+' '+this.timeForm.to_time.toString(),'YYYY-MM-DD HH:mm').format("X"))
                
            // }else{
            //     timestamp=Number(moment(this.timeForm.date.toString(),'YYYY-MM-DD').format("X"))
            // }
            // let payload={
            //     timestamp:timestamp,
            //     // from_time:Number(moment(this.timeForm.to_date.toString()+' '+this.timeForm.from_time.toString(),'YYYY-MM-DD HH:mm').format("X"))

            // }
            this.form['item_code']=this.item_code
            // this.form['from_time']=payload['from_time']

            return this.form

        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                let payload=this.getPayload()
                axios.post(this.$store.state.api+'addItemStock',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('update')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{
    }
}
</script>