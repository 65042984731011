<template>
    <div class="text-center">
      <v-btn    
                :background-color="$store.getters.getColorPalette().background1ColorCode"
                :color="$store.getters.getColorPalette().accent"
                small 
                text
                :dark="$store.getters.getColorPalette().isDark"
                outlined
                @click="dialog=!dialog" >
                Issue Items
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
      
  <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
      <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
      <v-toolbar
          :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().foregroundColorCode"
          >
            <v-btn
              icon
             :dark="$store.getters.getColorPalette().isDark" 
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.getTextMap().issue_items }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
               :dark="$store.getters.getColorPalette().isDark" 
                text
                @click="dialog = false"
              >
              {{ $store.getters.getTextMap().close }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row >
              <v-col cols="12">
                <IssueItemVoucher v-on:close="dialog=false" v-on:success="$emit('success')" :voucher="voucher" />
              </v-col>
            

            </v-row>
          </v-container>
      </v-card>
  </v-dialog>
    </div>
  </template>
  <script>
import IssueItemVoucher from '@/components/crud_components/manufacturing/stores/IssueItemVoucher';
//   import AddGaugeToDashboard from '@/components/crud_components/AddGaugeToDashboard'
//   import AddParamToDashboard from '@/components/crud_components/AddParamToDashboard'
//   import AddMachineToDashboard from '@/components/crud_components/AddMachineToDashboard'
  
  export default {
      name:'IssueStoreVoucherBottomSheet',
      components:{
          IssueItemVoucher,
        //   AddGaugeToDashboard,
        //   AddParamToDashboard,
        //   AddMachineToDashboard
      },
      props:{
      voucher:{
          type:Object,
          required:true
      }
  },
      data(){
          return {
              dialog:false,
              
            //   objectRules:[
            //     v => !!v || this.$store.getters.getTextMap().required,
            //   ],
        }
      },
      
  }
  </script>
  