<template>

  <v-container fluid>
    <v-dialog
    v-model="showDismissibleAlert"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">{{ $store.getters.getTextMap().info }}</v-card-title>

      <v-card-text>
       {{info}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        

        <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
          text
          @click="showDismissibleAlert = false"
        >
        {{ $store.getters.getTextMap().ok }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
      <v-row no-gutters>
          <v-col align-self="center">
              <span :color="$store.getters.getColorPalette().accentCode"> {{ $store.getters.getTextMap().time_of_day_trend}}</span>
          </v-col>
          <v-spacer></v-spacer>
            <v-col align="right" align-self="center" v-if=" $store.state.user ">
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
      </v-row>
      <v-form
  ref="usageForm"
  v-model="valid"
  
>
      <v-row>
          
        <v-col v-if="formType==='deviceForm'">
        <WorkspaceDeviceParamForm :multiple="true" v-on:value="updateParamList"/>
        </v-col>
        <v-col v-else>
              <WorkspaceMachineParamForm :multiple="true" v-on:value="updateParamList"/>
        </v-col>
          <!-- <v-col>
            <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-select  :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
            v-model="form.workspace"
        :items="workspaceOptions"
        label="Workspace"
        outlined
        item-text="label"
        item-value="value"
        required
          :rules="objectRules"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      ></v-select>
          </v-col>
          <v-col>
            <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-select  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
            v-model="form.device"
        :items="deviceOptions"
        label="Device"
        outlined
        item-text="label"
        item-value="value"
        required
          :rules="objectRules"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      ></v-select>
          </v-col>
          <v-col>
            <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-select :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
            v-model="form.parameters"
        :items="paramOptions"
        label="Device Parameter"
        outlined
        multiple
        clearable
        item-text="label"
        item-value="value"
        required
          :rules="objectRules"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      ></v-select>
          </v-col> -->
      </v-row>
      <v-row>
        
          
      </v-row>
      <v-row no-gutters>
        
        <v-col>
          <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-menu :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.date"
          :label="$store.getters.getTextMap().date"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="objectRules"
        ></v-text-field>
      </template>
      <v-date-picker :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.date"
        
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
          </v-col>
          {{ $store.getters.getTextMap().from }}
              <v-col align-self="center">
              <TimePicker v-model="fromTime"/>
            </v-col>
            {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 
<!-- <v-col>
  <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
  <v-menu  :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading"
      ref="menufrom"
      v-model="menu3"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="fromTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field  :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
          v-model="fromTime"
          label="From"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="objectRules"
        ></v-text-field>
      </template>
      <v-time-picker :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
        v-if="menu3"
        v-model="fromTime"
        full-width
        format="24hr"
        @click:minute="$refs.menufrom.save(fromTime)"
      ></v-time-picker>
    </v-menu>
          </v-col>

          <v-col>
              <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
<v-menu :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
      ref="menuto"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="toTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field  :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
          v-model="toTime"
          label="To"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="objectRules"
        ></v-text-field>
      </template>
      <v-time-picker  :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
        v-if="menu2"
        v-model="toTime"
        full-width
        format="24hr"
        @click:minute="$refs.menuto.save(toTime)"
      ></v-time-picker>
    </v-menu>
          </v-col> -->
          

          <v-col align="center" align-self="center">
            <v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-btn
              v-if="!loading"
         :color="$store.getters.getColorPalette().submitbtnColor"
         
          small
          @click="onSubmit"
        > {{ $store.getters.getTextMap().generate_report }}
        </v-btn>
        
          </v-col>
          
      </v-row>
      </v-form>
      <!-- <v-row no-gutters>
        <v-col>
          <v-col>
              
  <v-chip
    class="ma-2"
    close
    small
    color="teal"
    text-color="white"
    @click:close="remove(param)" 
    v-bind:key="param.parameter" v-for="param in form.parameters"
  >
   {{param.name}}
  </v-chip>
          </v-col>
        </v-col>
      </v-row>
           -->
      <v-row v-if="graphData.length>0 " no-gutters>
          <v-col    :color="$store.getters.getColorPalette().accentCode">
              <strong>{{ $store.getters.getTextMap().comparison }}</strong>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col>
            
               <div ref="graph"></div>
          </v-col>
      </v-row>
      
      <v-divider></v-divider>
  </v-container>
</template>
<script>
//import DualAxisLineGraphByTimestamp from '@/components/graphs/DualAxisLineGraphByTimestamp';
//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import TimePicker from '@/components/inputs/TimePicker'
export default {
  name:'ParameterRelationshipPanel',
  components:{
    WorkspaceDeviceParamForm,
    WorkspaceMachineParamForm,
    TimePicker
  },
  data(){
      return {
          form:{
              date:null,
              //device:null,
              //device2:null,
              parameters:null,
              //parameter2:null
          },
          formType:'machineForm',
          //date: new Date().toISOString().substr(0, 10),
          report:{
              date:null,
              parameter:null,
              //parameter2:null
              },
              valid:false,
              menu:false,
              menu2:false,
              menu3:false,
              toTime:null,
              layout:{
            //title:'Compare',
            showlegend:true,
            font:{
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                  plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                  paper_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                  
                  autosize: true,
                  xaxis: {
                      tickangle: -12,
                      zeroline: false,
                      showgrid: true,
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode
                    },       
                    yaxis: {
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode
                    },
                    uniformtext: {
                      "mode": "hide",
                      "minsize": 1
                    }
          },
          fromTime:null,
          graphData:[],
          graphData2:[],
          showDismissibleAlert:false,
          loading:false,
          info:'',
           objectRules:[
      v => !!v || 'Required',
    ],

      }
  },
  mounted(){

  },
  computed: {
      workspaceOptions(){
          let op=[]
        
            let g=this.$store.state.workspaces
            for(let i of g){
                //op.push({value:i, text: i.name })
                op.push( {value:i, label: i.name })
              }
        return op
      },
      dateRangeText () {
        if(this.form.date==null){
          return null
        }
      return this.form.date//.join(' ~ ')
    },
    timeUnit(){
      if(this.toTimeStamp && this.fromTimeStamp && this.toTimeStamp - this.fromTimeStamp>60*60*4 ){
        return "hour"
      }else{
        return 'minute'
      }
    },
    fromTimeStamp(){
      if(this.form.date){
        
          let dt=this.form.date.toString()
          if(this.fromTime){
            dt+=' '+this.fromTime.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').format("X"));
        
      }else{
        return null
      }
    },
    toTimeStamp(){
      if(this.form.date){
        
          let dt=this.form.date.toString()
          if(this.toTime){
            dt+=' '+this.toTime.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').format("X"));
        
      }else{
        return null
      }
    },
    label1(){
        if(this.report.parameter){
          let parameter=this.$store.getters.getParameterById(this.report.parameter)
          if(parameter.name){
            return parameter.name
          }else{
            return this.report.parameter
          }
        }else{
          return ''
        }
      },
      
      unit(){
        if(this.report.parameter){
          let parameter=this.$store.getters.getParameterById(this.report.parameter)
          if(parameter.unit){
            return parameter.unit
          }else{
            return null
          }
        }else{
          return ''
        }
      },
      deviceOptions(){
           
          let op=[]
        
        if(this.form.workspace && this.form.workspace.workspace_id ){
            let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
            for(let i of g){
                
                if(i && i.name ){
                  
                  op.push({value:i, label: i.name })
                }else{
                  op.push({value:i, label: i.snode_id })
                }
                
            
        }
        }
        return op
      },
      paramOptions(){
           
          let op=[]
        
        if(this.form.device && this.form.device.device_id ){
            let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
            for(let i of g){
                
                if(i && i.name ){
                  
                  op.push({value:i, label: i.name })
                }else{
                  op.push({value:i, label: i.parameter })
                }
                
            
        }
        }
        return op
      },
      
      
  },
  methods: {
    updateParamList(value){
          this.form.parameters=value.parameter
      },
    remove(param){
      this.form.parameters=this.form.parameters.filter(x=>x.parameter!=param.parameter)
    },
    onSubmit(){
          this.$refs.usageForm.validate()
          if(this.valid){
            this.loading=true
            this.graphData=[]
            //let fromTimeStamp=this.fromTimeStamp
            //let toTimeStamp=this.toTimeStamp
            let payload={
                parameter:this.form.parameters,
    from_timestamp:this.fromTimeStamp,
    to_timestamp:this.toTimeStamp
  }
            for(let p of this.form.parameters){
              payload['parameter']=p['param_id']
              // payload['parameter']=p['parameter']
              // let name=this.$store.getters.getDeviceById(p['device_id'])['name'] +" - " +p['name']
              let name=p['name']
              //console.log(fromTimeStamp)

              axios.post(this.$store.state.api+'getNormalisedDataByParameterBetweenTimestamp',payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
              }})
              .then(response=>{
                console.log("Param data received")
                //console.log(response.data.monthlyData)
                //context.state.monthlyData=response.data.monthlyData
          //console.log(response.data.data)
          //console.log('something')


          if(response.data.status==='success'){
          let data={
            x: [],
            y: [],
            name:name,
            type: 'scatter'
          }
          for (let i of response.data.data){
            
              data['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
              data['y'].push(Number(i.data))
          }
          this.graphData.push(data)
          
          Plotly.newPlot(this.$refs.graph, this.graphData, this.layout,{displaylogo: false});
                //console.log(response.data.data)
                this.report['parameter']=this.form.parameter
                //this.report['parameter2']=this.form.parameter2
                this.report['date']=this.form.date

              }else{
                this.info = response.data.msg;
                //this.info = d;
                      this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading=false
              }
                this.loading=false

              })
              .catch(err=>{
              this.showDismissibleAlert=true
                      this.info=err
                      this.loading=false
                      })

            }
            
  
 
          }
      }
  
  },
}
</script>