<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
        :dark="$store.getters.getColorPalette().isDark"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="sheets"
      item-key="sheet_id"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
    :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
       :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{ $store.getters.getTextMap().sheets }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <CreateSheetsSheet v-on:update="getSheets" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
        <v-icon small :color="$store.getters.getColorPalette().accentCode" @click="getSheets">mdi-refresh</v-icon>
      </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <Sheet :sheet="item" v-on:deleteSheets="getSheets"/>
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</template>

<script>
import Sheet from '@/components/items/derived/Sheet'
import CreateSheetsSheet from '@/components/modals/derived/CreateSheetsSheet'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'SheetsTable',
    components:{
        Sheet,
        CreateSheetsSheet,
        InfoAlert
    },
    mounted(){
      this.getSheets()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            sheets:[],
            expanded: [],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'sheet_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Type', value: 'sheet_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    methods:{
        getSheets(){
        this.loading=true
        //console.log('getParamEquations')
            axios.get(this.$store.state.api+'sheets',{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.sheets=response.data.data
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })


      }
    },
    computed:{
        calculatedParams(){
            return this.$store.state.calculatedParams;
        }
    }
}
</script>
<style scoped>


</style>
