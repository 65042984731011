<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col cols="12">
                <ESGScopeConversionTableTemp />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ESGScopeConversionTableTemp from '@/components/lists/derived/ESGScopeConversionTableTemp'
export default {
    name:'ESGScopeConversionPageTemp',
    components:{
        ESGScopeConversionTableTemp
    }
}
</script>