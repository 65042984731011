<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid
        :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <v-form ref="taskForm" v-model="valid">
            <v-row>
                <v-col cols="9">
                    <v-card flat outlined class="widget-card-container pa-3"
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                        <!-- <v-subheader>Activity: {{ activity.name }}</v-subheader> -->
                        <v-divider></v-divider>
                        <v-card flat outlined class="widgetype-card-container pa-2"
                            :dark="$store.getters.getColorPalette().isDark"
                            :style="{'border': '0px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                            <v-row>
                                <v-col align-self="center">
                                    <v-autocomplete v-if="!loading" v-model="activity_id" :items="activitiesOptions"
                                        @change="onActivityChange" :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}" outlined dense
                                        :rules="objectRules" :label="$store.getters.getTextMap().activities"
                                        item-text="label" item-value="value"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col align-self="center">
                                    <v-text-field dense outlined v-if="!loading" v-model="form.name" :counter="45"
                                        :rules="nameRules" :label="$store.getters.getTextMap().task_name"
                                        required></v-text-field>
                                </v-col>
                                <v-col align-self="center">
                                    <v-text-field dense outlined v-if="!loading" v-model="form.msg" :counter="200"
                                        :label="$store.getters.getTextMap().task_details" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col align="center" align-self="center">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-menu :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1"
                                        v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                                color="cyan lighten-1" v-model="from_date"
                                                :label="$store.getters.getTextMap().start_date"
                                                prepend-icon="mdi-calendar" readonly outlined clearable dense
                                                v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                                        </template>
                                        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                                            color="cyan lighten-1" v-model="from_date"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                {{ $store.getters.getTextMap().time }}
                                <v-col align-self="center">
                                    <TimePicker v-model="fromTime" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col align="center" align-self="center">
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-menu :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1"
                                        v-if="!loading" v-model="menu1" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                                color="cyan lighten-1" v-model="to_date"
                                                :label="$store.getters.getTextMap().end_date"
                                                prepend-icon="mdi-calendar" readonly outlined clearable dense
                                                v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                                        </template>
                                        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                                            color="cyan lighten-1" v-model="to_date"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                {{ $store.getters.getTextMap().time }}
                                <v-col align-self="center">
                                    <TimePicker v-model="toTime" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <WorkspaceMachineForm :multiple="false" v-on:value="updateAssetId" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card flat outlined class=" widget-card-container pa-3"
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                        <v-row>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-autocomplete v-if="!loading" v-model="form.assignee" :items="users"
                                    :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}" outlined dense
                                    :rules="objectRules" :label="$store.getters.getTextMap().assignee" item-text="label"
                                    item-value="value"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-autocomplete v-if="!loading" v-model="form.manager" :items="users"
                                    :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}" outlined dense
                                    :rules="objectRules" :label="$store.getters.getTextMap().manager"
                                    item-text="label" item-value="value"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="left">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" small filled
                        @click="onSubmit"> {{$store.getters.getTextMap().submit}} </v-btn>
                    <v-btn style="margin-left:20px;" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{ $store.getters.getTextMap().cancel }}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import moment from 'moment'
import TimePicker from '@/components/inputs/TimePicker'
export default {
    name:'createTask',
    props:['activity','task'],
    components:{
        WorkspaceMachineForm,
        TimePicker
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'createActivity',
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            from_date:null,
            to_date:null,
            fromTime:null,
            toTime:null,
            activity_id:null,
            form:{
                assignee:null,
                manager:null,
                asset_id:null,
                start_time:null,
                end_time:null
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 45 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 200 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    mounted(){
        if(this.task && this.task.task_id){
            this.form=Object.assign({},this.task)
            this.activity_id=this.task.activity_id
            this.from_date=moment(this.task.start_time).format('YYYY-MM-DD')
            this.to_date=moment(this.task.end_time).format('YYYY-MM-DD')
            this.fromTime=moment(this.task.start_time).format('HH:mm')
            this.toTime=moment(this.task.end_time).format('HH:mm')
            this.edit=true
            this.api='updateTask'
        }else{
            this.edit=false
            this.api='createTask'
        }
    },
    computed:{
        users(){
            let op=[]
            let g=this.$store.state.UAMUsers
            for(let i of g){
                op.push( {value:i.userid, label: i.name })
            }
            return op
        },
        activitiesOptions(){
            let op=[]
            let g=this.$store.state.activities
            for(let i of g){
                op.push( {value:i.activity_id, label: i.name })
            }
            return op
        },
        fromTimeStamp(){
            if(this.from_date){
              
                let dt=this.from_date.toString()
                if(this.fromTime){
                  dt+=' '+this.fromTime.toString()
                  return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
              
            }else{
              return null
            }
          },
          toTimeStamp(){
            if(this.to_date){
              
                let dt=this.to_date.toString()
                if(this.toTime){
                  dt+=' '+this.toTime.toString()
                  return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
            }else{
              return null
            }
          },
    },
    methods:{
 onActivityChange() {
        if (this.activity_id) {
              axios.post(this.$store.state.api+'getActivityById',{activity_id:this.activity_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
            .then(response => {
                if (response.data.status === 'success') {
                    this.form.assignee = response.data.activity.default_assignee; 
                    this.form.manager = response.data.activity.default_manager; 
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
            })
            .catch(err => {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            });
        }
    },
        updateAssetId(value){
            this.form.asset_id=value.machine.machine_id
        },
        onSubmit(){
            this.$refs.taskForm.validate()
            if(this.valid){
                this.loading=true
                this.form.activity_id=this.activity_id
                this.form.start_time=this.fromTimeStamp,
                this.form.end_time=this.toTimeStamp
                axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshTasks')
                        this.$emit('close')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>