<template>
    <v-container fluid>
        <v-row dense>
            <v-col>
                <v-simple-table height="150px">
                <template :dark="$store.getters.getColorPalette().isDark"  v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().name}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().item_code}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material_group}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material_desc}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().uom}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in items"
                            :key="item.item_code"
                            >
                            <td>{{ item.item_name }}</td>
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.material }}</td>
                            <td>{{ item.material_group }}</td>
                            <td>{{ item.material_desc }}</td>
                            <td>{{ item.uom }}</td>
                            <td>
                                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="()=>send(item)" >Add</v-btn>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                
                
                      </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import InfoAlert from '@/components/InfoAlert'
export default {
name:'ItemCodeSelectorTable',
props:['items'],
components:{
    // InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        workspace:null,
        device:null,
        parameter:null,
        form:{},
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
mounted(){
    if(this.exsistingParameter){
        this.parameter=this.exsistingParameter
    }
    if(this.exsistingWorkspace){
        this.workspace=this.exsistingWorkspace
    }
    if(this.exsistingDevice){
        this.device=this.exsistingDevice
    }
},
computed:{
    
},
methods:{
    // remove(param){
    //   this.parameter=this.parameter.filter(x=>x.param_id!=param.param_id)
    // },
    send(item){
        
        this.$emit('value',item)
    }
},
watch:{
    // parameter:{
    //     handler(){
    //         this.send()
    //     },
    //     deep:true
    // }
}
}
</script>