<template>
    <v-container  fluid  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="usageForm" v-model="valid">
            <v-row class="mt-2">
                <v-col cols="auto" class="d-flex">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu
                        v-if="!loading"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :dark="$store.getters.getColorPalette().isDark"
                                v-model="dateRangeText"
                                :label="$store.getters.getTextMap().dates"
                                dense
                                outlined
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.date" range></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().accentCode" small outlined text @click="onSubmit">
                        {{$store.getters.getTextMap().view_report}}

                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                            mdi-chart-box-multiple-outline
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-chip v-if="$store.state.user && $store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                        <v-avatar
                            :dark="$store.getters.getColorPalette().isDark"
                            :style="{color: $store.getters.getColorPalette().background2ColorCode,}"
                            :class="$store.getters.getColorPalette().foregroundColorName +'mx-1 px-0'"
                        >
                            <v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-file-chart-outline</v-icon>
                        </v-avatar
                    >{{report.report_id}}</v-chip>
                </v-col>
            </v-row>
        </v-form>
        <v-row no-gutters>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="reportData"
                    item-key="date"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-class="font-weight-black text-center"
                >
                    <template v-slot:top>
                        <v-toolbar flat :dark="$store.getters.getColorPalette().isDark">
                            <v-toolbar-title>{{$store.getters.getTextMap().report_list}}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.timestamp="{ item }">{{formatTimestamp(item.date)}}</template>
                    <template v-slot:item.report_loc="{ item }">
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="downloadReport(item)">
                            {{$store.getters.getTextMap().download_report}}

                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import fileDownload from 'js-file-download'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'ExcelTemplateBasedReportDetails',
    props:['report'],
    components:{
        InfoAlert
    },
    data(){
        return {
            edit:false,
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            search:null,
            menu:null,
            reportData:[],
            objectRules:[
                v => !!v || 'Required',
            ],
            expanded:[],
            headers:[
                    {
                        text: 'Date (DD-MM-YYYY)',
                        align: 'start',
                        filterable: false,
                        value: 'date',                        
                    },
                    // { text: 'Report', value: 'report_id', }, 
                    { text: 'Actions', value: 'report_loc', }, 
            ],
            form:{
                date:[]
            }    
        }
    },
    mounted() {
        this.form.date=[]
        this.form.date[0]=moment().subtract(31,'d').format('YYYY-MM-DD')
        this.form.date[1]=moment().subtract(1,'d').format('YYYY-MM-DD')
        this.onSubmit()
    },
    computed:{
        dateRangeText () {
            if(this.form.date==null){
                return null
            }
            return this.form.date.join(' - ')
        },
        fromDate(){
        if(this.form.date){
            if(this.form.date.length>1 ){
                return this.form.date[0].toString();
            }else{
                return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format('YYYY-MM-DD'));
            }
        }else{
          return null
        }
      },
      toDate(){
        if(this.form.date){
            if(this.form.date.length>1 ){
                return this.form.date[1].toString()
            }else{
                return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format('YYYY-MM-DD'));
            }
        }else{
          return null
        }
      },
    },
    methods:{
      formatTimestamp(item){
        return moment.unix(item).format('DD-MM-YYYY')
      },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                let payload={
                    report_id:this.report.report_id,
                    from_date:this.fromDate,
                    to_date:this.toDate
                }
                let endpoint='getExcelTemplateBasedReportBetweenTimestamp'
                axios.post(this.$store.state.api+endpoint,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.reportData=response.data.data
                        this.reportData.sort(function(a, b) {
                            return new Date(b.date) - new Date(a.date);
                        })
                        for(let i of this.reportData){
                            i['date']=moment(i['date']).format("DD-MM-YYYY")
                        }
                        if(this.reportData.length==0){
                            this.info = "No data to show";
                            this.showDismissibleAlert = true;
                        }
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                    }
                    this.loading=false
                }).catch(err=>{
                    this.showDismissibleAlert=true
                    this.info=err
                    this.loading=false
                })
            }
        },
        downloadReport(item){
            if(item){
                this.loading=true
                axios.post(this.$store.state.api+'downloadExcelTemplateBasedReport',{statement_id:item.report_loc},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                .then((response) => {
                    this.loading=false
                    if(response.data.status && response.data.status=='error'){
                        this.showDismissibleAlert=true
                        this.info=response.data.msg
                    }else{
                        fileDownload(response.data,item.report_loc)
                    }
                }).catch(err=>{
                    this.loading=false
                    this.showDismissibleAlert=true
                    this.info=err
                });
            }else{
                this.showDismissibleAlert=!this.showDismissibleAlert
                this.info='Report file name not specified'
            }
        },
    }
}
</script>