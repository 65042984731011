<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
            ref="paramForm"
            v-model="valid"           
        >
        <v-row>
            <v-col><v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                 :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.label"
      :counter="35"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field></v-col>
    <!---->
    <v-col><v-text-field   :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.unit"
      :counter="8"
      
      :label="$store.getters.getTextMap().unit"
      
    ></v-text-field></v-col>
        </v-row>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
        <v-row>
            <v-col >
                <v-row>
                <v-col>
                <strong :style="{'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().positive_params}}</strong>
                <span>
                    <v-icon small @click="showPositiveParamEdit=!showPositiveParamEdit" :color="$store.getters.getColorPalette().accentCode">mdi-pencil</v-icon>
                </span>
                <GetParamModal :multiple="true" :show="showPositiveParamEdit" v-on:value="updatePostive" v-on:close="showPositiveParamEdit=false" />
            </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col v-bind:key="param" v-for="param in form.positive">
                    <v-chip
                        class="ma-2"
                        color="teal"
                        text-color="white"
                        close
                        small
                        @click:close="removeFromPositive(param)"
                        >
                        {{$store.getters.getNameByParamId(param)}}
                      </v-chip>
                </v-col>
            </v-row>
            </v-col>
            <v-divider vertical :color="$store.getters.getColorPalette().accentCode"></v-divider>
            <v-col>
                <v-row>
                <v-col>
                <strong :style="{'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().params_to_be_substracted}}</strong>
            
                <span >
                    <v-icon small :color="$store.getters.getColorPalette().accentCode" @click="showNegativeParamEdit=!showNegativeParamEdit">mdi-pencil</v-icon>
                </span>
                <GetParamModal :multiple="true" :show="showNegativeParamEdit" v-on:value="updateNegative" v-on:close="showNegativeParamEdit=false" />
                </v-col>
                </v-row>
                <v-row dense no-gutters>
                    <v-col v-bind:key="param" v-for="param in form.negative">
                    <v-chip
                        class="ma-2"
                        color="teal"
                        text-color="white"
                        close
                        small
                        @click:close="removeFromNegative(param)"
                        >
                        {{$store.getters.getNameByParamId(param)}}
                      </v-chip>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
        <br>
        <v-row >
            
            <v-spacer></v-spacer>
            <v-col cols="2" align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                    :color="$store.getters.getColorPalette().accentCode"
                        outlined
                        @click="onSubmit"
                    >
                      {{  $store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col cols="2" align="right">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                        :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{  $store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
import GetParamModal from '@/components/modals/GetParamModal'

export default {
    name:'CreateParamEquation',
    props:['paramEquation'],
    components:{
        InfoAlert,
        GetParamModal
    },
    mounted(){
        this.init()
    },
    data(){
        return {
            valid:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            workspace:null,
            dev:null,
            param_type:null,
            initFlag:false,
            mode:'create',
            showPositiveParamEdit:false,
            showNegativeParamEdit:false,
            form:{
                name:null,
                label:null,
                positive:[],
                //negative:[],
                correction_operator:null,
                correction_operand:null,
            },
            interOperators:[
                {label:'SUM',value:'+'},
                {label:'PRODUCT',value:'*'}
            ],
            operators:EntureStaticLists.operators,
            param_types:EntureStaticLists.paramTypes,
            objectRules:FormRules.objectRules,
            nameRules: [FormRules.rules.required, FormRules.rules.nonZeroLengthDynamic(35)],
            numberRules: FormRules.numberRules,
            nonNegativeRules:FormRules.nonNegativeIntegerRules,
            listRules:FormRules.listRules,
        }
    },
    computed:{
        
    },
    methods:{
        init(){
            if(this.paramEquation){
            let d=Object.assign({},this.paramEquation)
            this.mode='edit'
            this.initFlag=true
            
            this.form=d
            
        }
        },
        updatePostive(params){
            this.form.positive=params
            this.showPositiveParamEdit=false
        },
        updateNegative(params){
            this.form.negative=params
            this.showNegativeParamEdit=false
        },
        removeFromPositive(param){
            this.form.positive=this.form.positive.filter(data=>{ return data != param })
        },
        removeFromNegative(param){
            this.form.negative=this.form.negative.filter(data=>{ return data != param })
        },
        onSubmit(){
            this.$refs.paramForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            if(!(this.form.positive && this.form.positive.length>0)){
                this.info="Atleast one positive param must be present"
                this.showDismissibleAlert=true
                this.loading=false
                return
            }
            let d=Object.assign({}, this.form)
            let api='createParamEquation'
            if(this.mode && this.mode=='edit'){
              api='updateParamEquation'
            }
            axios.post(this.$store.state.api+api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
            this.$emit('update')
         //this.$store.dispatch('refreshParamEquation')
          //console.log('emitted')
          this.loading=false
        //   this.close()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }
        },
        close(){
            this.$emit('close')
            this.dialog=false
            this.loading=false
        }
    },
    watch:{
        paramEquation: {
      handler() {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.init()
      },
      deep: true
    }
  
    }
}
</script>
