<template>
 
 <v-container fluid>
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
      <v-form
    ref="groupForm"
    v-model="valid"
  >
      <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-card-title class="headline">
         {{ $store.getters.getTextMap().edit}}
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
           



                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="form.name"
      :counter="32"
      :rules="nameRules"
      :label=" $store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
    v-if="!loading"
          v-model="form.byte_format"
          :items="byteFormat"
          
          :label=" $store.getters.getTextMap().byte_format"
         :rules="selectRules"
         required
       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
        ></v-select>
                    </v-col>
 <v-col>
   <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="5"
                        
                        :label=" $store.getters.getTextMap().unit"
                        required>

                        </v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.correction_factor"
                        :counter="12"
                        :rules="floatRules"
                        :label=" $store.getters.getTextMap().correction_factor"
                        required>
    
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    
                   
                    

                     <v-col>
                       <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.command"
          :items="commands"
          item-text="label"
          item-value="value"
          :label=" $store.getters.getTextMap().command"
         :rules="selectRules"
         required
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.address"
                        :counter="6"
                        :rules="[rules.required,rules.unum,rules.noSpace,rules.integ,rules.size(6)]"
                        :label=" $store.getters.getTextMap().address"

                        required>
    
                        </v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.ofst"
                        :counter="2"
                        :rules="[rules.required,rules.unum,rules.noSpace,rules.integ,rules.size(2)]"
                        :label=" $store.getters.getTextMap().offset"

                        required>
    
                        </v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.entropy"
                        :counter="6"
                        :rules="floatRules"
                        :label=" $store.getters.getTextMap().entropy"
                        required>

                        </v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.val_type"
          :items="valTypes"
          item-text="label"
          item-value="value"
          :label=" $store.getters.getTextMap().value_type"
         :rules="selectRules"
         required
    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                </v-row>
                
                <v-row>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_processing"
         :rules="selectRules"
         required
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                    <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
      fontSize="1.25 rem"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.data_type"
          :items="dataTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_type"
         :rules="selectRules"
         required
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.register_size"
          :items="registerSize"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().register_size"
         :rules="selectRules"
         required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox
          v-if="!loading"
      v-model="form.engineer_access_only"
      :label="$store.getters.getTextMap().restrict_user_access"
    ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
          v-if="!loading"
      v-model="form.aliasing"
      :label="$store.getters.getTextMap().aliasing"
    ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
          v-if="!loading"
      v-model="form.rem_zero"
      :label="$store.getters.getTextMap().no_zero_read"
    ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col><v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.entropy_limiter"
      :label="$store.getters.getTextMap().limit_entropy"
    ></v-checkbox>
    </v-col>
    <v-col>
      <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_limiter"
      v-model="form.entropy_lim_val"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().limiter_value"
      required
      ></v-text-field>
    </v-col>
    <v-col><v-checkbox
:dark="$store.getters.getColorPalette().isDark"
:color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
v-model="form.entropy_threshold"
:label="$store.getters.getTextMap().entropy_threshold"
></v-checkbox>
</v-col>
    <v-col>
      <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_threshold"
      v-model="form.entropy_threshold_val"
      :counter="5"
      :rules="[rules.required, rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().threshold_value"
      required
    ></v-text-field>
  </v-col>
                </v-row>
 




        </v-card-text>
        <v-card-actions>
          
          
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
       
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{ $store.getters.getTextMap().cancel}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      
      </v-form>
</v-container>
      
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
import FormRules from '@/utillities/FormRules'

  export default {
      name:'EditModbusParameter',
      props:['parameter'],
      components:{
        InfoAlert
      },
    data () {
      return {
        dialog: false,
        valid:false,
        info:'',
        showDismissibleAlert:false,
        loading:false,
        byteFormat:['bigEndian','littleEndian',"midLittle",'midBig'],
            latency:[
                {label:'5 minutes',value:300},
                {label:'15 minutes',value:900},
                {label:'Half hourly',value:1800},
                {label:'Hourly',value:3600},
                {label:'Quarter Day',value:21600},
                {label:'Half Day',value:43200},
                {label:'Daily',value:86400}                
            ],
            commands:[
                {label:'Read Input Register',value:4},
                {label:'Read Holding Register',value:3},
                {label:'Read Coil',value:1}
            ],
            processing:[
              {label:'Average',value:'mean'},
                {label:'Difference',value:'difference'}
            ],
        registerSize:[
              {label:'short(16 bit)',value:'short'},
              {label:'long(32 bit)',value:'long'},
              {label:'long(64 bit)',value:'longlong'},
            ],
            valTypes:[
              {label:'Unsigned Integer',value:'uint'},
              {label:'Signed Integer',value:'int'},
              {label:'float',value:'float'}
            ],
            rules:FormRules.rules,
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 32 && v.length > 0) || 'Name must be less than 33 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.toString().length <= 5 && v.toString().length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v!=0 )|| 'It cannot be zero',
          v => (v && v.toString().length <= 12 && v.toString().length > 0) || 'It must be less than 12 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
        form:{
                byte_format:null,
                correction_factor:null,
                name:null,
                entropy:null,
                latency:null,
                unit:null,
                processing:null,
                engineer_access_only:false,
                aliasing:false
            }
      }
    },
    mounted(){
        this.init()

    },
    computed:{
      dataTypes(){
        let op=[]
        if(this.$store.state.dataTypes){
          for(let i of this.$store.state.dataTypes){
            op.push({
              label:i.type,
              value:i.id
            })
          }
        }
        return op
      }
    },
    methods:{
        init(){
            this.form=Object.assign({}, this.parameter)
        },
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.parameter=this.parameter.parameter
                
                axios.post(this.$store.state.api+'updateParameter',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          
        this.$store.dispatch('refreshParameters')
        this.onReset()
            this.dialog=false
            this.$emit('update')
            this.$emit('close')
    }else{
        this.info=response.data.msg
        this.loading=false
        this.showDismissibleAlert=true
      //console.log(response.data.msg)
    }})
    .catch(err=>{//console.log(err)
    this.info=err
    this.loading=false});
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
    },
    watch:{
        parameter:{
            handler(){
                
                this.init()
            },
            deep:true
        },
    }
  }
</script>