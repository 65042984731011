<template>
    <div fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <SafetyInspectionPanel />
    </div>
</template>
<script>
import SafetyInspectionPanel from '@/components/panels/manufacturing/SafetyInspectionPanel'
export default {
    name:'SafetyInspectionPage',
    components:{
        SafetyInspectionPanel
    }
}
</script>