<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && area && area.area_id">
            <v-col cols="auto" class="d-flex">
                <CreateAreaModal :area="area" />
                <CreateAQModal  class="mx-1" :area="area"  v-on:success="getAirQuality" v-on:update="getAirQuality"/>
                <CreateAQModal v-if="air_quality && air_quality.length>0" :area="area" :air_quality="air_quality" v-on:success="getAirQuality" v-on:update="getAirQuality"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="getAirQuality">
                    {{$store.getters.getTextMap().refresh}}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small>
                        mdi-refresh
                    </v-icon>
                </v-btn>
                <DeleteConfirmation class="mx-1" v-on:confirm="deleteArea()" title="Delete Confirmation" description="Are you sure you want to delete this Premise?">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>
                       {{ $store.getters.getTextMap().delete_premise_area }}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                    </v-btn>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="air_quality && air_quality.length>0">
            <v-col>
                <AirQualityPanel :area="area" :air_quality="air_quality"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DeleteConfirmation from '@/components/DeleteConfirmation'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import CreateAreaModal from '@/components/modals/application/CreateAreaModal.vue'
import CreateAQModal from '@/components/modals/application/airquality/CreateAQModal.vue'
import AirQualityPanel from '@/components/panels/application/airquality/AirQualityPanel.vue'
export default {
    name:'AirQualityPage',
    components:{
        InfoAlert,
        DeleteConfirmation,
        CreateAreaModal,
        CreateAQModal,
        AirQualityPanel
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            area_id:null,
            area:null,
            air_quality:null
        }
    },
    mounted(){
        this.area_id=this.$route.params.id
        if(this.area_id){
            this.getArea()
            this.getAirQuality()
        }
    },
    computed:{
        
    },
    methods:{
        getArea(){
            this.area=null
            this.loading=true
            axios.post(this.$store.state.api+'getAreaById',{area_id:this.area_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.area=response.data.data
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        getAirQuality(){
            this.air_quality=null
            this.loading=true
            axios.post(this.$store.state.api+'getAQByAreaById',{area_id:this.area_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.air_quality=response.data.data
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteArea(){
            let payload={area_id:this.area_id}
            this.loading=true
            axios.post(this.$store.state.api+'deleteArea',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshAreas')
                if (this.$store.state.areas.length === 0) {
                    this.$router.push("/airquality");
                } else {
                    this.$router.push("/airquality/" + this.$store.state.areas[0].area_id);
                }
            }
            })
            .catch(error=>{
                    this.loading=false
                    this.info=error
                    this.showDismissibleAlert=true
            });
        }
    },
    watch:{
        '$route.params.id':{
            handler(){
                this.area_id=this.$route.params.id
                this.getArea()
                this.getAirQuality()
            },
            deep:true
        },
    }
}
</script>