<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <h2>{{report && report.report_id?$store.getters.getTextMap().update_excel_template_based_report:$store.getters.getTextMap().create_excel_template_based_report}}</h2>
            </v-col>
            <v-col v-if="report && report.report_id" align="right">
                <v-chip v-if="$store.state.user && $store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{color: $store.getters.getColorPalette().background2ColorCode,}"
                        :class="$store.getters.getColorPalette().foregroundColorName +'mx-1 px-0'"
                    >
                        <v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-file-chart-outline</v-icon>
                    </v-avatar
                >{{report.report_id}}</v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form ref="reportForm" v-model="reportValid">
                    <v-card  v-if="!report_id" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3">
                        <v-row>
                            <v-col>
                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="reportForm.report_name"
                                    :counter="30"
                                    outlined
                                    dense
                                    :rules="nameRules"
                                    :label="$store.getters.getTextMap().report_name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="reportForm.notification_group"
                                    outlined
                                    dense
                                    :items="notificationGroupList"
                                    :label="$store.getters.getTextMap().notification_groups"
                                    item-text="label"
                                    item-value="value"
                                    card
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-file-input
                                    v-model="file"
                                    show-size
                                    accept="xlsx"
                                    dense
                                    outlined

                                    :label="$store.getters.getTextMap().upload_excel_template_xlsx"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <v-spacer></v-spacer>
                            <v-col  cols="auto" class="d-flex" align="right" align-self=center>
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="reportSubmit">{{report_id?$store.getters.getTextMap().update_details:$store.getters.getTextMap().create_report}}
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-file-chart-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card  v-else :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3">
                        <v-row v-if="report && report.report_id && $store.state.user">
                            <v-col>
                                <v-card-title>{{$store.getters.getTextMap().edit_report_details}}</v-card-title>
                            </v-col>
                            <v-spacer/>
                            <v-col cols="auto">
                                <v-switch
                                    small
                                    inset
                                    style="transform: scale(0.8);"
                                    color="success"
                                    v-model="reportForm.disable"
                                    :label="$store.getters.getTextMap().disable"
                                ></v-switch>
                            </v-col>
                            <v-col v-if="report.report_id" align="right" cols="auto" align-self=center>
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="handleTemplateDownload">{{$store.getters.getTextMap().download_current_template}}
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-download-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <v-row class="mt-1">
                            <v-col>
                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="reportForm.report_name"
                                    :counter="30"
                                    outlined
                                    dense
                                    :rules="nameRules"
                                    :label="$store.getters.getTextMap().report_name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="reportForm.notification_group"
                                    outlined
                                    dense
                                    :items="notificationGroupList"
                                    :label="$store.getters.getTextMap().notification_groups"
                                    item-text="label"
                                    item-value="value"
                                    card
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                ></v-select>
                            </v-col>
                            <v-col  cols="auto" class="d-flex" align="right">
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="reportSubmit">{{report_id?$store.getters.getTextMap().update_details:$store.getters.getTextMap().create_report}}
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-file-chart-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-card-title>{{$store.getters.getTextMap().update_excel_template}}</v-card-title>
                        <v-row>
                            <v-col>
                                <v-file-input
                                    v-model="file"
                                    show-size
                                    accept="xlsx"
                                    dense
                                    outlined
                                    :label="$store.getters.getTextMap().upload_excel_template_xlsx"
                                ></v-file-input>
                            </v-col>                           
                             <v-col  cols="auto" class="d-flex" align="right">
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="handleTemplateUpload">{{$store.getters.getTextMap().upload}}
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-upload-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <AddSheetToExcelTemplateBasedReport v-if="report_id"  :sheet="{report_id}" :edit="false" v-on:sheetUpdated="getSheetDetails()"/>
                    <!-- <v-card v-if="report_id" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
                        <v-card-title color="secondary" >Add New Sheet</v-card-title>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <v-col>
                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="sheetForm.sheet_name"
                                    :counter="30"
                                    outlined
                                    dense
                                    :rules="nameRules"
                                    label="Sheet Name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="sheetForm.row_start"
                                    outlined
                                    dense
                                    :rules="numberRules"
                                    label="Row Start"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="sheetForm.meta.index_col"
                                    outlined
                                    dense
                                    :rules="numberRules"
                                    label="Date Column (Index)"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="sheetForm.data_source"
                                    outlined
                                    dense
                                    :items="dataSourceOptions"
                                    label="Data Source"
                                    item-text="label"
                                    item-value="value"
                                    card
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                ></v-select>
                            </v-col>
                            <v-col v-if="sheetForm.data_source=='param_daily_data'">
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="time_frame"
                                    outlined
                                    dense
                                    :items="timeframeOptions"
                                    label="Timeframe"
                                    item-text="label"
                                    item-value="value"
                                    card
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="sheetForm.data_source=='param_data'">
                                <v-row>
                                    <v-col align="center" align-self="center">
                                        <v-menu  
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            ref="menufrom"
                                            v-model="menu3"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="from_time"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                                    :color="$store.getters.getColorPalette().inputtextColor"
                                                    v-model="from_time"
                                                    label="From"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    clearable
                                                    dense
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="objectRules"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker   
                                                :dark="$store.getters.getColorPalette().isDark" 
                                                :color="$store.getters.getColorPalette().inputtextColor"
                                                v-if="menu3"
                                                v-model="from_time"
                                                full-width
                                                format="24hr"
                                                @click:minute="$refs.menufrom.save(from_time)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col align="center" align-self="center">
                                        <v-menu  
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            ref="menuto"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="to_time"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                                    :color="$store.getters.getColorPalette().inputtextColor"
                                                    v-model="to_time"
                                                    label="To"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    clearable
                                                    dense
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="objectRules"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker   
                                                :dark="$store.getters.getColorPalette().isDark" 
                                                :color="$store.getters.getColorPalette().inputtextColor"
                                                v-if="menu2"
                                                v-model="to_time"
                                                full-width
                                                format="24hr"
                                                @click:minute="$refs.menuto.save(to_time)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <v-spacer></v-spacer>
                            <v-col  cols="auto" class="d-flex" align="right">
                                <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="sheetSubmit">Add Sheet
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="sheetCancel">Clear
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card> -->
                </v-form>
                <v-form ref="paramColumnForm" v-model="paramColumnValid">
                    <v-card v-if="report_id" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
                        <v-row>
                            <v-col cols="auto" class="d-flex" align="right" align-self="center">
                                <v-card-title>{{$store.getters.getTextMap().sheets}}</v-card-title>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="d-flex" align="right" align-self="center">
                                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="getSheetDetails">
                                    {{$store.getters.getTextMap().refresh}}
                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>
                                        mdi-refresh
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider v-if="!(sheetDetails && sheetDetails.length>0 && report_id)"></v-divider>
                        <v-row no-gutters>
                            <v-col v-if="sheetDetails && sheetDetails.length>0 && report_id">
                                <v-tabs
                                    :dark="$store.getters.getColorPalette().isDark"
                                    :background-color="$store.getters.getColorPalette().backgroundColorCode"
                                    v-model="tab"
                                    show-arrows
                                >
                                    <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
                                    <v-tab
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().btnborderColorCode"
                                        v-for="sheet in sheetDetails"
                                        :key="sheet.sheet_name"
                                    >{{ sheet.sheet_name }}</v-tab>
                                </v-tabs>
                                <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
                                <v-tabs-items v-model="tab" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                    <v-tab-item :dark="$store.getters.getColorPalette().isDark" v-for="sheet in sheetDetails" :key="sheet.sheet_name">
                                        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                            <v-row class="ml-2 pa-0">
                                                <v-col v-if="$store.state.user">
                                                    <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                                                        <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                                                        <v-radio :label="$store.getters.getTextMap().device"  value="deviceForm"></v-radio>
                                                        <v-radio v-if="!sheet.meta.multiMachineParam && !sheet.meta.shiftBasedSheet" :label="$store.getters.getTextMap().param_equation" value="eqForm"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col>
                                                    <v-card-title>{{sheet && sheet.meta && sheet.meta.multiMachineParam?'Multiple Machine Param Sheet':sheet && sheet.meta && sheet.meta.shiftBasedSheet?'Shift Based Sheet':''}}</v-card-title>
                                                </v-col>
                                                <v-col align="right" class="mr-2" align-self="center" v-if=" $store.state.user">
                                                    <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="editSheet=!editSheet">{{$store.getters.getTextMap().edit_sheets}}
                                                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil-outline</v-icon>
                                                    </v-btn>   
                                                    <DeleteConfirmation v-on:confirm="deleteSheet(sheet)"  title="Delete Confirmation"  description="Are you sure you want to delete this Sheet?"> 
                                                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_sheets}}
                                                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                                                        </v-btn>
                                                    </DeleteConfirmation>     
                                                </v-col>
                                            </v-row>
                                            <v-divider></v-divider>
                                            <v-row class="ma-2" v-if="editSheet" no-gutters>
                                                <v-col>
                                                    <AddSheetToExcelTemplateBasedReport :sheet="sheet" :edit="true" v-on:sheetUpdated="getSheetDetails()"/>
                                                </v-col>
                                            </v-row>
                                            <v-divider></v-divider>
                                            <v-row no-gutters v-if="!editSheet">
                                                <v-col v-if="sheet.meta.multiMachineParam" class="mx-3" align-self="center" :class="formType=='eqForm'?'mt-2':'mt-0'">
                                                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                                        :color="$store.getters.getColorPalette().inputtextColor"
                                                        v-if="!loading"
                                                        v-model="row_no"
                                                        outlined
                                                        dense
                                                        :rules="numberRules"
                                                        :label="$store.getters.getTextMap().row_no"
                                                        required
                                                    ></v-text-field>
                                                </v-col>  
                                                <v-col class="mx-3" align-self="center" :class="formType=='eqForm'?'mt-2':'mt-0'">
                                                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                                        :color="$store.getters.getColorPalette().inputtextColor"
                                                        v-if="!loading"
                                                        v-model="col_no"
                                                        outlined
                                                        dense
                                                        :rules="numberRules"
                                                        :label="sheet && sheet.meta && sheet.meta.isTranspose && !sheet.meta.multiMachineParam?$store.getters.getTextMap().row_no:$store.getters.getTextMap().column_no"
                                                        required
                                                    ></v-text-field>
                                                </v-col>  
                                                <v-col cols="8" v-if="formType==='deviceForm'">
                                                    <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                                                </v-col>
                                                <v-col cols="8" v-if="formType==='machineForm'">
                                                    <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                                                </v-col>
                                                <v-col cols="8" class="mt-2" v-if="formType==='eqForm'">
                                                    <v-autocomplete
                                                        v-if="!loading"
                                                        v-model="eq"
                                                        :items="paramEqOptions"
                                                        :dark="$store.getters.getColorPalette().isDark" 
                                                        :color="$store.getters.getColorPalette().inputtextColor"
                                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                                        outlined
                                                        dense
                                                        small-chips
                                                        :label="$store.getters.getTextMap().param_equation"
                                                        item-text="label"
                                                        item-value="value"
                                                    ></v-autocomplete>
                                                </v-col> 
                                                <v-spacer></v-spacer>
                                                <v-col>
                                                    <v-btn class="mt-4 mr-3" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addParamsToList(sheet)">
                                                       {{$store.getters.getTextMap().add}}
                                                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                                                            mdi-tab-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="px-2 pb-2">
                                                <v-col v-if="sheet.params" cols="12">
                                                    <v-simple-table height="200px" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}"  >
                                                        <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th v-if="sheet && sheet.meta && sheet.meta.multiMachineParam" class="text-left">
                                                                        <strong> {{$store.getters.getTextMap().row_number}}</strong>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <strong>{{sheet && sheet.meta && sheet.meta.isTranspose && !sheet.meta.multiMachineParam?$store.getters.getTextMap().row_number :$store.getters.getTextMap().column_number}}</strong>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <strong>{{$store.getters.getTextMap().label}}</strong>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <strong>{{$store.getters.getTextMap().id}}</strong>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <strong>{{$store.getters.getTextMap().action}}</strong>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="item in sheet.params" :key="item.param_id" >
                                                                    <td v-if="sheet && sheet.meta && sheet.meta.multiMachineParam">{{ item.row_no}}</td>
                                                                    <td>{{ item.column_no}}</td>
                                                                    <td>{{ item.param_label }}</td>
                                                                    <td>{{ item.param_id }}</td>
                                                                    <td>
                                                                        <DeleteConfirmation v-on:confirm="deleteParameter(sheet,item.param_id)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                                                                            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete}}  
                                                                                <v-icon small class="ml-1"  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                                                            </v-btn>
                                                                        </DeleteConfirmation>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                    </v-simple-table>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-col>
                            <v-col v-else>
                                <div :style="[{'text-align':'center'}]">
                                    <v-icon small>mdi-hail</v-icon>
                                    <h4>{{$store.getters.getTextMap().no_sheets_added}}</h4>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import fileDownload from 'js-file-download'
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddSheetToExcelTemplateBasedReport from '@/components/crud_components/reports/AddSheetToExcelTemplateBasedReport'
export default {
    name:'CreateExcelTemplateBasedReport',
    props:['report'],
    components:{
        InfoAlert,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm,
        DeleteConfirmation,
        AddSheetToExcelTemplateBasedReport
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            reportValid:false,
            paramColumnValid:false,
            updateSheetValid:false,
            reportApi:'createExcelTemplateBasedReport',
            sheetApi:'addSheetToExcelTemplateBasedReport',
            rules:FormRules.rules,
            reportForm:{
                disable:false
            },
            editSheet:false,
            sheetForm:{
                data_source:'param_daily_data',
                row_start:1,
                meta:{
                    index_col:1
                }
            },
            paramColForm:{},
            sheetDetails:[],
            time_frame:'mtd',
            from_time:null,
            to_time:null,
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            parameter:null,
            eq:null,
            report_id:null,
            parameter_label:null,
            paramEqOptions:[],
            col_no:1,
            row_no:1,
            tab:null,
            formType:'machineForm',
            file:null,
            dataSourceOptions:[
                {label:"Daily Data",value:"param_daily_data"},
                {label:"Time Series",value:"param_data"},
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && !isNaN(v))|| 'It should be a valid number',
                v => (v && v > 0) || 'Number must be greater than 0',
            ],
        }
    },
    mounted(){
        this.getParamEquations()
        if(this.report && this.report.report_id){
            this.report_id=this.report.report_id
            this.getSheetDetails()
            this.reportForm['report_name']=this.report.name
            this.reportForm['notification_group']=this.report.notification_group
            this.reportForm['disable']=this.report.disable
            this.reportForm['meta']=this.report.meta
        }
    },
    computed:{
        notificationGroupList(){
            let op=[]
            if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
                for(let i of this.$store.state.notificationGroups){
                    op.push({label:i.name,value:i.group_id})
                }
            }
            return op
        },
        paramEquationOptions(){
            let op=[]
            for(let i of this.paramEqOptions){
                op.push( {value:i, label: i.label })
            }
            return op
        }
    },
    methods:{
        updateParamList(value){
            if(value && value.parameter && value.parameter.param_id){
                this.parameter=value.parameter.param_id
                this.parameter_label=value.parameter.name
            }
        },
        handleTemplateDownload(){
            if(this.report.report_id){
                axios.post(this.$store.state.api+'downloadExcelTemplate',{statement_id:this.report.report_id+'.xlsx'},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                .then((response) => {
                    this.loading=false
                    if(response.data.status && response.data.status=='error'){
                        this.showDismissibleAlert=true
                        this.info=response.data.msg
                    }else{
                        fileDownload(response.data,this.report.report_id+'.xlsx')
                    }
                }).catch(err=>{
                    this.loading=false
                    this.showDismissibleAlert=true
                    this.info=err
                });
            }else{
                this.showDismissibleAlert=true
                this.info="There is no template for this report."
            }
        },
        handleTemplateUpload(){
            let formData = new FormData();
            formData.append('file', this.file); 
            formData.append('report_id', this.report_id);
            axios.post(this.$store.state.api+'uploadExcelTemplate',formData,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt,'Content-Type': 'multipart/form-data'}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.info="Template Uploaded Successfully"
                        this.showDismissibleAlert=true
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        getParamEquations(){
            let op=[]
            this.loading=true
            axios.get(this.$store.state.api+'paramEquations',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    let equations=response.data.data
                    for(let i of equations){
                        if(i && i.label){
                            op.push({value:i, label: i.label })
                        }else{
                            op.push({value:i, label: i.eq_id })
                        }
                    }
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
            this.paramEqOptions=op
        },
        getSheetDetails(){
            this.editSheet=false
            axios.post(this.$store.state.api+'getExcelTemplateBasedReportSheetsByReportId',{"report_id":this.report_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.sheetDetails=response.data.reportDetails
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        checkifColumOrRowAlreadyExists(sheet){
            let params=sheet.params?sheet.params:[]
            if(sheet.meta.multiMachineParam){
                return (params && params.some(obj => (obj.param_id === (this.parameter?this.parameter:this.formType==='eqForm'?this.eq.eq_id:obj.param_id) || (obj.column_no === this.col_no && obj.row_no === this.row_no))))
            }else{
                return (params && params.some(obj => (obj.param_id === (this.parameter?this.parameter:this.formType==='eqForm'?this.eq.eq_id:obj.param_id) || obj.column_no === this.col_no)))
            }
        },
        addParamsToList(sheet){
            let params=sheet.params?sheet.params:[]
            // if(params && params.length>0 && this.row_no===1){
            //     this.row_no=Number(params.length)
            // }
            if(this.checkifColumOrRowAlreadyExists(sheet)){
                this.info=`Entered Parameter/Equation or ${sheet && sheet.meta && sheet.meta.isTranspose?"Row":"Column"} already exisits`
                this.showDismissibleAlert=true
            }else{
                let temp={"param_id":this.parameter,"param_label":this.parameter_label,"column_no":Number(this.col_no),"row_no":Number(this.row_no)}
                if(this.formType==='eqForm'){
                    temp['type']='param_equation'
                    temp['param_id']=this.eq.eq_id
                    temp['param_label']=this.eq.label
                }else{
                    temp['type']='parameter'
                }
                params.push(temp)
                this.paramColForm['report_id']=this.report_id
                this.paramColForm['sheet_name']=sheet.sheet_name
                this.paramColForm['params']=params
                axios.post(this.$store.state.api+"addParamToExcelTemplateBasedReportSheet",this.paramColForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.getSheetDetails()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
                this.parameter=null
                this.parameter_label=null
                if (!sheet.meta.multiMachineParam){

                    this.col_no=parseInt(this.col_no)+parseInt(1)
                    this.row_no=parseInt(this.row_no)+parseInt(1)
                }
                params=[]
            }
        },
        reportSubmit(){
            let formData=null
            let apiHeaders=null
            if(this.report_id){
                this.reportForm['report_id']=this.report_id
                formData=this.reportForm
                apiHeaders={Authorization: 'Bearer '+ this.$store.state.jwt}
            }else{
                formData = new FormData();
                formData.append('reportForm', JSON.stringify(this.reportForm));
                formData.append('file', this.file); 
                apiHeaders={Authorization: 'Bearer '+ this.$store.state.jwt,'Content-Type': 'multipart/form-data'}
            }
            let api=this.report_id?"updateExcelTemplateBasedReport":"createExcelTemplateBasedReport"
            let successMessage=this.report_id?"Report Details Updated":"New Report Created"
            axios.post(this.$store.state.api+api,formData,{headers: apiHeaders})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    if(!this.report_id){
                        this.report_id=response.data.data.report_id
                    }
                    this.getSheetDetails()
                    this.$store.dispatch('refreshExcelTemplateBasedReports')
                    this.info=successMessage
                    this.showDismissibleAlert=true
                }else{
                    this.loading=false
                    this.$store.dispatch('refreshExcelTemplateBasedReports')
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        sheetSubmit(){
            this.$refs.reportForm.validate()
            if(this.reportValid){
                let api=this.report_id?this.sheetApi :this.reportApi
                this.sheetForm['time_frame']=this.sheetForm.data_source==='param_daily_data'?{"timeframe":this.time_frame}:{"from_time":this.from_time,"to_time":this.to_time}
                if(!(this.report_id)){
                    this.sheetForm['report_name']=this.reportForm.report_name
                    this.sheetForm['notification_group']=this.reportForm.notification_group
                }else{
                    this.sheetForm['report_id']=this.report_id
                }
                axios.post(this.$store.state.api+api,this.sheetForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.report_id=response.data.data.report_id
                        this.getSheetDetails()
                        this.$store.dispatch('refreshExcelTemplateBasedReports')
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        deleteParameter(sheet,param_id){
            sheet.params=sheet.params.filter(d=>{return d.param_id != param_id})
            this.paramColForm['report_id']=this.report_id
            this.paramColForm['sheet_name']=sheet.sheet_name
            this.paramColForm['params']=sheet.params
            axios.post(this.$store.state.api+"addParamToExcelTemplateBasedReportSheet",this.paramColForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.getSheetDetails()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        deleteSheet(sheet){
            axios.post(this.$store.state.api+"deleteSheetFromExcelTemplateBasedReport",sheet,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.getSheetDetails()
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        sheetCancel(){
            this.sheetForm={}
        },
    },
    watch:{
        report_id(){
            this.getSheetDetails()
        }
    }
}
</script>