<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-form ref="watcherForm" v-model="valid">
                    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-card-title  class="headline">{{ title }}</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                    <v-autocomplete
                                        v-if="!loading"
                                        v-model="form.userid"
                                        :items="users"
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        outlined
                                        dense
                                        :rules="objectRules"
                                        :label="$store.getters.getTextMap().watcher"
                                        item-text="label"
                                        item-value="value"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{ $store.getters.getTextMap().submit}}</v-btn>
                            <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="onReset">{{ $store.getters.getTextMap().cancel}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>           
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'AddTaskWatcher',
    props:['task'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                userid:null
            },
            api:'AddTaskWatcher',
            title:'Add Task Watcher',
            loading:false,
            info:null,
            infoMessage:"Watcher Added",
            showDismissibleAlert:false,
            valid:false,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 11 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
            ],
            objectRules:FormRules.objectRules,
        }
    },
    mounted(){
        // console.log(this.task)
    },
    computed:{
        users(){
            let op=[]
            let g=this.$store.state.UAMUsers
            for(let i of g){
                op.push( {value:i.userid, label: i.name })
            }
            return op
        }
    },
    methods:{
        onSubmit(){
            this.$refs.watcherForm.validate()
            if(this.valid){
                this.loading = true;
                this.form['task_id']=this.task.task_id
                axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success" && response.data.group) {
                        // this.info = this.infoMessage;
                        // this.showDismissibleAlert = true;
                        // this.loading = false;
                        this.onReset()
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.watcherForm.reset()
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
</style>