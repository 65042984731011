<template>
    <span>
        <v-btn :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" text outlined small @click="dialog2=!dialog2">{{ $store.getters.getTextMap().trend_analysis_tools }}<v-icon right  small :color="$store.getters.getColorPalette().btnborderColorCode">mdi-google-analytics</v-icon></v-btn>
        <v-dialog v-model="dialog2" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-toolbar flat outlined :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
              <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog2 = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ $store.getters.getTextMap().trend_analysis_tools }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog2 = false">{{ $store.getters.getTextMap().close }}</v-btn>
              </v-toolbar-items>
          </v-toolbar>
          <AnalysisPanel/>
        </v-card>
        </v-dialog>
    </span>
</template>
<script>
import AnalysisPanel from '@/components/panels/AnalysisPanel'
export default {
    name:'AnalysisBottomSheet',
    components:{
        AnalysisPanel
    },
    data(){
        return{
            dialog2:false,
        }
    }
}
</script>