<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
        <v-col cols="12"  >
            <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.getTextMap().search" single-line
                hide-details></v-text-field>
            </v-card-title>
  
            <v-data-table :headers="tableHeaders" :items="voucherList" item-key="voucher_id" :search="search"
              :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
              loading-text="Loading... Please wait" >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $store.getters.getTextMap().voucher_requests }} For Issue</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="getVouchers">{{ $store.getters.getTextMap().refresh  }}</v-btn>
                </v-toolbar>
              </template>
             
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <StoreVoucher :voucher="item" v-on:update="getVouchers"/>
                </td>
              </template>
              <template v-slot:item.created="{ item }">
                <span>

                    {{getTimestamp(item.created)}}
                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import DashboardPanel from '@/components/items/DashboardPanel'
import InfoAlert from '@/components/InfoAlert';
import StoreVoucher from '@/components/items/manufacturing/StoreVoucher';
import axios from 'axios'
import moment from 'moment'
export default {
    name:'ApprovalPendingVoucherList',
    components:{
        // DashboardPanel,
        InfoAlert,
        StoreVoucher
    },
    mounted(){
        this.getVouchers()
    },
    data(){
        return {
            search:'',
            expanded:[],
            loading:false,
            info:'',
            showDismissibleAlert:false,
            voucherList:[],
            tableHeaders:[
            {
                text: 'Voucher ID',
                align: 'start',
                filterable: false,
                value: 'voucher_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Department', value: 'department_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Created', value: 'created', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: "Intendor", value: 'intendor', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: "Receiver", value: 'receiver', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Status', value: 'stage', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Approver', value: 'approver', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            //   { text: 'Approval', value: 'approval', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },

            ],
        }
    },
    computed:{},
    methods:{
        getTimestamp(time){
            return moment(time).format('MMMM Do YYYY, h:mm:ss a')

        },
        getVouchers(){
            // this.$refs.usageForm.validate()

                this.loading=true
                // this.form.process_id=this.process.process_id
                // let payload={
                //     stage:'intended'
                // }
                axios.get(this.$store.state.api+'issueVouchersToBeIssued',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.voucherList=response.data.data
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            
        },
        approveVoucher(voucher){
            // this.$refs.usageForm.validate()

                this.loading=true
                // this.form.process_id=this.process.process_id
                
                axios.post(this.$store.state.api+'authorizeItemIssue',voucher,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        // this.voucherList=response.data.data
                        this.getVouchers()
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            
        },
        rejectVoucher(voucher){
            // this.$refs.usageForm.validate()

                this.loading=true
                // this.form.process_id=this.process.process_id
                
                axios.post(this.$store.state.api+'rejectItemIssue',voucher,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        // this.voucherList=response.data.data
                        this.getVouchers()
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            
        },
    }
}
</script>