<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <ConfigChecklistTemplates/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <ConfigCheckLists/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ConfigCheckLists from './configurations/ConfigCheckLists.vue';
import ConfigChecklistTemplates from './configurations/ConfigChecklistTemplates.vue';
export default {
    name: 'ChecklistConfigurations',
    components: {
        ConfigCheckLists,
        ConfigChecklistTemplates
    },
}
</script>