<template>
    <div class="py-5 px-auto">
      <span :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{graphName}}</strong></span>
     
      <v-btn
              :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
               outlined
                small
                @click="downLoadPDF()">
            Download Trend
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-adobe-acrobat
      </v-icon>
        </v-btn>
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>
import {Bar } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'chartjs-plugin-annotation'
import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'DualBarGraphByDate',
    extends: Bar ,
    props:['graphName','label1','label2','graphData','lineLabel1','lineLabel2','lineValue1','lineValue2','dataKey1','dataKey2','timestamps','unit1','unit2','timeUnit','stopDownload'],
    components:{
        
    },
    computed:{
        dateLabels(){
            let l=[]

            if(this.timestamps){
              for(let i of this.timestamps){
                l.push(new Date(Number(i)*1000));
              }
            }else{
            for (let i of this.graphData){
                l.push(new Date(Number(i.timestamp)*1000));
            }
            }
            return l
            //return ['1','2','3','4']
        },
        consumed1(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey1])
            }
            
            return l
        },
        consumed2(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey2])
            }
            
            return l
        },
        line1(){
            
            //console.log(this.$store.state.last7Days)
            if (!isNaN(this.lineValue1)){
              return Array(this.graphData.length).fill(this.lineValue1)
            }
            
            return []
        },
        line2(){
            if (!isNaN(this.lineValue2)){
              return Array(this.graphData.length).fill(this.lineValue2)
            }
            
            return []
        },
        yAxisLabel1(){
          if(this.unit1 && this.label1){
            return this.label1 +" - "+ this.unit1
          }else if(this.unit1){
            return this.unit1
          }else if(this.label1){
            return this.label1
          }else{
            return ''
          }
        },
        yAxisLabel2(){
          if(this.unit2 && this.label2){
            return this.label2 +" - "+ this.unit2
          }else if(this.unit2){
            return this.unit2
          }else if(this.label2){
            return this.label2
          }else{
            return ''
          }
        },
    },
    data(){
        return {
  colorCode:ColorUtility.colorTypesCodeArray,
        }
    },
    methods: {
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);
        this.$emit('imageData',{
          imageData:pageData,
          height:canvas.height,
          width:canvas.width
        })
        if(this.stopDownload){
          return
        }
                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  pdf.text(40, 40, this.label1 +"  "+this.label2)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.graphName+'_'+this.label1+'_'+this.label2+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
      },
        createChart(){
          let datasets=[
            {
                barPercentage: 0.9,
        //barThickness: 6,
        maxBarThickness: 81,
                label:this.label1,
               backgroundColor: this.colorCode[0],//'#90A4AE',
               borderColor: this.colorCode[0],
               pointBorderColor: 'rgba(20, 25, 100,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                //pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 4,
                
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed1,
                fill:true,
                lineTension:0.1,
                yAxisID: "y-axis-1"
            },
            {
                barPercentage: 0.9,
        //barThickness: 6,
        maxBarThickness: 81,
                label:this.label2,
               backgroundColor:  this.colorCode[1],//'#90A4AE',
               borderColor: this.colorCode[1],
               pointBorderColor: 'rgba(200, 25, 10,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                //pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 4,
                
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed2,
                fill:true,
                lineTension:0.1,
                yAxisID: "y-axis-2"
            },
            
           /* {
              type: 'scatter',
              label:'Average production',
              pointBackgroundColor: '#2C73D2',
              backgroundColor: 'transparent',
              borderColor:'#2C73D2',
              //data: [42000,42000,42000,42000,42000,42000,42000,42000,42000,42000,
              //42000,42000,42000,42000,42000,42000,42000,42000,42000,42000,42000,
              //42000,42000,42000],
            },
            */
          ]
          if(this.line1.length>0){
            datasets.push(
              {
              type: 'line',
              //height: '300',
              label:this.lineLabel1,
              pointBackgroundColor: this.$store.getters.getColorPalette().graphPoints,
              backgroundColor: this.$store.getters.getColorPalette().accentCode,
              pointRadius:0.5,
              borderWidth: 2,
              hoverBorderWidth: 4,
              lineTension:0.1,
              fill:false,
              borderColor:this.$store.getters.getColorPalette().accentCode,
              data: this.line1,
              yAxisID: "y-axis-1"
            }
            )
          }
          if(this.line2.length>0){
            datasets.push(
              
            {
              type: 'line',
              height: '300',
              label:this.lineLabel2,
              pointBackgroundColor: this.$store.getters.getColorPalette().graphPoints,
              backgroundColor: this.$store.getters.getColorPalette().colorTypesCodeArray[3],
              pointRadius:0.5,
              borderWidth: 2,
              hoverBorderWidth: 4,
              lineTension:0.1,
              borderColor:this.$store.getters.getColorPalette().colorTypesCodeArray[3],
              data: this.line2,
              yAxisID: "y-axis-2"
            },
            )
          }
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dateLabels,
          datasets: datasets},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{display: true, labels:{fontColor:'#FFFFFF'}},
          scales: {
        xAxes: [ {
            offset: true,
          type: 'time',
          distribution: 'series', //series
          time: {
                    unit: this.timeUnit?this.timeUnit:'hour',
                    displayFormats: {
                        hour: 'hh a',
                        day_hour: 'DD/MM-hha'
                    }
                },
          display: true,
          scaleLabel: {
            fontColor: this.$store.getters.getColorPalette().colorTypesCodeArray[3],
            display: false,
            labelString: 'Date'
          },
          ticks: {
            fontColor:this.$store.getters.getColorPalette().tickfontColor,
              scaleBeginAtZero: false,
            major: {
              fontStyle: 'bold',
              fontColor: '#FF0000'
            },
            source:'auto'
          },
            gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        } ],
        yAxes: [         
        {
              type: 'linear',
          display: true,
          position:"left",
          id:"y-axis-1",
          scaleLabel: {
            fontColor: "rgba(165, 165, 165,0.6)",
            display: true,
            fontSize: 15,
            autoFit: true,
            wrap: true,
            maxHeight: 300,
            labelString:  this.yAxisLabel1
          },
          ticks: {
            fontColor:this.$store.getters.getColorPalette().tickfontColor,
            //scaleBeginAtZero: false,
          }
        },
            {
              fontColor: "rgba(165, 165, 165,0.6)",
              type: 'linear',
          display: true,
          position:"right",
          id:"y-axis-2",
          scaleLabel: {
            display: true,
            fontSize: 14,
            autoFit: true,
            wrap: true,
            maxHeight: 300,
            labelString: this.yAxisLabel2
          },
          ticks: {
             fontColor:this.$store.getters.getColorPalette().tickfontColor,
            //scaleBeginAtZero: false,
          }
        },
         ]
      }})
        }
    },
    mounted() {

        this.createChart();
        
    },
    watch: {
        consumed1:function(){
      
      this.createChart()

        }
    },
}
</script>