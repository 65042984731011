<template>
    <div fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <SafetyIncidentPanel />
    </div>
</template>
<script>
import SafetyIncidentPanel from '@/components/panels/manufacturing/SafetyIncidentPanel'
export default {
    name:'SafetyIncidentPage',
    components:{
        SafetyIncidentPanel
    }
}
</script>