<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-card-title>{{tariff.name?tariff.name:tariff.tariff_id}} 
                <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
                <v-row justify-end="end" v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <v-col cols="auto" class="d-flex ml-auto" align="right">
                        <v-btn @click="addDialog=!addDialog" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().add_tariff_data}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus</v-icon></v-btn>
                        <v-btn class="mx-1"  @click="editMode=!editMode" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().edit_tariff}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <DeleteConfirmation v-on:confirm="deleteTariff()"  title="Delete Confirmation"  description="Are you sure you want to delete this Tariff?"> 
                            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_tariff}}
                                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                            </v-btn>
                        </DeleteConfirmation>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle >
                <v-card v-if="editMode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :color="$store.getters.getColorPalette().backgroundColorCode"  :dark="$store.getters.getColorPalette().isDark">
                    <v-row>
                        <v-col>
                            <CreateTariff :tariff="tariff" v-on:close="editMode=false" v-on:update="$emit('update')"/>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="my-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <v-row>
                        <v-col>
                           <AddTariffData :tariff="tariff" v-if="addDialog"/>
                        </v-col> 
                    </v-row>
                </v-card>
                <v-row>
                    <v-col>
                        <v-simple-table height="200px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().month}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().day_of_week}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().tariff_value}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().from_time}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().to_time}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  v-for="(item,ind) in tariffData" :key="ind">
                                        <td>{{ monthValueMap[item.month] }}</td>
                                        <td>{{ weekValueMap[item.day_of_week] }}</td>
                                        <td>{{ item.tariff_val }}</td>
                                        <td>{{ item.from_time }}</td>
                                        <td>{{ item.to_time }}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteTariffData(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation> 
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddTariffData from '@/components/crud_components/derived/AddTariffData'
import CreateTariff from '@/components/crud_components/derived/CreateTariff';
export default {
name:'Tariffs',
props:['tariff'],
components:{
    InfoAlert,
    AddTariffData,
    DeleteConfirmation,
    CreateTariff
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        editMode:false,
        monthValueMap:{
            1:"January",
            2:"February",
            3:"March",
            4:"April",
            5:"May",
            6:"June",
            7:"July",
            8:"August",
            9:"September",
            10:"October",
            11:"November",
            12:"December",
        },
        weekValueMap:{
            6:"Sunday",
            0:"Monday",
            1:"Tuesday",
            2:"wednesday",
            3:"Thursday",
            4:"Friday",
            5:"Saturday",
        },
    }
},
computed:{
    tariffData(){
        return this.$store.getters.getTariffDataById(this.tariff.tariff_id)
    }
    
},
methods:{
    deleteTariff(){
        axios.post(this.$store.state.api+'deleteTariff',{tariff_id:this.tariff.tariff_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                this.$store.dispatch("refreshTariffData")
                this.$emit('deleteTariff')
                this.loading=false
                this.info='The tariff has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    deleteTariffData(tuple){
        axios.post(this.$store.state.api+'deleteTariffData',tuple,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                this.$store.dispatch("refreshTariffData")
                this.loading=false
                this.info='The tariff Data has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    cancel(){
        this.showDialog=false
    },
    confirm(){
        this.showDialog=false
    }
},
}
</script>
<style scoped>
.theme--light.v-tabs-items{
    background-color: #263238;
}
</style>