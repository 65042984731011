<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex" align="right">
                <v-btn v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])" @click="showFilter = !showFilter" :dark="$store.getters.getColorPalette().isDark" outlined text :color="$store.getters.getColorPalette().accentCode" small>{{$store.getters.getTextMap().filters}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-filter-outline</v-icon></v-btn>
                <CheckSafetyIncidentLogModal v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])" class="mx-1"/>
                <WriteSafetyIncidentModal v-on:success="refreshData()"/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-dialog
                    v-model="showFilter"
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <SafetyIncidentFilter :filter_for="'safety_incident'" v-on:filters="applyFilters" v-on:close="showFilter=false"/>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <SafetyIncidentTable :timeFilter="timeFilter" :locations="locations" :employees="employees" :refresh="refresh" v-on:refreshed="refreshData()"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WriteSafetyIncidentModal from '@/components/modals/manufacturing/WriteSafetyIncidentModal'
import CheckSafetyIncidentLogModal from '@/components/modals/manufacturing/CheckSafetyIncidentLogModal'
import SafetyIncidentTable from '@/components/lists/manufacturing/SafetyIncidentTable'
import SafetyIncidentFilter from '@/components/filters/manufacturing/SafetyIncidentFilter'
export default {
    name:'SafetyIncidentPanel',
    components:{
        WriteSafetyIncidentModal,
        SafetyIncidentTable,
        SafetyIncidentFilter,
        CheckSafetyIncidentLogModal
    },
    data(){
        return {
            refresh:false,
            showFilter:false,
            timeFilter:"month",
            locations:[],
            employees:[],
        }
    },
    methods:{
        refreshData(){
            this.refresh=!this.refresh
        },
        applyFilters(value){
            this.refresh=true
            this.showFilter=false
            this.timeFilter=value.timeFilter
            this.locations=value.locations
            this.employees=value.employees
        }
    }

}
</script>