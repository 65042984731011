<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" /> 
        <v-form ref="safetyInspectionSheetForm" v-model="valid" >
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title>{{$store.getters.getTextMap().safety_inspection_sheet}}</v-card-title>
                    </v-col>
                </v-row>
                <v-row no-gutters class="px-2">
                    <v-col>
                        <v-text-field
                            v-if="!loading"
                            v-model="form.name"
                            :counter="100"
                            :rules="[formRules.rules.required,formRules.rules.validString,formRules.rules.size(100)]"
                            :label="$store.getters.getTextMap().sheet_name"
                            dense
                            outlined
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>                  
                    <v-col class="mx-2">
                        <v-select  
                            v-if="!loading"
                            v-model="form.meta.type"
                            :items="sheetTypeOptions"
                            :label="$store.getters.getTextMap().type"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                    <v-col v-if="form.meta.type==='detailed'">
                        <v-select  
                            v-if="!loading"
                            v-model="form.meta.timeframe"
                            :items="timeframeOptionsForDetailed"
                            :label="$store.getters.getTextMap().time_frame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                    <v-col v-else>
                        <v-select  
                            v-if="!loading"
                            v-model="form.meta.timeframe"
                            :items="timeframeOptionsForConsolidated"
                            :label="$store.getters.getTextMap().time_frame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="form.meta.location_ids"
                            :items="locationOptions"
                            :label="$store.getters.getTextMap().location"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            multiple
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="ml-2" v-if="form.meta.type==='detailed'">
                        <v-autocomplete
                            v-if="!loading"
                            v-model="form.meta.status_list"
                            :items="statusOption"
                            :label="$store.getters.getTextMap().status"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            multiple
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col v-if="form.meta.type==='detailed'">
                        <v-autocomplete
                            v-if="!loading"
                            v-model="form.meta.inspection_types"
                            :items="inspectionTypeOption"
                            :label="$store.getters.getTextMap().inspection_type"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            multiple
                            :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :rules="[formRules.rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().submitbtnColor"  @click="sheetSubmit">
                    {{sheet && sheet.sheet_id?$store.getters.getTextMap().update:$store.getters.getTextMap().create}}
                </v-btn>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor"  @click="close">
                    {{$store.getters.getTextMap().cancel}}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'SafetyInspectionSheet',
    props: {
        "sheet": {
            type: Object,
        },
    },
    components:{
        InfoAlert,
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            valid:false,
            api:'createSheets',
            form:{         
                name:null,
                sheet_type:'safetyInspectionSheet',
                meta:{
                    location_ids:[],
                    timeframe:'month',
                    type:"consolidated",
                    inspection_types:["process_related","maintenance_related"],
                    status_list:["open","closed"],
                }
            },
            timeframeOptionsForDetailed:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
                {label:'Yesterday', value:'yesterday'},
                {label:'Last 30 Days', value:'last30'},
                {label:'Last 60 Days', value:'last60'},
                {label:'Last 90 Days', value:'last90'},
                {label:'Last 180 Days', value:'last180'},
                {label:'Last 365 Days', value:'last365'},
            ],
            timeframeOptionsForConsolidated:[
                {label:'Day', value:'day'},
                {label:'Month', value:'month'},
                {label:'Quarter', value:'quarter'},
            ],
            sheetTypeOptions:[
                {label:'Consolidated', value:'consolidated'},
                {label:'Detailed', value:'detailed'},
            ],
            inspectionTypeOption:[
                {label:"Process Related Finding",value:"process_related"},
                {label:"Maintenance Related Finding",value:"maintenance_related"},
            ],
            statusOption:[
                {label:"Open",value:"open"},
                {label:"Closed",value:"closed"},
            ],
            formRules: FormRules,
        }
    },
    mounted(){
        if(this.sheet && this.sheet.sheet_id && this.sheet.meta){
            this.form.name = this.sheet.name
            this.form.sheet_id = this.sheet.sheet_id
            this.form.meta.location_ids = this.sheet.meta.location_ids
            this.form.meta.type = this.sheet.meta.type
            this.form.meta.timeframe = this.sheet.meta.timeframe,
            this.form.meta.inspection_types = this.sheet.meta.inspection_types,
            this.form.meta.status_list = this.sheet.meta.status_list,
            this.api = 'updateSheets'
        }
        this.form.sheet_type = 'safetyInspectionSheet'
    },
    computed:{
        locationOptions(){
            let op=[]
            for(let i of this.$store.state.locations){
                op.push({
                    label:i.location_name,
                    value:i.location_id
                })
            }
            return op
        }
    },
    methods:{
        close(){
            this.$emit("close")
         },
        sheetSubmit(){
            this.$refs.safetyInspectionSheetForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshSheets')
                        this.$refs.safetyInspectionSheetForm.reset()
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
    }
}
</script>