<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                <v-card-title>
                 {{$store.getters.getTextMap().consolidated_shift_based_usage_data_sheet}}                </v-card-title>
                </v-col>
            </v-row>
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().time_frame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark:$store.getters.getColorPalette().isDark }"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.shifts"
                        :items="shiftOptions"
                        :label="$store.getters.getTextMap().shift"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        multiple
                      :dark="$store.getters.getColorPalette().isDark" 
                        :rules="objectRules"
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.dateFormat"
                        :items="dateFormatOption"
                        :label="$store.getters.getTextMap().date_format"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="process_label"
                        :label="$store.getters.getTextMap().process_label"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="process"
                        :items="processOptions"
                        :label="$store.getters.getTextMap().process"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        
                    ></v-select>
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().include_process_total" v-model="processTotal"></v-checkbox>
                </v-col>
                <GetTagTypeModal v-if="addTagTypes===true" v-on:close="addTagTypes=false" :tagTypes="tagTypeTableDetails" :show="true" v-on:value="createTagTypeArray" />
                <v-col v-if="!loading">
                    <v-text-field readonly :label="$store.getters.getTextMap().tag_types">
                        <template v-slot:prepend-inner>
                            <div >
                                <v-chip
                        class="ma-2"
                        close
                        small
                        color="teal"
                        text-color="white"
                        @click:close="remove(tag)" 
                        v-bind:key="tag.tag_type" v-for="tag in tagTypeTableDetails"
                    >
                        {{tag.label}}
                    </v-chip>
                            </div>
                        </template>
                     </v-text-field>
                    <v-btn style="margin-left:10px;margin-right:10px;" color="gray darken-1" small  @click="addTagTypes=true">{{$store.getters.getTextMap().add_tag_types}}</v-btn>
                    <v-btn style="margin-left:10px;margin-right:10px;"  color="gray darken-1" small  @click="addProcessToList">{{$store.getters.getTextMap().add_pocess_to_list}}</v-btn>
                    <!-- <span v-else>Add Tag Type First</span> -->
                    <v-btn  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onProcessReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
                    <v-card :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && processTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().process}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().include_process_total}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().action}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in processTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.process_id }}</td>
                                            <td>{{ item.processTotal }}</td>
                                            <!-- <td></td> -->
                                            <td>
                                                <v-row>
                                                    <v-col>
                                                        <v-checkbox label="Include Total"  v-model="item.processTotal" @change="handleCheckboxChange(item)"></v-checkbox>
                                                    </v-col>
                                                    <v-col>
                                                        <DeleteConfirmation v-on:confirm="deleteFromProcessTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?"><v-icon small :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon></DeleteConfirmation>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>
                </v-row>
            </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
            </v-form> 
        </v-container>
    </template>
    <script>
    import InfoAlert from '@/components/InfoAlert'
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    import GetTagTypeModal from '@/components/modals/GetTagTypeModal'
    import axios from 'axios'
    export default {
        name:'ConsolidatedShiftUsageDataSheet',
        props: {
        "sheet": {
            type: Object,
            // required: true
        },
        },
        components:{
            InfoAlert,
            GetTagTypeModal,
            DeleteConfirmation
        },
        mounted(){
            this.form.meta.dateFormat="%Y-%m-%d"
            if(this.sheet && this.sheet.meta){
                
                this.processTableDetails = this.sheet.meta.process_list
                this.tagTypeTableDetails = this.sheet.meta.tag_types
                this.form.name = this.sheet.name
                this.form.meta.data_type = this.sheet.meta.data_type
                this.form.meta.tag_types = this.sheet.meta.tag_types
                this.form.meta.rows = this.sheet.meta.rows
                this.form.meta.columns = this.sheet.meta.columns
                this.tagTypeTableDetails = []
                this.api = 'updateSheets'
                this.form.sheet_id = this.sheet.sheet_id
                this.form.meta.shifts=this.sheet.meta.shifts
                this.isTranspose=this.sheet.meta.isTranspose
            }
            this.form.sheet_type = 'consolidatedShiftUsageDataSheet'
            this.getShifts()
        },
        data(){ 
            return {
                showDialog:false,
                api:'createSheets',
                selected:[],
                selectedInRow:[],
                selectedInColumn:[],
                rowContains:[],
                columnContains:[],
                tagTypeTableDetails:[],
                processTableDetails:[],
                shiftOptions:[],
                process:null,
                addTagTypes:false,
                machine:null,
                tagType:null,
                process_label:null,
                tagType_label:null,
                machineOptions:[],
                processTotal:false,
                generalSheetValid:false,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                isTranspose:false,
                timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                // {label:'YTD', value:'ytd'},
                ],
                dateFormatOption:[
                    {label:"YYYY-MM-DD",value:"%Y-%m-%d"},
                    {label:"DD-MM-YYYY",value:"%d-%m-%Y"},
                ],
                form:{
                    name:null,
                    sheet_type:'general',
                    meta:{
                        process_list:{},
                        timeframe:'mtd',
                        shifts:[],
                        isTranspose:false
                    }
                },
                objectRules:[
                    v => !!v || 'Required',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
                ],
                numberRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
                nonNegativeRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
            }
        },
        computed:{
        processOptions(){
            let op=[]
          
              let g=this.$store.state.processes
            //   let g=this.$store.state.processes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{process_id:i.process_id,process_name:i.name}, label: i.name })
                }
          return op
        },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        },
        methods:{
            handleCheckboxChange(item) {
                for (let i of this.processTableDetails) {
                    if (i.process_id === item.process_id) {
                    i.processTotal = item.processTotal;
                    }
                }
                this.sheetSubmit(false)
            },
            createTagTypeArray(value){
                this.tagTypeTableDetails = value
            },
           close(){
                this.$emit("close")
            },
            getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
          this.shiftOptions = op
        },
            addProcessToList(){
                if(this.tagTypeTableDetails.length>0){

                    if(!this.processTableDetails){
                        this.processTableDetails = []
                    }
                    let add = true
                    for(let i of this.processTableDetails){
                        if(i['process_id']===this.process.process_id){
                            add = false
                        }
                    }
                    if(add===true){
                        this.processTableDetails.push(
                            {label:this.process_label,process_id:this.process.process_id,tag_types:this.tagTypeTableDetails,processTotal:this.processTotal}
                        )
                    }else{
                        this.loading=false
                        this.info="The Process is already added"
                        this.showDismissibleAlert=true
                    }
                    this.tagTypeTableDetails=[]
                }else{
                    this.loading=false
                    this.info="Please Add Tag Type First"
                    this.showDismissibleAlert=true
                }
                this.processTotal=false
            },
            addTagTypeToList(){
                if(!this.tagTypeTableDetails){
                    this.tagTypeTableDetails = []
                }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
            },
            remove(m){
                this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.label!=m.label)
            },
            sheetSubmit(close){
                this.$refs.generalSheetForm.validate()
                if(this.generalSheetValid){
                    this.loading=true
                    this.form.meta.process_list['tag_types'] = this.tagTypeTableDetails
                    this.form.meta.process_list = this.processTableDetails
                    this.form.meta.isTranspose = this.isTranspose
                    let d=Object.assign({}, this.form)
                    //d.target=this.emails.replaceAll(' ','').split(',')
                    axios.post(this.$store.state.api+this.api,d,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$emit('update')
                                this.$store.dispatch('refreshSheets')
                                this.$refs.generalSheetForm.reset()
                                if(close){

                                    this.close()
                                }
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            onProcessReset(){
            this.process=null
            this.process_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
            onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
            deleteFromProcessTable(item){
            this.processTableDetails=this.processTableDetails.filter(x=>x.process_id!=item.process_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },
              cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
        },
        watch:{
            isTranspose(){
                if(this.isTranspose===null){
                    this.isTranspose=false
                }
            }
    }
    
    }
    </script>