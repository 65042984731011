<template>
  <div class="text-center">
    <v-btn
     :background-color="$store.getters.getColorPalette().background1ColorCode"
    :color="$store.getters.getColorPalette().btnborderColorCode"

      class="list"
      small
  :dark="$store.getters.getColorPalette().isDark"
      outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().create_combo_param}} <v-icon
        right
       :dark="$store.getters.getColorPalette().isDark"
     :color="$store.getters.getColorPalette().deletebtnColor"
      >mdi-gesture-tap
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
       :color="$store.getters.getColorPalette().backgroundColorCode" 
      >
        
       <CreateComboParam v-on:close="dialog=false" />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateComboParam from '@/components/crud_components/CreateComboParam'

export default {
    name:'CreateComboParamBottomSheet',
    components:{
        CreateComboParam
    },
    data(){
        return {
            dialog:false,
                
      }
    },
    
}
</script>
<style scoped>
.list{
   border:3px solid;
  border-radius:20px;

}
   .v-dialog__content{
      transform:scale(0.75)
    }
.theme--light.v-sheet{
   background-color: #263238;
}
</style>