<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row>
            <v-col cols="12" align="center">
                <CalculatedParamTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import CalculatedParamTable from '@/components/lists/derived/CalculatedParamTable'
export default {
    name:'CalculatedParamPage',
    components:{
        CalculatedParamTable
    }
}
</script>