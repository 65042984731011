<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row>
            <v-col cols="12" align="center">
                <BatchMovementPanel/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import BatchMovementPanel from '@/components/panels/manufacturing/BatchMovementPanel'
export default {
    name:'BatchMovementPage',
    components:{
        BatchMovementPanel
    }
}
</script>