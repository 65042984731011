<template>
    
    <v-container fluid >
            <v-row >
                <v-col>
                    <v-card flat>
                      <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
      <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon> {{ $store.getters.getTextMap().add_usage }}
          </v-tab>
      <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon> {{ $store.getters.getTextMap().add_hub }}
          </v-tab>
      <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon> {{ $store.getters.getTextMap().add_maintainer }}
          </v-tab>
      <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon> {{ $store.getters.getTextMap().add_parent_org }}
          </v-tab>
      <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon> Update Payment Due Date
          </v-tab>
      
    </v-tabs>
    <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
  </v-sheet>
  
  </v-card>
                </v-col>
       
            </v-row>        
            <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-tabs-items v-model="tab" :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
        
          >
          <UpdateParamDailyData />
            
          </v-tab-item>
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
        
          >
          <AddHub />
            
          </v-tab-item>
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
        
          >
          <AssignMaintainerToOrg />
          
          </v-tab-item>
          <v-tab-item
          :dark="$store.getters.getColorPalette().isDark"
          
          >
          <AssignParentToOrg />
            
          </v-tab-item>
          <v-tab-item
          :dark="$store.getters.getColorPalette().isDark"
          
          >
          <UpdatePaymentDueDate />
            
          </v-tab-item>
        </v-tabs-items>
         <!-- <v-tabs-items v-model="tab" class="">
      <v-tab-item>
        <ShiftsPage/>
      </v-tab-item>
      <v-tab-item>
        <ListsPage/>
      </v-tab-item>
      <v-tab-item>
        <ParamEquationsPage/>
      </v-tab-item>
      <v-tab-item>
        <ComboParamPage/>
      </v-tab-item>
      <v-tab-item>
        <CalculatedParamPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <SheetsPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <WordPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <OEMDevicesPage/>
      </v-tab-item>
         </v-tabs-items> -->
        </v-container>
    
</template>

<script>
import AssignMaintainerToOrg from '@/components/crud_components/admin/AssignMaintainerToOrg'
import AssignParentToOrg from '@/components/crud_components/admin/AssignParentToOrg'
import AddHub from '@/components/crud_components/admin/AddHub'
import UpdateParamDailyData from '@/components/crud_components/admin/UpdateParamDailyData.vue'
import UpdatePaymentDueDate from '@/components/crud_components/admin/UpdatePaymentDueDate.vue'
export default {
    name:'EntureAdmin',
    components:{
      AssignMaintainerToOrg,
      AssignParentToOrg,
      AddHub,
      UpdateParamDailyData,
      UpdatePaymentDueDate
    },
    data(){
        return {
            show:'',
            tab:null
        }
    },
  //   created() {
  //     this.$router.push('/derived/shifts');
  // },
    mounted() {
      document.title='Enture Administration'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.theme--light.v-tabs-items{
  background-color:#263238
}
</style>