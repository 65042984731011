<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-container fluid >
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="1" align="right"><v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn></v-col>
            </v-row>
            <v-row  no-gutters>
                <v-col>
                    <v-container class="card-container" fluid>
                        <v-row>
                            <v-col cols="8">
                                <v-row  no-gutters>
                                    <v-col>
                                        <h3 :style="{'color':'#64B5F6'}">{{ activity&&activity.name?activity.name:'' }}</h3>
                                        <v-card class=" fixed-height-row-sub" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                            <v-container class="parameter-card-container" fluid>
                                                <v-row >
                                                    <v-col>
                                                        <v-card  class=" fixed-height-row-sub" flat :style="{ 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                                            <v-row>
                                                                <v-col align="left">
                                                                    <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                                        {{task.name}}
                                                                    </v-card-title>
                                                                </v-col>
                                                                <v-col cols="auto" class="d-flex" align="right">
                                                                    <CreateTaskModal class="mx-1" :task="task" v-on:success="getActivityById"/>
                                                                    <DeleteConfirmation  v-on:confirm="deleteTask(task)"  title="Delete Confirmation"  description="Are you sure you want to delete this Bar TD widget?">
                                                                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_task}}<v-icon small color="red">mdi-delete</v-icon></v-btn>  
                                                                    </DeleteConfirmation>
                                                                    <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark"  color="#66BB6A" small outlined text @click="showWatchList=!showWatchList">
                                                                        <v-icon class="mx-1" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode">mdi-eye</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                            <v-card v-if="showWatchList" flat outlined :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                                                                <v-row no-gutters>
                                                                    <v-col>
                                                                        <v-card-title  :style="{background: $store.getters.getColorPalette().background2ColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                                            {{$store.getters.getTextMap().watchers}}
                                                                        </v-card-title>
                                                                    </v-col>
                                                                    <v-col>
                                                                       <AddTaskWatcherModal :task="task" v-on:close="getTaskWatchers"/>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode"></v-divider>
                                                                <v-row no-gutters v-if="!loading && TaskWatchers ">
                                                                    <v-col cols="12">
                                                                    <v-simple-table :style="{background: $store.getters.getColorPalette().background2ColorCode}">
                                                                        <template v-slot:default>
                                                                        <!-- <thead :style="{background: $store.getters.getColorPalette().background2ColorCode}">
                                                                            <tr>
                                                                            <th class="text-left">
                                                                                <strong>Watcher</strong>
                                                                            </th>
                                                                            </tr>
                                                                        </thead> -->
                                                                        <tbody>
                                                                            <tr
                                                                            :key="index" v-for="(item,index) in TaskWatchers"
                                                                            >
                                                                            <td>{{ item.userid }}</td>                     
                                                                            <td>
                                                                            <DeleteConfirmation  v-on:confirm="deleteFromTable(item.userid)"  title="Delete Confirmation"  description="Are you sure you want to delete this Bar TD widget?">
                                                                            <v-icon small color="red">mdi-delete</v-icon>  
                                                                            </DeleteConfirmation>
                                                                        </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        </template>
                                                                    </v-simple-table>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>  
                                                            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:'#A5D6A7'}">
                                                                {{$store.getters.getTextMap().description}}
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.msg}}
                                                            </v-card-subtitle>
                                                            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:'#A5D6A7'}">
                                                                {{$store.getters.getTextMap().asset_under_maintenance}}
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{asset&&asset.name?asset.name:''}}
                                                            </v-card-subtitle>
                                                            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                                                            <v-card-subtitle>
                                                                {{$store.getters.getTextMap().comments}}
                                                            </v-card-subtitle>
                                                            <v-card flat outlined :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                                                                <v-col cols="auto" class="d-flex">
                                                                    <v-avatar class="mx-2" size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>{{ $store.state.user.userid.charAt(0) }}</v-avatar>
                                                                    <v-text-field dense outlined v-if="!loading" v-model="comment_form.comment" :counter="200"  label="Add a comment"></v-text-field>
                                                                    <v-btn class="mx-1" :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small text @click="addComment"> {{$store.getters.getTextMap().add}}</v-btn>
                                                                </v-col>
                                                                <v-row class="my-1" no-gutters v-bind:key="comment.comment" v-for="comment in comments">
                                                                    <v-col align="left" cols="auto" class="d-flex">
                                                                        <v-avatar class="mx-1" size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>{{ comment.userid.charAt(0) }}</v-avatar>
                                                                        <v-card-subtitle>
                                                                            {{comment&&comment.comment?comment.comment:''}}
                                                                        </v-card-subtitle>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col>
                                        <h3 :style="{'color':'#FFA726'}"> {{$store.getters.getTextMap().task_details}}</h3>
                                        <v-card class=" fixed-height-row-sub" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                            <v-container class="parameter-card-container" fluid>
                                                <v-row>
                                                    <v-col>
                                                        <v-card class=" fixed-height-row-sub" flat :style="{ 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                                            <v-row>
                                                                <v-col cols="auto" class="d-flex" align="right">
                                                                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                                                    <v-select
                                                                        :dark="$store.getters.getColorPalette().isDark" 
                                                                        :color="$store.getters.getColorPalette().inputtextColor"
                                                                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                                                        v-if="!loading"
                                                                        v-model="form.new_status"
                                                                        :items="statusOptions"
                                                                        :label="$store.getters.getTextMap().status"
                                                                        outlined
                                                                        dense
                                                                        item-text="label"
                                                                        item-value="value"
                                                                        required
                                                                        :rules="selectRules"
                                                                    ></v-select>    
                                                                    <v-btn align-items="center" class="mx-1" :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small text @click="updateStatus">{{$store.getters.getTextMap().update}}</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                               {{ $store.getters.getTextMap().assignee }}<v-icon class="mr-2" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-account</v-icon>
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.assignee}}
                                                            </v-card-subtitle>
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                                {{ $store.getters.getTextMap().manager }}<v-icon class="mr-2" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-shield-account</v-icon>
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.manager}}
                                                            </v-card-subtitle>
                                                            <!-- <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:'#64B5F6'}">
                                                                Status <v-icon class="mr-2" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>mdi-list-status</v-icon>
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.status}}
                                                            </v-card-subtitle> -->
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:'#EF9A9A'}">
                                                                {{ $store.getters.getTextMap().start_time }} <v-icon class="mr-2" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>mdi-clock-time-eight</v-icon>
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.start_time}}
                                                            </v-card-subtitle>
                                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:'#A5D6A7'}">
                                                                {{ $store.getters.getTextMap().end_time }}<v-icon class="mr-2" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small>mdi-clock-time-four</v-icon>
                                                            </v-card-title>
                                                            <v-card-subtitle>
                                                                {{task.end_time}}
                                                            </v-card-subtitle>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</v-dialog>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment';
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import AddTaskWatcherModal from '@/components/modals/application/taskmanagment/AddTaskWatcherModal.vue';
import CreateTaskModal from '@/components/modals/application/taskmanagment/CreateTaskModal.vue';
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'TaskDetails',
    props:['task'],
    components:{
        InfoAlert,
        AddTaskWatcherModal,
        CreateTaskModal,
        DeleteConfirmation
    },
    data(){
        return {
            dialog:false,
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activity:null,
            showWatchList:false,
            showAddWatcher:false,
            TaskWatchers:[],
            asset:null,
            comments:[],
            comment_form:{
                comment:null,
            },
            form:{
                new_status:null
            },
            statusOptions:[
                {label:'TODO',value:'TODO'},
                {label:'INPROGRESS',value:'INPROGRESS'},
                {label:'DONE',value:'DONE'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
        }
    },
    mounted(){
        this.dialog=true
        if(this.task && this.task.task_id){
            this.form.new_status=this.task.status
            this.getActivityById()
            this.getTaskWatchers()
            this.getAssetDetails()
            this.getComments()
            this.task['start_time']=moment(this.task.start_time).format('YYYY-MM-DD')+':'+moment(this.task.start_time).format('HH:mm')
            this.task['end_time']=moment(this.task.end_time).format('YYYY-MM-DD')+':'+moment(this.task.end_time).format('HH:mm')
        }
    },
    methods:{
        addComment(){
            this.comment_form['task_id']=this.task.task_id
            this.comment_form['userid']=this.$store.state.user.userid
            axios.post(this.$store.state.api+'addTaskComment',this.comment_form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.getComments()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        getComments(){
            axios.post(this.$store.state.api+'getTaskComments',{task_id:this.task.task_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.comments=response.data.comments
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        getAssetDetails(){
            axios.post(this.$store.state.api+'getMachineById',{machine_id:this.task.asset_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.asset=response.data.machine
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        getActivityById(){
            axios.post(this.$store.state.api+'getActivityById',{activity_id:this.task.activity_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.activity=response.data.activity
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        getTaskWatchers(){
            axios.post(this.$store.state.api+'getTaskWatcherByTaskId',{task_id:this.task.task_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.TaskWatchers=response.data.watchers
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        updateStatus(){
            this.form['task_id']=this.task.task_id
            axios.post(this.$store.state.api+'updateTaskStatus',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshTasks')
                        this.$emit('close')
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        deleteTask(item){
            axios.post(this.$store.state.api+'deleteTask',{task_id:item.task_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.getActivityById()
                this.getTaskWatchers()
                this.getAssetDetails()
                this.getComments()
                this.$store.dispatch('refreshTasks')
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteFromTable(userid){
            let payload={
                userid,
                task:this.task
            }
            axios.post(this.$store.state.api+'removeTaskWatcher',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.getTaskWatchers()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        }
    }
}
</script>
<style scoped>
.card-container {
  max-height: 600px; /* Set the desired maximum height */
  overflow-y: auto;  
}
.card-container h3 {
  color:#66BB6A;
  font-weight: bold;
  /* Set the desired maximum height */
  /* overflow-y: auto;   */
}
.parameter-card-container {
    height: 300px;
  max-height: 300px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
.axis-card-container {
    height: 120px;
  max-height: 120px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
</style>