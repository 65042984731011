<template>
    <span class="text-center">
        <v-btn @click="dialog = !dialog" text :color="$store.getters.getColorPalette().accentCode" small :style="{transform:'scale(0.8)'}">{{$store.getters.getTextMap().dispatch}}<v-icon small>mdi-truck-cargo-container</v-icon></v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <WriteBatchShipping :batch="batch" v-on:close="dialog=false" v-on:success="$emit('success')" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import WriteBatchShipping from '@/components/crud_components/manufacturing/WriteBatchShipping'
export default {
    name:'WriteBatchShippingModal',
    props:['batch'],
    components:{
        WriteBatchShipping  
    },
    data(){
        return {
            dialog:false
        }
    },
}
</script>