<template>

  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-row no-gutters>
          <v-col align-self="center">
              <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().data_historian }}</span>
          </v-col>
          <v-spacer></v-spacer>
            <v-col align="right" align-self="center" v-if=" $store.state.user ">
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
      </v-row>
      <v-form
  ref="usageForm"
  v-model="valid"
  
>
      <v-row>
          
        <v-col v-if="formType==='deviceForm'">
        <WorkspaceDeviceParamForm :multiple="true" v-on:value="updateParamList"/>
        </v-col>
        <v-col v-else>
              <WorkspaceMachineParamForm :multiple="true" v-on:value="updateParamList"/>
        </v-col>
    
      </v-row>

      <v-col align="right" align-self="center" v-if=" $store.state.user">
              <v-radio-group v-model="viewType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().trend_view" value="trendView"></v-radio>
                <v-radio :label="$store.getters.getTextMap().table_view" value="tableView"></v-radio>
              </v-radio-group>
            </v-col>
      <!-- Raw,Trend and Normalised radio-button section -->
      <v-col align-self="center">
              <v-skeleton-loader
                v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-radio-group
      v-model="normalised"
      row :dark="$store.getters.getColorPalette().isDark"
    >
      <v-radio
        value="raw"             
>
<template v-slot:label>{{ $store.getters.getTextMap().raw }} <InfoComponent hint="This is Raw data collected from the assets. This data is collected based on change or is collected after a minute from the last recorded data" /></template>
</v-radio>

      <v-radio
        value="trend"
      ><template v-slot:label>{{ $store.getters.getTextMap().trend }}<InfoComponent hint="This is trend data of a parameter. This showcases trend in any parameter. In case of counter parameters (such as kWH), it will show the minute wise change." /></template></v-radio>
      <v-radio
        
        value="normalised"
      ><template v-slot:label>{{ $store.getters.getTextMap().normalised }}<InfoComponent hint="This is minute wise data and will have start of a minute timestamp. This doesn’t depend on the change." /></template></v-radio>
    </v-radio-group></v-col>  
            <v-col align-self="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    </v-col>




      <v-row>
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>  
      </v-menu>
            </v-col>

       
             {{ $store.getters.getTextMap().from }}
              <v-col align-self="center">
               <TimePicker v-model="fromTime"/>
            </v-col>
       
          </v-row>
<v-row>
  
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            outlined
            dense
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
         
        {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 

          
            
          </v-row>
          <v-row>

            <v-col class="my-2" align="center" align-self="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
  
    
                <v-btn
                v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
           
            small
            @click="onSubmit"
          >
            {{ $store.getters.getTextMap().generate_report }}
          </v-btn>
            </v-col>
          </v-row>
      </v-form>
      <!-- <v-row no-gutters>

        
        <v-col>
          <v-col>
              
  <v-chip
    class="ma-2"
    close
    small
    color="teal"
    text-color="white"
    @click:close="remove(param)" 
    v-bind:key="param.parameter" v-for="param in form.parameters"
  >
   {{param.name}}
  </v-chip>
          </v-col>
        </v-col>
      </v-row> -->
      <v-col v-if="viewType!=='trendView' && graphData && graphData.length>0" align="center" align-self="center">
              <v-btn 
                small
             :color="$store.getters.getColorPalette().downloadbtnColor"
                outlined
                @click="download">
               {{ $store.getters.getTextMap().download_table }}
                </v-btn>
                <a ref="csvDownload" hidden></a>
            </v-col>
      <v-row v-if="graphData.length>0 " no-gutters>
          <v-col    :color="$store.getters.getColorPalette().accentCode">
              <strong></strong>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col v-if="viewType==='trendView'">
            
               <div ref="graph"></div>
          </v-col>
          <v-col v-else>
            <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && graphData && graphData.length>0 " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{device_machine_label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().values }}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.param_label" v-for="item in graphData"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ Math.round(item.usage) }}</td>
                                            
                                            <td hidden><v-icon small color="red" @click="deleteFromMachineTable(item)">mdi-delete</v-icon></td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
          </v-col>
      </v-row>
      
      <v-divider></v-divider>
      
  </v-container>
</template>
<script>
//import DualAxisLineGraphByTimestamp from '@/components/graphs/DualAxisLineGraphByTimestamp';
//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import TimePicker from '@/components/inputs/TimePicker'
import InfoAlert from '@/components/InfoAlert'
import InfoComponent from '@/components/InfoComponent'
export default {
  name:'dataHistorian',
  components:{
    WorkspaceDeviceParamForm,
    WorkspaceMachineParamForm,
    TimePicker,
    InfoAlert,
    InfoComponent
  },
  data(){
      return {
          form:{
              date:null,
              parameters:null,
          },
          formType:'machineForm',
          report:{
              date:null,
              parameter:null,
            },
            normalised:'raw',
            tableData:[],
            valid:false,
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            toTime:null,
            layout:{
              showlegend:true,
              font:{color:this.$store.getters.getColorPalette().accentCode},
              plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
              paper_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
              autosize: true,
              xaxis: {
                  tickangle: -12,
                  zeroline: false,
                  showgrid: true,
                  showticklabels: true,
                  color:this.$store.getters.getColorPalette().accentCode
                },       
                yaxis: {
                  showticklabels: true,
                  color:this.$store.getters.getColorPalette().accentCode
                },
                uniformtext: {
                  "mode": "hide",
                  "minsize": 1
                }
              },
              fromTime:null,
              viewType:'tableView',
              graphData:[],
              device_machine_label:null,
              showDismissibleAlert:false,
              loading:false,
              info:'',
              objectRules:[
                v => !!v || this.$store.getters.getTextMap().required,
              ],
              ISODateRules:[
                  v => !!v ||this.$store.getters.getTextMap().required,
                  v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
              ]
      }
  },
  computed: {
    api(){
      switch(this.normalised){
        case 'normalised':
          return 'getNormalisedDataByParameterBetweenTimestamp';
        case 'trend':
          return 'getUsageDataByParamBetweenTimestamp'
        default:
          return 'getDataByParameterBetweenTimestamp'
      }
    },
      workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i, label: i.name })
        }
        return op
      },
      dateRangeText () {
        if(this.form.date==null){
          return null
        }
      return this.form.date
    },
    timeUnit(){
      if(this.toTimeStamp && this.fromTimeStamp && this.toTimeStamp - this.fromTimeStamp>60*60*4 ){
        return "hour"
      }else{
        return 'minute'
      }
    },
    fromTimeStamp(){
      if(this.form.from_date){
          let dt=this.form.from_date.toString()
          if(this.fromTime){
            dt+=' '+this.fromTime.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').format("X"));
        
      }else{
        return null
      }
    },
    toTimeStamp(){
      if(this.form.to_date){
          let dt=this.form.to_date.toString()
          if(this.toTime){
            dt+=' '+this.toTime.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').format("X"));
      }else{
        return null
      }
    },
    label1(){
        if(this.report.parameter){
          let parameter=this.$store.getters.getParamById(this.report.parameter)
          if(parameter.name){
            return parameter.name
          }else{
            return this.report.parameter
          }
        }else{
          return ''
        }
      },
      unit(){
        if(this.report.parameter){
          let parameter=this.$store.getters.getParamById(this.report.parameter)
          if(parameter.unit){
            return parameter.unit
          }else{
            return null
          }
        }else{
          return ''
        }
      },
      deviceOptions(){
        let op=[]
        if(this.form.workspace && this.form.workspace.workspace_id ){
            let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
            for(let i of g){
              if(i && i.name ){
                op.push({value:i, label: i.name })
              }else{
                op.push({value:i, label: i.snode_id })
              }
          }
        }
        return op
      },
      paramOptions(){
        let op=[]
        if(this.form.device && this.form.device.device_id ){
          let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
          for(let i of g){
              if(i && i.name ){
                op.push({value:i, label: i.name })
              }else{
                op.push({value:i, label: i.parameter })
              } 
          }
        }
        return op
      },
  },
  methods: {
    populateGraph(){
      setTimeout(()=>{Plotly.newPlot(this.$refs.graph, this.graphData, this.layout,{displaylogo: false});},2)
    },
    updateParamList(value){
      this.device_machine_label = value.device.name
        this.form.parameters=value.parameter
        // console.log(value.parameter)
      },
    remove(param){
      // console.log(param)
      this.form.parameters=this.form.parameters.filter(x=>x.parameter!=param.parameter)
    },
    getMachineTableData(){
      this.tableData=[]
      for(let i of this.graphData){
            let temp={}
            if(temp['param_label']!==i['name']){
              temp['param_label']=i['name']
              temp['value']=0
            }
            for(let j of i['y']){
              temp['value'] = (Number(temp['value']) + Number(j))
            }
            this.tableData.push(temp)
          }
    },
    async onSubmit(){
        this.$refs.usageForm.validate()
        if(this.valid){
          this.loading=true
          this.graphData=[]
          let payload={
            parameter:this.form.parameters,
            from_timestamp:this.fromTimeStamp,
            to_timestamp:this.toTimeStamp
          }
          try{
            for(let p of this.form.parameters){
              payload['parameter']=p['param_id']
              let name=p['name']
                let response = await axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                  this.machineUsageData=response.data.data
                  if(response.data.status==='success'){
                  // let data={
                  //   x: [],
                  //   y: [],
                  //   name:name,
                  //   type: 'scatter'
                  // }
                  // for (let i of response.data.data){
                  //     data['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                  //     data['y'].push(Number(i.data))
                  // }
                  let data=response.data.data.reduce((accumulator, currentValue) => {
                    accumulator['x'].push(moment.unix(currentValue.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                    accumulator['y'].push(Number(currentValue.data))
                    accumulator['usage']+=Number(currentValue.data)
                      return accumulator
                    },{
                    x: [],
                    y: [],
                    name:name,
                    type: 'scatter',
                    usage:0
                  })
// console.log(p['processing'])
                  switch(p['processing']){

                          case 'difference':
                            if(this.normalised!='trend'){

                              data['usage']=data['y'][data['y'].length-1]-data['y'][0]
                              
                            }                           

                            break;
                          case 'negative_difference':
                            if(this.normalised!='trend'){

                              data['usage']=data['y'][0]-data['y'][data['y'].length-1]
                            }                           

                            break;

                          case 'mean':


                            data['usage']=Number(data['usage'])/Number(data['y'].length)

                            break;

                          }
                          if(data['usage']<0){
                            data['usage']=0
                          }

                  this.graphData.push(data)
                  if(this.viewType==='trendView'){
                    Plotly.newPlot(this.$refs.graph, this.graphData, this.layout,{displaylogo: false});
                  }
                  this.report['parameter']=this.form.parameter
                  this.report['date']=this.form.date
                }else{
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                  this.loading=false
                }
                  this.loading=false
                }
                // this.getMachineTableData()
            }catch(err){
              this.showDismissibleAlert=true
              this.info=err
              this.loading=false
            }
        }
      },
      download() {
        let header = [this.device_machine_label, 'Values'];
        let body = this.graphData.map(item => [item.name, Math.round(item.usage)]);
        let csvHeader = header.join(',') + '\n';
        let csv = csvHeader + body.map(row => row.join(',')).join('\n');
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.device_machine_label+ '.csv';
        hiddenElement.click();
    }
  },
  watch:{
    viewType(val){
      if(val==='trendView' && this.graphData && this.graphData.length>0){
        this.populateGraph()
        

      }
    }
  }
}
</script>