<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
        ref="logForm"
        v-model="valid"
        
      >
        <v-row>
            <v-col align-self="center">
                  <v-skeleton-loader
            v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
                    <v-menu
            v-if="!loading"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
             :dark="$store.getters.getColorPalette().isDark" 
                v-model="form.date"
                :label="$store.getters.getTextMap().date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="objectRules"
                small
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker   :dark="$store.getters.getColorPalette().isDark" 
              v-model="form.date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
                </v-col>
                <v-col align-self="center">
                  <v-skeleton-loader
            v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
                    <v-menu
            v-if="!loading"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
             :dark="$store.getters.getColorPalette().isDark" 
                v-model="form.to_date"
                :label="$store.getters.getTextMap().to_date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="objectRules"
                small
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker   :dark="$store.getters.getColorPalette().isDark" 
              v-model="form.to_date"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
                </v-col>
    
              
                <v-col >
                    <v-skeleton-loader
            v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                    ref="submitBtn"
              :color="$store.getters.getColorPalette().submitbtnColor"
                @click="onSubmit"
              >
             {{ $store.getters.getTextMap().fetch_targets }}
              </v-btn>
              
                </v-col>
                
        </v-row>
        </v-form>
        <v-row>
                    <v-col cols="12">
                        <v-card  :dark="$store.getters.getColorPalette().isDark" >
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
           :dark="$store.getters.getColorPalette().isDark" 
          ></v-text-field>
        </v-card-title>
                        <v-data-table
          :headers="headers"
          :items="logs"
          item-key="index"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
        loading-text="Loading... Please wait"
        :dark="$store.getters.getColorPalette().isDark" 
        >
        <template v-slot:top>
          <v-toolbar
            flat
            :dark="$store.getters.getColorPalette().isDark" 
          >
            <v-toolbar-title >{{ $store.getters.getTextMap().day_wise_targets }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <AddTargetDayWiseDataModal :target="target" v-on:success="onSubmit" />
                </v-toolbar>
              </template>
              
                        </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
                
    
    </v-container>
        
    </template>
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import InfoAlert from '@/components/InfoAlert'
    
    import AddTargetDayWiseDataModal from '@/components/modals/derived/AddTargetDayWiseDataModal.vue'

    export default {
        name:'TargetDayWiseTable',
        props:{
            'target':{
                required:true
            }
    },
        components:{
            InfoAlert,
            AddTargetDayWiseDataModal
        },
        mounted(){
            this.form.date=moment().subtract(1,'days').format('YYYY-MM-DD')
            this.form.to_date=moment().add(15,'days').format('YYYY-MM-DD')
            //let btn=this.$refs.submitBtn
            //btn.click()
            this.onSubmit()
        },
        data(){
            return {
                search:'',
                 valid:false,
                    menu:false,
                    menu1:false,
                    menu2:false,
                    menu3:false,
                graphData:[],
                showDismissibleAlert:false,
                loading:false,
                info:'',
                expanded: [],
                logs:[],
                form:{
                    date:null
                },
                headers: [
              {
                text: 'Date',
                align: 'start',
                filterable: false,
                value: 'date',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              { text: 'Target Value', value: 'target_val', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            //   { text: 'Logged By', value: 'logged_by', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              ],
            
                objectRules:[
                    v => !!v || 'Required',
                ],
            }
        },
        computed:{
        //     fromTimeStamp(){
        //     if(this.form.date){
              
        //         let dt=this.form.date.toString()
        //         if(this.fromTime){
        //           dt+=' '+this.fromTime.toString()
        //           return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
        //         }
        //         return Number(moment(dt,'YYYY-MM-DD').format("X"));
              
        //     }else{
        //       return null
        //     }
        //   },
        //   toTimeStamp(){
        //     if(this.form.date){
              
        //         let dt=this.form.date.toString()
        //         if(this.form.to_date){
        //           dt=this.form.to_date.toString()
        //         }
        //         if(this.toTime){
        //           dt+=' '+this.toTime.toString()
        //           //return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
        //         }else{
        //             dt+=' 23:59'
        //         }
        //         //return Number(moment(dt,'YYYY-MM-DD').format("X"));
        //         return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              
        //     }else{
        //       return null
        //     }
        //   },
        },
        methods: {
          getFormattedTime(t){
            return moment(t).format('YYYY-MM-DD HH:mm')
          },
            onSubmit(){
                this.$refs.logForm.validate()
                if(this.valid){
                  this.loading=true
                  //let fromTimeStamp=this.fromTimeStamp
                  //let toTimeStamp=this.toTimeStamp
                  let payload={
          from_date:this.form.date.toString(),
          to_date:this.form.to_date.toString(),
          target_id:this.target.target_id
        }
        
        //console.log(fromTimeStamp)
        axios.post(this.$store.state.api+'getTargetDayWiseDataByID',payload,{headers: {
          Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
          console.log("Target data received")
          //console.log(response.data.monthlyData)
          //context.state.monthlyData=response.data.monthlyData
    //console.log(response.data.data)
    //console.log('something')
    
    
    if(response.data.status==='success'){
    let data=[]
    let count=0
    for (let i of response.data.data){
        i['date'] = moment(i['date']).format('YYYY-MM-DD');
        i['index']=count++
        data.push(i)
      
    }
    this.logs=data
          //console.log(response.data.data)
          
          if(this.logs.length==0){
              this.info = "No Plans show. Please create Day wise Plan";
                this.showDismissibleAlert = true;
          }
        }else{
          this.info = response.data.msg;
          //this.info = d;
                this.showDismissibleAlert = true;
          //console.log(response.data.msg)
          //this.loading=false
        }
          this.loading=false
    
        })
        .catch(err=>{
        this.showDismissibleAlert=true
                this.info=err
                this.loading=false
                })
    
        
        
        
        
                }
            }
        
        },
    }
    </script>