<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card flat :dark="$store.getters.getColorPalette().isDark">
        <v-row no-gutters>
          <v-col>
            <v-card-title>{{shift.name?shift.name:shift.list_id}} </v-card-title>
          </v-col>
          <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="d-flex" align="right" align-self=center>
            <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
              <v-btn class="mr-1" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="addDialog=!addDialog">
                {{$store.getters.getTextMap().add}} {{$store.getters.getTextMap().shift}}
                <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-plus</v-icon>
              </v-btn>
              <v-btn :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="addMachine=!addMachine">
                {{$store.getters.getTextMap().add}} {{$store.getters.getTextMap().machine}}
                <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-engine-outline</v-icon>
              </v-btn>
              <v-btn @click="editMode=!editMode"  class="mx-1" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text>
                {{$store.getters.getTextMap().edit}}
                <v-icon
                  small
                  class="ml-1"
                >mdi-pencil
                </v-icon>
              </v-btn>
              <DeleteConfirmation v-on:confirm="deleteShift()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                <v-btn :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text>
                  {{$store.getters.getTextMap().delete}}
                  <v-icon class="ml-1" small :color="$store.getters.getColorPalette().alertIconColor">mdi-delete</v-icon>
                </v-btn>
                </DeleteConfirmation>
          </span>
          </v-col>
        </v-row>
        <v-divider  v-if="addDialog || editMode"  :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row no-gutter v-if="editMode">
          <v-col>
            <CreateShift :shift="shift" v-on:close="editMode=false" v-on:update="$emit('update')"/>
          </v-col>
        </v-row>
        <v-divider  v-if="addDialog"  :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row no-gutters>
          <v-col>
            <AddShiftData :shift="shift" v-if="addDialog" />
          </v-col>
        </v-row>
        <v-divider  v-if="addMachine"  :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row no-gutters v-if="addMachine" >
          <v-col>
            <WorkspaceMachineForm :multiple="false" v-on:value="updateMachineList"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-simple-table :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().accentCode}" height="160px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().name}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().from}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().to}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().action}}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in shiftData" :key="item.from_time">
                    <td>{{ item.name }}</td>
                    <td>{{ item.from_time }}</td>
                    <td>{{ item.to_time }}</td>
                    <td v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.engineerAccessLevel">
                      <DeleteConfirmation v-on:confirm="deleteShiftData(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation> 
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-card-title>{{$store.getters.getTextMap().machines}} </v-card-title>
        <v-row>
          <v-col>
            <v-simple-table :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().accentCode}" height="160px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().machine_id}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().name}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().action}}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in machine_list" :key="item.machine_id">
                    <td>{{ item.machine_id }}</td>
                    <td>{{ machineDetails(item.machine_id) && machineDetails(item.machine_id).name? machineDetails(item.machine_id).name:'-' }}</td>
                    <td v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.engineerAccessLevel">
                      <DeleteConfirmation v-on:confirm="deleteMachine(item.machine_id)"  title="Delete Confirmation"  description="Are you sure you want to delete this machine from the shift?">    
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation> 
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddShiftData from '@/components/crud_components/derived/AddShiftData'
import CreateShift from '@/components/crud_components/derived/CreateShift';
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm.vue'
export default {
name:'Shift',
props:['shift'],
components:{
    InfoAlert,
    AddShiftData,
    DeleteConfirmation,
    CreateShift,
    WorkspaceMachineForm
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        editMode:false,
        addMachine:false,
        machine_id:null,
        machine_list:[]
    }
},
mounted(){
  if(this.shift && this.shift.shift_id){
    this.getMachineListByShift()
  }
},
computed:{
    shiftData(){
      return this.$store.getters.getShiftDataById(this.shift.shift_id)
    },
},
methods:{
    machineDetails(machine_id){
      return this.$store.getters.getMachineById(machine_id)
    },
    getMachineListByShift(){
      axios.post(this.$store.state.api+'getMachinesByShift',{shift_id:this.shift.shift_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
              this.machine_list=response.data.data
              this.loading=false
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
        }).catch(err=>{
          this.loading=false
          this.info=err
          this.showDismissibleAlert=true
        })
    },
    updateMachineList(value){
      this.machine_id=value.machine.machine_id
    },
    addMachineToList(){
      let payload={
        shift_id:this.shift.shift_id,
        machine_id:this.machine_id
      }
      axios.post(this.$store.state.api+'AddMachineToShift',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
              this.getMachineListByShift()
              this.loading=false
              this.info='Machine Added'
              this.showDismissibleAlert=true
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
        this.machine_id=null
    },
    deleteShift(){
        axios.post(this.$store.state.api+'deleteShift',{shift_id:this.shift.shift_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
              this.$store.dispatch('refreshShifts')
              this.loading=false
              this.info='The Shift has been deleted'
              this.showDismissibleAlert=true
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    deleteShiftData(tuple){
        axios.post(this.$store.state.api+'deleteShiftData',tuple,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
              this.$store.dispatch('refreshShiftData')
              this.loading=false
              this.info='The Shift Data has been deleted'
              this.showDismissibleAlert=true
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
        }).catch(err=>{
          this.loading=false
          this.info=err
          this.showDismissibleAlert=true
        })
    },
    deleteMachine(machine_id){
      axios.post(this.$store.state.api+'deleteMachineFromShift',{shift_id:this.shift.shift_id,machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
              this.getMachineListByShift()
              this.loading=false
              this.info='The machine has been deleted from shift'
              this.showDismissibleAlert=true
            }else{
              this.loading=false
              this.info=response.data.msg
              this.showDismissibleAlert=true
            }
        }).catch(err=>{
          this.loading=false
          this.info=err
          this.showDismissibleAlert=true
        })
    },
    cancel(){
      this.showDialog=false
    },
    confirm(){
      this.showDialog=false
    }
  },
  watch:{
    machine_id(){
      this.addMachineToList()
    }
  }
}
</script>