<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().create_issue_voucher }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row dense>
                       
                        
                        
     
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="departmentID"
                            :items="departmentOptions"
                            :label="$store.getters.getTextMap().department"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-autocomplete>
                    </v-col>
                    <v-col v-if="departmentID">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="receiver"
                            :items="receiverOptions"
                            :label="$store.getters.getTextMap().receiver"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-autocomplete
                            v-if="!loading"
                            v-model="searchBy"
                            :items="searchByOptions"
                            :label="$store.getters.getTextMap().search"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-autocomplete>
                        </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="searchString"
                        :counter="25"
                        :label="$store.getters.getTextMap().search"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.code"
                        :counter="15"
                        :label="$store.getters.getTextMap().code"
                        required
                        ></v-text-field>
                    </v-col> -->
                    
                </v-row>
                <ItemCodeSelectorTable v-if="filteredItemList && filteredItemList.length>0" :items="filteredItemList" v-on:value="addItem" />
                    <v-row>
                        <v-col>

                            <v-simple-table :dark="$store.getters.getColorPalette().isDark" height="350px">
                                <template   v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().item_code}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().material_desc}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().uom}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().intended_qty}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in selectedItems"
                            :key="item.item_code"
                            >
                            
                            <td>{{ item.item_code }}</td>
                            
                            <td>{{ item.material_desc }}</td>
                            <td>{{ item.uom }}</td>
                            <td>
                                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    v-model="item.intended_qty"
                                    :counter="5"
                                    :label="$store.getters.getTextMap().intended_qty"
                                    outlined
                                    ></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="!loading">
                            <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{ $store.getters.getTextMap().submit  }}</v-btn>
                        </v-col>
                    </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import ItemCodeSelectorTable from '@/components/forms/ItemCodeSelectorTable.vue'
import axios from 'axios'
// import moment from 'moment'
export default {
    name:'CreateIssueVoucher',
    props:{

    },
    components:{
        InfoAlert,
        ItemCodeSelectorTable
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            departments:[],
            departmentID:null,
            receiver:null,
            itemList:[],
            selectedItems:[],
            // filteredItemList:[],
            searchBy:'item_code',
            searchString:null,
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            // showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
            receiverOptions:[],
            searchByOptions:[
                {label:"Item Code",value:'item_code'},
                {label:"material",value:'material'},
                {label:"material Description",value:'material_desc'},
                {label:"material Group",value:'material_group'},
            ]
        }
    },
    mounted(){
        this.getDepartments()
        
    },
    computed:{
        departmentOptions(){
            let op=[]

            for(let d of this.departments){
                op.push({
                    label:d.department_name,
                    value:d.department_id
                })
            }

            return op

        },
        filteredItemList(){
            let lst=[]
            if(this.searchBy && this.searchString && this.searchString!=''){
                lst=this.itemList.filter(item=>{
                    if(!item[this.searchBy])
                        return false
                    let iterItem=item[this.searchBy].toString().toLowerCase()
                    let itemCode=item['item_code']
                    let fnd=this.selectedItems.find(i=>{
                        return i['item_code']==itemCode
                    })
                    if(fnd){
                        return false

                    }
                    return iterItem.includes(this.searchString.toString().toLowerCase())
                })

            }

            return lst

        },
        // skuOptions(){
        //     let op=[]
        //     let g=this.$store.state.skus
        //     for(let i of g){
        //         if(this.showSKUID){
        //             op.push( {value:i.sku_id, label: i.sku_id })

        //         }else{

        //             op.push( {value:i.sku_id, label: i.sku_name })
        //         }
        //     }
        //   return op
        // },
    },
    methods:{
        addItem(item){
            this.selectedItems.push(item)

        },
        getPayload(){
            let payload={
                department_id:this.departmentID,
                receiver:this.receiver,
                items:[]
            }
            if(this.selectedItems && this.selectedItems.length>0){
                for(let item of this.selectedItems){
                    if(!item['intended_qty']){
                        throw "Missing Qty in One of the items";
                    }
                    payload.items.push(item)
                }
                
            }else{
                throw "No Items selected"
            }
            
            return payload
            

        },
        getDepartments(){
            
                this.loading=true
                // this.form.process_id=this.process.process_id
                // let payload=this.getPayload()
                axios.get(this.$store.state.api+'getDepartmentsByUser',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.departments=response.data.data
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            
        },
        getItems(){
            // this.$refs.usageForm.validate()
            if(this.departmentID){
                this.loading=true
                // this.form.process_id=this.process.process_id
                let payload={
                    department_id:this.departmentID
                }
                axios.post(this.$store.state.api+'getItemsByDepartment',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.itemList=response.data.data
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        getUsersByDepartment(){
            if(this.departmentID){
                this.loading=true
        axios.post(this.$store.state.api+'getUsersByDepartment',{department_id:this.departmentID},{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.receiverOptions=[]
                        for(let user of response.data.data){
                            this.receiverOptions.push({
                                label:user.userid+' - '+user.name,
                                value:user.userid
                            })
                        }
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

            }
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                try{

                    let payload=this.getPayload()
                    axios.post(this.$store.state.api+'createIssueVoucher',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }catch(e){
                this.info=e
                this.showDismissibleAlert = true;
                        this.loading=false
            }
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{

        departmentID(){
            this.getItems()
            this.getUsersByDepartment()
            this.receiver=null
        }

    }
}
</script>