<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" /> 
        <v-form ref="parameterInstantaneousDataSheetFrom" v-model="valid" >
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title>{{$store.getters.getTextMap().parameter_instantaneous_data_sheet}}</v-card-title>
                    </v-col>
                </v-row>
                <v-row no-gutters class="px-2">
                    <v-col>
                        <v-text-field
                            v-if="!loading"
                            v-model="form.name"
                            :rules="[rules.required]"
                            :label="$store.getters.getTextMap().sheet_name"
                            dense
                            outlined
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>                    
                    <v-col class="mx-4">
                        <v-select  
                            v-if="!loading"
                            v-model="form.meta.timeframe"
                            :items="timeframeOptions"
                            :label="$store.getters.getTextMap().time_frame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                    <v-col>
                        <v-checkbox  :label="$store.getters.getTextMap().transpose"  v-model="form.meta.isTranspose"></v-checkbox>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark" />
                <v-row class="mt-2">
                    <v-col>
                        <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().timestamp }}</div>
                        <TimePicker v-model="timestamp"/>
                    </v-col>
                    <v-col cols="auto" class="d-flex">
                        <v-btn  v-if="!loading" class="mt-9 mx-1"  color="gray darken-1" small outlined @click="addTimestampToList">{{$store.getters.getTextMap().add_timestamp_to_list}}</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!loading" no-gutters>
                    <v-col>
                        <v-simple-table height="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}" >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <strong>{{$store.getters.getTextMap().timestamp}}</strong>
                                        </th>
                                        <th class="text-center">
                                            <strong>{{$store.getters.getTextMap().actions}}</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="index" v-for="item,index in form.meta.timestamps">
                                        <td class="text-center">{{ item }}</td>
                                        <td class="text-center">
                                            <DeleteConfirmation v-on:confirm="deleteFromTimestampTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this timestamp?">    
                                                <v-icon  small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2 my-2">
                <v-row>
                    <v-col>
                        <v-card-title class="sub-heading">{{$store.getters.getTextMap().create_param_list}}</v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col  cols="auto" class="d-flex" align="right" align-self="center" v-if=" $store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
                        <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col v-if="formType==='deviceForm'">
                        <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col v-else>
                        <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
                    </v-col>
                    <v-col cols="auto" class="d-flex">
                        <v-btn  v-if="!loading" class="mt-4 mx-5"  color="gray darken-1" small outlined @click="addParamToList">{{$store.getters.getTextMap().add_param_to_list}}</v-btn>
                        <!-- <v-btn class="mt-4 mx-5" :color="$store.getters.getColorPalette().deletebtnColor" outlined small @click="onProcessReset">{{$store.getters.getTextMap().cancel}}</v-btn> -->
                    </v-col>
                </v-row>
                <v-row v-if="!loading" no-gutters>
                    <v-col cols="12">
                        <v-simple-table height="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}" >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <strong>{{$store.getters.getTextMap().label}}</strong>
                                        </th>
                                        <th class="text-center">
                                            <strong>{{$store.getters.getTextMap().parameter}}</strong>
                                        </th>
                                        <th class="text-center">
                                            <strong>{{$store.getters.getTextMap().actions}}</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.param_id" v-for="item in form.meta.param_list">
                                        <td class="text-center">{{ item.param_label }}</td>
                                        <td class="text-center">{{ item.param_id }}</td>
                                        <td class="text-center">
                                            <DeleteConfirmation v-on:confirm="deleteFromParameterTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this parameter?">    
                                                <v-icon  small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().submitbtnColor"  @click="sheetSubmit">
                    {{sheet && sheet.sheet_id?$store.getters.getTextMap().update:$store.getters.getTextMap().create}}
                </v-btn>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor"  @click="close">
                    {{$store.getters.getTextMap().cancel}}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import TimePicker from '@/components/inputs/TimePicker'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'ParameterInstantaneousDataSheet',
    props: {
        "sheet": {
            type: Object,
        },
    },
    components:{
        InfoAlert,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm,
        DeleteConfirmation,
        TimePicker
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            valid:false,
            formType:'machineForm',
            api:'createSheets',
            parameter:null,
            parameter_label:null,
            timestamp:null,
            form:{         
                name:null,
                sheet_type:'parameterInstantaneousDataSheet',
                meta:{
                    param_list:[],
                    timeframe:'mtd',
                    timestamps:[],
                    isTranspose:false
                }
            },
            timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
                {label:'Yesterday', value:'yesterday'},
                {label:'Last 30 Days', value:'last30'},
                {label:'Last 60 Days', value:'last60'},
                {label:'Last 90 Days', value:'last90'},
                {label:'Last 180 Days', value:'last180'},
                {label:'Last 365 Days', value:'last365'},
            ],
            rules:FormRules.rules
        }
    },
    mounted(){
        if(this.sheet && this.sheet.sheet_id && this.sheet.meta){
            this.form.name = this.sheet.name
            this.form.sheet_id = this.sheet.sheet_id
            this.form.meta.param_list = this.sheet.meta.param_list
            this.form.meta.timestamps = this.sheet.meta.timestamps
            this.form.meta.timeframe = this.sheet.meta.timeframe
            this.form.meta.isTranspose=this.sheet.meta.isTranspose
            this.api = 'updateSheets'
        }
        this.form.sheet_type = 'parameterInstantaneousDataSheet'
    },
    methods:{
        updateParamList(value){
            if(value && value.parameter && value.parameter.param_id){
                this.parameter=value.parameter.param_id
                this.parameter_label=value.parameter.name
            }
        },
        addParamToList(){
            let paramList=[]
            if(paramList.some(item => item.param_id === this.parameter)){
                this.info="Parameter already exists in th list!"
                this.showDismissibleAlert=true
            }else{
                this.form.meta.param_list.push({param_label:this.parameter_label,param_id:this.parameter})
            }
            this.parameter=null
            this.parameter_label=null
        },
        addTimestampToList(){
            let t=this.timestamp
            let timestampList=[]
            timestampList=this.form.meta.timestamps
            if(!this.timestamp){
                t="00:00"
            }
            if(timestampList.includes(t)){
                this.info="Timestamp already exists in the list!"
                this.showDismissibleAlert=true
            }else{
                this.form.meta.timestamps.push(t)
            }
            this.timestamp=null
        },
        deleteFromParameterTable(item){
            this.form.meta.param_list=this.form.meta.param_list.filter(x=>x.param_id!=item.param_id)
        },
        deleteFromTimestampTable(item){
            this.form.meta.timestamps=this.form.meta.timestamps.filter(x=>x!=item)
        },
        close(){
            this.$emit("close")
         },
        sheetSubmit(){
            this.$refs.parameterInstantaneousDataSheetFrom.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshSheets')
                        this.$refs.parameterInstantaneousDataSheetFrom.reset()
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }else{
                this.loading=false
                this.info="Please add parameter"
                this.showDismissibleAlert=true       
            }
        },
    }
}
</script>