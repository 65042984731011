<template>

        <v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card flat>
            <v-card-title>{{list.name?list.name:list.list_id}} <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-spacer></v-spacer>
    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess','engineeraccess'])"
        @click="editMode=!editMode"
        >
        mdi-pencil
      </v-icon>
      <DeleteConfirmation  v-on:confirm="deleteList()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
      <v-icon
        small
      
      >
        mdi-delete
      </v-icon>
    </DeleteConfirmation>
    </span>
    </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle >

          <v-row v-if="editMode==true">
            <v-col>
              <CreateList :list="list" v-on:close="editMode=false"  v-on:update="$emit('update')" />
            </v-col>
          </v-row>
        
        <v-row v-if="loading">
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
        </v-row>
        
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" >
            <v-col cols="1">
                <span><v-btn   fab :dark="$store.getters.getColorPalette().isDark"                                       @click="addDialog=!addDialog" color="blue" >
        <v-icon>mdi-plus</v-icon>
      </v-btn></span>
            </v-col>
            <v-col>
                <span><AddTupleToList :list="list" v-if="addDialog" /></span>
            </v-col>
            
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table height="150px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().tag}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().value}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tuples"
          :key="item.value"
        >
          <td>{{ item.tag }}</td>
          <td>{{ item.value }}</td>
          <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"><v-icon small color="red" @click="deleteTupleFromList(item)">mdi-delete</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-col>
        </v-row>
        </v-card-subtitle>
            </v-card>
        </v-container>

</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddTupleToList from '@/components/crud_components/derived/AddTupleToList'
import CreateList from '../../crud_components/CreateList.vue'
export default {
    name:'List',
    props:['list'],
    components:{
        InfoAlert,
        AddTupleToList,
        DeleteConfirmation,
        CreateList
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            addDialog:false,
            editMode:false
        }
    },

    computed:{
        tuples(){
            return this.$store.getters.getListDataById(this.list.list_id)
        }
        
    },

    methods:{
        deleteList(){

            axios.post(this.$store.state.api+'deleteList',this.list,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshLists')
                            this.loading=false
                            this.info='The List has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        deleteTupleFromList(tuple){

            axios.post(this.$store.state.api+'deleteTupleFromList',tuple,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshListData')
                            this.loading=false
                            this.info='The Tuple has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    }
}
</script>
<style scoped>
.theme--light.v-tabs-items{
  background-color: #263238;
}
</style>