<template>
    <v-container class="card-container"  fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col  cols="4">
                <v-row  no-gutters dense>
                    <v-col>
                        <h3 :style="{'color':'white','text-align': 'center', 'background-color': '#EF9A9A'}">TO DO</h3>
                        <v-card class=" " flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="parameter-card-container" fluid>
                                <v-row class="my-1" no-gutters v-bind:key="task.task_id" v-for="task in inToDoTasks">
                                    <v-col>
                                        <v-card @click="showDetails(task)" class=" " flat :style="{ 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                {{task.name}}
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{task.msg}}
                                            </v-card-subtitle>
                                            <!-- <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                Asset Under Maintenance
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{getAssetDetails.name}}
                                            </v-card-subtitle> -->
                                                <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().deletebtnColor}">
                                                Due
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ task.start_time }}
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-row :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark" no-gutters class="px-2 py-2">
                                            <v-col align="center">
                                                <v-btn :dark="$store.getters.getColorPalette().isDark"  color="#64B5F6" small text outlined @click="updateStatus(task,'INPROGRESS')">Move To IN PROGRESS</v-btn>
                                            </v-col>
                                            <v-col align="right">
                                                <v-avatar size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>{{ task.assignee.charAt(0) }}</v-avatar>
                                            </v-col>
                                        </v-row>
                                        <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row no-gutters>
                    <v-col>
                        <h3 :style="{'color':'white' ,'text-align': 'center', 'background-color': '#64B5F6'}">IN PROGRESS</h3>
                        <v-card class=" " flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="parameter-card-container" fluid>
                                <v-row class="my-1" no-gutters v-bind:key="task.task_id" v-for="task in inProgressTasks">
                                    <v-col>
                                        <v-card @click="showDetails(task)"  class=" " flat :style="{ 'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                {{task.name}}
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{task.msg}}
                                            </v-card-subtitle>
                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().deletebtnColor}">
                                                {{$store.getters.getTextMap().due}}

                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ task.start_time }}
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-row  :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark" no-gutters class="px-2 py-2">
                                            <v-col align="center">
                                                <v-btn :dark="$store.getters.getColorPalette().isDark"  color="#66BB6A" small outlined text @click="updateStatus(task,'DONE')">{{$store.getters.getTextMap().move_to_done}}
</v-btn>
                                            </v-col>
                                            <v-col align="right">
                                                <v-avatar size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>{{ task.assignee.charAt(0) }}</v-avatar>
                                            </v-col>
                                        </v-row>
                                        <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row no-gutters>
                    <v-col>
                        <h3 :style="{'color':'white','text-align': 'center', 'background-color': '#A5D6A7'}">DONE</h3>
                        <v-card class=" " flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode,'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="parameter-card-container" fluid>
                                <v-row  class="my-1" no-gutters v-bind:key="task.task_id" v-for="task in inDoneTasks">
                                    <v-col>
                                        <v-card @click="showDetails(task)"  class=" " flat :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                                            <v-card-title  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
                                                {{task.name}}
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{task.msg}}
                                            </v-card-subtitle>
                                            <!-- <v-row no-gutters class="px-2 py-2">
    
                                            </v-row> -->
                                            <v-row no-gutters class="px-2 py-2">
                                                <!-- <v-col align="center">
                                                    <v-btn :dark="$store.getters.getColorPalette().isDark"  color="#64B5F6" small filled @click="updateStatus">Move To IN PROGRESS</v-btn>
                                                </v-col> -->
                                                <v-col>
                                                <h3>
                                                    ✅
                                                </h3>
                                                </v-col>
                                                <v-col align="right">
                                                    <v-avatar size="22" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().accentCode}" :class="$store.getters.getColorPalette().avatarColorName " small>{{ task.assignee.charAt(0) }}</v-avatar>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="showTaskDetails">
            <v-col>
                <TaskDetails :task="task" v-on:close="refreshTask"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import TaskDetails from '@/components/items/application/taskmanagment/TaskDetails.vue';
import moment from 'moment';
import axios from 'axios'
export default {
    name:'TaskPanel',
    props:['activity','tasks'],
    components:{
        InfoAlert,
        TaskDetails
    },
    data(){
        return {
            showDialog:false,
            showTaskDetails:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            api:"updateTaskStatus",
            task:null,
            asset:null
        }
    },
    mounted(){
        if(this.$store.state.tasks && this.$store.state.tasks.length>0){
            this.$store.dispatch('refreshTasks')
            for(let i of this.$store.state.tasks){
                i['start_time']=moment(i.start_time).format('YYYY-MM-DD')+':'+moment(i.start_time).format('HH:mm')
                i['end_time']=moment(i.end_time).format('YYYY-MM-DD')+':'+moment(i.end_time).format('HH:mm')
            }
        }
    },
    computed:{
        inToDoTasks(){
            let op=this.$store.state.tasks
            return op.filter(f=>{return f.status==='TODO'})
        },
        inProgressTasks(){
            let op=this.$store.state.tasks
            return op.filter(f=>{return f.status==='INPROGRESS'})
        },
        inDoneTasks(){
            let op=this.$store.state.tasks
            return op.filter(f=>{return f.status==='DONE'})
        },
    },
    methods:{
        showDetails(task){
            this.task=null
            this.task=task
            this.showTaskDetails=true
        },
        updateStatus(task,status){
            task['new_status']=status
            axios.post(this.$store.state.api+this.api,task,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshTasks')
                        this.$emit('update')
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
        },
        refreshTask(){
            this.showTaskDetails=!this.showTaskDetails
            this.$emit('update')
        }
    }
}
</script>
<style scoped>
.card-container {
  max-height: 600px; /* Set the desired maximum height */
  /* overflow-y: auto;   */
}
.card-container h3 {
  color:#66BB6A;
  font-weight: bold;
  /* Set the desired maximum height */
  /* overflow-y: auto;   */
}
.parameter-card-container {
    height: 500px;
  max-height: 500px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
.axis-card-container {
    height: 120px;
  max-height: 120px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
</style>