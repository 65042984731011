<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-data-table
            :headers="headers"
            :items="skus"
            item-key="sku_id"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :dark="$store.getters.getColorPalette().isDark"
        >
            <template v-slot:top>
            <v-toolbar
                flat
                :dark="$store.getters.getColorPalette().isDark"
            >
            <v-toolbar-title>{{ $store.getters.getTextMap().skus }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <CreateSKUModal v-on:update="getSKUs" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                <v-icon class="ml-2" small :color="$store.getters.getColorPalette().accentCode" @click="getSKUs">mdi-refresh</v-icon>
            </v-toolbar>
          </template>
          
    </v-data-table>
  </v-card>
</template>
<script>
import CreateSKUModal from '@/components/modals/derived/CreateSKUModal'
// import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'SKUTable',
    components:{
        CreateSKUModal,
        InfoAlert
    },
    mounted(){
    //   this.getSKUs()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            // skus:[],
            expanded: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    filterable: false,
                    value: 'sku_id',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Name', value: 'sku_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    computed:{
        skus(){
            let skus=[]
            if(this.$store.state.skus){
                skus=this.$store.state.skus
            }
            return skus
        },

    },
    methods:{
        getSKUs(){
            this.loading=true
            // axios.get(this.$store.state.api+'sku',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            // .then(response=>{
            //     if(response.data.status=='success'){
            //         this.skus=response.data.data
            //     }else{
            //         this.info=response.data.msg
            //         this.showDismissibleAlert=true
            //     }
            //     this.loading=false
            // }).catch(err=>{
            //     this.loading=false
            //     this.info=err
            //     this.showDismissibleAlert=true 
            // })
            this.$store.dispatch('refreshSKUs')
            this.loading=false
        }
    },
}
</script>
