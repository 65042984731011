<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row no-gutters >
                <v-col>
                    <v-card flat>
                      <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/store/requests'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-view-dashboard-outline</v-icon>{{ $store.getters.getTextMap().voucher_requests }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/store/vouchers'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-view-dashboard-outline</v-icon>{{ $store.getters.getTextMap().issue_vouchers }}
      </v-tab>
    </router-link>
    </v-tabs>
    </v-sheet>
    </v-card>
    </v-col>
    </v-row>
    <router-view></router-view>
    </v-container>
</template>
<script>
// import BatchesTable from '@/components/lists/manufacturing/BatchesTable'
export default {
    name:'Stores',
    components:{
        // BatchesTable
    },
    data(){
        return {
            show:'',
            tab:null
        }
    },

    mounted() {
      document.title='Stores'
      if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>