<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-row>
            <v-col>
                <div :style="{ display:'flex', alignItems:'center'}">
                    <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">Ongoing Checklist</h2>
                    <v-btn class="ml-2" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="init">
                        <v-icon :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-refresh</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
            <v-col  cols="12" v-if="checklistTable && checklistTable.length>0">
                <v-data-table 
                    :headers="headers" 
                    :items="checklistTable" 
                    item-key="index" 
                    :search="search"
                    :single-expand="true" 
                    show-expand 
                    :expanded.sync="expanded" 
                    :loading="loading"
                    loading-text="Loading... Please wait" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                    :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $store.getters.getTextMap().checklists }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <div :style="getStatusStyle(item.status)">{{ statusNameMap[item.status] }}</div>
                    </template>
                    <template v-slot:expanded-item="{ headers,item }">
                        <td :colspan="headers.length" v-if="stage==='maker'">
                            <ChecklistMakerForm :checklist="item" v-on:submitted="getOpenChecklists"/>
                        </td>
                        <td :colspan="headers.length" v-else-if="stage === 'checker' || stage==='approver'">
                            <ChecklistFormDetails :checklist="item" v-on:submitted="init" v-on:refillChecklist="init" :readOnly="false"/>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col  cols="12" v-else-if="!loading">
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row v-if="refillChecklistTable && refillChecklistTable.length>0">
            <v-col>
                <div :style="{ display:'flex', alignItems:'center'}">
                    <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">Checklist For Refill</h2>
                    <v-btn class="ml-2" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="getRefillChecklists">
                        <v-icon :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-refresh</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col  cols="12">
                <v-data-table 
                    :headers="headers" 
                    :items="refillChecklistTable" 
                    item-key="index" 
                    :refill_search="search"
                    :refill_single-expand="true" 
                    show-expand 
                    :refill_expanded.sync="expanded" 
                    :loading="loading"
                    loading-text="Loading... Please wait" 
                    :refill_sort-by.sync="sortBy" 
                    :refill_sort-desc.sync="sortDesc"
                    :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $store.getters.getTextMap().checklists }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <div :style="getStatusStyle(item.status)">{{ statusNameMap[item.status] }}</div>
                    </template>
                    <template v-slot:expanded-item="{ headers,item }">
                        <td :colspan="headers.length">
                            <ChecklistMakerForm :checklist="item" v-on:submitted="init" :readOnly="false"/>
                        </td>
                        <!-- <td :colspan="headers.length" v-else-if="stage === 'checker' || stage==='approver'">
                            <ChecklistFormDetails :checklist="item" v-on:submitted="getRefillChecklists"/>
                        </td> -->
                    </template>
                </v-data-table>
            </v-col>
            <!-- <v-col  cols="12" v-else>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col> -->
        </v-row>
        <!-- Makers Checklist -->
        <!-- <v-row v-if="showMakerChecklist">
            <v-col  cols="12">
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">Makers Checklist</h2>
            </v-col>
            <v-col  cols="12" v-if="makersChecklists && makersChecklists.length>0">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-data-table 
                    :headers="headers" 
                    :items="makersChecklists" 
                    item-key="timestamp" 
                    :search="search"
                    :single-expand="true" 
                    show-expand 
                    :expanded.sync="expanded" 
                    :loading="loading"
                    loading-text="Loading... Please wait" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $store.getters.getTextMap().checklists }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers,item }">
                        <td :colspan="headers.length">
                            <ChecklistMakerForm :checklist="item" v-on:submitted="getOpenChecklists"/>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col  cols="12" v-else>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="showCheckerChecklist">
            <v-col  cols="12">
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">Checkers Checklist</h2>
            </v-col>
            <v-col cols="12" v-if="checkersChecklists && checkersChecklists.length>0">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-data-table 
                    :headers="headers" 
                    :items="checkersChecklists" 
                    item-key="timestamp" 
                    :search="search"
                    :single-expand="true" 
                    show-expand 
                    :expanded.sync="expanded" 
                    :loading="loading"
                    loading-text="Loading... Please wait" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $store.getters.getTextMap().checklists }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers,item }">
                        <td :colspan="headers.length">
                            <ChecklistFormDetails :checklist="item" v-on:submitted="getOpenChecklists"/>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col  cols="12" v-else>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="showApproverChecklist">
            <v-col  cols="12">
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">Approvers Checklist</h2>
            </v-col>
            <v-col  cols="12" v-if="approversChecklists && approversChecklists.length>0">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-data-table 
                    :headers="headers" 
                    :items="approversChecklists" 
                    item-key="timestamp" 
                    :search="search"
                    :single-expand="true" 
                    show-expand 
                    :expanded.sync="expanded" 
                    :loading="loading"
                    loading-text="Loading... Please wait" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $store.getters.getTextMap().checklists }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers,item }">
                        <td :colspan="headers.length">
                            <ChecklistFormDetails :checklist="item" v-on:submitted="getOpenChecklists"/>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col  cols="12" v-else>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue'
import ChecklistMakerForm from '@/components/forms/checklists/ChecklistMakerForm.vue';
import ChecklistFormDetails from '@/components/details/checklist/ChecklistFormDetails.vue';
import axios from 'axios';
import moment from 'moment';
export default {
    name: 'ChecklistDataLists',
    components: {
        InfoAlert,
        ChecklistMakerForm,
        ChecklistFormDetails
    },
    data() { 
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            showMakerChecklist:false,
            showApproverChecklist:false,
            showCheckerChecklist: false,
            openChecklists:[],
            refillChecklists:[],
            makersChecklists:[],
            approversChecklists:[],
            checkersChecklists: [],
            checklistTable: [],
            refillChecklistTable: [],
            stage:"maker",
            singleExpand: false,
            search: '',
            sortDesc: false,
            sortBy: 'timestamp',
            expanded: [],
            refill_singleExpand: false,
            refill_search: '',
            refill_sortDesc: false,
            refill_sortBy: 'timestamp',
            refill_expanded: [],
            statusNameMap: {
                "in_process":"In Process",
                "rejected":"Rejected",
                "completed":"Completed",
                "refill":"Refill",
            },
            headers: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getOpenChecklists()
            this.getRefillChecklists()
        },
        createChecklistsByStage() {
            let user_id = this.$store.state.user && this.$store.state.user.userid ? this.$store.state.user.userid : null
            let makersUserids=this.$store.state.checklistMakersList.map((x)=>x.userid)
            let approversUserids=this.$store.state.checklistsApproverLists.map((x)=>x.userid)
            let checkersUserids = this.$store.state.checklistsCheckersList.map((x) => x.userid)
            this.checklistTable = []
            let temp = []
            if (makersUserids && makersUserids.length > 0 && makersUserids.includes(user_id)) {
                this.showMakerChecklist = true
                this.stage="maker"
                temp = this.openChecklists.filter(x => x.stage === 'maker')
                // this.makersChecklists=this.openChecklists.filter(x=>(x.stage==='maker'))
            }
            if (approversUserids && approversUserids.length > 0 && approversUserids.includes(user_id) && !(temp && temp.length>0)) {
                this.showApproverChecklist = true
                this.stage = "approver"
                console.log(this.openChecklists,'ll')
                temp=this.openChecklists.filter(x=>x.stage==='approver')
                // this.approversChecklists=this.openChecklists.filter(x=>x.stage==='approver')
            }
            if (checkersUserids && checkersUserids.length > 0 && checkersUserids.includes(user_id)  && !(temp && temp.length>0)) {
                this.showCheckerChecklist = true
                this.stage="checker"
                temp=this.openChecklists.filter(x=>x.stage==='checker')
                // this.checkersChecklists=this.openChecklists.filter(x=>x.stage==='checker')
            }
            this.checklistTable=temp
        },
        createRefillChecklistsByAssignee() {
            this.refillChecklistTable = []
            let count=0
            for (let i of this.refillChecklists) {
                if (this.$store.state.user && this.$store.state.user.userid && this.$store.state.user.userid === i.req_assignee) {
                    i['index']=count++
                    this.refillChecklistTable.push(i)
                }
            }
        },
        getRefillChecklists() {
            this.loading = true
            this.openChecklists = []
            this.headers=[
                {
                    text: 'Created At',
                    align: 'start',
                    filterable: false,
                    value: 'timestamp',
                    class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Checklist', value: 'name', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Stage', value: 'stage', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Status', value: 'status', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Submitted At', value: 'submitted_timestamp', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
               
            ]
            axios.get(this.$store.state.api + "openRefillChecklists", {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    let dbData = [...response.data.data]
                    let tempList = []
                    let refilled_by_present = false
                    let submitted_by_present=false
                    let approved_by_present=false
                    let checked_by_present=false
                    let issued_by_present = false
                    for (let i of dbData) {
                        let temp = i
                        temp['timestamp'] = moment(i.timestamp).format("DD-MM-YYYY HH:mm")
                        temp['submitted_timestamp'] = i.submitted_timestamp ? moment(i.submitted_timestamp).format("DD-MM-YYYY HH:mm") : '-'
                        tempList.push(temp)
                        if (i.refilled_by) { 
                            refilled_by_present=true
                        }
                        if (i.submitted_by) {
                            submitted_by_present=true
                        }
                        if (i.approved_by) {
                            approved_by_present=true
                        }
                        if (i.checked_by) {
                            checked_by_present=true
                        }
                        if (i.issued_by) {
                            issued_by_present=true
                        }
                        if (i.auto_submitted) {
                            submitted_by_present=true
                            temp['submitted_by']="Enture"
                        }
                    }
                    if (refilled_by_present) {
                        this.headers.push({ text: 'Refilled By', value: 'refilled_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },)
                    }
                    if (submitted_by_present) {
                        this.headers.push({ text: 'Submitted By', value: 'submitted_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (approved_by_present) {
                        this.headers.push({ text: 'Approved By', value: 'approved_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (checked_by_present) {
                        this.headers.push({ text: 'Checked By', value: 'checked_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (issued_by_present) {
                        this.headers.push({ text: 'Issued By', value: 'issued_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }

                    this.refillChecklists = tempList
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        getOpenChecklists() {
            this.loading = true
            this.openChecklists = []
            this.headers= [
                {
                    text: 'Created At',
                    align: 'start',
                    filterable: false,
                    value: 'timestamp',
                    class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Checklist', value: 'name', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Stage', value: 'stage', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Status', value: 'status', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Submitted At', value: 'submitted_timestamp', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
               
            ]
            axios.get(this.$store.state.api + "getOpenChecklistsData", {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    let dbData = [...response.data.data]
                    let tempList = []
                    let submitted_by_present=false
                    let approved_by_present=false
                    let checked_by_present=false
                    let issued_by_present = false
                    let refilled_by_present = false
                    let count=0
                    for (let i of dbData) {
                        let temp = i
                        temp['index']=count++
                        temp['timestamp'] = moment(i.timestamp).format("DD-MM-YYYY HH:mm")
                        temp['submitted_timestamp'] = i.submitted_timestamp? moment(i.submitted_timestamp).format("DD-MM-YYYY HH:mm"):'-'
                        tempList.push(temp)
                        if (i.submitted_by) {
                            submitted_by_present=true
                        }
                        if (i.approved_by) {
                            approved_by_present=true
                        }
                        if (i.checked_by) {
                            checked_by_present=true
                        }
                        if (i.issued_by) {
                            issued_by_present=true
                        }
                        if (i.refilled_by) {
                            refilled_by_present=true
                        }
                        if (i.auto_submitted) {
                            submitted_by_present=true
                            temp['submitted_by']="Enture"
                        }
                    }
                    if (submitted_by_present) {
                        this.headers.push({ text: 'Submitted By', value: 'submitted_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (approved_by_present) {
                        this.headers.push({ text: 'Approved By', value: 'approved_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (checked_by_present) {
                        this.headers.push({ text: 'Checked By', value: 'checked_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (issued_by_present) {
                        this.headers.push({ text: 'Issued By', value: 'issued_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    if (refilled_by_present) {
                        this.headers.push({ text: 'Refilled By', value: 'refilled_by', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName })
                    }
                    this.openChecklists = tempList
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        getStatusStyle(status) {
            return {
                width: '80px', // Fixed width for all statuses
                padding: '5px',
                borderRadius: '5px',
                backgroundColor: this.getBackgroundColor(status),
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                display: 'inline-block', // Ensures the box respects width
                lineHeight: '20px' // Adjust to vertically center the text
            };
        },
        getBackgroundColor(status) {
            switch (status) {
                case 'completed':
                    return '#43A047'; // Green
                case 'rejected':
                    return '#E53935'; // Red
                case 'refill':
                    return '#FFB300'; // Yellow
                case 'in_process':
                    return '#3949AB'; // Blue
                default:
                    return 'black'; // Default color
            }
        }
    },
    watch: {
        openChecklists: {
            handler() {
                if (this.openChecklists) {
                    this.createChecklistsByStage()
                }
            },
            deep:true
        },
        refillChecklists: {
            handler() {
                if (this.refillChecklists) {
                    this.createRefillChecklistsByAssignee()
                }
            },
            deep:true
        }
    }
}
</script>