<template>
    <v-container :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
            
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess']) ">
            <v-col>
       <v-chip
      class="ma-2"
      small
      outlined
      color="teal"
      text-color="green" v-for="param in report.list"
        :key="param">
        {{getParamName(param)}}
        </v-chip>
            </v-col>
        </v-row>
        <v-card style="margin-bottom:20px;margin-top:20px;" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row style="margin-left:20px;">
             <v-col>
                <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.from_date"
          @input="menu1 = false"
       :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu2 = false"
       :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col align-self="center">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn
                        v-if="!loading"  small :color="$store.getters.getColorPalette().foregroundColorCode" filled  @click="fetchReport">{{$store.getters.getTextMap().view_report}}
                    </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-spacer></v-spacer>
        <v-card  style="margin-top:20px;" elevation = 4 :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
            <v-row>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-btn
                    style="margin-bottom:15px;"
                    v-if="!loading"
                    :color="$store.getters.getColorPalette().accentCode"
                    outlined
                    small
                    @click="openForm"
                    >
                    {{$store.getters.getTextMap().generate_report_manually}}

                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form ref="listForm" v-model="listValid" v-if="showListForm===true" >
            <v-row style="margin-left:20px;">
                <v-col>
                <v-menu
        v-model="menu6"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="from_date_list"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
           :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="from_date_list"
          @input="menu6 = false"
         :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>

                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu
                        v-if="!loading"
                        ref="menufrom"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.from_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      :dark="$store.getters.getColorPalette().isDark"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="form.from_time"
                                :label="$store.getters.getTextMap().from_time"

                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                              :dark="$store.getters.getColorPalette().isDark"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu3"
                            v-model="form.from_time"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menufrom.save(form.from_time)"
                          :dark="$store.getters.getColorPalette().isDark"
                        ></v-time-picker>
                    </v-menu>
                </v-col>d                <v-col>
                <v-menu
        v-model="menu7"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="to_date_list"
            :label="$store.getters.getTextMap().to_date"

            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="to_date_list"
          @input="menu7 = false"
        :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu
                        v-if="!loading"
                        ref="menuto"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.to_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      :dark="$store.getters.getColorPalette().isDark"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="form.to_time"
                                :label="$store.getters.getTextMap().to_time"

                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                              :dark="$store.getters.getColorPalette().isDark"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu4"
                            v-model="form.to_time"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menuto.save(form.to_time)"
                          :dark="$store.getters.getColorPalette().isDark"
                        ></v-time-picker>
                    </v-menu>
                </v-col> 
                <!-- <v-col  style="margin-right:15px;">
                    <v-text-field
                        v-if="!loading"
                        v-model="form.bonus_charge"
                        :rules="numberRules"
                        label="Bonus Charge"
                        required
                        dark
                    ></v-text-field>
                </v-col> -->
                <v-row style="margin-top:15px;">
                    <v-col>
                        <v-btn
                        style="margin-right:5px;"
                        v-if="!loading"
                       :color="$store.getters.getColorPalette().submitbtnColor"
                        outlined
                        @click="onSubmit"
                        >
                        {{$store.getters.getTextMap().generate}}

                        </v-btn>
                        <v-btn
                        v-if="!loading"
                        :color="$store.getters.getColorPalette().foregroundColorCode"
                        outlined
                        @click="onReset"
                        >
                        {{$store.getters.getTextMap().cancel}}

                    </v-btn>
                </v-col>
            </v-row>
            </v-row>
        </v-form>
        </v-card>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
        <!-- <v-row v-if="tableData.length>0">
            <v-col align-self="center">
                <strong>{{report.name ?report.name : report.report_id}} - {{dateRangeText}}</strong>
            
                <strong>{{report.from_date}}</strong> - <strong>{{report.to_date}}</strong>
            </v-col>
            <v-col v-if="!loading " align-self="center">
              <v-select
                v-if="!loading"
              v-model="processing"
          :items="processingOptions"
          label="Data"
          outlined
          small
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
        ></v-select>
        </v-col>
            <v-col align="right">
                <v-btn-toggle
        v-if="tableData.length>0"
          v-model="graphType"
          mandatory
        >
          <v-btn small>
            Bar
          </v-btn>
          <v-btn small>
            Line
          </v-btn>
              </v-btn-toggle>
            </v-col>
        </v-row> -->
        
            
        
        <!-- <v-row>
          <v-col>
            <div ref="graph"></div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <v-data-table
      :headers="headers"
      :items="tableData"
      item-key="from_time"
      :search="search"
      :loading="loading"
      :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    loading-text="Loading... Please wait"
   :dark="$store.getters.getColorPalette().isDark"
    class="elevation-4"
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{$store.getters.getTextMap().energy_distribution_detailed_report}}
</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="item.status==='processing'" :colspan="headers.length">
        The report is  processing
      </td>
      <td v-else :colspan="headers.length">
        <EnergyDistributionDetailedReport :energyDistReport="item" />
      </td>
    </template>
    </v-data-table>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
        </v-container>
</template>

<script>
//import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
//import BarGraphByDate from '@/components/graphs/BarGraphByDate'
//import LineGraphByDate from '@/components/graphs/LineGraphByDate'
import InfoAlert from '@/components/InfoAlert'
import EnergyDistributionDetailedReport from '@/components/items/EnergyDistributionDetailedReport'
import axios from 'axios'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export default {
    name:'EnergyDistributionReportDetails',
    props:['report'],
    components:{
        InfoAlert,
        EnergyDistributionDetailedReport
        //LineGraphByTimeStamp,
        //LineGraphByDate,
        //BarGraphByDate
    },
    mounted(){
        this.form['from_date']=moment().subtract(180,'d').format('YYYY-MM-DD')
        this.form['to_date']=moment().format('YYYY-MM-DD')
        this.fetchReport()
          /*if(this.report.unit){
          this.headers.push({text:'Usage-'+this.report.unit, value:'usage',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "})
          }else{
            this.headers.push({text:'Usage', value:'usage',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "})
          }*/
    },
    data(){
        return {
            form:{
                from_date:null,
                to_date:null,
                from_time:null,
                to_time:null,
                bonus_charge:0,
            },
            //date: new Date().toISOString().substr(0, 10),
            label:null,
            dateRangeText:null,
            unit:null,
                valid:false,
                listValid:false,
                menu:false,
                menu1:null,
            menu2:null,
            menu6:null,
            menu7:null,
            expanded: [],
            graphData:{},
            processing:'usage',
            graphType:0,
            showDismissibleAlert:false,
            from_date_list:null,
            to_date_list:null,
            from_time:null,
            to_time:null,
            showListForm:false,
            loading:false,
            info:'',
            menu3:null,
            menu4:null,
            search:null,
            Data:[],
            tableData:[],
            numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0)|| 'It should be a valid number',
          v => (v && v.length <= 10 && v.length > 0) || 'Name must be less than 5 digits',
      ],
            headers:[
                {   
                    text:'From Time', value:'from_time',align:'start',filterable: false,class: "text-subtitle-1 text-capitalize  "+this.$store.getters.getColorPalette().tableHeaderColorName   
          },
          { text: 'To Time', value: 'to_time', class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Status', value: 'status', class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
          // { text: 'Tariff', value: 'tariff', class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName},
          // { text: 'Bonu Charge', value: 'bonus_charge',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'File Name', value: 'report_loc',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
            processingOptions:[
                {label:'Usage or Average',value:'usage'},
                //{label:'Standard Deviation',value:'std'},
                //{label:'Statistical Mode',value:'mode'},
                {label: 'Peak Value', value:'max'},
                {label: 'Min Value', value:'min'}
                ],
            ISODateRules:[
            v => !!v || 'Required',
            v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ],
             objectRules:[
        v => !!v || 'Required',
      ],
        }
    },
    computed:{
        fromTimeStamp(){
        if(this.from_date_list){
            let dt=this.from_date_list.toString()
            if(this.form.from_time){
              dt+=' '+this.form.from_time.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.to_date_list){
          
            let dt=this.to_date_list.toString()
            if(this.form.to_time){
              dt+=' '+this.form.to_time.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').add('days',1).format("X"));
          
        }else{
          return null
        }
      },
    },
    methods:{
        // createTable(){
        //     this.tableData=Object.values(this.rawData)
        //     this.headers.push({text:'From Time', value:'from_time',align:'start',filterable: false,class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
        //     this.headers.push({text:'To Time', value:'to_time',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
        //     this.headers.push({text:'Status', value:'status', class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
        //     this.headers.push({text:'Tariff', value:'tariff',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
        //     this.headers.push({text:'Bonus Charge', value:'bonus_charge',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
        // },
        openForm(){
            this.showListForm = true
        },
        fetchReport(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              let payload={
                report_id:this.report.report_id,
                from_date:moment(this.form.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
                to_date:moment(this.form.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
                discrete:true
                }
                this.dateRangeText=moment(this.form.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")+' - '+moment(this.form.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
                let endpoint='getDataByEnergyDistributionReportBetweenDate'
    
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log(response.data)
      if(response.data.status==='success'){
        this.rawData=[]
        for(let i of response.data.data){
            // i.from_time = moment(i.from_time, "DD MM YYYY hh:mm:ss", true);
            i.from_time = moment(i.from_time).format("YYYY/MM/DD - HH:mm");
            i.to_time = moment(i.to_time).format("YYYY/MM/DD - HH:mm");
            let temp = ({'from_time':i.from_time,'to_time':i.to_time,'status':i.status,'tariff':this.report.tariff_rate,'bonus_charge':i.bonus_charge,'report_loc':i.report_loc,'excel_loc':i.excel_loc})
            this.rawData.push(temp)
        }
        this.tableData=Object.values(this.rawData)
        // this.createTable()
      //console.log(response.data.data)
      //this.report['parameter']=this.form.parameter
      //this.report['date']=this.form.date
      if(this.graphData.length==0){
          this.info = "No data to show";
            this.showDismissibleAlert = true;
      }

    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        }
    },
    onSubmit(){
        this.$refs.listForm.validate()
            if(this.listValid){
              this.loading=true
              this.from_time=this.fromTimeStamp
              this.to_time=this.toTimeStamp
              let payload={
                report_id:this.report.report_id,
                bonus_charge:this.form.bonus_charge,
                tariff:this.report.tariff_rate,
                from_time:this.from_time,
                to_time:this.to_time
                }
                this.dateRangeText=moment(this.form.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")+' - '+moment(this.form.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
                let endpoint='createEnergyDistributionList'
                axios.post(this.$store.state.api+endpoint,payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                //console.log(response.data)
                if(response.data.status==='success'){
                    this.fetchReport()
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if(this.graphData.length==0){
                    this.info = "No data to show";
                        this.showDismissibleAlert = true;
                }

                }else{
                this.info = response.data.msg;
                //this.info = d;
                        this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading=false
                }
                this.loading=false

                })
                .catch(err=>{
                this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                        })

                    }
                },
                onReset(){
                    this.showListForm=false
                    this.$refs.listForm.reset()
                    
                }
                },
                watch:{
                processing(){

                    this.createTable()
                },

    }
}
</script>