<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
            
                    <v-card-title>{{$store.getters.getTextMap().dispatch_details}}</v-card-title>
            <v-form ref="usageForm" v-model="valid">
                
                <v-row >        
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.vehicle_no"
                        :counter="25"
                        :rules="[rules.required, rules.noSpace]"
                        :label="$store.getters.getTextMap().vehicle_no"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.net_weight"
                        :counter="15"
                        :rules="[rules.required, rules.noSpace, rules.unum]"
                        :label="$store.getters.getTextMap().net_weight"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.actual_weight"
                        :counter="15"
                        :rules="[rules.required, rules.noSpace, rules.unum]"
                        :label="$store.getters.getTextMap().actual_weight"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.vendor"
                        :counter="15"
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().vendor"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.loaded_by"
                        :counter="35"
                        :label="$store.getters.getTextMap().loaded_by"
                        :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.verified_by"
                        :counter="35"
                        :label="$store.getters.getTextMap().verified_by"
                        :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.document_no"
                        :counter="35"
                        :label="$store.getters.getTextMap().document_no"
                        :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor"  small  @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>

                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
// import moment from 'moment'
export default {
    name:'WriteBatchShipping',
    props:{
        batch:{
            type:Object,
            required:true
        }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
        }
    },
    mounted(){
        if(this.productionPlan){
            this.form=Object.assign({},this.productionPlan)
        }
    },
    computed:{
        skuOptions(){
            let op=[]
            let g=this.$store.state.skus
            for(let i of g){
                if(this.showSKUID){
                    op.push( {value:i.sku_id, label: i.sku_id })

                }else{

                    op.push( {value:i.sku_id, label: i.sku_name })
                }
            }
          return op
        },
    },
    methods:{
        getPayload(){
            
            // let payload={
            //     // to_time:Number(moment(this.timeForm.from_date.toString()+' '+this.timeForm.to_time.toString(),'YYYY-MM-DD HH:mm').format("X")),
            //     // from_time:Number(moment(this.timeForm.to_date.toString()+' '+this.timeForm.from_time.toString(),'YYYY-MM-DD HH:mm').format("X"))
            //     batch_id:this.batch_id,
            //     vehicle_no:form.vehicle_no.toString().toLowerCase(),
            //     nat

            // }
            this.form['batch_id']=this.batch.batch_id
            this.form['vehicle_no']=this.form.vehicle_no.toString().toLowerCase()


            return this.form

        },
        
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                let payload=this.getPayload()
                axios.post(this.$store.state.api+'shipBatch',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                    
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{
    }
}
</script>