<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
      
  <v-container fluid>
        <v-card-title class=" text-subtitle-2"  :style="{color:$store.getters.getColorPalette().textColor}">{{report.name ?report.name : report.report_id}}
          <v-spacer></v-spacer>
            <span v-if="$store.state.user && $store.state.settingMode ">  </span>
            <span> 
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular> 
              <v-btn
              v-if="!loading && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
      class="mx-1"
      icon
      color="pink"
      @click="deleteParameterReport()"
    >
     <DeleteConfirmation  v-on:confirm="deleteParameterReport()"  title="Delete Confirmation"  description="Are you sure you want to delete this?"/>
      <v-icon small  :dark="$store.getters.getColorPalette().isDark" >
        mdi-trash-can
      </v-icon>
    </v-btn>
   
    </span>
    </v-card-title>
    
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-divider color="grey"></v-divider>
        <v-hover v-slot="{ hover }">
    <v-card-subtitle :style="{'border-radius': '5px '}" @click="overlay=!overlay" class="">
        <v-row>
            <v-col>
                <strong :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().notification_groups}}:</strong>
                <strong :color="$store.getters.getColorPalette().accentCode">{{report.notification_groups?report.notification_groups.length:0}}</strong>
            </v-col>
            <v-col>
                <strong :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().email_ids}}:</strong>
                <strong :color="$store.getters.getColorPalette().accentCode">{{report.emails ? report.emails.length : "Not configured"}}</strong>
            </v-col>
            
        </v-row>
        
        <v-overlay
          absolute
          :value="hover"
        >
        <v-row justify="center" align="center" align-content="center">
            <v-col justify="center" align="stretch" align-content="center">
          <v-btn
            color="success"
            @click="dialog = true"
          >
          {{$store.getters.getTextMap().view_report}}
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
            </v-col>
        </v-row>
        </v-overlay>
    </v-card-subtitle>
        </v-hover>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card   :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorName">
    <v-toolbar
        
       :color="$store.getters.getColorPalette().foregroundColorName"
          
        >
          <v-btn
            icon
           :dark="$store.getters.getColorPalette().isDark" 
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{report.name ?report.name : report.report_id}} -  {{$store.getters.getTextMap().consolidated_report}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
                      <v-col cols="11" align="left">
          <v-chip
            v-if="
              $store.state.user &&
              $store.state.settingMode
            "
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            ><v-avatar
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                color: $store.getters.getColorPalette().background2ColorCode,
              }"
              :class="
                $store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0'
              "
              ><v-icon
                :color="$store.getters.getColorPalette().accentCode"
                small
                >mdi-file-chart-outline</v-icon
              ></v-avatar
            >{{report.report_id}}</v-chip
          >
          <!-- <h3 ></h3> -->
        </v-col>
        <ConsolidatedReportDetails :report="report" />
    </v-card>
        </v-dialog>
        </v-container>
    </v-card>

</template>
<script>
import axios from 'axios'
//import EditReportModal from '@/components/modals/EditReportModal'
import ConsolidatedReportDetails from '@/components/ConsolidatedReportDetails'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'ParameterReport',
    props:['report'],
    components:{
        ConsolidatedReportDetails,
        InfoAlert,
        //EditReportModal
        DeleteConfirmation

    },
    data(){
        return {
             showDialog:false,
            overlay:false,
            dialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:''
        }
    },
    computed:{

    },
    methods:{
        deleteParameterReport(){
          this.loading=true
          console.log('del')
            this.$emit('deleteReport',this.report)
            axios.post(this.$store.state.api+'deleteConsolidatedReport',this.report,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshConsolidatedReports')
        //context.dispatch('refreshNodeStates')
        this.loading=false

      }else{
        //console.log(response.data.msg)
        this.loading=false
        this.info=response.data.msg
        this.showDismissibleAlert=true
      }})
      .catch(err=>{console.log(err)})
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
        
    }
}
</script>