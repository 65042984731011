<template>
    <v-dialog
        v-model="show"
    >
    <v-form
    ref="groupForm"
    v-model="valid"
    
  >
  <v-card class="px-3 py-3" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                        v-model="tagType_label"
                        :label="$store.getters.getTextMap().label"
                      :dark="$store.getters.getColorPalette().isDark"
                        :rules="nameRules"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                    v-if="!loading"
                   :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="tagType"
                        :items="tagTypeOptions"
                        :label="$store.getters.getTextMap().tag_type"
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="objectRules"
                    ></v-select>
                </v-col>
                <v-col align-self="center">
                    <v-btn color="gray darken-1" small  @click="addTagTypeToList">{{$store.getters.getTextMap().add_tag_type_to_list}}
</v-btn>
                    <v-btn style="margin-left:20px;" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onTagTypeReset">{{$store.getters.getTextMap().cancel}}
</v-btn>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-card class="my-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" elevation="3">
                        <v-row  v-if="!loading" style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}
</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().tag_type}}
</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.label" v-for="item in tagTypeTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.tag_type }}</td>
                                            
                                            <!-- <td>
                                            <DeleteConfirmation  :confirm="deleteFromTagTypeTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                                           <v-icon small color="red" >mdi-delete</v-icon>
                                           </DeleteConfirmation>
                                            </td> -->
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col> 
            </v-row>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
        :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}

          </v-btn>
          <v-btn
        :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
        </v-card-actions>
  </v-card>
    </v-form>
    </v-dialog>
</template>
<script>
// import DeleteConfirmation from '@/components/DeleteConfirmation'
// import axios from 'axios'
export default {
    name:'GetTagTypeModal',
    props:['tagTypes','show'],
    components:{
        // DeleteConfirmation
    },
    mounted(){
        // console.log(this.tagTypes)
        // if(this.tagTypes){
        //     this.tagTypeTableDetails = this.tagTypes
        // }
    },
    data(){
        return {
            showDialog:false,
            valid:false,
            machine:null,
            workspace:null,
            param:null,
            tag_list :[],
            tagTypeTableDetails:[],
            tagType:null,
            tagType_label:null,
            machineOptions:[],
            paramOptions:[],
            loading:false,
            form:{},
            objectRules:[
                v => !!v || 'Required',
              ],
              nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
        }
    },
    computed:{
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },  
    },
    methods:{
        addTagTypeToList(){
                // if(!this.tagTypeTableDetails){
                //     this.tagTypeTableDetails = []
                // }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
                console.log(this.tagTypeTableDetails)
            },
        removeFromList(param){
            this.param=this.param.filter(data=>{ return data != param })
        },
        // deleteFromTagTypeTable(item){
        //     this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
        //     },
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.loading=true
                this.$emit('value',this.tagTypeTableDetails)
                if(!this.params){
                    this.param=null
                    this.loading=false
                }
                //this.param=null
                this.$emit('close')
                this.loading=false
            }

        },
        onTagTypeReset(){
            this.tagType=null
            //this.$emit('close')
        },
        onReset(){
            this.$emit('close')
            this.loading=false
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
        
    }
}
</script>