<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <div class="py-0">
            <v-form ref="parameterForm" v-model="valid" >
                <v-card flat :color="$store.getters.getColorPalette(). background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <v-card-title class="headline">{{mode}} {{$store.getters.getTextMap().energy_report}}</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-text-field
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.name"
                                            :counter="35"
                                            :rules="nameRules"
                                            :label="$store.getters.getTextMap().name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    
                                    <v-col>
                                        <v-select
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.incomers"
                                            :items="paramEquationOptions"
                                            :label="$store.getters.getTextMap().incomers"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            multiple
                                            :rules="objectRules"
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col> 
                                    <v-col>
                                        <v-select
                                         :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.sections"
                                            :items="paramEquationOptions"
                                            :label="$store.getters.getTextMap().sections"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            multiple
                                            :rules="objectRules"
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col> 
                                    
                                </v-row>
                               
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-select
                                       :dark="$store.getters.getColorPalette().isDark" 
                                       :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.notification_groups"
                                        :items="notificationGroupList"
                                        :label="$store.getters.getTextMap().notification_groups"
                                        dense
                                        filled
                                        item-text="label"
                                        item-value="value"
                                        multiple
                                        card
                                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor"  @click="onSubmit">
                                {{mode}}
                            </v-btn>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor" @click="onReset">
                               {{ $store.getters.getTextMap().cancel }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'CreateEnergyReport',
    props:['report'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            
            this.api='updateEnergyReport'
            this.mode=this.$store.getters.getTextMap().edit
            this.showMachine=false
            
        }else{
            this.api='createEnergyReport'
        }
        this.getParamEquations()
    },
     data(){
        return {
            api:'createEnergyReport',
            mode:this.$store.getters.getTextMap().create,
            dialog:false,
            showMachine:true,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            loading:false,
            paramEquations:[],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      form:{
            name:null,
            incomers:[],
            sections:[],
            notification_groups:[]
            },
        headers: [
        {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'machine_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        },
        {
            text: 'Name',
            value: 'name',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        }
    ],
        }
    },
computed:{
    
    paramEquationOptions(){
        let op=[]
        
        for(let i of this.paramEquations){
            op.push( {value:i.eq_id, label: i.label })
        }
        return op
         },

    notificationGroupList(){
        let op=[]
        if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
        }
        return op
        },
    },
methods:{
    getParamEquations(){
        this.loading=true
        //console.log('getParamEquations')
            axios.get(this.$store.state.api+'paramEquations',{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            
                            
                            this.paramEquations=response.data.data
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })


      },
    
    close(param){
            this.form.list=this.form.list.filter(
            function(data){ return data.parameter != param.parameter }
        );
      },
    onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.automated=this.automate
            let d=Object.assign({}, this.form)
            axios.post(this.$store.state.api+this.api,d,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshEnergyReports')
                // console.log("Report added "+d)
                this.onReset()
                    //this.dialog=false
                    //this.$emit('close')
            }else{
                //console.log(response.data.msg)
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading=false
            }})
            .catch(err=>{
                this.loading=false
                console.log(err)
            });
                //this.$store.dispatch('addDevice', d)
            }
        },
    onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.automate=false
            this.$emit('close')
        },
    resetMachineList(){
        this.addMachine=!this.addMachine
        this.machine_form = {}
        this.$refs.machineListForm.reset()


        }
    },
    watch:{
        selected(){
            this.automate_report()
        },
        selectedRootMachine(){
            this.show_root_machine = ! this.show_root_machine
            if(this.show_root_machine===false){
                this.form.root_machine = null
            }
        },
        workspace(){
            this.getMachineList()
        },
        rootWorkspace(){
            this.getRootMachineList()
        },
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>