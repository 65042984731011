<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
            <v-card-title  class="sub-heading">{{$store.getters.getTextMap().filters}}({{ filterCount }})</v-card-title>
            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
            <v-row no-gutters>
                <v-col cols="2">
                    <v-list :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-list-item>
                                <v-radio-group column v-model="timeFilter" row :dark="$store.getters.getColorPalette().isDark">
                                    <v-radio
                                        label="Day"
                                        value="day"
                                    ></v-radio>
                                </v-radio-group>
                        </v-list-item>
                        <v-list-item>
                                <v-radio-group column v-model="timeFilter" row :dark="$store.getters.getColorPalette().isDark">
                                    <v-radio
                                        label="Month"
                                        value="month"
                                    ></v-radio>
                                </v-radio-group>
                        </v-list-item>
                        <v-list-item>
                                <v-radio-group column v-model="timeFilter" row :dark="$store.getters.getColorPalette().isDark">
                                    <v-radio
                                        label="Quarter"
                                        value="quarter"
                                    ></v-radio>
                                </v-radio-group>
                        </v-list-item>
                        <!-- <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <v-list-item>
                                <v-radio-group v-model="timeFilter" row :dark="$store.getters.getColorPalette().isDark">       
                                    <v-radio
                                        label="Month"
                                        value="month"
                                    ></v-radio>
                                </v-radio-group>
                        </v-list-item>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <v-list-item>  
                                <v-radio-group v-model="timeFilter" row :dark="$store.getters.getColorPalette().isDark">             
                                    <v-radio
                                        label="Quarter"
                                        value="quarter"
                                    ></v-radio>
                                </v-radio-group>
                        </v-list-item> -->
                    </v-list>
                </v-col>
                <v-divider :dark="$store.getters.getColorPalette().isDark" vertical/>
                <v-col class="mx-2">
                    <v-row>
                        <v-col>
                            <v-card-title  class="sub-heading">{{$store.getters.getTextMap().choose_location_optional}}</v-card-title>
                            <v-autocomplete
                                v-if="!loading"
                                v-model="locations"
                                :items="locationOptions"
                                :label="$store.getters.getTextMap().location"
                                outlined   
                                dense      
                                multiple 
                                item-text="label"
                                item-value="value"
                                required
                                :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                :rules="[formRules.rules.required]"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-card-title v-if="filter_for==='safety_inspection'"  class="sub-heading">{{$store.getters.getTextMap().choose_inspection_type_optional}}</v-card-title>
                    <v-row no-gutters v-if="filter_for==='safety_inspection'">
                        <v-col>
                            <v-autocomplete
                                v-if="!loading"
                                v-model="inspection_types"
                                :items="inspectionTypeOption"
                                :label="$store.getters.getTextMap().inspection_type"
                                outlined   
                                dense       
                                item-text="label"
                                item-value="value"
                                required
                                multiple
                                :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
            <v-row no-gutters class="pb-2 px-2">
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="applyFilter">{{$store.getters.getTextMap().apply}}</v-btn>
                    <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" text small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'SafetyIncidentFilter',
    props:['filter_for'],
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            timeFilter:"month",
            locations:[],
            inspection_types:[],
            employees:[],
            filterCount:1,
            inspectionTypeOption:[
                {label:"Process Related Finding",value:"process_related"},
                {label:"Maintenance Related Finding",value:"maintenance_related"},
            ],
            formRules: FormRules,
        }
    },
    computed:{
        locationOptions(){
            let op=[]
            for(let i of this.$store.state.locations){
                op.push({
                    label:i.location_name,
                    value:i.location_id
                })
            }
            return op
        }
    },
    methods:{
        applyFilter(){
            let filters={
                "timeFilter":this.timeFilter,
                "locations":this.locations,
                "employees":this.employees,
                "inspection_types":this.inspection_types
            }
            this.$emit('filters',filters)
        },
        onClose(){
            this.$emit('close')
        }
    },
    watch:{
        locations(){
            if(this.locations && this.locations.length>0){
                this.filterCount = Number(this.filterCount + 1)
            }
        },
        inspection_types(){
            if(this.inspection_types && this.inspection_types.length>0){
                this.filterCount = Number(this.filterCount + 1)
            }
        },
        employees(){
            if(this.employees && this.employees.length>0){
                this.filterCount +=1
            }
        },
    }
}
</script>
