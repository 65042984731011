<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col cols="12">
                <WordTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WordTable from '@/components/lists/WordTable'
export default {
    name:'WordPage',
    components:{
        WordTable
    }
}
</script>