<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().add_production_plans }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">
                <v-row no-gutters>
                    <v-spacer></v-spacer><v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="showSKUID" :label="$store.getters.getTextMap().show_sku_id" ></v-checkbox>
                </v-row>
                <v-row dense>
                       
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="timeForm.date" :label="$store.getters.getTextMap().date" prepend-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on" :rules="objectRules" outlined
                                        dense></v-text-field>
                                </template>
                                <v-date-picker v-model="timeForm.date" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" ref="menufrom" v-model="menu3" :close-on-content-click="false"
                                :nudge-right="40" :return-value.sync="timeForm.time" transition="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="timeForm.time" :label="$store.getters.getTextMap().from_time"
                                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                         outlined dense></v-text-field>
                                </template>
                                <v-time-picker v-if="menu3" v-model="timeForm.from_time" full-width format="24hr"
                                    @click:minute="$refs.menufrom.save(timeForm.from_time)"></v-time-picker>
                            </v-menu>
                        </v-col>
                        
     
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="form.sku_id"
                            :items="skuOptions"
                            :label="$store.getters.getTextMap().sku"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.quantity"
                        :counter="15"
                        :rules="[rules.required, rules.noSpace, rules.unum]"
                        :label="$store.getters.getTextMap().quantity"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    
                                    outlined
                                    dense
                        v-model="form.code"
                        :counter="15"
                        :label="$store.getters.getTextMap().code"
                        required
                        ></v-text-field>
                    </v-col> -->
                    <v-col v-if="!loading">
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{ $store.getters.getTextMap().add  }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
import moment from 'moment'
export default {
    name:'AddProurQuantity',
    props:{
        quantity:{
            type:Object,
            required:false
        }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
        }
    },
    mounted(){
        if(this.quantity){
            this.form=Object.assign({},this.quantity)
        }
    },
    computed:{
        skuOptions(){
            let op=[]
            let g=this.$store.state.skus
            for(let i of g){
                if(this.showSKUID){
                    op.push( {value:i.sku_id, label: i.sku_id })

                }else{

                    op.push( {value:i.sku_id, label: i.sku_name })
                }
            }
          return op
        },
    },
    methods:{
        getPayload(){
            let timestamp;
            if(this.timeForm.time){
                timestamp=Number(moment(this.timeForm.date.toString()+' '+this.timeForm.to_time.toString(),'YYYY-MM-DD HH:mm').format("X"))
                
            }else{
                timestamp=Number(moment(this.timeForm.date.toString(),'YYYY-MM-DD').format("X"))
            }
            let payload={
                timestamp:timestamp,
                // from_time:Number(moment(this.timeForm.to_date.toString()+' '+this.timeForm.from_time.toString(),'YYYY-MM-DD HH:mm').format("X"))

            }
            this.form['timestamp']=payload['timestamp']
            // this.form['from_time']=payload['from_time']

            return this.form

        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                let payload=this.getPayload()
                axios.post(this.$store.state.api+'addPourQty',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{
    }
}
</script>