<template>
    <v-container>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-col align="right">
                <v-icon small :color="$store.getters.getColorPalette().successColor" @click="showEdit=!showEdit">mdi-pencil</v-icon>
                <DeleteConfirmation v-on:confirm="deleteConversion()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                    <v-icon small :color="$store.getters.getColorPalette().alertColor">mdi-delete</v-icon>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="showEdit">
            <v-col>
                <CreateESGScopeConversionTemp :esgData="esgData" v-on:close="cancel" v-on:update="$emit('update')"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import CreateESGScopeConversionTemp from '@/components/crud_components/derived/CreateESGScopeConversionTemp.vue';
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default{
    name: "ESGScopeConversionTemp",
    props: {
        "esgData": {
            type: Object,
            required: true
        },
    },
    components:{
        CreateESGScopeConversionTemp,
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            showEdit:false
        }
    },
    methods: {
        deleteConversion() {
            axios.post(this.$store.state.api + "deleteEsgScopeConversionTemp", this.esgData, { headers: {
                    Authorization: "Bearer " + this.$store.state.jwt
                } })
                .then(response => {
                if (response.data.status == "success") {
                    this.$emit("deleteConversion");
                }
                else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            });
        },
        cancel(){
            this.showEdit=false
            this.$emit('close')
        },
        confirm(){
            this.showEdit=false
        }
    },
    
}
</script>