<template>
  
    <v-card flat :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
      <v-container fluid >
        <v-card-title :class="'text-subtitle-2'"  :style="{color:$store.getters.getColorPalette().textColor}">{{report.name ?report.name : report.report_id}} - Timeframed SEC
          <v-spacer></v-spacer>
            <span v-if="!loading && $store.state.user && $store.state.settingMode "><EditSECReportModal :report="report" /> </span>
            <span> 
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular> 
              <v-btn
              v-if="!loading && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"
      class="mx-1"
   
      icon
      color="pink"
    >
         <DeleteConfirmation v-on:confirm="deleteSECReport()"  title="Delete Confirmation"  description="Are you sure you want to delete this Report?">
      <v-icon small  :dark="$store.getters.getColorPalette().isDark">
        mdi-trash-can
      </v-icon>
   </DeleteConfirmation>
    </v-btn></span>
    </v-card-title>
    
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-divider color="grey"></v-divider>
        <v-hover v-slot="{ hover }">
    <v-card-subtitle :style="{'border-radius': '5px '}" @click="overlay=!overlay" class="">
        <v-row>
            <v-col>
                <strong >{{$store.getters.getTextMap().energy_parameters}}:</strong>
                <strong >{{report.energy_list.length}}</strong>
            </v-col>
            <v-col>
                <strong >{{$store.getters.getTextMap().production_parameters}}:</strong>
                <strong >{{report.prod_list.length}}</strong>
            </v-col>
            
        </v-row>
        <v-row>
          <v-col>
            <strong >{{$store.getters.getTextMap().email_ids}}:</strong>
                <strong >{{report.emails ? report.emails.length : $store.getters.getTextMap().not_configured}}</strong>
          </v-col>
          <v-col><strong >{{report.unit}}</strong></v-col>
        </v-row>
        <v-overlay
          absolute
          :value="hover"
        >
        <v-row justify="center" align="center" align-content="center">
            <v-col justify="center" align="stretch" align-content="center">
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="dialog = true"
          >
          {{$store.getters.getTextMap().view_report}}
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
            </v-col>
        </v-row>
        </v-overlay>
    </v-card-subtitle>
        </v-hover>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
     :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorName"
          
        >
          <v-btn
            icon
     :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{report.name ?report.name : report.report_id}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
         :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
                              <v-col cols="11" align="left">
          <v-chip
            v-if="
              $store.state.user &&
              $store.state.settingMode
            "
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            ><v-avatar
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                color: $store.getters.getColorPalette().background2ColorCode,
              }"
              :class="
                $store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0'
              "
              ><v-icon
                :color="$store.getters.getColorPalette().accentCode"
                small
                >mdi-file-chart-outline</v-icon
              ></v-avatar
            >{{report.report_id}}</v-chip
          >
          <!-- <h3 ></h3> -->
        </v-col>
        <SECReportDetails :report="report" />
    </v-card>
        </v-dialog>
        </v-container>
    </v-card>
</template>
<script>
import axios from 'axios'
import EditSECReportModal from '@/components/modals/EditSECReportModal'
import SECReportDetails from '@/components/SECReportDetails'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'SECTimeFrameReport',
    props:['report'],
    components:{
        SECReportDetails,
        InfoAlert,
        EditSECReportModal,
        DeleteConfirmation
    },
    data(){
        return {
           showDialog:false,
            overlay:false,
            dialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:''
        }
    },
    computed:{

    },
    methods:{
        deleteSECReport(){
          this.loading=true
            this.$emit('deleteSECReport',this.report)
            axios.post(this.$store.state.api+'deleteSECReport',this.report,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshSECReports')
        //context.dispatch('refreshNodeStates')
        this.loading=false

      }else{
        //console.log(response.data.msg)
        this.loading=false
        this.info=response.data.msg
        this.showDismissibleAlert=true
      }})
      .catch(err=>{console.log(err)})
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
        
    }
}
</script>