<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                
                <v-autocomplete
                v-if="!loading"
            v-model="process"
            :items="processOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            
            :label="$store.getters.getTextMap().process"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col>
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    
                
                <v-autocomplete
                v-if="!loading"
            v-model="machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            
            :label="$store.getters.getTextMap().machine"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-if="multiple">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            clearable
            :rules="objectRules"
            multiple
            :label="$store.getters.getTextMap().parameters"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            
            :label="$store.getters.getTextMap().parameter"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row  v-if="multiple">
            <v-col>
              
              <v-chip
                class="ma-2"
                close
                small
                color="teal"
                text-color="white"
                @click:close="remove(param)" 
                v-bind:key="param.parameter" v-for="param in parameter"
              >
              {{param.name}}
              </v-chip>
                      </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
name:'ProcessMachineParamForm',
props:['multiple','removeParam'],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        process:null,
        machine:null,
        parameter:null,
        form:{},
        machineOptions:[],
        paramOptions:[],
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
computed:{
    processOptions(){
        let op=[]
        let g=this.$store.state.processes
        for(let i of g){
            op.push( {value:{process_id:i.process_id,process_name:i.name}, label: i.name })
        }
        return op
    },
},
methods:{
    remove(param){
      this.parameter=this.parameter.filter(x=>x.param_id!=param.param_id)
    },
    getMachineList(){
        let op=[]
        if(this.process.process_id){
            let payload={
                process_id:this.process.process_id
            }
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByProcess", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    let machines=response.data.machines
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:{machine_id:i.machine_id,machine_name:i.name,process_id:this.process.process_id}, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
        this.machineOptions=op
        },
    getParams(){
        if(this.machine ){
            this.loading=true
            axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine.machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.paramOptions=[]
                    for(let i of response.data.params){
                        this.paramOptions.push({label:i.name,value:{machine_name:this.machine.machine_name,param_id:i.param_id,name:this.machine.machine_name+' - '+i.name}})
                    }
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
    removeParameters(){
        for(let m of this.removeParam){
            this.parameter=this.parameter.filter(x=>x.param_id!=m.param_id)
        }
        this.send()
    },
    send(){
        this.form.parameter=this.parameter
        this.$emit('value',this.form)
    }
},
watch:{
    process(){
        this.getMachineList()
    },
    machine(){
        this.getParams()
    },
    parameter:{
        handler(){
            this.send()
        },
        deep:true
    },
    removeParam:{
        handler(){
            this.removeParameters()
        },
        deep:true
    }
}
}
</script>