<template>
  <span>
    <v-btn :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" text outlined small @click="dialog=!dialog">{{$store.getters.getTextMap().create_report}}<v-icon small :color="$store.getters.getColorPalette().btnborderColorCode" right>mdi-file-chart-outline</v-icon></v-btn>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-toolbar flat outlined :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
              <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{$store.getters.getTextMap().create_report}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
              </v-toolbar-items>
          </v-toolbar>
            <ReportsPanel/>
        </v-card>

        </v-dialog>
  </span>
</template>
<script>
import ReportsPanel from '@/components/panels/ReportsPanel'
export default {
    name:'CreateReportBottomSheet',
    components:{
        ReportsPanel
    },
    data(){
        return{
            dialog:false,
        }
    }
}
</script>
<style scoped>
.v-dialog--fullscreen {
  overflow-y: hidden;
}
</style>