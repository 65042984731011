<template>
    <v-container fluid>
        
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  class="mx-1 my-1" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
<v-row>
    <v-col>
 <v-form
    ref="orgForm"
    v-model="valid"
    
  >
      <v-card flat outlined  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
        <v-card-title class="headline">
          Update Payment Due date
        </v-card-title>
        <v-card-text>
            <v-row>
                
                <v-col><v-skeleton-loader
      v-if="loading"
    class="mx-auto"
    type="text"
  ></v-skeleton-loader>
              <v-menu :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.due_date"
          :label="$store.getters.getTextMap().due_date"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="[rules.required]"
        ></v-text-field>
      </template>
      <v-date-picker :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.due_date"
        
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
</v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-col>
</v-row>
</v-card>
    </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import moment from 'moment'
export default {
    name:'UpdatePaymentDueDate',
    components:{
        InfoAlert
    },
    mounted(){
        if(this.$store.state.organisation && this.$store.state.organisation.payment_due_date){
                const date=moment(this.$store.state.organisation.payment_due_date).format('YYYY-MM-DD')
                this.form.due_date=date
                
            }

    },
    data(){
        return {
            form:{
                
            },
                loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
                menu:null,
                rules:FormRules.rules,
        }
    },
    methods:{
        onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "updatePaymentDueDate", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshOrganisation");
            
           
            this.info = "Data added";
          this.showDismissibleAlert = true;
          this.loading = false;
            // this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
        //   this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            
            
            
        }
    }
}
</script>
<style scoped>
.v-sheet.v-card{
  background-color:#B2DFDB;
}
</style>