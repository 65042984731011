<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row>
            <v-col cols="12" align="right">
                <CreateIssueVoucherBottomSheet />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ApprovalPendingVoucherList/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <IssuePendingVoucherList/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ReceiptPendingVoucherList/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import CreateIssueVoucherBottomSheet from '@/components/modals/manufacturing/stores/CreateIssueVoucherBottomSheet'
import ApprovalPendingVoucherList from '@/components/lists/manufacturing/stores/ApprovalPendingVoucherList.vue';
import IssuePendingVoucherList from '@/components/lists/manufacturing/stores/IssuePendingVoucherList.vue';
import ReceiptPendingVoucherList from '@/components/lists/manufacturing/stores/ReceiptPendingVoucherList.vue';
export default {
    name:'VoucherRequestPage',
    components:{
        CreateIssueVoucherBottomSheet,
        ApprovalPendingVoucherList,
        IssuePendingVoucherList,
        ReceiptPendingVoucherList,
    }
}
</script>