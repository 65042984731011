<template>

<v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card flat>
            <v-card-title>{{comboParam.name?comboParam.name:comboParam.param_id}} <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-spacer></v-spacer>
    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        color="blue"
        v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.betaAccessLevel"
      >
        mdi-pencil
      </v-icon>
       <DeleteConfirmation v-on:confirm="deleteComboParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this combo param?"> 
      <v-icon
        small
      :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
        
      </v-icon> 
      </DeleteConfirmation>
    </span>
    </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle >
            
            <span class="h4">{{operator}}{{params}} {{comboParam.correction_operator}} {{comboParam.correction_operand}}</span>
        </v-card-subtitle>
            </v-card>
</v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'ComboParam',
    props:['comboParam'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
        }
    },
    computed:{
        operator(){
            if(this.comboParam.operator=='+'){
                return 'SUM'
            }else if(this.comboParam.operator=='*'){
                return 'PRODUCT'
            }else{
                return 'Unknown Operator'
            }
        },
        params(){
            let op=[]
            if(this.comboParam && this.comboParam.params){
            if(this.comboParam.param_type=="computed"){
                for(let i of this.comboParam.params){
                    let v=this.$store.getters.getComputedParameterById(i)
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    op.push(dev.name+' - '+v.name)
                }
            }else if(this.comboParam.param_type=="parameter"){
                for(let i of this.comboParam.params){
                    let v=this.$store.getters.getParameterById(i)
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    op.push(dev.name+' - '+v.name)
                }
            }
            }
            return op
        }
    },
    methods:{
        getParamNameById(id){
            if(id){
                if(this.comboParam.param_type=='computed'){
                    let v=this.$store.getters.getComputedParameterById(id)
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    return dev.name+' - '+v.name
                }else if(this.comboParam.param_type=='parameter'){
                    let v=this.$store.getters.getParameterById(id)
                    let dev=this.$store.getters.getDeviceById(v.device_id)
                    return dev.name+' - '+v.name
                }else{
                    return "Unassigned Name"
                }
            }else{
                return null
            }
        },
        deleteComboParam(){

            axios.post(this.$store.state.api+'deleteComboParam',this.comboParam,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshComboParams')
                            this.loading=false
                            this.info='The Param has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
       
        }
    }
}
</script>