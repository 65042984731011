<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col>
                <LocationEventTypeTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import LocationEventTypeTable from '@/components/lists/derived/LocationEventTypeTable'
export default {
    name:'LocationEventTypePage',
    components:{
        LocationEventTypeTable
    }
}
</script>