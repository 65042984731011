<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
          <v-col align-self="center">
              <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().aqi_comparison_panel }}</span>
          </v-col>
        </v-row>
        <v-row>
            <v-col align-self="center">
                <v-form ref="AQIForm" v-model="valid">
                    <v-row>
                        <v-col>
                            <v-autocomplete
                                v-model="area"
                                :items="AQAreasOptions"
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                outlined
                                dense
                                :label="$store.getters.getTextMap().area"
                                item-text="label"
                                item-value="value"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="AQDevice"
                                :items="AQDeviceOptions"
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                outlined
                                dense
                                multiple
                                :label="$store.getters.getTextMap().aq_device"
                                item-text="label"
                                item-value="value"
                            ></v-autocomplete>
                        </v-col>
                        <!-- <v-col v-if="AQDevice">
                            <v-autocomplete
                                v-model="pollutant"
                                :items="pollutantsOptions"
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                outlined
                                dense
                                label="Pollutants"
                                item-text="label"
                                item-value="value"
                            ></v-autocomplete>
                        </v-col> -->
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-model="from_date"
                                        outlined
                                        dense
                                        :label="$store.getters.getTextMap().from_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="ISODateRules"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-model="from_date"
                                    @input="menu1 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu 
                                :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-model="to_date"
                                        outlined
                                        dense
                                        :label="$store.getters.getTextMap().to_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="ISODateRules"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-model="to_date"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">{{ $store.getters.getTextMap().generate_report }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <v-row v-if="Object.values(this.graphData) && (Object.values(this.graphData)).length>0">
            <v-col v-if="AQDevice">
                <v-autocomplete
                    v-model="pollutant"
                    :items="pollutantsOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    :label="$store.getters.getTextMap().pollutants"
                    item-text="label"
                    item-value="value"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
                                <v-btn small>Bar</v-btn><v-btn small>Line</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="aqiGraph" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import Plotly from 'plotly.js'
import moment from 'moment'
export default {
    name:'AirQualityComparisionPanel',
    components:{
        InfoAlert,
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            form:{},
            showGraph:false,
            from_date:null,
            to_date:null,
            valid:false,
            menu1:null,
            menu2:null,
            area:null,
            AQDevice:null,
            pollutant:null,
            AQDeviceOptions:[],
            graphData:{},
            graphType:1,
            barMode:'stack',
            dbData:[],
            pollutantsOptions:[
                {label:"PM10",value:{name:"PM10",parameter:"pm10"}},
                {label:"PM2.5",value:{name:"PM2.5",parameter:"pm25"}},
                {label:"CO",value:{name:"CO",parameter:"co"}},
                {label:"CO2",value:{name:"CO2",parameter:"co2"}},
                {label:"SO2",value:{name:"SO2",parameter:"so2"}},
                {label:"NO2",value:{name:"NO2",parameter:"no2"}},
                {label:"VOC",value:{name:"VOC",parameter:"voc"}},
                {label:"O3",value:{name:"O3",parameter:"o3"}},
                {label:"NO",value:{name:"NO",parameter:"no"}},
                {label:"Humidity",value:{name:"Humidity",parameter:"humidity"}},
                {label:"Temperature",value:{name:"Temperature",parameter:"temp"}},
                {label:"AQI",value:{name:"AQI",parameter:"aqi"}}
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            ISODateRules:[
                v => !!v || 'Required',
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ],
            layout : {
                barmode: this.barMode,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:true,
                autosize: true,
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    computed:{
        AQAreasOptions(){
            let op=[]
            let g=this.$store.state.areas
                for(let i of g){
                    op.push( {value:{area_id:i.area_id,area_name:i.name}, label: i.name })
                }
            return op
        },
    },
    methods:{
        init(){
            if(this.AQDevice && this.AQDevice.length>0){
                let type = this.graphType == 1 ? this.$store.getters.getTextMap().line : this.$store.getters.getTextMap().bar
                this.graphData={}
                for(let i of this.AQDevice ){
                    this.graphData[i.aq_name]={
                    x: [],
                    y: [],
                    name:i.aq_name,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        plotGraph(){
            Plotly.newPlot(this.$refs.aqiGraph, Object.values(this.graphData), this.layout, {displaylogo: false});
        },
        createChart(){
            this.init()
            this.layout['title']= this.pollutant.name
            this.layout['showlegend']=true
            for(let i of this.dbData){
                if(!(i['device'] in this.graphData)){
                    let type = this.graphType == 1 ? this.$store.getters.getTextMap().line : this.$store.getters.getTextMap().bar
                    this.graphData[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                    }
                }   
                this.graphData[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                this.graphData[i['device']]['y'].push(i[this.pollutant.parameter])
                if(this.dbData && this.dbData.length>0){
                    Plotly.newPlot(this.$refs.aqiGraph, Object.values(this.graphData), this.layout, {displaylogo: false});
                }
            } 
        },
        getAQDevices(){
            let op=[]
            this.loading=true
            axios.post(this.$store.state.api+'getAQByAreaById',{area_id:this.area.area_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if (response.data.status == "success") {
                    let aqData=response.data.data
                    for(let i of aqData){
                        if(i && i.label){
                            op.push({value:{aq_id:i.aq_id,aq_name:i.label,hub_id:i.hub_id}, label: i.label })
                        }else{
                            op.push({value:{aq_id:i.aq_id,aq_name:i.aq_id,hub_id:i.hub_id}, label: i.aq_id })
                        }
                    }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
            this.AQDeviceOptions=op
        },
        onSubmit(){
            if(!this.pollutant){
                this.pollutant={name:"PM10",parameter:"pm10"}
            }
            if(this.AQDevice && this.AQDevice.length>0 && this.pollutant.parameter){
                this.graphData={}
                let aq_ids=[]
                this.dbData=[]
                let deviceMap={}
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
                this.form['pollutant']=this.pollutant.parameter
                this.form['area_id']=this.area.area_id
                this.form['from_date']=this.from_date
                this.form['to_date']=this.to_date
                for(let i of this.AQDevice){
                    aq_ids.push(i.aq_id)
                    deviceMap[i.aq_id]=i.aq_name
                }
                this.form['aq_ids']=aq_ids
                axios.post(this.$store.state.api+'getAQIDataByPollutantsAndGroupedByDate',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if (response.data.status == "success") {
                        this.dbData=response.data.data
                            this.dbData.map(x=>{
                            x.device=deviceMap[x.aq_id]?deviceMap[x.aq_id]:'Device Not define'
                        })
                        this.createChart()
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    },
    watch:{
        area:{
            handler(){
                this.getAQDevices()
            },
            deep:true
        },
        pollutant:{
            handler(){
                this.onSubmit()
            },
            deep:true
        },
        graphType: function (){
            this.createChart()
        },
}
}
</script>