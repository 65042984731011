<template>
    <v-container fluid :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode }">

        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />

        <!-- config checklist button -->
        <div :style="{display:'flex', justifyContent:'space-between',alignItems:'center',marginBottom:'5px'}">
             <div :style="{marginRight:'5px'}">
                <v-text-field v-model="search" append-icon="mdi-magnify"
                    :label="$store.getters.getTextMap().search" single-line hide-details outlined
                    dense></v-text-field>
             </div>
            <div>
               <v-btn :color="$store.getters.getColorPalette().btnColor" outlined text elevation="0" @click="openDialog"
                >
                {{ $store.getters.getTextMap().create_checklist_template }}
            </v-btn>
            </div>
         </div>

        <v-dialog :dark="$store.getters.getColorPalette().isDark" v-model="dialog"
            :color="$store.getters.getColorPalette().backgroundColorCode" fullscreen hide-overlay
            transition="dialog-bottom-transition">
            <v-card
            :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode" flat
            
            >
                <!-- config checklist form -->
                <v-form ref="form" v-model="valid">
                    <v-card class="px-10 py-5" elevation="0" :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().backgroundColorCode" flat>
                        <div class="d-flex justify-space-between">
                            <h3 class="py-4">

                                {{
                                    $store.getters.getTextMap().create_checklist_template

                                }}

                            </h3>
                            <div>
                                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="closeForm"
                                    class="mr-3">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field v-if="!loading" placeholder="Checklist Name" v-model="name"
                                    :rules="[rules.required]" outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field v-if="!loading" placeholder="Checklist Description" v-model="msg" outlined
                                    dense :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="5">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field v-if="!loading" placeholder="Enter Field Name" v-model="field_name"
                                    :rules="[rules.required]" outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="5">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select v-if="!loading" placeholder="Enter Field Type" v-model="field_type"
                                    :rules="[required]" :items="fieldTypeOptions" item-text="label" item-value="value"
                                    outlined dense>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="2">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor"
                                    @click="addToList()">Add</v-btn>
                            </v-col>
                            <span class="mt-n10 px-5">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-checkbox v-if="!loading" :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor" v-model="type_rule"
                                    :label="$store.getters.getTextMap().type_rule"></v-checkbox>
                            </span>
                        </v-row>
                    </v-card>
                </v-form>

                <!-- meta data table -->
                <v-row v-if="!loading">
                    <v-col cols="12" v-if="tableData.length > 0" class="px-10">
                        <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense
                        :dark="$store.getters.getColorPalette().isDark"
                            :style="{
                                'background-color':
                                    $store.getters.getColorPalette().backgroundColorCode,
                                color: $store.getters.getColorPalette().accentCode,
                                border: `1px solid ${$store.getters.getColorPalette().accentCode
                                    }`,
                            }">
                            <template v-slot:default>
                                <thead :style="{
                                    'background-color':
                                        $store.getters.getColorPalette().backgroundColorCode,
                                    color: $store.getters.getColorPalette().accentCode,
                                }">
                                    <tr>
                                        <th :class="' text-left  text-subtitle-1 text-capitalize '" :style="{
                                            'background-color':
                                                $store.getters.getColorPalette().foregroundColorCode,
                                            color: $store.getters.getColorPalette().accentCode,
                                        }">
                                            {{ $store.getters.getTextMap().field_name }}
                                        </th>
                                        <th :class="' text-left  text-subtitle-1 text-capitalize '" :style="{
                                            'background-color':
                                                $store.getters.getColorPalette().foregroundColorCode,
                                            color: $store.getters.getColorPalette().accentCode,
                                        }">
                                            {{ $store.getters.getTextMap().field_type }}
                                        </th>
                                        <th :class="' text-left  text-subtitle-1 text-capitalize '" :style="{
                                            'background-color':
                                                $store.getters.getColorPalette().foregroundColorCode,
                                            color: $store.getters.getColorPalette().accentCode,
                                        }">
                                            {{ $store.getters.getTextMap().type_rule }}
                                        </th>
                                        <th :class="' text-left  text-subtitle-1 text-capitalize '" :style="{
                                            'background-color':
                                                $store.getters.getColorPalette().foregroundColorCode,
                                            color: $store.getters.getColorPalette().accentCode,
                                        }">
                                            {{ $store.getters.getTextMap().delete }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody :style="{
                                    'background-color':
                                        $store.getters.getColorPalette().backgroundColorCode,
                                }">
                                    <tr v-for="(d, ind) in tableData" :key="ind" :style="{
                                        'background-color':
                                            $store.getters.getColorPalette().backgroundColorCode,
                                        color: $store.getters.getColorPalette().accentCode,
                                    }">
                                        <td class="text-lef">{{ d.key_text }}</td>
                                        <td class="text-left">{{ d.entry_type }}</td>
                                        <td class="text-left">{{ d.required }}</td>
                                        <td class="text-left">
                                            <DeleteConfirmation v-on:confirm="deleteFromTableData(d)"
                                                title="Delete Confirmation"
                                                description="Are you sure you want to delete this gauge?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-col cols="12" class="d-flex justify-center pa-8">
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit"
                                class="mr-3">
                                {{ $store.getters.getTextMap().submit }}
                            </v-btn>
                            <v-btn :color="$store.getters.getColorPalette().cancelbtnColor"
                                :dark="$store.getters.getColorPalette().isDark" @click="onReset" class="ml-3">
                                {{ $store.getters.getTextMap().cancel }}
                            </v-btn>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>












        <!-- List of added checklist Templates -->
        <v-row>
            <v-col cols="12">
                    <!-- data table -->
                    <v-data-table :headers="headers" :items="templates" item-key="template_id" :search="search"
                        :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
                        loading-text="Loading... Please wait" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :style="{border:'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"
                        >

                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>{{ $store.getters.getTextMap().checklist_templates }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                            </v-toolbar>
                        </template>


                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text
                                    outlined v-on:click="editTemplate(item)">{{
                                        $store.getters.getTextMap().edit_checklist_template
                                    }}

                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().deletebtnColor"
                                        small>mdi-pencil</v-icon>
                                </v-btn>


                                <DeleteConfirmation v-on:confirm="deleteChecklistTemplate(item)"
                                    title="Delete Confirmation" description="Are you sure you want to delete this Log?">
                                    <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text
                                        outlined>
                                        {{ $store.getters.getTextMap().delete_checklist_template }}

                                        <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                                            :color="$store.getters.getColorPalette().deletebtnColor"
                                            small>mdi-trash-can</v-icon>
                                    </v-btn>
                                </DeleteConfirmation>

                            </td>
                        </template>


                    </v-data-table>
            </v-col>
        </v-row>




    </v-container>
</template>

<script>

import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
// import createChecklistTemplateDialog from './createChecklistTemplateDialog.vue';




export default {
    name: "ConfigChecklist",
    components: {
        DeleteConfirmation,
        InfoAlert,
        // createChecklistTemplateDialog
    },
    mounted() {
        this.getConfiguredChecklistData()
    },


    data() {
        return {
            rules: FormRules.rules,
            showDismissibleAlert: false,
            info: '',
            search: '',
            loading: false,
            sortDesc: false,
            sortBy: 'created',
            expanded: [],
            singleExpand: false,
            dialog: false,
            openForm: false,
            name: null,
            msg: null,
            field_name: null,
            field_type: null,
            type_rule: false,
            selectedTemplate: null,
            endpoint: "createChecklistTemplate",
            meta: [],
            tableData: [],
            templates: [],
            fieldTypeOptions: [
                {
                    label: 'Text',
                    value: 'text'
                },
                {
                    label: "Image",
                    value: 'image'
                },
                {
                    label: "Check",
                    value: "check"
                }
            ],
            valid: false,
            edit: false,
            required: (value) => value !== " " && value !== undefined,
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    filterable: false,
                    value: 'name',
                    class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Description', value: 'msg', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Created', value: 'created', class: " text-capitalize font-weight-black " + this.$store.getters.getColorPalette().tableHeaderColorName },

            ]

        }
    },
    methods: {




        onReset() {
            this.$refs.form.reset();
        },

        onSubmit() {

            // create submit
            if (!this.edit) {
                this.$refs.form.validate();
                if (this.valid) {
                    let payload = {
                        name: this.name,
                        msg: this.msg,
                        checklist_meta: this.meta,
                    };
                    this.loading = true;
                    // console.log(payload, "payload");
                    axios
                        .post(this.$store.state.api + this.endpoint, payload, {
                            headers: {
                                Authorization: "Bearer " + this.$store.state.jwt,
                            },
                        })
                        .then((response) => {
                            // console.log(response);
                            if (response.data.status === "success") {
                                this.info = "Checklist Template Created";
                                this.showDismissibleAlert = true;
                                this.closeForm()
                                this.loading = false;
                                this.getConfiguredChecklistData()

                            } else {
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                                this.loading = false;
                            }
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.showDismissibleAlert = true;
                            this.info = err;
                            this.loading = false;
                        });
                }
            }

            // update submit
       

                if (this.edit && this.selectedTemplate) {
                    let payload = {
                        name: this.name,
                        msg: this.msg,
                        checklist_meta: this.meta,
                        template_id: this.selectedTemplate.template_id
                    };
                    this.loading = true;
                    // console.log(payload, "payload");
                    axios
                        .post(this.$store.state.api + this.endpoint, payload, {
                            headers: {
                                Authorization: "Bearer " + this.$store.state.jwt,
                            },
                        })
                        .then((response) => {
                            // console.log(response);
                            if (response.data.status === "success") {
                                this.info = "Checklist Template Created";
                                this.showDismissibleAlert = true;
                                this.loading = false;
                                this.closeForm()

                                this.getConfiguredChecklistData()

                            } else {
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                                this.loading = false;
                            }
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.showDismissibleAlert = true;
                            this.info = err;
                            this.loading = false;
                        });
                }
            
        },


        deleteFromTableData(item) {
            this.tableData = this.tableData.filter(
                (i) => item.key_text !== i.key_text
            );
        },

        addToList() {
            if (this.field_name && this.field_type) {
                const exists = this.tableData.some(
                    (item) =>
                        item.key_text === this.field_name &&
                        item.entry_type === this.field_type
                );

                if (exists) {
                    alert("This field name and type are already in the list.");
                } else {
                    this.tableData.push({
                        key_text: this.field_name,
                        entry_type: this.field_type,
                        required: this.type_rule,
                    });
                }
            }
            this.meta = [...this.tableData];
        },



        openDialog() {
            this.dialog = !this.dialog
        },

        closeForm() {
            this.endpoint="createChecklistTemplate"
            this.dialog = false;
            this.onReset();
            this.edit = false;
            this.selectedTemplate = null;
            this.tableData=[]
        },

        deleteChecklistTemplate(d) {
            axios
                .post(this.$store.state.api + 'deleteChecklistTemplate', { template_id: d.template_id }, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.jwt,
                    },
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.info = "Checklist Template Deleted";
                        this.showDismissibleAlert = true;
                        this.loading = false;
                        this.getConfiguredChecklistData()
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    this.showDismissibleAlert = true;
                    this.info = err;
                    this.loading = false;
                });



        },
        getConfiguredChecklistData() {
            this.loading = true
            // console.log("inside..");

            axios.get(this.$store.state.api + "checklistTemplates", {
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.jwt
                }
            })
                .then(response => {
                    this.templates = [...response.data.data]
                    if (response.data.status === 'success') {
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false

                })
                .catch(err => {
                    console.log(err);

                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
        },

        editTemplate(item) {
            this.edit = true;
            this.selectedTemplate = item;
            this.name = item.name;
            this.msg = item.msg;


            axios.post(this.$store.state.api + 'checklistTemplateMetaById', { template_id: item.template_id },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                }
            ).then((response) => {
                this.tableData = response.data.data

            })

            this.dialog = true;
        }

    }
}
</script>

<style scoped>
.no-horizontal-scroll {
    overflow: hidden;
}
</style>