<template>

    <v-container fluid    :color="$store.getters.getColorPalette().backgroundColor">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
            <v-col align-self="center">
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().timeframed_trends }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="right" align-self="center" >
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row >
            
           
        
            <v-col v-if="formType==='deviceForm'">
              <WorkspaceDeviceParamForm :multiple="true" v-on:value="updateParamList"/>
              </v-col>
            <v-col v-else>
              <WorkspaceMachineParamForm :multiple="true" v-on:value="updateParamList"/>
              </v-col>
              <!-- <v-skeleton-loader
                  v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-select  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.workspace"
              :items="workspaceOptions"
              label="Workspace"
              outlined
              item-text="label"
              item-value="value"
              required
              :rules="objectRules"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
              ></v-select>
            </v-col>
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          label="Device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.parameters"
          :items="paramOptions"
          label="Device Parameter"
          outlined
          multiple
          clearable
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"  
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col> -->
        </v-row>
        <v-row>
          
            
        </v-row>
        <v-row>
          
            
            <v-col align="center" align-self="center" v-if="timeRange=='custom'">
                <v-menu :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            dense
            outlined
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col v-if="timeRange=='custom'">
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            dense
            outlined
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col align="center" align-self="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="timeRange"
          :items="timeRangeOptions"
          :label="$store.getters.getTextMap().time_range"
          dense
            outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col align="center" align-self="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.timeframe"
          :items="timeframeOptions"
          :label="$store.getters.getTextMap().time_frame"
          dense
            outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col align="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
       
            small
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().generate_report }}
          </v-btn>
          
            </v-col>
            
        </v-row>
        
        </v-form>
        
        <!-- <v-row no-gutters>
          <v-col>
            <v-col>
                
    <v-chip
      class="ma-2"
      close
      small
      color="teal"
      text-color="white"
      @click:close="remove(param)" 
      v-bind:key="param.param_id" v-for="param in form.parameters"
    >
     {{param.name}}
    </v-chip>
            </v-col>
          </v-col>
        </v-row> -->
            <v-divider></v-divider>
        <v-row v-if="Object.keys(graphData).length>0 " no-gutters>
            <v-col align-self="center" :style="{'color':  $store.getters.getColorPalette().accentCode}">
                
                <v-btn-toggle
                 :dark="$store.getters.getColorPalette().isDark"
                v-if="rawData.length>0"
                  v-model="graphType"
                  mandatory
                  rounded
                >
                  <v-btn small>
                    Bar
                  </v-btn>
                  <v-btn small>
                    Line
                  </v-btn>
                      </v-btn-toggle>
            </v-col>
            <v-col v-if="!loading " align-self="end">
              <v-select
            :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="processing"
          :items="processingOptions"
          :label="$store.getters.getTextMap().date"
          
          small
          
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col align="center" align-self="center">
              <v-btn 
                small
             :color="$store.getters.getColorPalette().downloadbtnColor"
                outlined
                @click="downloadTable">
                {{ $store.getters.getTextMap().download_table }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                
                <div ref="graph"></div>
              </v-col>
            </v-row>
            <v-row v-if="Object.keys(tableData).length>0 ">
          <v-col>
            <v-data-table
      :headers="headers"
      :items="tableData"
      item-key="date"
      :search="search"
      :loading="loading"
    loading-text="Loading... Please wait"
:dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
   :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title> {{ $store.getters.getTextMap().table }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          <v-spacer></v-spacer>
                        <v-switch
                        class="mt-4"
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="showMinMax"
                            :label=" $store.getters.getTextMap().showMinMax_values"
                        ></v-switch>
        <v-btn 
                small
             :color="$store.getters.getColorPalette().downloadbtnColor"
                outlined
                @click="downloadTable">
                {{ $store.getters.getTextMap().download_table }}
                </v-btn>
        
      </v-toolbar>
          </template>
          
          
    </v-data-table>
          </v-col>
        </v-row>
            
            <v-divider></v-divider>
            <a ref="csvDownload" hidden></a>
    </v-container>
</template>
<script>
//import DualAxisLineGraphByTimestamp from '@/components/graphs/DualAxisLineGraphByTimestamp';
//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import InfoAlert from '@/components/InfoAlert'
import ColorUtility from '@/utillities/ColorUtility'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm.vue'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
export default {
    name:'ParamTimeframedPanel',
    components:{
        InfoAlert,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm
    },
    data(){
        return {
          colorCode:ColorUtility.colorTypesCodeArray,
            form:{
                //date:null,
                //device:null,
                //device2:null,
                parameters:null,
                timeframe:'day',
            },
            search:null,
            timeRange:'custom',
            formType:'machineForm',
            graphType:'line',
            showMinMax:false,
            //date: new Date().toISOString().substr(0, 10),
            report:{
                //date:null,
                //parameter:null,
                //parameter2:null
                },
                valid:false,
                menu:false,
                menu1:false,
                menu2:false,
                menu3:false,
                toTime:null,
                layout:{
              //title:'Compare',
              showlegend:true,
              
              font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
            },
            fromTime:null,
            processing:'usage',
            graphData:[],
            graphData2:[],
            rawData:[],
            tableData:[],
            headers:[],
            showDismissibleAlert:false,
            loading:false,
            info:'',
            processingOptions:[
                {label:  this.$store.getters.getTextMap().usage_or_average,value:'usage'},
                //{label:'Standard Deviation',value:'std'},
                //{label:'Statistical Mode',value:'mode'},
                {label: this.$store.getters.getTextMap().peak_value, value:'max'},
                {label: this.$store.getters.getTextMap().min_value, value:'min'}
                ],
            timeframeOptions:[
                {label:this.$store.getters.getTextMap().daily,value:'day'},
                {label:this.$store.getters.getTextMap().monthly,value:'month'},
                {label:this.$store.getters.getTextMap().weekly,value:'week'},
                {label:this.$store.getters.getTextMap().yearly,value:'year'},
            ],
            timeRangeOptions:[
            {label:this.$store.getters.getTextMap().custom,value:'custom'},
            {label:this.$store.getters.getTextMap().last_30_days,value:'30_day'},
            {label:this.$store.getters.getTextMap().last_90_days,value:'90_day'},
                {label:this.$store.getters.getTextMap().last_12_weeks,value:'12_week'},
                {label:this.$store.getters.getTextMap().last_6_months,value:'6_month'},
                {label:this.$store.getters.getTextMap().last_12_months,value:'12_month'},
                {label:this.$store.getters.getTextMap().year_5,value:'5_year'},
            ],
            objectRules:[
                v => !!v || this.$store.getters.getTextMap().required
            ],
            ISODateRules:[
                v => !!v || this.$store.getters.getTextMap().required,
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ]

        }
    },
    mounted(){

    },
    computed: {
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        
      
      label1(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParamById(this.report.parameter)
            if(parameter.name){
              return parameter.name
            }else{
              return this.report.parameter
            }
          }else{
            return ''
          }
        },
        
        unit(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParamById(this.report.parameter)
            if(parameter.unit){
              return parameter.unit
            }else{
              return null
            }
          }else{
            return ''
          }
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.snode_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
        
        
    },
    methods: {
      updateParamList(value){
        this.form.parameters=value.parameter
        this.form.device=value.device
        this.form.machine=value.machine
        

        },
      remove(param){
        this.form.parameters=this.form.parameters.filter(x=>x.parameter!=param.parameter)
      },
      createTable(){
        this.tableData=[]
        this.headers=[{text:'Date',value:'date'}]
        let temp=this.rawData.reduce((prev,current)=>{
              let dt=moment(current.date,'YYYYMMDD')
              switch(this.report['timeframe']){
                case 'day':
                  dt=dt.format('YYYY-MM-DD')
                  break;
                case 'month':
                  dt=dt.format('MMM-YYYY')
                  break;
                case 'week':
                  dt=dt.format('YYYY-MM-DD')
                  break;
                case 'year':
                  dt=dt.format('YYYY')
                  break;
                default:
                dt=dt.format('YYYY-MM-DD')

              }
              
              if(!(dt in prev)){
                prev[dt]={
                  date:dt
                }
                
              }
              
              prev[dt][current.param_id]=current[this.processing]
              prev[dt][current.param_id+'_min']=current['min']
              prev[dt][current.param_id+'_max']=current['max']
              return prev
            },{})
            this.tableData=Object.values(temp)
            for(let h of this.report.parameters){
              // console.log(h)
              this.headers.push({text:this.getName(h.param_id), value:h.param_id,class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
              if(this.showMinMax){

                this.headers.push({text:this.getName(h.param_id)+'(MIN)', value:h.param_id+'_min',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
                this.headers.push({text:this.getName(h.param_id)+'(MAX)', value:h.param_id+'_max',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
              }
            }
      },
      // download(){

      //     //let pdf = new jsPDF('landscape','pt',[512,600]);
      //       //let pdf = new jsPDF();
      //       //pdf.table(20, 30, this.currentData);
      //       let header=['Date','Param Name','usage','peak', "min"]
            
      //       let body=[]
      //       for (let i of this.rawData){
      //           let temp=[]
      //           temp.push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
      //           temp.push(this.$store.getters.getParameterById(i.param_id).name)
                
      //           temp.push(i.usage)
      //           temp.push(i.max)
      //           temp.push(i.min)
                

                
      //           body.push(temp)
      //       }
      //       /*pdf.autoTable({
      //           head: [header],
      //           body: body
      //           })*/
      //       //pdf.save(this.productionLog.name+'_'+this.dateRangeText+'.pdf');

      //       let csvHeader=header.join(',')+"\n"
      //       let csv=csvHeader
      //       csv+=body.map(e => e.join(",")).join("\n");
      //       let csvData = new Blob([csv], { type: 'text/csv' });  
      //       let csvUrl = URL.createObjectURL(csvData);
      //       //let hiddenElement = document.createElement('a');
      //       let hiddenElement = this.$refs.csvDownload;
      //       hiddenElement.href = csvUrl;
      //       hiddenElement.target = '_blank';
      //       hiddenElement.download = this.report.device.name+'_'+this.processing+'_'+this.report.from_date+'_'+this.report.to_date + '.csv';
      //       hiddenElement.click();

      // },
      downloadTable(){
        //let pdf = new jsPDF('landscape','pt',[512,600]);
            // let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=this.headers.map(x=>x['text'])
            let headerVal=this.headers.map(x=>x['value'])
            
            let body=[]
            for (let i of this.tableData){
                let temp=[]
                for(let j of headerVal){
                  temp.push(i[j])
                }
                
                body.push(temp)
            }
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Param_trends_'+this.processing+'_'+this.report.from_date+'_'+this.report.to_date + '.csv';
            hiddenElement.click();
      },
      getName(param_id){
        if(this.form.parameters && this.form.parameters.length){
          let param=this.form.parameters.find(x=>x.param_id === param_id)
          if(param.name){
            return param.name
          }

        }
        let prm=this.$store.getters.getParamById(param_id)
                          //let name=this.$store.getters.getParameterById(i.param_id)+prm.name

                          let name=prm.name
                          if(this.$store.getters.getDeviceById(prm['device_id'])){
                            this.$store.getters.getDeviceById(prm['device_id'])['name']+'-'+prm.name
                          }
                          if (prm.unit){
                            name+='('+prm.unit+')'
                          }
                            return name
      },
      createGraph(){
        if(this.rawData && this.rawData.length>0){
          
            this.graphData={}
            let type=this.graphType==1?'scatter':'bar'
            if(!this.processing){
              this.processing='usage'
            }
            this.layout.colorway = this.colorCode
            for (let i of this.rawData){
                //console.log(i)
                        if(i.param_id && i.param_id in this.graphData){
                            this.graphData[i.param_id]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }else{
                          // let prm=this.$store.getters.getParameterById(i.param_id)
                          // //let name=this.$store.getters.getParameterById(i.param_id)+prm.name
                          //   let name=this.$store.getters.getDeviceById(prm['device_id'])['name']+'-'+prm.name
                            this.graphData[i.param_id]={
                                x: [],
                                y: [],
                                name:i.name?i.name:this.getName(i.param_id),
                                type: type
                            }
                            this.graphData[i.param_id]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }
                    
                }
                //this.graphData.push(data)
                
            Plotly.newPlot(this.$refs.graph, Object.values(this.graphData), this.layout,{displaylogo: false, displayModeBar:true});
        }

      },
      getStartDate(timeRange){
        switch(timeRange){
          case '30_day':
          return moment().subtract(30,'days');
          case '90_day':
          return moment().subtract(90,'days');
          case '12_week':
          return moment().subtract(12,'weeks').startOf('week');
          case '6_month':
          return moment().subtract(6,'months').startOf('month');
          case '12_month':
          return moment().subtract(12,'months').startOf('month');
          case '5_year':
          return moment().subtract(5,'years').startOf('year');
          default:
            return moment().subtract(12,'months')
        }
      },
      onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              this.graphData={}
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let d=Object.assign({}, this.form)
              // console.log(this.timeRange)
              if(this.timeRange=='custom'){
                d.from_date=moment(d.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
            d.to_date=moment(d.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
              }else{
                d.from_date=this.getStartDate(this.timeRange).format("YYYYMMDD")
                d.to_date=moment().format("YYYYMMDD")
              }
            
            d['parameters']=this.form.parameters.map(a=>a.param_id)
              
              
                //let name=this.$store.getters.getDeviceById(p['device_id'])['name'] +" - " +p['name']
                let payload={
                    timeframe:d.timeframe,
                    from_date:d.from_date,
                    to_date:d.to_date,
                    parameters:d.parameters
                }
                this.report['timeRange']=this.form.timeRange
                console.log(payload)
                axios.post(this.$store.state.api+'getParamGroupedUsageDataByTimeframe',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                  console.log("Param data received")
                  //console.log(response.data.monthlyData)
                  //context.state.monthlyData=response.data.monthlyData
            //console.log(response.data.data)
            //console.log('something')


            if(response.data.status==='success'){
                //console.log(response.data.data)
                this.rawData=response.data.data
                
                  this.report['parameters']=this.form.parameters
                  this.report['timeframe']=payload['timeframe']
                  //this.report['parameter2']=this.form.parameter2
                  this.report['from_date']=payload.from_date
                  this.report['to_date']=payload.to_date
                  this.report['device']=this.form.device
                  this.createGraph()
                  this.createTable()

                }else{
                  this.info = response.data.msg;
                  //this.info = d;
                        this.showDismissibleAlert = true;
                  //console.log(response.data.msg)
                  this.loading=false
                }
                  this.loading=false

                })
                .catch(err=>{
                this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                        })

              
              
    
   
            }
        }
    
    },
    watch:{
      processing(){
        this.createGraph()
        this.createTable()
      },
      showMinMax(){
        this.createTable()
      },
      graphType(){
            // this.init()
            this.createGraph()
        },
    }
}
</script>