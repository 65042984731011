<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <AirQualityInsights :area="area" :air_quality="air_quality"/>
        <AirQualityRealTimeTable :air_quality="air_quality"/>
        <AirQualityHistoricalDataItem :air_quality="air_quality"/>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import AirQualityHistoricalDataItem from '@/components/items/application/airquality/AirQualityHistoricalDataItem'
import AirQualityRealTimeTable from '@/components/items/application/airquality/AirQualityRealTimeTable'
import AirQualityInsights from '@/components/items/application/airquality/AirQualityInsights.vue'
export default {
    name:'AirQualityPanel',
    props:['area','air_quality'],
    components:{
        InfoAlert,
        AirQualityHistoricalDataItem,
        AirQualityRealTimeTable,
        AirQualityInsights
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
        }
    }
}
</script>