<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-data-table
            :headers="headers"
            :items="locationEventTypes"
            item-key="index"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :dark="$store.getters.getColorPalette().isDark"
        >
            <template v-slot:top>
            <v-toolbar
                flat
                :dark="$store.getters.getColorPalette().isDark"
            >
            <v-toolbar-title> {{ $store.getters.getTextMap().locations_event_types }} </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <CreateLocationEventTypeModal v-on:update="getLocationEventTypes" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                <v-icon class="ml-2" small :color="$store.getters.getColorPalette().accentCode" @click="getLocationEventTypes">mdi-refresh</v-icon>
            </v-toolbar>
          </template>
          <template v-slot:item.from_location="{item}">
            {{ getLocationName(item.from_location) }}
        </template>
          <template v-slot:item.to_location="{item}">
            {{ getLocationName(item.to_location) }}
        </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <LocationEventType :locationEventType="item" />
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</template>
<script>
import CreateLocationEventTypeModal from '@/components/modals/derived/CreateLocationEventTypeModal'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import LocationEventType from '@/components/items/derived/LocationEventType.vue'
export default {
    name:'LocationEventTypeTable',
    components:{
        CreateLocationEventTypeModal,
        InfoAlert,
        LocationEventType,
        // DeleteConfirmation
    },
    mounted(){
      this.getLocationEventTypes()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            // locations:[],
            expanded: [],
            headers: [
                {
                    text: 'From location',
                    align: 'start',
                    // filterable: false,
                    value: 'from_location',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                {
                    text: 'To Location',
                    // filterable: false,
                    value: 'to_location',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Event Type', value: 'event_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                // { text: 'Type', value: 'location_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    computed:{
        locationEventTypes(){
            return this.$store.state.locationEventTypes.map((item,index)=> ({...item, index: `item_${index}` }))
        }
    },
    methods:{
        getLocationName(locationID){
            let i=this.$store.getters.getLocationById(locationID)
            if(i && i.location_name){
                return i.location_name
            }
            return 'Undefined'
        },
        getLocationEventTypes(){
            // this.loading=true
            this.$store.dispatch('refreshLocationEventTypes')
            // axios.get(this.$store.state.api+'locationEventType',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            // .then(response=>{
            //     if(response.data.status=='success'){
            //         this.locations=response.data.data
            //     }else{
            //         this.info=response.data.msg
            //         this.showDismissibleAlert=true
            //     }
            //     this.loading=false
            // }).catch(err=>{
            //     this.loading=false
            //     this.info=err
            //     this.showDismissibleAlert=true 
            // })
        },
        deleteLocationEventType(item){
            this.loading=true
            axios.post(this.$store.state.api+'deleteLocationEventType',item,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.getLocationEventTypes()
                    
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true 
            })
        },
    },
}
</script>
