<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="safetyInspectionForm" v-model="valid">
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.inspector_id"
                        :counter="25"
                        :rules="[formRules.rules.required,formRules.rules.validString,formRules.rules.size(25)]"
                        :label="$store.getters.getTextMap().inspector_id"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.location_id"
                        :items="locationOptions"
                        :label="$store.getters.getTextMap().location"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-menu :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="form.inspection_date"
                                :label="$store.getters.getTextMap().inspection_date"
                                outlined
                                dense
                                readonly
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="formRules.ISODateRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.inspection_date"
                            @input="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-menu :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="form.followup_date"
                                :label="$store.getters.getTextMap().followup_date"
                                outlined
                                dense
                                readonly
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.followup_date"
                            @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-textarea
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.findings"
                        :counter="300"
                        :rules="[formRules.rules.required,formRules.rules.validString,formRules.rules.size(300)]"
                        :label="$store.getters.getTextMap().findings"
                        outlined
                        dense
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.severity"
                        :items="severityOption"
                        :label="$store.getters.getTextMap().severity"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!editMode">
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.inspection_type"
                        :items="inspectionTypeOption"
                        :label="$store.getters.getTextMap().inspection_type"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.status"
                        :items="statusOption"
                        :label="$store.getters.getTextMap().status"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-textarea
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.actions_required"
                        :counter="300"
                        :rules="[formRules.rules.validString,formRules.rules.size(300)]"
                        :label="$store.getters.getTextMap().actions_required"
                        outlined
                        dense
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
import moment from "moment";
export default {
    name:'WriteSafetyInspectionLog',
    props:['safetyInspectionDetails'],
    components:{
        InfoAlert,
    },
    data(){
        return {
            valid:false,
            loading:false,
            info:'',
            api:'writeSafetyInspectionLog',
            editMode:false,
            showDismissibleAlert:false,
            menu1:false,
            menu2:false,
            severityOption:[
                {label:"High",value:"High"},
                {label:"Medium",value:"Medium"},
                {label:"Low",value:"Low"},
            ],
            statusOption:[
                {label:"Open",value:"open"},
                {label:"Closed",value:"closed"},
            ],
            inspectionTypeOption:[
                {label:"Process Related Finding",value:"process_related"},
                {label:"Maintenance Related Finding",value:"maintenance_related"},
            ],
            form:{
                inspector_id:null,
                accident:null,
                inspection_date:null,
                followup_date:null,
                severity:"High",
                inspection_type:"process_related",
                findings:null,
                status:"open",
                actions_required:null,
                location_id:null,
            },
            formRules: FormRules,
        }
    },
    mounted(){
        if(this.safetyInspectionDetails && this.safetyInspectionDetails.inspection_id){
            this.api='updateSafetyInspectionLog'
            this.editMode=true
            this.form=Object.assign({},this.safetyInspectionDetails)
        }else{
            this.api='writeSafetyInspectionLog'
            this.editMode=false
        }
    },
    computed:{
        locationOptions(){
            let op=[]
            for(let i of this.$store.state.locations){
                op.push({
                    label:i.location_name,
                    value:i.location_id
                })
            }
            return op
        }
    },
    methods:{
        getTimestamp(time){
            let dt=moment().format("YYYYMMDD")
            if(this.date){
                dt=this.date.toString()
            }
            if(time){
                dt+=' '+time.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        onSubmit(){
            this.$refs.safetyInspectionForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['inspection_id']=this.safetyInspectionDetails.inspection_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        // this.$store.dispatch('refreshTariffs')
                        this.loading=false
                        this.$emit('success')
                        if(!this.editMode){
                            this.$refs.safetyInspectionForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$refs.safetyInspectionForm.reset()
            this.$emit("close")
        }
    }
}
</script>