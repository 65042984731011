<template>

        <v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card flat>
            <v-card-title>{{word.name?word.name:list.word_id}} <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-spacer></v-spacer>
    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"
      >
        mdi-pencil
      </v-icon>
        <DeleteConfirmation  v-on:confirm="deleteWord()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
      <v-icon
        small
    :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
      </v-icon>
    </DeleteConfirmation>
    </span>
    </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle >
        
        <v-row v-if="loading">
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
            <v-col>
                <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
            </v-col>
        </v-row>
        
        <v-row >
            <v-col cols="1">
                <span><v-btn   fab :dark="$store.getters.getColorPalette().isDark" @click="addDialog=!addDialog" color="blue" >
        <v-icon>mdi-plus</v-icon>
      </v-btn></span>
            </v-col>
            <v-col v-if="addDialog">
                <v-form
    ref="addForm"
    v-model="valid">
                <v-row>
                    <v-col>
                        <v-text-field
        :loading="loading"
        v-model="form.bit"
        :counter="2"
        :rules="bitRules"
        :label="$store.getters.getTextMap().bit"
        required
    ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
            :loading="loading"
      v-model="form.low"
      :counter="30"
      :rules="stringRules"
      :label="$store.getters.getTextMap().low"
      required
    ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
            :loading="loading"
      v-model="form.high"
      :counter="30"
      :rules="stringRules"
      :label="$store.getters.getTextMap().high"
      required
    ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="addTupleToWord"
          >
          {{$store.getters.getTextMap().add}}

          </v-btn>
                    </v-col>
                </v-row>
                </v-form>
            </v-col>
            
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table height="150px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().bit}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().low}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().high}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tuples"
          :key="item.bit"
        >
          <td>{{ item.bit }}</td>
          <td>{{ item.low }}</td>
          <td>{{ item.high }}</td>
          <td>
             <DeleteConfirmation  v-on:confirm="deleteTupleFromWord(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
            <v-icon small color="red" >mdi-delete</v-icon>
            </DeleteConfirmation>
            </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-col>
        </v-row>
        </v-card-subtitle>
            </v-card>
        </v-container>

</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
//import AddTupleToList from '@/components/crud_components/AddTupleToList'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'Word',
    props:['word'],
    components:{
        InfoAlert,
        //AddTupleToList
        DeleteConfirmation
    },
    mounted(){
        this.getTuples()
    },
    data(){
        return {
             showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            addDialog:false,
            valid:false,
            tuples:[],
            form:{
              bit:null,
              low:null,
              high:null,
            },
            stringRules: [
        v => !!v || 'Required',
        v => /\S+/.test(v) || 'Required',
        v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
      ],
      bitRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && !isNaN(v))|| 'It should be a valid number',
          v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
      ],
        }
    },

    computed:{
        
        
    },

    methods:{
        getTuples(){
            this.loading=true
            axios.post(this.$store.state.api+'getWordTuplesByID',this.word,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.tuples=response.data.data
                            this.loading=false
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
        },
        deleteWord(){

            axios.post(this.$store.state.api+'deleteWord',this.word,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshWords')
                            this.loading=false
                            this.info='The List has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        deleteTupleFromWord(tuple){

            axios.post(this.$store.state.api+'deleteTupleFromWord',tuple,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            //this.$store.dispatch('refreshListData')
                            this.loading=false
                            this.info='The Tuple has been deleted'
                            this.showDismissibleAlert=true
                            this.getTuples()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })

        },
        addTupleToWord(){
          this.form['word_id']=this.word.word_id
          axios.post(this.$store.state.api+'addTupleToWord',this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            //this.$store.dispatch('refreshListData')
                            this.loading=false
                            this.info='The Tuple has been added'
                            this.showDismissibleAlert=true
                            this.tuples.push(response.data.tuple)
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }

    },
    watch:{
      word: function (){
            this.getTuples()
        }
    
  }
}
</script>
<style scoped>
.theme--light.v-tabs-items{
  background-color:#263238
}
</style>