<template>
  <div>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" /> 
      <div class="py-0">
        <v-form ref="parameterForm" v-model="valid" >
          <v-card flat :color="$store.getters.getColorPalette(). background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
            <v-card-title class="headline">{{report?$store.getters.getTextMap().edit:$store.getters.getTextMap().create}} {{$store.getters.getTextMap().usage_Report}}</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                         :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                          v-if="!loading" 
                          v-model="form.name" 
                          :counter="35" 
                          :rules="nameRules" 
                          :label="$store.getters.getTextMap().name" 
                          required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                      <v-text-field
                      :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="8"
                        :label="$store.getters.getTextMap().unit" 
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                      :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="workspace"
                        :items="workspaceOptions"
                        :label="$store.getters.getTextMap().workspace" 
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="device"
                        :items="deviceOptions"
                        :label="$store.getters.getTextMap().device" 
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.list"
                        :items="paramOptions"
                        :label="$store.getters.getTextMap().parameters" 
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                        multiple
                        :rules="listRules"
                        clearable
                        small-chips
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                      :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.notification_groups"
                        :items="notificationGroupList"
                        :label="$store.getters.getTextMap().notification_groups" 
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        multiple
                        card
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-chip
                        class="ma-2"
                        color="teal"
                        text-color="white"
                        close
                        @click:close="close(param)"
                        v-bind:key="param.parameter" v-for="param in form.list">
                        {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="selected" :label="$store.getters.getTextMap().send_excel_file"  value="Excel"></v-checkbox>
                    </v-col>
                    <v-col v-if="this.showExcelFreq===true">
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                          v-if="!loading"
                          v-model="form.ecxel_frequency"
                          :items="excelFrequency"
                          :label="$store.getters.getTextMap().excel_frequency"
                          dense
                          filled
                          item-text="label"
                          item-value="value"
                          required
                          card
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor"  @click="onSubmit">{{report? $store.getters.getTextMap().update: $store.getters.getTextMap().create}}</v-btn>
                <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor"  @click="onReset">{{ $store.getters.getTextMap().cancel}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </div>
      </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateUsageReport',
    props:['report'],
    components:{
        InfoAlert
    },
    mounted(){
        //console.log(this.report.report_id)
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            let list=[]
            this.form['correction_factor']=Number(this.form['correction_factor'])
            for(let i of this.form.list){
                let tmp=this.$store.getters.getParameterById(i)
                if(tmp){
                    list.push(tmp)
                }
                
            }
            this.form['list']=list
            this.api='updateUsageReport'
        }else{
            this.api='createUsageReport'
        }
    },
     data(){
        return {
            api:'createUsageReport',
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            selected:'',
            sendExcel:false,
            showExcelFreq:false,
            showDismissibleAlert:false,
            device:null,
            workspace:null,
            excelFrequency : [
            {label:this.$store.getters.getTextMap().daily,value:'day'},
            {label:this.$store.getters.getTextMap().weekly,value:'week'},
            {label:this.$store.getters.getTextMap().monthly,value:'month'},
            ],
            list:[],
            
            paramTypes:[
                {label: this.$store.getters.getTextMap().parameter,value:'parameter'},
                {label: this.$store.getters.getTextMap().computed_param,value:'computed'},            
                {label:this.$store.getters.getTextMap().derived_param,value:'derived'},            
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Name must be less than 35 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 8) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      
      form:{
                name:null,
                type:null,
                correction_factor:null,
                list:[],
                unit:null,
                
            }
        }
    },
    computed:{
      notificationGroupList(){
            let op=[]
            if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
                for(let i of this.$store.state.notificationGroups){
                    op.push({
                        label:i.name,
                        value:i.group_id
                    })
                }
            }
            return op
        },
      workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
      deviceOptions(){
             
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.device && this.device.device_id ){
              let g=[]
              
              g=this.$store.getters.getParametersByDeviceID(this.device.device_id)
              
              for(let i of g){
                  if(i && i.name ){
                    if(i.disabled!=true){
                      op.push({value:i, label: i.name })
                    }
                  }else{
                    op.push({value:i, label: "Unassigned Name" })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
        close(param){
        
        
        this.form.list=this.form.list.filter(
      function(data){ return data.parameter != param.parameter }
  );
  
      },
      showExcelFreqOption(){
        this.sendExcel = ! this.sendExcel
        this.showExcelFreq = ! this.showExcelFreq
      },
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.send_excel = this.sendExcel
            let d=Object.assign({}, this.form)
            let list=[]
            let key='parameter'
            for(let i of this.form.list){
              list.push(i[key])
            }
            d['list']=list
            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshUsageReports')
        //console.log("Report added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      console.log(err)
      this.info=err
      this.showDismissibleAlert=true
      });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    },
    watch:{
        selected(){
            this.showExcelFreqOption()
        }
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */
</style>
