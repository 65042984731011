<template>
      <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card flat  :color="$store.getters.getColorPalette().backgroundColorCode"   >
      <v-card-title
        >
        </v-card-title>
        <v-card-subtitle>
            <v-row no-gutters v-if="!batch.shipped">
                <v-spacer></v-spacer>
                <v-col align="right" v-if="!batch.shipped">
                    <WriteBatchShippingModal :batch="batch" v-on:success="$emit('update')" />
                </v-col>
                <v-col align="right" v-else>
                    <strong :style="{color:$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().dispatched}}</strong>
                </v-col>
            </v-row>
            <v-simple-table v-if="!loading && metaItems.length" >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().prop}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().value}}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                    
                  <tr v-for="(item,index) in metaItems" :key="index">

                    <td>{{ item.label }}</td>
                    <td>{{ item.value }}</td>

                   
                  </tr>
                  <tr>
                    <td>{{$store.getters.getTextMap().code}}</td>
                    <td>{{batch.code}}</td>
                  </tr>
                  <tr>
                    <td>{{$store.getters.getTextMap().entered_by}}</td>
                    <td>{{batch.logged_by}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card-subtitle>
        </v-card>
        </v-container>

</template>
<script >
import InfoAlert from "@/components/InfoAlert";
import WriteBatchShippingModal from "@/components/modals/manufacturing/WriteBatchShippingModal";
export default{
    name:'BatchDetails',
    props:{
        batch:{
            type:Object,
            required:true
        }
    },
    components:{
        InfoAlert,
        WriteBatchShippingModal
    },
    data(){
        return {
            loading: false,
      info: "",
      showDismissibleAlert: false,

        }
    },
    computed:{
        metaItems(){
            let result=[]
            if(this.batch && this.batch.meta){
                let meta=this.batch.meta
                result = Object.keys(meta).map((key) => {return {'label':key,'value':meta[key]}})
            }
            return result
        }
    }

}
</script>