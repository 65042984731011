<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}" >
        <v-row no-gutters dense class="mb-1" v-if=" $store.state.user">
          <v-col cols="auto" class="d-flex">
            <AnalysisBottomSheet class="mr-1" />

          </v-col>
          <v-col cols="auto" class="d-flex">
            <UsageByMachineBottomSheet class="mr-1" />

          </v-col>
          <v-col cols="auto" class="d-flex">
            <CreateReportBottomSheet class="mr-1" v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"/>

          </v-col>
          <v-col cols="auto" class="d-flex">
                <v-btn class="mr-1" v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" text outlined small @click="showSheets=!showSheets">{{$store.getters.getTextMap().sheets}}<v-icon right small :color="$store.getters.getColorPalette().btnborderColorCode">mdi-clipboard-text-outline</v-icon></v-btn>

          </v-col>
          <v-col cols="auto" class="d-flex"  >
            <!-- <v-btn :color="$store.getters.getColorPalette().btnborderColorCode" text outlined small @click="dialog2=!dialog2"><v-icon small :color="$store.getters.getColorPalette().accentCode">mdi-google-analytics</v-icon>Run Analysis</v-btn> -->
            <!-- <v-btn :color="$store.getters.getColorPalette().btnborderColorCode" text outlined small @click="dialog=!dialog"><v-icon small :color="$store.getters.getColorPalette().accentCode">mdi-clipboard-text</v-icon>Create Report</v-btn> -->
                <v-btn class="mr-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" text outlined small @click="showShifts=!showShifts">{{$store.getters.getTextMap().shifts}}<v-icon right small :color="$store.getters.getColorPalette().btnborderColorCode">mdi-clock-time-five-outline</v-icon></v-btn>
            
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"></v-divider>
        <!-- <v-divider  v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "></v-divider> -->

      <!-- <span v-if=" $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-row no-gutters>
          <v-col>
            <h5 class="px-1">Create reports</h5>
          </v-col>
        </v-row>
      <v-card flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().foregroundColorCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark">
        
        <v-row class="my-1" no-gutters dense v-if="$store.state.parameters.length>0 && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
          <v-col align-self="center" align="center"  v-if="$store.getters.getAccessByLevels(['betaaccess'])" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined> 
            <CreateParameterReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
              <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
                <CreateParameterSECReportBottomSheet />
              </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateUsageReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center"  >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateEnergyReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center"  >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateSECReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateConsolidatedReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center"  >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateDynamicExcelReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" flat class="py-2 mx-2 my-0" outlined>
              <CreateEnergyUsageReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateEnergyDistributionReportBottomSheet />
            </v-card>
          </v-col>
          <v-col align-self="center" align="center" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateEnergyBillDistReportBottomSheet />
            </v-card>
          </v-col>  
          <v-col align-self="center" align="center"  v-if="$store.getters.getAccessByLevels(['betaaccess'])" >
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode" class="py-2 mx-2 my-0" flat outlined>
              <CreateGranularSECReportBottomSheet />
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </span> -->
    <span v-if="showSheets" :style="{color: $store.getters.getColorPalette().accentCode}">
    <v-row>
          <v-col align="left">
            <v-card-title>{{$store.getters.getTextMap().sheets}}</v-card-title>
              <!-- <v-avatar size="15" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="' mx-1 px-0'"><v-icon small :color=" $store.getters.getColorPalette().accentCode">mdi-file-chart</v-icon></v-avatar> -->
            <!-- <v-chip :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"><v-avatar :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="$store.getters.getColorPalette().foregroundColorName +' mx-1 px-0'"><v-icon small>mdi-file-chart</v-icon></v-avatar>Reports</v-chip> -->
          </v-col>
    </v-row>
    <v-card flat outlined :style="{'border': '0px solid '+ $store.getters.getColorPalette().foregroundColorCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark">
        <v-row no-gutters>
          <v-col cols="12" >
            <SheetsTable />
          </v-col>
        </v-row>
      </v-card>
    </span>
    <span v-if="showShifts" :style="{color: $store.getters.getColorPalette().accentCode}">
    <v-row>
          <v-col align="left">
            <v-card-title>{{$store.getters.getTextMap().shifts}}</v-card-title>
              <!-- <v-avatar size="15" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="' mx-1 px-0'"><v-icon small :color=" $store.getters.getColorPalette().accentCode">mdi-file-chart</v-icon></v-avatar> -->
            <!-- <v-chip :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"><v-avatar :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="$store.getters.getColorPalette().foregroundColorName +' mx-1 px-0'"><v-icon small>mdi-file-chart</v-icon></v-avatar>Reports</v-chip> -->
          </v-col>
    </v-row>
    <v-card flat outlined :style="{'border': '0px solid '+ $store.getters.getColorPalette().foregroundColorCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark">
        <v-row no-gutters>
          <v-col cols="12" >
            <ShiftTable />
          </v-col>
        </v-row>
      </v-card>
    </span>
    <span :style="{color: $store.getters.getColorPalette().accentCode}">
    <v-row>
          <v-col align="left">
            <v-card-title>{{ $store.getters.getTextMap().reports }}</v-card-title>
              <!-- <v-avatar size="15" :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="' mx-1 px-0'"><v-icon small :color=" $store.getters.getColorPalette().accentCode">mdi-file-chart</v-icon></v-avatar> -->
            <!-- <v-chip :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"><v-avatar :dark="$store.getters.getColorPalette().isDark" :style="{color: $store.getters.getColorPalette().background2ColorCode}" :class="$store.getters.getColorPalette().foregroundColorName +' mx-1 px-0'"><v-icon small>mdi-file-chart</v-icon></v-avatar>Reports</v-chip> -->
          </v-col>
    </v-row>
    <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 
    <v-card flat outlined :style="{'border': '0px solid '+ $store.getters.getColorPalette().foregroundColorCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark">
        <v-row no-gutters>
          <v-col cols="12" >
            <ReportList />
          </v-col>
        </v-row>
      </v-card>
    </span>
        <v-row>
          <v-col>
            <ProductionLoggerList />
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import CreateParameterReportBottomSheet from '@/components/modals/CreateParameterReportBottomSheet'
// import CreateEnergyUsageReportBottomSheet from '@/components/modals/CreateEnergyUsageReportBottomSheet'
// import CreateEnergyReportBottomSheet from '@/components/modals/CreateEnergyReportBottomSheet'
// import CreateEnergyDistributionReportBottomSheet from '@/components/modals/CreateEnergyDistributionReportBottomSheet'
// import CreateEnergyBillDistReportBottomSheet from '@/components/modals/CreateEnergyBillDistReportBottomSheet'
// import CreateParameterSECReportBottomSheet from '@/components/modals/CreateParameterSECReportBottomSheet'
// import CreateGranularSECReportBottomSheet from '@/components/modals/CreateGranularSECReportBottomSheet'
// import CreateSECReportBottomSheet from '@/components/modals/CreateSECReportBottomSheet'
// import CreateConsolidatedReportBottomSheet from '@/components/modals/CreateConsolidatedReportBottomSheet'
// import CreateDynamicExcelReportBottomSheet from '@/components/modals/CreateDynamicExcelReportBottomSheet'
// import CreateUsageReportBottomSheet from '@/components/modals/CreateUsageReportBottomSheet'
import ReportList from '@/components/lists/ReportList'
import ProductionLoggerList from '@/components/lists/ProductionLoggerList'
import UsageByMachineBottomSheet from '@/components/modals/UsageByMachineBottomSheet'
import CreateReportBottomSheet from '@/components/modals/CreateReportBottomSheet'
import SheetsTable from '@/components/lists/derived/SheetsTable'
import ShiftTable from '@/components/lists/derived/ShiftTable'
import AnalysisBottomSheet from '@/components/modals/AnalysisBottomSheet'
export default {
    name:'GenerateReportPage',
    components:{
        // CreateParameterReportBottomSheet,
        // CreateEnergyUsageReportBottomSheet,
        // CreateEnergyReportBottomSheet,
        // CreateEnergyDistributionReportBottomSheet,
        // CreateEnergyBillDistReportBottomSheet,
        // CreateParameterSECReportBottomSheet,
        // CreateGranularSECReportBottomSheet,
        // CreateSECReportBottomSheet,
        // CreateConsolidatedReportBottomSheet,
        // CreateDynamicExcelReportBottomSheet,
        // CreateUsageReportBottomSheet,
        ReportList,
        ProductionLoggerList,
        UsageByMachineBottomSheet,
        SheetsTable,
        ShiftTable,
        CreateReportBottomSheet,
        AnalysisBottomSheet
    },
    data(){
        return {
          showSheets:false,
          showShifts:false
        }
      },
}
</script>