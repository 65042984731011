<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <v-row v-if="$store.getters.getAccessByLevels(['ebill','testAccess'])">
            <v-col>
                <EbillMonthlyConsumptionPanel />
            </v-col>
        </v-row>
        <v-row v-if="$store.getters.getAccessByLevels(['ebill','testAccess'])">
            <v-col>
                <v-card class="pa-2">
                    <v-card-title>
                        View By Consumer ID
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <FetchConsumerData />
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row v-if="$store.getters.getAccessByLevels(['ebill','testAccess'])">
            <v-col >
                <v-card class="pa-2">
                    <v-card-title>
                        View By Site ID
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <FetchSiteData />
                    </v-card-subtitle>
                
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters dense><v-col cols="12">
        <v-expansion-panels
      
      multiple
      hover
      
    >
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">Parameter trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">Time Of Day Trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterTODPanel />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">Param Usage trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterUsageGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">Param Daily stats</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterStatGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
          
</v-col></v-row>
        
    </v-container>
</template>

<script>
// import SNodeUsageGraphByDays from '@/components/panels/SNodeUsageGraphByDays'
import ParameterUsageGraphByDays from '@/components/panels/ParameterUsageGraphByDays'
import ParameterStatGraphByDays from '@/components/panels/ParameterStatGraphByDays'
import ParameterGraphByDays from '@/components/panels/ParameterGraphByDays'
import ParameterTODPanel from '@/components/panels/ParameterTODPanel'
// import SNodeTODPanel from '@/components/panels/SNodeTODPanel'
import EbillMonthlyConsumptionPanel from '@/components/panels/EbillMonthlyConsumptionPanel'
import FetchConsumerData from '@/components/crud_components/FetchConsumerData'
import FetchSiteData from '@/components/crud_components/FetchSiteData'

export default {
    name:'CustomReportsPage',
    components:{
        EbillMonthlyConsumptionPanel,
        FetchConsumerData,
        FetchSiteData,
        //NodeLastXConsumedGraph,
        //MonthlyConsumptionGraph,
        // SNodeUsageGraphByDays,
        ParameterUsageGraphByDays,
        ParameterStatGraphByDays,
        //SNodeComparisonPanel,
        //SNodeGroupConsumptionPanel,
        //ParameterRelationshipPanel,
        ParameterGraphByDays,
        ParameterTODPanel,
        // SNodeTODPanel,
        //CreateParameterReportBottomSheet,
        //CreateParameterSECReportBottomSheet,
        //CreateGranularSECReportBottomSheet,
        //CreateSECReportBottomSheet,
        //ParameterHistoricalTODComparison,
        //ReportList,
        //ProductionLoggerList
    },
    
}
</script>