<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
            :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </v-card-title>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row no-gutters>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.date"
            :label="$store.getters.getTextMap().date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.date"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-btn :color="$store.getters.getColorPalette().tableHeaderColorName" @click="getData()">Submit</v-btn>
            </v-col>
        </v-row>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="apiDataCountData"
          item-key="param_id"
          :search="search"


          :loading="loading"
          :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
        >
        <template v-slot:top>
          <v-toolbar
            flat
            
          >
            <v-toolbar-title>{{$store.getters.getTextMap().api_data_count_view}}
</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <span>
              <v-icon  :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark"  :style="{fontSize:'25px'}" @click="downloadCsv">mdi-download-circle</v-icon>
            </span>
           
            <v-icon @click="getData">mdi-refresh</v-icon>
            
          </v-toolbar>
              </template>
              <template v-slot:item.date="{ item }">
              {{getDate(item.date)}}
    </template>
              
        </v-data-table>
      </v-card>
    </v-container>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import InfoAlert from '@/components/InfoAlert'
    import FormRules from '@/utillities/FormRules'
    
    export default {
        name:'APIDataCountTableView',
        components:{
            InfoAlert
        },
        mounted(){
            this.init()
        },
        data(){
            return {
                apiDataCountData:[],
                rules:FormRules.rules,
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                menu:null,
                valid:false,
                form:{},
                expanded: [],
                headers: [
              
              { text: 'Date', value: 'date', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'API', value: 'api', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Data Point Count', value: 'count', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              {
                text: 'Organisation ID',
                align: 'start',
                filterable: false,
                value: 'organisation_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'Root Organisation ID',
                align: 'start',
                filterable: false,
                value: 'root_org',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
            ],
            }
        },
        computed:{
            
        },
        methods:{
            getDate(dt){
               return moment(dt).format('YYYY-MM-DD')

            },
            init(){
                this.form.date=moment().subtract(3,'d').format('YYYY-MM-DD')
                // console.log(this.form.date)
                this.getData()
            },
            getData(){
                this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
                axios.post(this.$store.state.api + "getAPIDataCountByDate",this.form,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    this.apiDataCountData=response.data.data
                
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },

        downloadCsv(){           
          let csvContent = 'date,api,count,organisation_id,root_org\n' + this.apiDataCountData.map(
            (item)=>{
              return `${item.date},"${item.api}",${item.count},${item.organisation_id},${item.root_org}`
            }
          ).join('\n');  
          const blob = new Blob([csvContent], {type:'text/csv'})
             const link = document.createElement('a');
             link.href = URL.createObjectURL(blob);
             link.download = 'APIDataCountTableViewData.csv'
             document.body.appendChild(link);
             link.click()
             document.body.removeChild(link)
           
            }
        }
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>