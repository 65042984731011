<template>
<v-container>
        
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    
    <v-form
    ref="paramForm"
    v-model="valid"
    
  >
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
               :dark="$store.getters.getColorPalette().isDark" 
                  
                v-if="!loading"
              v-model="oem"
          :items="oemOptions"
          :label="$store.getters.getTextMap().choose_OEM"
          outlined
          dense
          item-text="label"
          item-value="value"
          required
            :rules="selectRules"
               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        </v-col>
        <v-col align-self="center">
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
            v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="getModelByOEM"
          >
            {{$store.getters.getTextMap().get_the_models}}
          </v-btn>
        </v-col>
    </v-row>
    </v-form>
  <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
       :dark="$store.getters.getColorPalette().isDark" 
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="models"
      item-key="model"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
    :dark="$store.getters.getColorPalette().isDark" 
    >
    <template v-slot:top>
      <v-toolbar
        flat
        :dark="$store.getters.getColorPalette().isDark" 
      >
        <v-toolbar-title>{{$store.getters.getTextMap().get_oem_devices}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
          </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        {{item}}
       
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</v-container>
</template>

<script>
//import CreateActionBottomSheet from '@/components/modals/CreateActionBottomSheet'
//import Word from '@/components/items/Word'
import InfoAlert from '@/components/InfoAlert'
// import ColorUtility from '@/utillities/ColorUtility'
import axios from 'axios'

export default {
    name:'OEMTable',
    components:{
        //CreateActionBottomSheet,
        //Word,
        InfoAlert
    },
    data(){
        return {
            search:'',
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            expanded: [],
            models:[],
            oem:null,
            oemOptions:[
                {label:'Danfoss', value:'danfoss'},
                {label: 'ABB', value:'abb'},
                {label: 'Yaskawa', value:'yaskawa'},
            ],
            headers: [
                {
                    text: 'Model Number',
                    align: 'start',
                    filterable: false,
                    value: 'model',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'OEM', value: 'oem', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Template ID', value: 'template_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    computed:{
        words(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getWords()
        }
    },
    methods:{
        getModelByOEM(){
            this.$refs.paramForm.validate()
            if(this.valid){
                this.loading=true
                axios.post(this.$store.state.api+'getModelsByOEM',{oem:this.oem},{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }}).then(res=>{
        if(res.data.status=='success'){
            this.models=res.data.data

        }else{
            this.info = res.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      
        }
        this.loading=false
    }).catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    });
            }

        }
    }
}
</script>
<style scoped>

.v-card__title{
  background-color:#BDBDBD00;
}
.theme--light.v-toolbar.v-sheet{
  background-color: #B2DFDB00;
}
.theme--light.v-data-table{
   background-color: #B2DFDB00;
}
</style>