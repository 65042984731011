<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row no-gutters dense>
            <v-spacer></v-spacer>
            <v-col cols="1" align="center">
                <v-btn
                small
                fab
                :dark="$store.getters.getColorPalette().isDark"
                @click="addDialog = !addDialog"
                color="blue"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="addDialog">
            <v-col cols="12">
                <CreateProductionPlan />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ProductionPlanTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import CalculatedParamTable from '@/components/lists/derived/CalculatedParamTable'
import CreateProductionPlan from '@/components/crud_components/manufacturing/CreateProductionPlan';
import ProductionPlanTable from '@/components/lists/manufacturing/ProductionPlanTable';
export default {
    name:'ProductionPlanningPage',
    components:{
        // CalculatedParamTable,
        CreateProductionPlan,
        ProductionPlanTable,
    },
    data(){
        return {
            addDialog:false,
        }
    }
}
</script>