<template>
    <div>
        <v-dialog
      v-model="showDismissibleAlert"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Ooops!!</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        
        <div class="py-0">
          
          <v-form
    ref="parameterForm"
    v-model="valid" >
      <v-card outlined flat :color="$store.getters.getColorPalette().background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
         {{ $store.getters.getTextMap().create_parameter_report }}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="6">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="30"
      :rules="nameRules"
      outlined
          dense
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          outlined
          dense
          item-value="value"
          :label="$store.getters.getTextMap().data_processing"
         :rules="selectRules"
         required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
                    <v-col>
                
    <v-chip
      class="ma-2"
      close
      color="teal"
      text-color="white"
      @click:close="close(param)" 
      v-bind:key="param.parameter" v-for="param in form.list"
    >
     {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
    </v-chip>
            </v-col>

                </v-row>
                
                <v-row>
                  
<v-col>
    <!-- <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
  :dark="$store.getters.getColorPalette().isDark" 
     :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
        :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_time"
            label="From time (optional)"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            
          ></v-text-field>
        </template>
        <v-time-picker
      :dark="$store.getters.getColorPalette().isDark" 
     :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu3"
          v-model="form.from_time"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.from_time)"
        ></v-time-picker>
      </v-menu> -->
     
            </v-col>

            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<!-- <v-menu
  :dark="$store.getters.getColorPalette().isDark" 
     :color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.to_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          :dark="$store.getters.getColorPalette().isDark" 
    :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_time"
            label="To time (optional)"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            
          ></v-text-field>
        </template>
        <v-time-picker
          :dark="$store.getters.getColorPalette().isDark" 
     :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu2"
          v-model="form.to_time"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(form.to_time)"
        ></v-time-picker>
      </v-menu> -->

            </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  {{ $store.getters.getTextMap().from }}
            <v-col align-self="center">
            <TimePicker v-model="form.from_time"/>
            </v-col>
                  </v-col>
                  <v-col>
                    {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="form.to_time"/>
            </v-col> 
                  </v-col>
                </v-row>
                <v-row>
            
           
        
            <v-col>
                <v-select
                :dark="$store.getters.getColorPalette().isDark" 
      :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
                 :dark="$store.getters.getColorPalette().isDark" 
     :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="device"
          :items="deviceOptions"
          label="Device"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
                 :dark="$store.getters.getColorPalette().isDark" 
      :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.list"
          :items="paramOptions"
          label="Device Parameter"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
            multiple
         :rules="listRules"
         clearable
         small-chips
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>
        <v-row>
          
            
        </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="onSubmit"
          >
            Create
          </v-btn>
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().cancelbtnColor"
          
            @click="onReset"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import TimePicker from '@/components/inputs/TimePicker'
export default {
    name:'CreateParameterReport',
    components:{
    TimePicker,
  },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            device:null,
            workspace:null,
            menu2:null,
            menu3:null,
            toTime:null,
            fromTime:null,
            list:[],
            frequency:[
                {label:'Monthly',value:'monthly'},
                {label:'Daily',value:'daily'}                
            ],
            processing:[
              {label:'Average',value:'mean'},
                {label:'Total',value:'sum'},
                {label:'Singular', value:'single'}
            ],
            type:[
              {label:'Average',value:'mean'},
                {label:'Addition',value:'sum'}
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
        //v => (v && (v.length==1 || this.form.processing != 'single')) || 'Please choose processing other than single to use more than one param'
      ],
      form:{
                name:null,
                type:null,
                processing:'single',
                frequency:'daily',
                from_time:null,
                to_time:null,
                list:[],
                emails:[]
                
            }
        }
    },
    computed:{
      workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
      deviceOptions(){
             
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.device && this.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    if(i.disabled!=true){

                      op.push({value:i, label: i.name })
                    }
                  }else{
                    op.push({value:i, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
      close(param){
        //console.log(param)
        this.form.list=this.form.list.filter(
      function(data){ return data.parameter != param.parameter }
  );
      },
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
//console.log(d)
            d['type']="parameter"
            let list=[]
            for(let i of this.form.list){
              list.push(i['parameter'])
            }
            d['list']=list
            axios.post(this.$store.state.api+'createReport',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshReports')
        console.log("Report added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      console.log(err)
      });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }

}
</script>
<style scoped>

/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */
</style>