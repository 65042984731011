<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="skuForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.item_code"
                        :counter="25"
                        :rules="[rules.required, rules.nonZeroLength, rules.noSpace, ]"
                        :label="$store.getters.getTextMap().item_code"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.item_name"
                        :counter="25"
                        :rules="[rules.required, rules.nonZeroLength, rules.validString ]"
                        :label="$store.getters.getTextMap().name"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.material"
                        :counter="25"
                        :rules="[rules.required, rules.nonZeroLength, rules.validString ]"
                        :label="$store.getters.getTextMap().material"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.material_group"
                        :counter="25"
                        :rules="[rules.required, rules.nonZeroLength, rules.validString ]"
                        :label="$store.getters.getTextMap().material_group"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.material_desc"
                        :counter="45"
                        outlined
                        dense
                        :label="$store.getters.getTextMap().material_desc"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.uom"
                        :counter="12"
                        
                        :label="$store.getters.getTextMap().uom"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'CreateItem',
    props:['item'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.item && this.item.item_code){
            this.api='updateItemCode'
            this.editMode=true
            this.form=Object.assign({},this.item) 
        }else{
            this.api='createItemCode'
            this.editMode=false
        }
    },
    data(){
        return {
            api:'createItemCode',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                name:null,
                msg:null,
                meta:null
            },
            rules:FormRules.rules,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        onSubmit(){
            this.$refs.skuForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['item_code']=this.item.item_code
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        // this.$store.dispatch('refreshSKUs')
                        this.loading=false
                        this.$emit('update')
                        if(!this.editMode){
                            this.$refs.skuForm.reset()
                            this.info="Item Code created"
                            this.showDismissibleAlert=true
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    }
}
</script>