<template>
    <span class="text-center">
        <v-btn @click="dialog = !dialog" :dark="$store.getters.getColorPalette().isDark" outlined text :color="$store.getters.getColorPalette().accentCode" small >{{$store.getters.getTextMap().write_incident}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-lead-pencil</v-icon></v-btn>
        <!-- <v-btn @click="dialog = !dialog" :dark="$store.getters.getColorPalette().isDark" outlined text :color="$store.getters.getColorPalette().accentCode" small :style="{transform:'scale(0.8)'}">{{$store.getters.getTextMap().write_incident}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-lead-pencil</v-icon></v-btn> -->
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-row no-gutters>
                    <v-col cols="12">
                        <WriteSafetyIncident :safetyIncidentDetails="safetyIncidentDetails" v-on:close="dialog=false" v-on:success="$emit('success')" />
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import WriteSafetyIncident from '@/components/crud_components/manufacturing/WriteSafetyIncident'
export default {
    name:'WriteSafetyIncidentModal',
    props:['safetyIncidentDetails'],
    components:{
        WriteSafetyIncident  
    },
    data(){
        return {
            dialog:false
        }
    },
}
</script>