<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>
                   {{$store.getters.getTextMap().machine_group_based_usage_sheet}}
                </v-card-title>
                </v-col>
            </v-row>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().time_frame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row no-gutters>
                <GetTagTypeModal v-if="addTagTypes===true" v-on:close="addTagTypes=false" :tagTypes="tagTypeTableDetails" :show="true" v-on:value="createTagTypeArray" />
                <v-col v-if="!loading"  cols="auto" class="d-flex">
                    <v-text-field dense outlined readonly :label="$store.getters.getTextMap().tag_types">
                        <template v-slot:prepend-inner>
                            <div >
                                <v-chip
                                    class="ma-2"
                                    close
                                    small
                                    color="teal"
                                    text-color="white"
                                    @click:close="remove(tag)" 
                                    v-bind:key="tag.tag_type" v-for="tag in tagTypeTableDetails"
                                >{{tag.label}}</v-chip>
                            </div>
                        </template>
                     </v-text-field>
                    <!-- <span v-else>Add Tag Type First</span> -->
                    <v-btn class="mt-1 mx-10" color="gray darken-1" small  outlined @click="addTagTypes=true">{{$store.getters.getTextMap().add_tag_types}}</v-btn>
                </v-col>
            </v-row>
            <v-row >
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="machine_group_label"
                        :label="$store.getters.getTextMap().machine_group_label"
                        dense
                        outlined
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <!-- :rules="nameRules" -->
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="machine_group"
                        :items="machineGroupOptions"
                        :label="$store.getters.getTextMap().machine_group"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        ></v-select>
                    </v-col>
                    <!-- :rules="objectRules" -->
                <v-col cols="auto" class="d-flex">
                    <v-btn class="mt-1 mx-5"  color="gray darken-1" small outlined @click="addMachineGroupToList">{{$store.getters.getTextMap().add_machine_group_to_list}}</v-btn>
                    <v-btn class="mt-1 mx-5" :color="$store.getters.getColorPalette().deletebtnColor" outlined small @click="onProcessReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                    <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().machine}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_group_id" v-for="item in machineTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.machine_group_id }}</td>
                                            
                                            <td>
                                         <DeleteConfirmation v-on:confirm="deleteFromMachineGroupTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                         <v-icon 
                                         small 
                                       :color="$store.getters.getColorPalette().deletebtnColor">
                                         mdi-delete
                                         </v-icon>
                                         </DeleteConfirmation>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>
                </v-row>
            </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" outlined small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
            </v-form> 
        </v-container>
    </template>
    <script>
    import InfoAlert from '@/components/InfoAlert'
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    import GetTagTypeModal from '@/components/modals/GetTagTypeModal'
    import axios from 'axios'
    export default {
        name:'MachineGroupUsageSheet',
        props: {
        "sheet": {
            type: Object,
        },
        },
        components:{
            InfoAlert,
            GetTagTypeModal,
            DeleteConfirmation
        },
        mounted(){
            if(this.sheet && this.sheet.meta){
                this.machineTableDetails = this.sheet.meta.machine_group_list
                this.tagTypeTableDetails = this.sheet.meta.tag_types
                this.form.name = this.sheet.name
                // this.form.meta.data_type = this.sheet.meta.data_type
                // this.form.meta.tag_types = this.sheet.meta.tag_types
                // this.form.meta.rows = this.sheet.meta.rows
                // this.form.meta.columns = this.sheet.meta.columns
                this.tagTypeTableDetails = []
                this.api = 'updateSheets'
                this.form.sheet_id = this.sheet.sheet_id
                this.isTranspose=this.sheet.meta.isTranspose
            }
            this.form.sheet_type = 'machineGroupBased'
        },
        data(){ 
            return {
                showDialog:false,
                api:'createSheets',
                selected:[],
                selectedInRow:[],
                selectedInColumn:[],
                rowContains:[],
                columnContains:[],
                tagTypeTableDetails:[],
                machineTableDetails:[],
                machine_group:null,
                addTagTypes:false,
                machine:null,
                tagType:null,
                machine_group_label:null,
                tagType_label:null,
                machineOptions:[],
                generalSheetValid:false,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                isTranspose:false,
                timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
                ],
                form:{
                    name:null,
                    sheet_type:'general',
                    meta:{
                        machine_group_list:{},
                        timeframe:'mtd',
                        isTranspose:false
                    }
                },
                objectRules:[
                    v => !!v || 'Required',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
                ],
                numberRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
                nonNegativeRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
            }
        },
        computed:{
        machineGroupOptions(){
            let op=[]
              let g=this.$store.state.machineGroups
              for(let i of g){
                  op.push( {value:{machine_group_id:i.machine_group_id,machine_group_label:i.name}, label: i.name })
                }
          return op
        },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        },
        methods:{
            createTagTypeArray(value){
                this.tagTypeTableDetails = value
            },
           close(){
                this.$emit("close")
            },
            addMachineGroupToList(){
                if(this.tagTypeTableDetails.length>0){

                    if(!this.machineTableDetails){
                        this.machineTableDetails = []
                    }
                    this.machineTableDetails.push(
                        {label:this.machine_group_label,machine_group_id:this.machine_group.machine_group_id,tag_types:this.tagTypeTableDetails}
                    )
                    this.tagTypeTableDetails=[]
                }else{
                    this.loading=false
                    this.info="Please Add Tag Type First"
                    this.showDismissibleAlert=true
                }

            },
            addTagTypeToList(){
                if(!this.tagTypeTableDetails){
                    this.tagTypeTableDetails = []
                }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
            },
            remove(m){
                this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.label!=m.label)
            },
            sheetSubmit(){
                this.$refs.generalSheetForm.validate()
                if(this.generalSheetValid){
                    this.loading=true
                    this.form.meta.machine_group_list['tag_types'] = this.tagTypeTableDetails
                    this.form.meta.machine_group_list = this.machineTableDetails
                    this.form.meta.isTranspose = this.isTranspose
                    let d=Object.assign({}, this.form)
                    //d.target=this.emails.replaceAll(' ','').split(',')
                    axios.post(this.$store.state.api+this.api,d,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$emit('update')
                                this.$store.dispatch('refreshSheets')
                                this.$refs.generalSheetForm.reset()
                                this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            onProcessReset(){
            this.machine_group=null
            this.machine_group_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
            onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
            deleteFromMachineGroupTable(item){
            this.machineTableDetails=this.machineTableDetails.filter(x=>x.machine_group_id!=item.machine_group_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },
              cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
        },
        watch:{
            isTranspose(){
                if(this.isTranspose===null){
                    this.isTranspose=false
                }
            }
    }
    
    }
    </script>