<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>
                   {{$store.getters.getTextMap().shift_based_usage_sheet}}
                </v-card-title>
                </v-col>
            </v-row>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        dense
                        outlined
                        required
                     :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.shift_id"
                        :items="shiftOptions"
                        :label="$store.getters.getTextMap().shift"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                    :dark="$store.getters.getColorPalette().isDark" 
                        :rules="objectRules"
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="machine_label"
                        :label="$store.getters.getTextMap().machine_label"
                        dense
                        outlined
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="workspace"
                        :items="workspaceOptions"
                        :label="$store.getters.getTextMap().workspace"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="machine"
                        :items="machineOptions"
                        :label="$store.getters.getTextMap().machine"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                    ></v-select>
                </v-col>
                <v-col v-if="!loading">
                    <v-btn color="gray darken-1" small  @click="addMachineToList">{{$store.getters.getTextMap().add_machine_to_list}}</v-btn>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onMachineReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
                    <v-card :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().machines}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in machineTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.machine_id }}</td>
                                            
                                            <td><v-icon small color="red" @click="deleteFromMachineTable(item)">mdi-delete</v-icon></td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>
                </v-row>
            </v-card>
            <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="tagType_label"
                        :counter="15"
                        :label="$store.getters.getTextMap().label"
                         :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                       :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="tagType"
                        :items="tagTypeOptions"
                        :label="$store.getters.getTextMap().tag_type"
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                    ></v-select>
                </v-col>
                <v-col v-if="!loading">
                    <v-btn color="gray darken-1" small  @click="addTagTypeToList">{{$store.getters.getTextMap().add_tag_type_to_list}}</v-btn>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onTagTypeReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().tag_type}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.label" v-for="item in tagTypeTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.tag_type }}</td>
                                            
                                            <td><v-icon small color="red" @click="deleteFromTagTypeTable(item)">mdi-delete</v-icon></td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col> 
            </v-row>
        </v-card>
        <!-- <v-card dark :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
        <v-row>
            <v-col>
                <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                    <v-checkbox v-if="!(form.meta.rows.includes('machine_list') || form.meta.columns.includes('machine_list'))" dark v-model="selected" value="machine_list" label="Machine List"></v-checkbox>
                    <v-checkbox v-if="!(form.meta.rows.includes('tag_type') || form.meta.columns.includes('tag_type'))" dark v-model="selected" value="tag_type" label="Tag Type"></v-checkbox>
                </v-card>
            </v-col>
            <v-divider vertical style="margin:20px;" :color="$store.getters.getColorPalette().accentColor"></v-divider>
            <v-col style="margin-left: 30px;">
                <v-row>
                    <v-col style="margin-top:20px; margin-bottom:20px;">
                        <v-row style=" margin-bottom:20px;">
                           <v-btn @click="addToRow"> Add to Row</v-btn>
                        </v-row>
                        <v-row>
                            <v-icon @click="addRowBack">
                                mdi-pencil
                            </v-icon>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                            ROWS
                            <v-checkbox v-if="form.meta.rows.includes('machine_list')" dark v-model="selectedInRow" value="machine_list" label="Machine List"></v-checkbox>
                            <v-checkbox v-if="form.meta.rows.includes('tag_type')" dark v-model="selectedInRow" value="tag_type" label="Tag Type"></v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider width="100%" style="margin:20px;" :color="$store.getters.getColorPalette().accentColor"></v-divider>
                <v-row>
                    <v-col style="margin-top:20px; margin-bottom:20px;">
                        <v-row style=" margin-bottom:20px;">
                           <v-btn @click="addToColumn"> Add to Column</v-btn>
                        </v-row>
                        <v-row>
                            <v-icon @click="addColumnBack">
                                mdi-pencil
                            </v-icon>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                            COLUMNS
                            <v-checkbox v-if="form.meta.columns.includes('machine_list')" dark v-model="selectedInColumn" value="machine_list" label="Machine List"></v-checkbox>
                            <v-checkbox v-if="form.meta.columns.includes('tag_type')" dark v-model="selectedInColumn" value="tag_type" label="Tag Type"></v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-row>
            </v-row>
        </v-row>
        </v-card> -->
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
            </v-form> 
        </v-container>
    </template>
    <script>
    import InfoAlert from '@/components/InfoAlert'
    import axios from 'axios'
    export default {
        name:'ShiftBasedUsageSheetForm',
        props: {
        "sheet": {
            type: Object,
            // required: true
        },
        },
        components:{
            InfoAlert,
        },
        mounted(){
            console.log(this.sheet)
            if(this.sheet && this.sheet.meta){
                this.machineTableDetails = this.sheet.meta.machine_list
                this.tagTypeTableDetails = this.sheet.meta.tag_types
                this.form.name = this.sheet.name
                this.form.meta.data_type = this.sheet.meta.data_type
                this.form.meta.tag_types = this.sheet.meta.tag_types
                this.form.meta.machine_list = this.sheet.meta.machine_list
                this.form.meta.rows = this.sheet.meta.rows
                this.form.meta.columns = this.sheet.meta.columns
                this.api = 'updateSheets'
                this.form.sheet_id = this.sheet.sheet_id
                this.form.meta.shift_id = this.sheet.meta.shift_id
                this.isTranspose=this.sheet.meta.isTranspose
            }
            this.form.sheet_type = 'shiftBasedUsageSheet'
            this.getShifts()
        },
        data(){ 
            return {
                api:'createSheets',
                selected:[],
                selectedInRow:[],
                selectedInColumn:[],
                rowContains:[],
                columnContains:[],
                tagTypeTableDetails:[],
                shiftOptions:[],
                machineTableDetails:[],
                workspace:null,
                machine:null,
                tagType:null,
                machine_label:null,
                tagType_label:null,
                machineOptions:[],
                generalSheetValid:false,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                isTranspose:false,
                dataTypeOptions:[
                {label:'Raw', value:'raw'},
                {label:'Normalised', value:'normalised'},
                {label:'Usage', value:'usage'},
                {label:'Trend', value:'trend'},
                ],
                form:{
                    name:null,
                    sheet_type:'general',
                    meta:{
                        machine_list:{},
                        tag_types:{},
                        isTranspose:false
                    }
                },
                objectRules:[
                    v => !!v || 'Required',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
                ],
                numberRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
                nonNegativeRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
            }
        },
        computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
                }
          return op
        },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        },
        methods:{
           close(){
                this.$emit("close")
            },
            addMachineToList(){
                if(!this.machineTableDetails){
                    this.machineTableDetails = []
                }
                this.machineTableDetails.push(
                    {label:this.machine_label,machine_id:this.machine.machine_id}
                )
            },
            addTagTypeToList(){
                if(!this.tagTypeTableDetails){
                    this.tagTypeTableDetails = []
                }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
            },
            getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
          this.shiftOptions = op
        },
            getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                // console.log(payload)
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },

            sheetSubmit(){
                this.$refs.generalSheetForm.validate()
                if(this.generalSheetValid){
                    this.loading=true
                    this.form.meta.machine_list = this.machineTableDetails
                    this.form.meta.tag_types = this.tagTypeTableDetails
                    this.form.meta.isTranspose = this.isTranspose
                    let d=Object.assign({}, this.form)
                    //d.target=this.emails.replaceAll(' ','').split(',')
                    axios.post(this.$store.state.api+this.api,d,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$emit('update')
                                this.$store.dispatch('refreshSheets')
                                this.$refs.generalSheetForm.reset()
                                this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            onMachineReset(){
            this.workspace=null
            this.machine=null
            this.machine_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
            onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
            deleteFromMachineTable(item){
            this.machineTableDetails=this.machineTableDetails.filter(x=>x.machine_id!=item.machine_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },
        },
        watch:{
        workspace(){
            this.getMachineList()
        },
        isTranspose(){
            if(this.isTranspose===null){
                this.isTranspose=false
            }
        }
    }
    
    }
    </script>