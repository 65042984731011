<template>
    <v-container fluid >
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form ref="esgConversionForm" v-model="valid">
        <v-row>
            <v-col>
                <v-autocomplete
                    :items="scopeOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().scope"
                    item-text="label"
                    item-value="value"
                    v-model="scope"
                ></v-autocomplete>
            </v-col>
            <v-col v-if="categorieOptions && categorieOptions.length>0">
                <v-autocomplete
                    :items="categorieOptions"
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    small-chips
                    :label="$store.getters.getTextMap().category"
                    item-text="label"
                    item-value="value"
                    v-model="form.category"
                ></v-autocomplete>
            </v-col>
            <v-col>
                <v-text-field
                    :dark="$store.getters.getColorPalette().isDark"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    :loading="loading"
                    outlined
                    dense
                    v-model="form.source"
                    :counter="25"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().source"
                    required
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                :dark="$store.getters.getColorPalette().isDark"
                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                :loading="loading"
                v-model="form.type"
                outlined
                dense
                :counter="25"
                :rules="nameRules"
                :label="$store.getters.getTextMap().source_type"
                required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    :dark="$store.getters.getColorPalette().isDark"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    :loading="loading"
                    outlined
                    dense
                    v-model="form.name"
                    :counter="25"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    :dark="$store.getters.getColorPalette().isDark"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    :loading="loading"
                    v-model="form.uom"
                    outlined
                    dense
                    :counter="25"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().unit_of_measurement"
                    required
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    :dark="$store.getters.getColorPalette().isDark"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    :loading="loading"
                    v-model="form.emission_factor"
                    outlined
                    dense
                    :counter="25"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().emission_factor"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row >
            <v-col align-self="center"><v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="form.automated"  :label="$store.getters.getTextMap().get_from_param" ></v-checkbox></v-col>
            <v-col v-if="form.automated" align-self="center">
                <v-chip small v-if="form.param_id " :dark="$store.getters.getColorPalette().isDark">{{ $store.getters.getNameByParamId(form.param_id) }}</v-chip>
                <v-btn class="ml-1" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="showParamGet=true">{{$store.getters.getTextMap().choose_param}}</v-btn>
                <GetParamModal v-on:value="updateParam" :show="showParamGet" :params="form.param_id" v-on:close="showParamGet=false"/>
            </v-col>
        </v-row>
        <v-row >
            <v-col align="right">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-btn class="mr-1" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import GetParamModal from '@/components/modals/GetParamModal'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateESGScopeConversionTemp',
    props:['esgData'],
    components:{
        InfoAlert,
        GetParamModal
    },
    mounted(){
        if(this.esgData && this.esgData.scope && this.esgData.source && this.esgData.type && this.esgData.name && this.esgData.uom){
            this.api='updateEsgScopeConversionTemp'
            this.editMode=true
            this.form=Object.assign({},this.esgData)
            this.scope=this.form.scope
        }else{
            this.api='createEsgScopeConversionTemp'
            this.editMode=false
        }
        this.fetchCategories()
    },
    data(){
        return {
            api:'createEsgScopeConversionTemp',
            showParamGet:false,
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            scope:null,
            form:{
                scope:null,
                source:null,
                type:null,
                name:null,
                uom:null,
                emission_factor:0,
            },
            categorieOptions:[],
            scopeOptions:[
                {label:"Scope 1",value:"1"},
                {label:"Scope 2",value:"2"},
                {label:"Scope 3",value:"3"}
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            numberRules: FormRules.numberRules,
        }
    },
    methods:{
        fetchCategories(){
            let op=[]
            if(this.scope){
                this.loading = true;
                axios.post(this.$store.state.api + "getCategoriesByScopeTemp", {scope:this.scope},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let categories=response.data.categories
                        for(let i of categories){
                            op.push({value:i.category, label: i.category })
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.categorieOptions=op
        },
        close(){
            this.$emit("close")
        },
        updateParam(param){
            // console.log(param)
            this.form.param_id=param
        },
        onSubmit(){
            this.$refs.esgConversionForm.validate()
            if(this.valid){
                this.loading=true
                this.form['scope']=this.scope
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshShifts')
                        if(!this.editMode){
                            this.$refs.esgConversionForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                    
                })
            }
        }
    },
    watch:{
        scope(){
            this.fetchCategories()
        },
    }
}
</script>