<template>
    <div class="text-center">
        <v-btn @click="dialog = !dialog" icon color="blue darken-2" ><v-icon small>mdi-pencil</v-icon></v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <CreateDynamicExcelReport :report="report" v-on:close="dialog=false"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CreateDynamicExcelReport from '@/components/crud_components/CreateDynamicExcelReport'
export default {
    name:'EditDynamicExcelReportBottomSheet',
    props:['report'],
    components:{
        CreateDynamicExcelReport  
    },
    data(){
        return {
            dialog:false
        }
    },
}
</script>