<template>
    
  <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
       :dark="$store.getters.getColorPalette().isDark"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="lists"
      item-key="list_id"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
    :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
        :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{ $store.getters.getTextMap().lists }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <CreateListModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
      </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <List :list="item" />
       
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</template>

<script>
import CreateListModal from '@/components/modals/derived/CreateListModal'
import List from '@/components/items/derived/List'

export default {
    name:'ListsTable',
    components:{
        CreateListModal,
        List
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'list_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    computed:{
        lists(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getLists()
        }
    },
}
</script>
<style scoped>

.v-card__title{
  background-color:#BDBDBD00;
}
.theme--light.v-toolbar.v-sheet{
  background-color: #B2DFDB00;
}
.theme--light.v-data-table{
   background-color: #B2DFDB00;
}
</style>