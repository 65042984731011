<template>
  <div class="text-center">
    
    <v-btn  @click="dialog = !dialog" icon 
           color="blue darken-2" >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <CreateUsageReport :report="report" v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateUsageReport from '@/components/crud_components/CreateUsageReport'
export default {
    name:'EditUsageReportBottomSheet',
    props:['report'],
    components:{
      CreateUsageReport  
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>