<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <div class="py-0">
          
          <v-form
    ref="parameterForm"
    v-model="valid" >
      <v-card flat :color="$store.getters.getColorPalette(). background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
          {{report?'Edit':'Create'}} {{$store.getters.getTextMap().timeframed_sec_report}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="35"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>

                    <v-col>
                
    <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white"
      
      v-bind:key="param.parameter" v-for="param in form.energy_list"
    >
     {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
    </v-chip>
            </v-col>
                    <v-col>
                
    <v-chip
      class="ma-2"
      
      color="blue"
      text-color="white"
      
      v-bind:key="param.parameter" v-for="param in form.prod_list"
    >
     {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
    </v-chip>
            </v-col>

                </v-row>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.energy_unit"
      :counter="8"
      
      :label="$store.getters.getTextMap().energy_unit"
      
    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.prod_unit"
      :counter="8"
      
      :label="$store.getters.getTextMap().prod_unit"
      
    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.correction_factor"
      :counter="6"
      :rules="floatRules"
      :label="$store.getters.getTextMap().correction_factor"
      
    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.unit"
      :counter="8"
      
      :label="$store.getters.getTextMap().SEC_unit"
      
    ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
            <v-col>
                <v-select
                  :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="workspace1"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          filled
          item-text="label"
          item-value="value"
          required
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
               :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="device1"
          :items="deviceOptions1"
          :label="$store.getters.getTextMap().device"
          dense
          filled
          item-text="label"
          item-value="value"
          required
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
                :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.energy_type"
          :items="paramTypes"
          :label="$store.getters.getTextMap().energy_param_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
                  :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.energy_list"
          :items="paramOptions1"
          :label="$store.getters.getTextMap().energy_parameters"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            multiple
         :rules="listRules"
         clearable
         small-chips
      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>
                <v-row>
            
           
        
            <v-col>
                <v-select
                 :dark="$store.getters.getColorPalette().isDark" 
       :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="workspace2"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          filled
          item-text="label"
          item-value="value"
          required
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
               :dark="$store.getters.getColorPalette().isDark" 
       :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="device2"
          :items="deviceOptions2"
          :label="$store.getters.getTextMap().device"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
                :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.prod_type"
          :items="paramTypes"
          :label="$store.getters.getTextMap().prod_param_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select
               :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.prod_list"
          :items="paramOptions2"
          :label="$store.getters.getTextMap().production_parameters"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            multiple
         :rules="listRules"
         clearable
         small-chips
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>
        <v-row>
            
<v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
    :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.from_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
         :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_time"
            :label="$store.getters.getTextMap().from"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
        :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu3"
          v-model="form.from_time"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.from_time)"
        ></v-time-picker>
      </v-menu>
            </v-col>
                        <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
 :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.to_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
           :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_time"
            :label="$store.getters.getTextMap().to"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
        :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu2"
          v-model="form.to_time"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(form.to_time)"
        ></v-time-picker>
      </v-menu>
            </v-col>
            <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.time_frames"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().no_of_intervals"
      
    ></v-text-field>
                    </v-col>
        </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
          
          v-if="!loading"
      v-model="form.granular"
      :label="$store.getters.getTextMap().granular_data"
    ></v-checkbox>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            
            @click="onSubmit"
          >
            {{report&&report.report_id?$store.getters.getTextMap().update:$store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().cancelbtnColor"
           
            @click="onReset"
          >
           {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateParameterSECReport',
    props:['report'],
    components:{
        InfoAlert
    },
    mounted(){
      //console.log('mounted')
     if(this.report && this.report.report_id){
       //console.log(this.report.energy_list)
       this.form={
         name:this.report.name,
                type:this.report.type,
                correction_factor:this.report.correction_factor,
                frequency:'daily',
                energy_list:[],
                prod_list:[],
                unit:this.report.unit,
                production_unit:this.report.production_unit,
                to_time:this.report.to_time,
                from_time:this.report.from_time,
                time_frames:Number(this.report.time_frames),
                prod_type:this.report.prod_type,
                energy_unit:this.report.energy_unit,
                energy_type:this.report.energy_type,
                //emails:[]
                }
                for(let i of this.report.prod_list){
                  let param=null
                  switch (this.report.prod_type){
                    case 'parameter':
                      param=this.$store.getters.getParameterById(i)
                      break;
                    case 'computed':
                      param=this.$store.getters.getComputedParameterById(i)
                      break;
                    case 'derived':
                      param=this.$store.getters.getDerivedParametersById(i)
                      break;
                    default:
                      continue
                  }
                  if(param){
                    this.form.prod_list.push(param)
                  }
                  
                }
                for(let i of this.report.energy_list){
                  let param=null
                  switch (this.report.energy_type){
                    case 'parameter':
                      param=this.$store.getters.getParameterById(i)
                      break;
                    case 'computed':
                      param=this.$store.getters.getComputedParameterById(i)
                      break;
                    case 'derived':
                      param=this.$store.getters.getDerivedParametersById(i)
                      break;
                    default:
                      continue
                  }
                  if(param){
                    this.form.energy_list.push(param)
                  }
                  
                }
       this.editMode=true
       this.api='updateSECReport'
       //console.log(this.form)
     }else{
       this.editMode=false
       this.api='createTimeFramedSECReport'
     }
    },
     data(){
        return {
          editMode:false,
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            device1:null,
            workspace1:null,
            device2:null,
            workspace2:null,
            api:'createTimeFramedSECReport',
            menu2:null,
            menu3:null,
            energy_list:[],
            frequency:[
                {label:'Monthly',value:'monthly'},
                {label:'Daily',value:'daily'}                
            ],
            paramTypes:[
                {label:'Parameter',value:'parameter'},
                {label:'Computed Param',value:'computed'},            
                {label:'Derived Param',value:'derived'},            
            ],
            processing:[
              {label:'Average',value:'mean'},
                {label:'Addition',value:'sum'},
                {label:'Single', value:'single'}
            ],
            type:[
              {label:'Average',value:'mean'},
                {label:'Addition',value:'sum'}
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Name must be less than 35 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 8) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.toString().length <= 5 && v.toString().length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      form:{
                name:null,
                type:null,
                correction_factor:null,
                frequency:'daily',
                energy_list:[],
                prod_list:[],
                unit:null,
                production_unit:null,
                to_time:null,
                from_time:null,
                time_frames:null,
                prod_type:'parameter',
                energy_unit:null,
                energy_type:'parameter',
                emails:[]
                
            }
        }
    },
    computed:{
      workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
      deviceOptions1(){
             
            let op=[]
          
          if(this.workspace1 && this.workspace1.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace1.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
      deviceOptions2(){
             
            let op=[]
          
          if(this.workspace2 && this.workspace2.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace2.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions1(){
             
            let op=[]
          
          if(this.device1 && this.device1.device_id ){
              let g=[]
              if(this.form.energy_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.device1.device_id)
              }else if(this.form.energy_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.device1.device_id)
              }else if(this.form.energy_type=='derived'){
                  g=this.$store.getters.getDerivedParamsByDeviceID(this.device1.device_id)
              }
              for(let i of g){
                  
                  if(i && i.name ){
                    if(i.disabled!=true){

                      op.push({value:i, label: i.name })
                    }
                  }else{
                    op.push({value:i, label: "Unassigned Name" })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions2(){
             
            let op=[]
          
          if(this.device2 && this.device2.device_id ){
              let g=[]
              if(this.form.prod_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.device2.device_id)
              }else if(this.form.prod_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.device2.device_id)
              }else if(this.form.prod_type=='derived'){
                  g=this.$store.getters.getDerivedParamsByDeviceID(this.device2.device_id)
              }
              for(let i of g){
                  
                  if(i && i.name ){
                    if(i.disabled!=true){

                      op.push({value:i, label: i.name })
                    }
                  }else{
                    op.push({value:i, label: "Unassigned Name" })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
      close(param){
        console.log(param)
        /*
        this.form.list=this.form.list.filter(
      function(data){ return data.parameter != param.parameter }
  );
  */
      },
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
//console.log(d)
            //d['type']="parameter"
            let list=[]
            let key='parameter'
            if(this.form.prod_type=='computed' || this.form.prod_type=='derived'){
                key='param_id'
            }
            for(let i of this.form.prod_list){
              list.push(i[key])
            }
            d['prod_list']=list
            list=[]
            key='parameter'
            if(this.form.energy_type=='computed' || this.form.energy_type=='derived'){
                key='param_id'
            }
            for(let i of this.form.energy_list){
              list.push(i[key])
            }
            d['energy_list']=list
            if(this.report && this.report.report_id){
              d['report_id']=this.report.report_id
            }
            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshSECReports')
        //console.log("Report added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      
    }
    this.loading=false
    })
    .catch(err=>{
      this.loading=false
      //console.log(err)
      this.info=err
      this.showDismissibleAlert=true
      });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            if(!this.report){
              this.$refs.parameterForm.reset()
            }
            
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }

}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>
