<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-btn
                v-if="!loading"
                :color="$store.getters.getColorPalette().foregroundColorCode"
                text
                @click="fetchReport"
                >View Report</v-btn>
            </span> -->
            <span v-if="energyDistReport.status=='processed'">
                <v-btn small text :style="{color:$store.getters.getColorPalette().foregroundColorCode}" @click="downloadReportTemp()">{{$store.getters.getTextMap().download_report}}</v-btn>
            </span>
        
    </v-container>
</template>

<script>
import axios from 'axios'
import fileDownload from 'js-file-download'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
//import AddTupleToList from '@/components/crud_components/AddTupleToList'
export default {
name:'EnergyDistributionDetailedReport',
props:['energyDistReport'],
components:{
    InfoAlert,
    //AddTupleToList
},
mounted(){
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        showeReport:false,
        form:{
          address:null,
          name:null,
        },
        stringRules: [
    v => !!v || 'Required',
    v => /\S+/.test(v) || 'Required',
    v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
  ],
  bitRules:[
      v => !!v || 'Required',
      v => /[0-9]+$/.test(v) || 'It should be a number',
      v => (v && !isNaN(v))|| 'It should be a valid number',
      v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
  ],
  emailRules:FormRules.emailRules,
  nameRules:FormRules.nameRules
    }
},

computed:{
    
    
},

methods:{
    fetchReport(){
        this.showeReport=true
        // let payload={

        // }
    },
    downloadReport(){
        if(this.energyDistReport){
                //this.subTableData=item
                this.loading=true
                console.log(this.energyDistReport)
                axios.post(this.$store.state.api+'downloadEnergyDistReport',{
                    file_list:[this.energyDistReport.report_loc,this.energyDistReport.excel_loc]
                },{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                .then((response) => {
                    this.loading=false
                    /*const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item+'.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();*/
                    if(response.data.status && response.data.status=='error'){
                      this.showDismissibleAlert=true
                      this.info=response.data.msg
                    }else{
                      fileDownload(response.data,'energyDistReport.zip')
                    }
    
                }).catch(err=>{
                    this.loading=false
                    this.showDismissibleAlert=true
                    this.info=err
                });
        }else{
            this.showDismissibleAlert=!this.showDismissibleAlert
            this.info='Report file name not specified'
        }
    },
    async downloadReportTemp(){
        try{
            if(this.energyBillDistReport){
                let PDF_Excel_reports = [this.energyDistReport.report_loc,this.energyDistReport.excel_loc]
                this.loading=true
                for(let i of PDF_Excel_reports){
                    let payload={
                        statement_id:i
                    }
                    let response=await axios.post(this.$store.state.api+'downloadEnergyBillDistReport',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                    if(response.data.status && response.data.status=='error'){
                      this.showDismissibleAlert=true
                      this.info=response.data.msg
                    }else{
                      fileDownload(response.data,i)
                    }
                }
            }else{
                this.showDismissibleAlert=!this.showDismissibleAlert
                this.info='Report file name not specified'
            }
        }catch(error){
            this.loading=false
            this.showDismissibleAlert=true
            this.info=error
        }
    },
},
watch:{

}

}
</script>