var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({ background: _vm.$store.getters.getColorPalette().backgroundColorCode, color: _vm.$store.getters.getColorPalette().accentCode }),attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.graphData.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredData,"item-key":"event_id","search":_vm.search,"single-expand":true,"show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().batch_movement))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.graphData.length > 0)?_c('v-btn',{attrs:{"outlined":"","color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""},on:{"click":_vm.downloadPDF}},[_vm._v(" Download Table ")]):_vm._e(),_c('a',{ref:"csvDownload",attrs:{"hidden":""}})],1)]},proxy:true},{key:"item.to_location",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.getLocationNameById(item.to_location)))])]}},{key:"item.from_location",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.getLocationNameById(item.from_location)))])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.toReadableTime(item.timestamp)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ObjectTable',{attrs:{"meta":item.meta},on:{"update":_vm.fetchBatchMovements}})],1)]}}],null,false,412687703)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }