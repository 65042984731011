<template>
    <div>
        <v-btn 
        v-if="mode==='Edit'"
        :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
            @click="dialog = !dialog"
        >
        {{ mode }}{{$store.getters.getTextMap().aq_device}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else
        :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
            @click="dialog = !dialog"
        >
        {{ mode }} {{$store.getters.getTextMap().aq_device}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-weather-windy</v-icon>
        </v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ mode }} {{$store.getters.getTextMap().aq_quality}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <CreateAirQuality :area="area" :air_quality="air_quality" v-on:close="dialog=false"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CreateAirQuality from '@/components/crud_components/application/airquality/CreateAirQuality'
export default {
    name:'CreateAreaModal',
    props:['area','air_quality'],
    components:{
        CreateAirQuality  
    },
    data(){
        return {
            dialog:false,
            mode:'Add'
        }
    },
    mounted(){
        if(this.area && this.area.area_id && this.air_quality && this.air_quality.length>0){
            this.mode = 'Edit'
        }
    }
}
</script>

  