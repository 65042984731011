<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row>
            <v-col cols="12" align="center">
                <VoucherTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import VoucherTable from '@/components/lists/manufacturing/stores/VoucherTable'
export default {
    name:'VoucherPage',
    components:{
        VoucherTable
    }
}
</script>