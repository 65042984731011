<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col  v-if="air_quality && air_quality.length>0 && showPM10">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypePM10" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="pm10" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
            <v-col   v-if="air_quality && air_quality.length>0 && showPM25">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypePM25" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="pm25" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col   v-if="air_quality && air_quality.length>0 && showCO2">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeCO2" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="co2" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card> 
            </v-col>
            <v-col v-if="air_quality && air_quality.length>0 && showVOC">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeVOC" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="voc" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="air_quality && air_quality.length>0 && showSO2">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeSO2" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="so2" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
            <v-col  v-if="air_quality && air_quality.length>0 && showCO">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeCO" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="co" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col  v-if="air_quality && air_quality.length>0 && showO3">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeO3" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="o3" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
            <v-col   v-if="air_quality && air_quality.length>0 && showNO2">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeNO2" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="no2" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="air_quality && air_quality.length>0 && showHumidity">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeHumidity" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="humidity" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
            <v-col  v-if="air_quality && air_quality.length>0 && showTemperature">
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row no-gutters>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphTypeTemperature" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn><v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div ref="temperature" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}" ></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import Plotly from 'plotly.js'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'AirQualityHistoricalDataItem',
    props:['air_quality'],
    components:{
        InfoAlert,
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            showPM10:false,
            showPM25:false,
            showCO2:false,
            showVOC:false,
            showSO2:false,
            showCO:false,
            showO3:false,
            showNO2:false,
            showHumidity:false,
            showTemperature:false,
            graphDataPM10:{},
            graphDataPM25:{},
            graphDataCO2:{},
            graphDataVOC:{},
            graphDataSO2:{},
            graphDataCO:{},
            graphDataO3:{},
            graphDataNO2:{},
            graphDataHumidity:{},
            graphDataTemperature:{},
            graphTypePM10:1,
            graphTypePM25:1,
            graphTypeCO2:1,
            graphTypeVOC:1,
            graphTypeSO2:1,
            graphTypeCO:1,
            graphTypeO3:1,
            graphTypeNO2:1,
            graphTypeHumidity:1,
            graphTypeTemperature:1,
            barMode:'stack',
            tempDataFinale:[],
            layout : {
                barmode: this.barMode,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                legend:{
                    y:-30
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:true,
                autosize: true,
                xaxis: {
                    tickangle: -65,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        initPM10(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypePM10==1?'line':'bar'
                this.graphDataPM10={}
                for(let i of this.air_quality ){
                    this.graphDataPM10[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initPM2(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypePM25==1?'line':'bar'
                this.graphDataPM25={}
                for(let i of this.air_quality ){
                    this.graphDataPM25[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initCO2(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeCO2==1?'line':'bar'
                this.graphDataCO2={}
                for(let i of this.air_quality ){
                    this.graphDataCO2[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initVOC(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeVOC==1?'line':'bar'
                this.graphDataVOC={}
                for(let i of this.air_quality ){
                    this.graphDataVOC[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initSO2(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeSO2==1?'line':'bar'
                this.graphDataSO2={}
                for(let i of this.air_quality ){
                    this.graphDataSO2[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initCO(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeCO==1?'line':'bar'
                this.graphDataCO={}
                for(let i of this.air_quality ){
                    this.graphDataCO[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initO3(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeO3==1?'line':'bar'
                this.graphDataO3={}
                for(let i of this.air_quality ){
                    this.graphDataO3[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initNO2(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeNO2==1?'line':'bar'
                this.graphDataNO2={}
                for(let i of this.air_quality ){
                    this.graphDataNO2[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initHumidity(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeHumidity==1?'line':'bar'
                this.graphDataHumidity={}
                for(let i of this.air_quality ){
                    this.graphDataHumidity[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        initTemprature(){
            if(this.air_quality && this.air_quality.length>0){
                let type=this.graphTypeTemperature==1?'line':'bar'
                this.graphDataTemperature={}
                for(let i of this.air_quality ){
                    this.graphDataTemperature[i.label]={
                    x: [],
                    y: [],
                    name:i.label,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        createPM10Chart(){
            this.initPM10()
            this.layout['title']= "PM10"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='PM10' ){
                        if(!(i['device'] in this.graphDataPM10)){
                            let type=this.graphTypePM10==1?'line':'bar'
                            this.graphDataPM10[i['device']]={
                                x: [],
                                y: [],
                                name:i['device'],
                                type:type,
                                opacity:0.9,
                                marker: {
                                    line: {
                                    color: this.$store.getters.getColorPalette().accentCode,
                                    width: 1
                                    },
                                    }
                            }
                    }
                    this.graphDataPM10[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataPM10[i['device']]['y'].push(i.usage)
                    // if(this.graphDataPM10[i['device']]['y'].length>0 && !this.showPM10){
                    //     this.showPM10=true
                    // }
                }
            } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataPM10) && (Object.values(this.graphDataPM10)).length>0 &&this.showPM10){
                Plotly.newPlot(this.$refs.pm10, Object.values(this.graphDataPM10), this.layout, {displaylogo: false});
            }

        },
        createPM2Chart(){
            this.initPM2()
            this.layout['title']= "PM2.5"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='PM2.5'){
                        if(!(i['device']in this.graphDataPM25)){
                        let type=this.graphTypePM25==1?'line':'bar'
                        this.graphDataPM25[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataPM25[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataPM25[i['device']]['y'].push(i.usage)
                    // if(this.graphDataPM25[i['device']]['y'].length>0 && !this.showPM25){
                    //     this.showPM25=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataPM25) && (Object.values(this.graphDataPM25)).length>0 && this.showPM25){
                Plotly.newPlot(this.$refs.pm25, Object.values(this.graphDataPM25), this.layout, {displaylogo: false});
            }
        },
        createCO2Chart(){
            this.initCO2()
            this.layout['title']= "CO2"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='CO2'){
                        if(!(i['device'] in this.graphDataCO2)){
                        let type=this.graphTypeCO2==1?'line':'bar'
                        this.graphDataCO2[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataCO2[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataCO2[i['device']]['y'].push(i.usage)
                    // if(this.graphDataCO2[i['device']]['y'].length>0 && !this.showCO2){
                    //     this.showCO2=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataCO2) && (Object.values(this.graphDataCO2)).length>0 && this.showCO2){
                Plotly.newPlot(this.$refs.co2, Object.values(this.graphDataCO2), this.layout, {displaylogo: false});
            }
        },
        createVOCChart(){
            this.initVOC()
            this.layout['title']= "VOC"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='VOC'&& i['device']==i['device']){
                        if(!(i['device'] in this.graphDataVOC)){
                        let type=this.graphTypeVOC==1?'line':'bar'
                        this.graphDataVOC[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataVOC[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataVOC[i['device']]['y'].push(i.usage)
                    // if(this.graphDataVOC[i['device']]['y'].length>0 && !this.showVOC){
                    //     this.showVOC=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataVOC) && (Object.values(this.graphDataVOC)).length>0 && this.showVOC){
                Plotly.newPlot(this.$refs.voc, Object.values(this.graphDataVOC), this.layout, {displaylogo: false});
            }
        },
        createSO2Chart(){
            this.initSO2()
            this.layout['title']= "SO₂"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='SO₂'&& i['device']==i['device']){
                        if(!(i['device'] in this.graphDataVOC)){
                        let type=this.graphTypeSO2==1?'line':'bar'
                        this.graphDataSO2[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataSO2[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataSO2[i['device']]['y'].push(i.usage)
                    // if(this.graphDataSO2[i['device']]['y'].length>0 && !this.showSO2){
                    //     this.showSO2=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataSO2) && (Object.values(this.graphDataSO2)).length>0 && this.showSO2){
                Plotly.newPlot(this.$refs.so2, Object.values(this.graphDataSO2), this.layout, {displaylogo: false});
            }
        },
        createCOChart(){
            this.initCO()
            this.layout['title']= "CO"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='SO₂'&& i['device']==i['device']){
                        if(!(i['device'] in this.graphDataCO)){
                        let type=this.graphTypeCO==1?'line':'bar'
                        this.graphDataCO[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataCO[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataCO[i['device']]['y'].push(i.usage)
                    // if(this.graphDataCO[i['device']]['y'].length>0 && !this.showCO){
                    //     this.showCO=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataCO) && (Object.values(this.graphDataCO)).length>0 && this.showCO){
                Plotly.newPlot(this.$refs.co, Object.values(this.graphDataCO), this.layout, {displaylogo: false});
            }
        },
        createO3Chart(){
            this.initCO()
            this.layout['title']= "O3"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='O3'&& i['device']==i['device']){
                        if(!(i['device'] in this.graphDataO3)){
                        let type=this.graphTypeO3==1?'line':'bar'
                        this.graphDataO3[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataO3[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataO3[i['device']]['y'].push(i.usage)
                    // if(this.graphDataO3[i['device']]['y'].length>0 && !this.showO3){
                    //     this.showO3=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataO3) && (Object.values(this.graphDataO3)).length>0 && this.showO3){
                Plotly.newPlot(this.$refs.o3, Object.values(this.graphDataO3), this.layout, {displaylogo: false});
            }
        },
        createNO2Chart(){
            this.initCO()
            this.layout['title']= "NO₂"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='NO₂'&& i['device']==i['device']){
                        if(!(i['device'] in this.graphDataNO2)){
                        let type=this.graphTypeNO2==1?'line':'bar'
                        this.graphDataNO2[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataNO2[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataNO2[i['device']]['y'].push(i.usage)
                    // if(this.graphDataNO2[i['device']]['y'].length>0 && !this.showNO2){
                    //     this.showNO2=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataNO2) && (Object.values(this.graphDataNO2)).length>0 && this.showNO2){
                Plotly.newPlot(this.$refs.no2, Object.values(this.graphDataNO2), this.layout, {displaylogo: false});
            }
        },
        createHumidityChart(){
            this.initHumidity()
            this.layout['title']= "Humidity"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='Humidity'){
                        if(!(i['device'] in this.graphDataHumidity)){
                        let type=this.graphTypeHumidity==1?'line':'bar'
                        this.graphDataHumidity[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataHumidity[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataHumidity[i['device']]['y'].push(i.usage)
                    // if(this.graphDataHumidity[i['device']]['y'].length>0 && !this.showHumidity){
                    //     this.showHumidity=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataHumidity) && (Object.values(this.graphDataHumidity)).length>0 && this.showHumidity){
                Plotly.newPlot(this.$refs.humidity, Object.values(this.graphDataHumidity), this.layout, {displaylogo: false});
            }
        },
        createTemperatureChart(){
            this.initTemprature()
            this.layout['title']= "Temperature"
            this.layout['showlegend']=true
            // for(let j of this.air_quality){
                for(let i of this.tempDataFinale){
                    if(i['label']==='Temperature'){
                        if(!(i['device'] in this.graphDataTemperature)){
                        let type=this.graphTypeTemperature==1?'line':'bar'
                        this.graphDataTemperature[i['device']]={
                        x: [],
                        y: [],
                        name:i['device'],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                            },
                            }
                        }
                    }
                    this.graphDataTemperature[i['device']]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                    this.graphDataTemperature[i['device']]['y'].push(i.usage)
                    // if(this.graphDataTemperature[i['device']]['y'].length>0 && !this.showTemperature){
                    //     this.showTemperature=true
                    // }
                }
                } 
            // }
            if(this.air_quality && this.air_quality.length>0 && Object.values(this.graphDataTemperature) && (Object.values(this.graphDataTemperature)).length>0 && this.showTemperature){
                Plotly.newPlot(this.$refs.temperature, Object.values(this.graphDataTemperature), this.layout, {displaylogo: false});
            }
        },
        getData(){
            this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
            this.graphData={}
            let from_date;
            from_date=moment().subtract(30,'days').format('YYYYMMDD')
            let to_date=moment().format('YYYYMMDD')
            if(this.air_quality){
                this.tempDataFinale=[]
                this.tempData={}
                let payload={
                        parameters:[],
                        from_date:from_date,
                        to_date:to_date
                    }
                let labelMap={}
                let deviceMap={}
                // console.log(this.air_quality)
                for(let i of this.air_quality){
                    if(i.pm10){
                        if(!this.showPM10){
                            this.showPM10=true
                        }
                        payload.parameters.push(i.pm10)
                        labelMap[i.pm10]='PM10'
                        deviceMap[i.pm10]=i.label
                    }
                    if(i.pm25){
                        if(!this.showPM25){
                            this.showPM25=true
                        }
                        payload.parameters.push(i.pm25)
                        labelMap[i.pm25]='PM2.5'
                        deviceMap[i.pm25]=i.label
                    }
                    if(i.co2){
                        if(!this.showCO2){
                            this.showCO2=true
                        }
                        payload.parameters.push(i.co2)
                        labelMap[i.co2]='CO2'
                        deviceMap[i.co2]=i.label
                    }
                    if(i.voc){
                        if(!this.showVOC){
                            this.showVOC=true
                        }
                        payload.parameters.push(i.voc)
                        labelMap[i.voc]='VOC'
                        deviceMap[i.voc]=i.label
                    }
                    if(i.so2){
                        if(!this.showSO2){
                            this.showSO2=true
                        }
                        payload.parameters.push(i.voc)
                        labelMap[i.voc]='SO₂'
                        deviceMap[i.voc]=i.label
                    }
                    if(i.co){
                        if(!this.showCO){
                            this.showCO=true
                        }
                        payload.parameters.push(i.voc)
                        labelMap[i.co]='CO'
                        deviceMap[i.co]=i.label
                    }
                    if(i.o3){
                        if(!this.showO3){
                            this.showO3=true
                        }
                        payload.parameters.push(i.voc)
                        labelMap[i.o3]='O3'
                        deviceMap[i.o3]=i.label
                    }
                    if(i.no2){
                        if(!this.showNO2){
                            this.showNO2=true
                        }
                        payload.parameters.push(i.voc)
                        labelMap[i.no2]='NO₂'
                        deviceMap[i.no2]=i.label
                    }
                    if(i.humidity){
                        if(!this.showHumidity){
                            this.showHumidity=true
                        }
                        payload.parameters.push(i.humidity)
                        labelMap[i.humidity]='Humidity'
                        deviceMap[i.humidity]=i.label
                    }
                    if(i.temp){
                        if(!this.showTemperature){
                            this.showTemperature=true
                        }
                        payload.parameters.push(i.temp)
                        labelMap[i.temp]='Temperature'
                        deviceMap[i.temp]=i.label
                    }
                }
 
                axios.post(this.$store.state.api+'getParamGroupedUsageDataByTimeframe',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.tempDataFinale=response.data.data
                        this.tempDataFinale.map(x=>{
                        x.label=labelMap[x.param_id]?labelMap[x.param_id]:'Label Not defined'
                        x.device=deviceMap[x.param_id]?deviceMap[x.param_id]:'Device Not define'
                    })
                    // console.log(this.tempDataFinale)
                    this.createPM10Chart()
                    this.createPM2Chart()
                    this.createCO2Chart()
                    this.createVOCChart()
                    this.createSO2Chart()
                    this.createCOChart()
                    this.createO3Chart()
                    this.createNO2Chart()
                    this.createHumidityChart()
                    this.createTemperatureChart()
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                })
                .catch(err=>{
                    console.error(err)
                    this.loading=false
                });
            }
        },
    },
    watch:{
        graphTypePM10: function (){
            this.createPM10Chart()
        },
        graphTypePM25: function (){
            this.createPM2Chart()
        },
        graphTypeCO2: function (){
            this.createCO2Chart()
        },
        graphTypeVOC: function (){
            this.createVOCChart()
        },
        graphTypeSO2: function (){
            this.createSO2Chart()
        },
        graphTypeC0: function (){
            this.createCOChart()
        },
        graphTypeO3: function (){
            this.createO3Chart()
        },
        graphTypeNO2: function (){
            this.createNO2Chart()
        },
        graphTypeHumidity: function (){
            this.createHumidityChart()
        },
       graphTypeTemperature: function (){
            this.createTemperatureChart()
        },
  }
}
</script>