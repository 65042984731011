<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>
                  {{$store.getters.getTextMap().process_based_timeframed_data_sheet}}
                </v-card-title>
                </v-col>
            </v-row>
            <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        required
                        dense
                        outlined
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col align="center" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu  
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        ref="menufrom"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.meta.timestamp.from_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field  
                              :dark="$store.getters.getColorPalette().isDark" 
                              :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="form.meta.timestamp.from_time"
                                :label="$store.getters.getTextMap().from"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                clearable
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                            ></v-text-field>
                        </template>
                        <v-time-picker  
                          :dark="$store.getters.getColorPalette().isDark" 
:color="$store.getters.getColorPalette().inputtextColor"
                            v-if="menu3"
                            v-model="form.meta.timestamp.from_time"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menufrom.save(form.meta.timestamp.from_time)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-col align="center" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu  
                      :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        ref="menuto"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.meta.timestamp.to_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                          :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.meta.timestamp.to_time"
                            :label="$store.getters.getTextMap().to"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :rules="objectRules"
                        ></v-text-field>
                    </template>
                    <v-time-picker   :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="menu2"
                        v-model="form.meta.timestamp.to_time"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menuto.save(form.meta.timestamp.to_time)"
                    ></v-time-picker>
                </v-menu>
            </v-col>
            <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().time_frame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
            <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="process_label"
                        :label="$store.getters.getTextMap().process_label"
                        dense
                        outlined
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                       :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="process"
                        :items="processOptions"
                        :label="$store.getters.getTextMap().process"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <GetTagTypeModal v-if="addTagTypes===true" v-on:close="addTagTypes=false" :tagTypes="tagTypeTableDetails" :show="true" v-on:value="createTagTypeArray" />
                <v-col v-if="!loading">
                    <v-text-field readonly :label="$store.getters.getTextMap().tag_types">
                        <template v-slot:prepend-inner>
                            <div >
                                <v-chip
                        class="ma-2"
                        close
                        small
                        color="teal"
                        text-color="white"
                        @click:close="remove(tag)" 
                        v-bind:key="tag.tag_type" v-for="tag in tagTypeTableDetails"
                    >
                        {{tag.label}}
                    </v-chip>
                            </div>
                        </template>
                     </v-text-field>
                    <v-btn style="margin-left:10px;margin-right:10px;" color="gray darken-1" small  @click="addTagTypes=true">{{$store.getters.getTextMap().add_tag_types}}</v-btn>
                    <v-btn style="margin-left:10px;margin-right:10px;" color="gray darken-1" small  @click="addProcessToList">{{$store.getters.getTextMap().add_pocess_to_list}}</v-btn>
                    <!-- <span v-else>please add Tag Type First</span> -->
                    <v-btn  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onProcessReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && processTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().process}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in processTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.process_id }}</td>
                                            
                                            <td><v-icon small color="red" @click="deleteFromProcessTable(item)">mdi-delete</v-icon></td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>
                </v-row>
            </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
            </v-form> 
        </v-container>
    </template>
    <script>
    import InfoAlert from '@/components/InfoAlert'
    import GetTagTypeModal from '@/components/modals/GetTagTypeModal'
    import axios from 'axios'
    export default {
        name:'ProcessBasedHourlyDataSheet',
        props: {
        "sheet": {
            type: Object,
            // required: true
        },
        },
        components:{
            InfoAlert,
            GetTagTypeModal,
        },
        mounted(){
            if(this.sheet && this.sheet.meta){
                this.processTableDetails = this.sheet.meta.process_list
                this.tagTypeTableDetails = this.sheet.meta.tag_types
                this.form.name = this.sheet.name
                this.form.meta.data_type = this.sheet.meta.data_type
                this.form.meta.tag_types = this.sheet.meta.tag_types
                this.form.meta.rows = this.sheet.meta.rows
                this.form.meta.columns = this.sheet.meta.columns
                this.tagTypeTableDetails = []
                this.api = 'updateSheets'
                this.form.sheet_id = this.sheet.sheet_id
                this.form.meta.timestamp.from_time=this.sheet.meta.timestamp.from_time
                this.form.meta.timestamp.to_time=this.sheet.meta.timestamp.to_time
                this.isTranspose=this.sheet.meta.isTranspose
                this.form.meta.timeframe = this.sheet.meta.timeframe
            }
            this.form.sheet_type = 'processBasedHourlyDataSheet'
        },
        data(){ 
            return {
                menu:false,
                menu1:false,
                menu2:false,
                menu3:false,
                api:'createSheets',
                selected:[],
                selectedInRow:[],
                selectedInColumn:[],
                rowContains:[],
                columnContains:[],
                tagTypeTableDetails:[],
                processTableDetails:[],
                process:null,
                addTagTypes:false,
                machine:null,
                tagType:null,
                process_label:null,
                tagType_label:null,
                machineOptions:[],
                generalSheetValid:false,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                isTranspose:false,
                timeframeOptions:[
                    {label:'2 Hours', value:'120Min'},
                    {label:'1 Hour', value:'60Min'},
                    {label:'30 Minutes', value:'30Min'},
                    {label:'15 Minutes', value:'15Min'},
                    {label:'5 Minutes', value:'5Min'},
                ],
                form:{
                    name:null,
                    sheet_type:'general',
                    meta:{
                        process_list:{},
                        timestamp:{},
                        data_type:'normalised',
                        isTranspose:false,
                        timeframe:'60Min'
                    }
                },
                objectRules:[
                    v => !!v || 'Required',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
                ],
                numberRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
                nonNegativeRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
            }
        },
        computed:{
        processOptions(){
            let op=[]
          
              let g=this.$store.state.processes
            //   let g=this.$store.state.processes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{process_id:i.process_id,process_name:i.name}, label: i.name })
                }
          return op
        },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        },
        methods:{
            createTagTypeArray(value){
                this.tagTypeTableDetails = value
            },
           close(){
                this.$emit("close")
            },
            addProcessToList(){
                if(this.tagTypeTableDetails.length>0){
                if(!this.processTableDetails){
                    this.processTableDetails = []
                }
                this.processTableDetails.push(
                    {label:this.process_label,process_id:this.process.process_id,tag_types:this.tagTypeTableDetails}
                )
                this.tagTypeTableDetails=[]
            }else{
                this.loading=false
                this.info="Please Add Tag Type First"
                this.showDismissibleAlert=true
            }

            },
            addTagTypeToList(){
                if(!this.tagTypeTableDetails){
                    this.tagTypeTableDetails = []
                }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
            },
            remove(m){
                this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.label!=m.label)
            },
            sheetSubmit(){
                this.$refs.generalSheetForm.validate()
                if(this.generalSheetValid){
                    this.loading=true
                    this.form.meta.process_list['tag_types'] = this.tagTypeTableDetails
                    this.form.meta.process_list = this.processTableDetails
                    this.form.meta.isTranspose = this.isTranspose
                    let d=Object.assign({}, this.form)
                    //d.target=this.emails.replaceAll(' ','').split(',')
                    axios.post(this.$store.state.api+this.api,d,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$emit('update')
                                this.$store.dispatch('refreshSheets')
                                this.$refs.generalSheetForm.reset()
                                this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            onProcessReset(){
            this.process=null
            this.process_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
            onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
            deleteFromProcessTable(item){
            this.processTableDetails=this.processTableDetails.filter(x=>x.machine_id!=item.machine_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },
        },
        watch:{
            isTranspose(){
                if(this.isTranspose===null){
                    this.isTranspose=false
                }
            }
    }
    
    }
    </script>