<template>
    <v-container fluid>
      
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <v-row>
        <v-col>
          <v-data-table
          :headers="headers"
          :items="productionData"
          item-key="index"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
        loading-text="Loading... Please wait"
        :dark="$store.getters.getColorPalette().isDark" 
        >
        <template v-slot:top>
          <v-toolbar
            flat
            :dark="$store.getters.getColorPalette().isDark" 
          >
            <v-toolbar-title >{{ $store.getters.getTextMap().production_pending }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
            :dark="$store.getters.getColorPalette().isDark" 
          ></v-text-field>
                </v-toolbar>
              </template>
                        </v-data-table>
                        
        </v-col>
      </v-row>
    </v-container>
    </template>

<script>
import axios from 'axios'
import moment from 'moment'
// import InfoAlert from '@/components/InfoAlert'
export default{
    name:'BatchBalanceTable',
    props:[],
    compoenents:{
      // InfoAlert
    },
    mounted(){
      this.init()
    },
    data(){
        return {
            loading:false,
          info:'',
          showDismissibleAlert:false,
          productionData:[],
          productionPlanData:[],
          graphType:'bar',
          showGraphType:true,
          expanded:[],
          search:null,
          headers: [
              {
                text: 'Date',
                align: 'start',
                filterable: false,
                value: 'date',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
              },
              // { text: 'Log Id', value: 'log_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'SKU ID', value: 'sku_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Planned', value: 'planned_quantity', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Produced', value: 'produced_quantity', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Pending', value: 'balance_quantity', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              ],

        }
    },
    methods:{
      getSKUName(sku_id){
        let temp=this.$store.getters.getSKUById(sku_id)
        if(temp){
          return temp.sku_name
        }
        return sku_id

      },
        init(){
            
        this.getData()
        // this.getPlanData()
        // this.createChart()
          
    },

      getData(){
    
          // this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
          
          let from_date=moment().subtract(30,'days').format('YYYY-MM-DD')
          let to_date=moment().add(1,'days').format('YYYY-MM-DD')
          
              this.productionData=[]
              
              
                
                
                this.tempData={}
                let payload={
                    from_date:from_date,
                    to_date:to_date
                    // to_timestamp:to_timestamp
                      }
                
                  axios.post(this.$store.state.api+'getProductionPlanActualView',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response.data)
                  if(response.data.status==='success'){
                    let data=[]
                    let count=0
                    for (let i of response.data.data){
                        i['date'] = moment(i['date'],'YYYYMMDD').format('YYYY-MM-DD');
                        i['index']=count++
                        data.push(i)
                      
                    }
                      this.productionData=data
                      // this.createChart()
                  }else{
                      this.info=response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                  })
                  .catch(err=>{
                      console.error(err)
                      this.loading=false});

      },
    }
}

</script>