<template>
    <v-container fluid>
        
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />

 <v-form
    ref="orgForm"
    v-model="valid"
    
  >
    <v-card :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title >
        {{param? $store.getters.getTextMap().edit:$store.getters.getTextMap().create }} Calculated Param
        </v-card-title>
        <v-card-text>
        <v-row>
    <v-col>
        
            <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
            :loading="loading"
      v-model="form.name"
      :counter="35"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
    </v-col>
    <v-col>
        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
    </v-col>
    <v-col>
        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.correction_factor"
      :counter="10"
      :rules="numberRules"
      :label="$store.getters.getTextMap().correction_factor"
      required
    ></v-text-field>
    </v-col>
        </v-row>
        <v-row>
            <v-col>
                 <v-select  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="workspace1"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
            </v-col>
             <v-col>
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="dev1"
                            :items="deviceOptions1"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param1_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param1_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param1"
                            :items="paramOptions1"
                            :label="$store.getters.getTextMap().param1"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            small-chips
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param1_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().param1_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.param1_operand"
                        :counter="10"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().param1_operand"
                        required
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-text-field>
                    </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                 <v-select  :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="workspace2"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            
                        ></v-select>
            </v-col>
             <v-col> 
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="dev2"
                            :items="deviceOptions2"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param2_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param2_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param2"
                            :items="paramOptions2"
                            :label="$store.getters.getTextMap().param2"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            small-chips
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                   :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.param2_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().param2_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.param2_operand"
                        :counter="10"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().param2_operand"
                        required
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-text-field>
                    </v-col>
        </v-row>
        </v-card-text>
    
         <v-card-actions >
          <span  :style="{color:$store.getters.getColorPalette().accentCode}" ><strong>Equation:</strong>  ( (Param1 {{form.param1_operator?form.param1_operator:'"Param_1_Operator"'}} {{form.param1_operand?form.param1_operand:'"Param_1_Operand"'}}) {{form.operator?form.operator:'"Operator"'}} (Param2 {{form.param2_operator?form.param2_operator:'"param_2_operator"'}} {{form.param2_operand?form.param2_operand:'"param_2_operand"'}}) ) * {{form.correction_factor?form.correction_factor:'"Correction_Factor"'}}</span>
          <v-spacer></v-spacer>
          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
      
            @click="onSubmit"
          >
             {{param ?$store.getters.getTextMap().update:$store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
          :color="$store.getters.getColorPalette().cancelbtnColor"
           
            @click="onReset"
          >
          {{  $store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
 </v-form>
    </v-container>
                
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateCalculatedParam',
    components:{
        InfoAlert
    },
    props:['param'],
    mounted(){
      // console.log(this.param);
      if(this.param && this.param.param_id) {
        this.form={...this.param}
        this.api='updateCalculatedParam'
      }else{
        this.api='createCalculatedParam'
      }
      console.log(this.api);
    },
    data(){
        return {
          api:'createCalculatedParam',
            workspace1:null,
            workspace2:null,
            dev1:null,
            dev2:null,
            form:{
                name:null,
                param1_type:null,
                param1:null,
                param2_type:null,
                param2:null,
                operator:null,
                correction_factor:null,
            },
                loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
                param_types:[
        //{ label: 'Computed',value: 'computed'},
        { label: 'Parameter',value: 'parameter'},
        { label: 'Manual Parameter',value: 'manual'},
    ],
        nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Name must be less than 36 characters',
      ],
      objectRules:FormRules.objectRules,
      numberRules: FormRules.numberRules,
      operators:EntureStaticLists.operators,
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions1(){
            let op=[]
          
          if(this.workspace1 && this.workspace1.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace1.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions1(){
             
            let op=[]
          
          if(this.dev1 && this.dev1.device_id ){
              let g=[]
              if(this.form.param1_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev1.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.form.param1_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.dev1.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }else if(this.form.param1_type=='manual'){
                  g=this.$store.getters.getManualParametersByDeviceID(this.dev1.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
        deviceOptions2(){
            let op=[]
          
          if(this.workspace2 && this.workspace2.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace2.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions2(){
             
            let op=[]
          
          if(this.dev2 && this.dev2.device_id ){
              let g=[]
              if(this.form.param2_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev2.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.form.param2_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.dev2.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }else if(this.form.param2_type=='manual'){
                  g=this.$store.getters.getManualParametersByDeviceID(this.dev2.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
    },
    methods:{
        onSubmit(){
          // console.log("Form data before submission:", this.form);
          //  console.log(this.api,"submit")
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){                
            this.loading = true;
          console.log(this.api,"api")
      axios
        .post(this.$store.state.api + this.api, this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            // this.$store.commit("addCaculatedParam",response.data.calculated_param);
           this.$store.dispatch('refreshCalculatedParams') 
           
            this.info = "Param added";
          this.showDismissibleAlert = true;
          this.loading = false;
            this.onReset()
          this.$emit('close')
          } else {
            console.error(response.data)
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.v-application .headline{
  color:#fff;
}
.theme--light.v-card {
   background-color: #263238;
}


</style>