<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.data_type"
                        :items="dataTypeOptions"
                        :label="$store.getters.getTextMap().data_type"
                        outlined
                        item-text="label"
                        item-value="value"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
            </v-row>
        </v-card>
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="machine_label"
                        :label="$store.getters.getTextMap().label"
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="workspace"
                        :items="workspaceOptions"
                        :label="$store.getters.getTextMap().workspace"
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                     :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="machine"
                        :items="machineOptions"
                        :label="$store.getters.getTextMap().machine"
                        outlined
                        item-text="label"
                        item-value="value"
                    ></v-select>
                </v-col>
                <v-col v-if="!loading">
                    <v-btn color="gray darken-1" small  @click="addMachineToList">{{$store.getters.getTextMap().add_machine_to_list}}</v-btn>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onMachineReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
                    <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().machines}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in machineTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.machine_id }}</td>
                                            
                                            <td>
                                            <DeleteConfirmation v-on:confirm="deleteFromMachineTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this machine?">
                                           <v-icon small color="red">mdi-delete</v-icon>
                                           </DeleteConfirmation>
                                           
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   
            </v-col>
                </v-row>
            </v-card>
            <v-card :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="tagType_label"
                        :counter="15"
                        :label="$store.getters.getTextMap().label"
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                       :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="tagType"
                        :items="tagTypeOptions"
                        :label="$store.getters.getTextMap().tag_type"
                        item-text="label"
                        item-value="value"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col v-if="!loading">
                    <v-btn color="gray darken-1" small  @click="addTagTypeToList">{{$store.getters.getTextMap().add_tag_type_to_list}}</v-btn>
                    <v-btn style="margin-left:20px;"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="onTagTypeReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" flat>
                        <v-row v-if="!loading && machineTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().tag_type}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.label" v-for="item in tagTypeTableDetails"
                                            >
                                            <td>{{ item.label }}</td>
                                            <td>{{ item.tag_type }}</td>
                                            
                                            <td>
                                                 <DeleteConfirmation v-on:confirm="deleteFromTagTypeTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this machine?">
                                                <v-icon small color="red" >mdi-delete</v-icon>
                                                </DeleteConfirmation>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col> 
            </v-row>
        </v-card>
        <v-card :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
        <v-row>
            <v-col>
                <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                    <v-checkbox v-if="!(form.meta.rows.includes('machine_list') || form.meta.columns.includes('machine_list'))" :dark="$store.getters.getColorPalette().isDark" v-model="selected" value="machine_list" :label="$store.getters.getTextMap().machine_list"></v-checkbox>
                    <v-checkbox v-if="!(form.meta.rows.includes('tag_type') || form.meta.columns.includes('tag_type'))" :dark="$store.getters.getColorPalette().isDark" v-model="selected" value="tag_type" :label="$store.getters.getTextMap().tag_type"></v-checkbox>
                </v-card>
            </v-col>
            <v-divider vertical style="margin:20px;" :color="$store.getters.getColorPalette().accentColor"></v-divider>
            <v-col style="margin-left: 30px;">
                <v-row>
                    <v-col style="margin-top:20px; margin-bottom:20px;">
                        <v-row style=" margin-bottom:20px;">
                           <v-btn @click="addToRow">  {{$store.getters.getTextMap().add_to_row}}</v-btn>
                        </v-row>
                        <v-row>
                            <v-icon @click="addRowBack">
                                mdi-pencil
                            </v-icon>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                            {{$store.getters.getTextMap().rows}}
                            <v-checkbox v-if="form.meta.rows.includes('machine_list')"  :dark="$store.getters.getColorPalette().isDark"  v-model="selectedInRow" value="machine_list" :label="$store.getters.getTextMap().machine_list"></v-checkbox>
                            <v-checkbox v-if="form.meta.rows.includes('tag_type')"  :dark="$store.getters.getColorPalette().isDark"  v-model="selectedInRow" value="tag_type" :label="$store.getters.getTextMap().tag_type"></v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider width="100%" style="margin:20px;" :color="$store.getters.getColorPalette().accentColor"></v-divider>
                <v-row>
                    <v-col style="margin-top:20px; margin-bottom:20px;">
                        <v-row style=" margin-bottom:20px;">
                           <v-btn @click="addToColumn"> Add to Column</v-btn>
                        </v-row>
                        <v-row>
                            <v-icon @click="addColumnBack">
                                mdi-pencil
                            </v-icon>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-card elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
                            COLUMNS
                            <v-checkbox v-if="form.meta.columns.includes('machine_list')"  :dark="$store.getters.getColorPalette().isDark"  v-model="selectedInColumn" value="machine_list" :label="$store.getters.getTextMap().machine_list"></v-checkbox>
                            <v-checkbox v-if="form.meta.columns.includes('tag_type')"  :dark="$store.getters.getColorPalette().isDark"  v-model="selectedInColumn" value="tag_type" :label="$store.getters.getTextMap().tag_type"></v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-row>
            </v-row>
        </v-row>
        </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor"  :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
                </v-row>
            </v-form> 
        </v-container>
    </template>
    <script>
    import InfoAlert from '@/components/InfoAlert'
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    import axios from 'axios'
    export default {
        name:'GeneralSheetForm',
        props: {
        "sheet": {
            type: Object,
            // required: true
        },
        },
        components:{
            InfoAlert,
            DeleteConfirmation
        },
        mounted(){
            if(this.sheet && this.sheet.meta){
                this.machineTableDetails = this.sheet.meta.machine_list
                this.tagTypeTableDetails = this.sheet.meta.tag_types
                this.form.name = this.sheet.name
                this.form.meta.data_type = this.sheet.meta.data_type
                this.form.meta.tag_types = this.sheet.meta.tag_types
                this.form.meta.machine_list = this.sheet.meta.machine_list
                this.form.meta.rows = this.sheet.meta.rows
                this.form.meta.columns = this.sheet.meta.columns
                this.api = 'updateSheets'
                this.form.sheet_id = this.sheet.sheet_id
            }
            this.form.sheet_type = 'general'
        },
        data(){ 
            return {
                 showDialog:false,
                api:'createSheets',
                selected:[],
                selectedInRow:[],
                selectedInColumn:[],
                rowContains:[],
                columnContains:[],
                tagTypeTableDetails:[],
                machineTableDetails:[],
                workspace:null,
                machine:null,
                tagType:null,
                machine_label:null,
                tagType_label:null,
                machineOptions:[],
                generalSheetValid:false,
                showDismissibleAlert:false,
                loading:false,
                info:'',
                dataTypeOptions:[
                {label:'Raw', value:'raw'},
                {label:'Normalised', value:'normalised'},
                {label:'Usage', value:'usage'},
                {label:'Trend', value:'trend'},
                ],
                form:{
                    name:null,
                    sheet_type:'general',
                    meta:{
                        machine_list:{},
                        tag_types:{},
                        data_type:'usage',
                        rows:[],
                        columns:[],
                    }
                },
                objectRules:[
                    v => !!v || 'Required',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
                ],
                numberRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
                nonNegativeRules:[
                    v => !!v || 'Required',
                    v => /[0-9]+$/.test(v) || 'It should be a number',
                    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
                ],
            }
        },
        computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
                }
          return op
        },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        },
        methods:{
            addToRow(){
               this.form.meta.rows = this.selected
               this.selected = []
            },
            addToColumn(){
               this.form.meta.columns = this.selected
               this.selected = []
            },
            addRowBack(){
                for(let i of this.selectedInRow){
                    this.form.meta.rows=this.form.meta.rows.filter(x=>x!=i)
                }
            },
            addColumnBack(){
                for(let i of this.selectedInColumn){
                    this.form.meta.columns=this.form.meta.columns.filter(x=>x!=i)
                }
            },
           close(){
                this.$emit("close")
            },
            addMachineToList(){
                if(!this.machineTableDetails){
                    this.machineTableDetails = []
                }
                this.machineTableDetails.push(
                    {label:this.machine_label,machine_id:this.machine.machine_id}
                )
            },
            addTagTypeToList(){
                if(!this.tagTypeTableDetails){
                    this.tagTypeTableDetails = []
                }
                this.tagTypeTableDetails.push(
                    {label:this.tagType_label,tag_type:this.tagType.tag_type}
                )
            },
            getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                // console.log(payload)
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },

            sheetSubmit(){
                this.$refs.generalSheetForm.validate()
                if(this.generalSheetValid){
                    this.loading=true
                    this.form.meta.machine_list = this.machineTableDetails
                    this.form.meta.tag_types = this.tagTypeTableDetails
                    let d=Object.assign({}, this.form)
                    //d.target=this.emails.replaceAll(' ','').split(',')
                    axios.post(this.$store.state.api+this.api,d,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$emit('update')
                                this.$store.dispatch('refreshSheets')
                                this.$refs.generalSheetForm.reset()
                                this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            onMachineReset(){
            this.workspace=null
            this.machine=null
            this.machine_label=null
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
            onTagTypeReset(){
            this.tagType=null
            this.tagType=null
            this.loading=false
            //this.$emit('close')
        },
            deleteFromMachineTable(item){
            this.machineTableDetails=this.machineTableDetails.filter(x=>x.machine_id!=item.machine_id)
            },
            deleteFromTagTypeTable(item){
            this.tagTypeTableDetails=this.tagTypeTableDetails.filter(x=>x.tag_type!=item.tag_type)
            },
              cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
        },
        watch:{
        workspace(){
            this.getMachineList()
        },
    }
    
    }
    </script>