<template>
    <v-container fluid>
        <v-row>
            <v-col align="right">
                <v-btn
                    :background-color="$store.getters.getColorPalette().background1ColorCode"
                    :color="$store.getters.getColorPalette().accentCode"
                    small 
                    text
                    :dark="$store.getters.getColorPalette().isDark"
                    outlined
                    @click="dialog = !dialog"
                >
                {{$store.getters.getTextMap().add_watcher}}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-eye</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" max-width="290">  
                    <AddTaskWatcher :task="task" v-on:close="onClose" />
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template> 
<script>
import AddTaskWatcher from '@/components/crud_components/application/taskmanagment/AddTaskWatcher'
export default {
    name:'AddTaskWatcherModal',
    props:['task'],
    components:{
        AddTaskWatcher
    },
    data () {
    return {
        dialog: false,
        valid:false,
    }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.emailgrp{
    border:3px solid;
    border-radius:20px;
}
.theme--light.v-card{
    background-color: #263238
}
</style>