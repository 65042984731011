<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().add_day_wise_target }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">

                <v-row dense>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.date" :label="$store.getters.getTextMap().date" prepend-icon="mdi-calendar" readonly
                                        outlined dense v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                                </template>
                                <v-date-picker v-model="form.date" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
     
                   
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                        v-model="form.target_val"
                        :counter="15"
                        :rules="[rules.required, rules.noSpace, rules.num]"
                        :label="$store.getters.getTextMap().target_value"
                        outlined
                        dense
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="!loading">
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{ $store.getters.getTextMap().add  }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
// import moment from 'moment'
export default {
    name:'AddTargetDayWiseData',
    props:{
        target:{
            type:Object,
            required:false
        }
    },
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            workspace:null,
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
        }
    },
    mounted(){
        // if(this.productionPlan){
        //     this.form=Object.assign({},this.productionPlan)
        // }
    },
    computed:{

    },
    methods:{
        getPayload(){
            
            let payload={
                date:this.form.date.toString(),
                target_id:this.target.target_id,
                target_val:this.form.target_val

            }
            return payload

        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                let payload=this.getPayload()
                axios.post(this.$store.state.api+'addTargetDayWiseData',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{
    }
}
</script>