<template>
    <div class="py-5 px-auto">
      <span  :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{label1}} Vs {{label2}}</strong></span>
      <v-btn
              :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
                outlined
                @click="downLoadPDF()">
            Download PDF
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-file-pdf-box
      </v-icon>
        </v-btn> 
      <v-btn
               :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
                outlined
                @click="downloadTable()">
            Download Table
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-download
      </v-icon>
        </v-btn> <a ref="csvDownload" hidden></a>
        <canvas  ref="canvas"></canvas>
    </div>
</template>

<script>
import {Line } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'
import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'
import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'SingleAxisLineGraphByTimestamp',
    extends: Line ,
    props:['label1','label2','graphData1','graphData2','dataKey1','dataKey2','unit','timeUnit'],
    components:{
        
    },
    computed:{
      df(){
        return DataProcessingUtillities.mergeDataByTimeStamp(this.graphData1,this.graphData2,this.dataKey1,this.dataKey2)
      },
        usageData(){
            return this.df.toDict()

        },
        dateLabels(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.usageData.timestamp){
                //console.log(moment(this.$store.state.last7Days[i].date.toString(),'YYYYMMDD').format('dddd'));
                //l.push(moment(Number(i.timestamp)*1000).format('HH:mm'));
                l.push(new Date(Number(i)*1000));
                //console.log(i)
            }
            return l
            //return ['1','2','3','4']
        },
        consumed1(){
                        
            return DataProcessingUtillities.normaliseSeries(this.usageData['data1'])
        },
        consumed2(){
            return DataProcessingUtillities.normaliseSeries(this.usageData['data2'])
        },
        consumed(){
            let arr=[]
            for(let i in this.consumed1){
                arr.push(Number(this.consumed1[i])+Number(this.consumed2[i]))
            }
            return arr
        }
    },
    data(){
        return {
                colorCode:ColorUtility.colorTypesCodeArray,
                 tickfontColor:ColorUtility.fontcolorTypescodes,
        }
    },
    methods: {
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);

                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  pdf.text(40, 40, this.label1+" vs "+this.label2)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.label1+"_"+this.label2+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
                  
      },
      downloadTable(){
        // let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Time (24 HRS)',this.label1,this.label2]
            let body=[]

            for (let i=0;i<this.usageData.timestamp.length;i++){
                let temp=[]
                if(this.timeUnit && this.timeUnit!='day'){
                  temp.push(moment.unix(Number(this.usageData.timestamp[i])).format('HH:mm:ss'))
                }else{
                  temp.push(moment.unix(Number(this.usageData.timestamp[i])).format('MMMM Do YYYY HH:mm'))
                }
                
                    temp.push((Math.round(Number(this.consumed1[i])*100)/100))
                    temp.push((Math.round(Number(this.consumed2[i])*100)/100))
                
                body.push(temp)
            }
            // pdf.autoTable({
            //     head: [header],
            //     body: body
            //     })
            // pdf.save(this.label1+'_vs_'+this.label2+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'table_'+this.label1+"_"+this.label2+'_'+moment().format('YYYYMMDDHHmm')+ '.csv';
            hiddenElement.click();
      },
        createChart(){
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dateLabels,
          datasets: [
            {
                label:this.label1,
               backgroundColor:this.colorCode[0],
               borderColor:this.colorCode[0],
               pointBorderColor: 'rgba(20, 250, 10,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 5,
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed1,
                fill:false,
                lineTension:0.1,
                //yAxisID: "y-axis-1"
            },
            {
                label:this.label2,
               backgroundColor: this.colorCode[1],
               borderColor:this.colorCode[1],
               pointBorderColor: '#FF6D00',
               pointBackgroundColor: '#FF6D00',
                pointRadius:1,
                borderWidth: 3,
                hoverBorderWidth: 5,
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed2,
                fill:false,
                lineTension:0.1,
                //yAxisID: "y-axis-2"
            },
          ]},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{labels:{fontColor:'rgba(165, 165, 165,1)'},display: true},
          scales: {
        xAxes: [ {
          type: 'time',
          distribution: 'linear', //series
          time: {
                    unit: this.timeUnit?this.timeUnit:'minute',
                    displayFormats: {
                        hour: 'hh a'
                    }
                },
          display: true,
           gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
          scaleLabel: {
        fontColor:this.$store.getters.getColorPalette().tickfontColor,
            display: false,
            labelString: 'Date'
          },
          ticks: {
            fontColor:this.$store.getters.getColorPalette().tickfontColor,
            major: {
              fontStyle: 'bold',
              fontColor:this.$store.getters.getColorPalette().tickfontColor,
            },
            source:'auto'
          }
        } ],
        yAxes: [ 
            {
              type: 'linear',
          display: true,
           ticks: {
           fontColor:this.$store.getters.getColorPalette().tickfontColor,
            major: {
              fontStyle: 'bold',
              fontColor: '#FF0000'
            },
            source:'auto'
          },
           gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        
          position:"left",
          
          scaleLabel: {
             fontColor:this.$store.getters.getColorPalette().tickfontColor,
            display: true,
            labelString: this.unit?this.unit:''
          }
        }
         ]
      }})
        }
    },
    mounted() {

        this.createChart()
        
    },
    watch: {
        consumed1:function(){
      
      this.createChart()

        },
        consumed2:function(){
      
      this.createChart()

        }
    },
}
</script>