<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col>
                <TargetsTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TargetsTable from '@/components/lists/derived/TargetsTable'
export default {
    name:'TargetsPage',
    components:{
        TargetsTable
    }
}
</script>