<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <div class="py-0">
            <v-form ref="parameterForm" v-model="valid" >
                <v-card flat :color="$store.getters.getColorPalette(). background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <v-card-title class="headline">{{mode}} {{ $store.getters.getTextMap().energy_usage_report }}</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-text-field
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.name"
                                            :counter="25"
                                            :rules="nameRules"
                                            :label="$store.getters.getTextMap().name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col>
                                        <v-chip
                                            class="ma-2"
                                            close
                                            color="teal"
                                            text-color="white"
                                            @click:close="close(param)" 
                                            v-bind:key="param.parameter" v-for="param in form.energy_list"
                                            >
                                            {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col>
                                    <v-col>    
                                        <v-chip
                                             class="ma-2"
                                             close
                                             color="blue"
                                             text-color="white"
                                             @click:close="close(param)" 
                                             v-bind:key="param.parameter" v-for="param in form.reactive_list"
                                            >                                   
                                            {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col>
                                    <v-col>    
                                        <v-chip
                                             class="ma-2"
                                             close
                                             color="blue"
                                             text-color="white"
                                             @click:close="close(param)" 
                                             v-bind:key="param.parameter" v-for="param in form.max_list"
                                            >                                   
                                            {{$store.getters.getMachineById(param['machine_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="workspace1"
                                            :items="workspaceOptions"
                                            :label="$store.getters.getTextMap().workspace"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                           :dark="$store.getters.getColorPalette().isDark" 
                                           :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="machine1"
                                            :items="machineOptions1"
                                            :label="$store.getters.getTextMap().machines"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col>        
                                </v-row>
                                <v-row>
                                    <!-- <v-col>
                                        <v-select
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="workspace2"
                                            :items="workspaceOptions"
                                            label="Workspace"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                            :menu-props="{dark: true}"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="machine2"
                                            :items="machineOptions2"
                                            label="Machines"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                            :menu-props="{dark: true}"
                                        ></v-select>
                                    </v-col> -->
                                    <v-col>
                                        <v-select
                                          :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.energy"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().energy_parameters_kwh"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="listRules"
                                            clearable
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col> 
                                    <v-col>
                                        <v-select
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.apparent_energy"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().apparent_energy_KVAh"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            
                                            clearable
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col>   
                                    <v-col>
                                        <v-select
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.md"
                                            :items="paramOptions1"
                                            :label="$store.getters.getTextMap().maximum_demand_MD"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            
                                            clearable
                                            small-chips
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-select>
                                    </v-col>    
                                </v-row>
                                <v-row>
                                    <!-- <v-col>
                                        <v-select
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="workspace3"
                                            :items="workspaceOptions"
                                            label="Workspace"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                            :menu-props="{dark: true}"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="machine3"
                                            :items="machineOptions3"
                                            label="Machines"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                            :menu-props="{dark: true}"
                                        ></v-select>
                                    </v-col> --> 
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="form.send_rep" label="Send Excel File" ></v-checkbox>
                                </v-col>
                                <v-col v-if="form.send_rep">
                                    <v-select
                                  :dark="$store.getters.getColorPalette().isDark" 
                                   :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="form.rep_freq"
                                    :items="excelFrequency"
                                    :label="$store.getters.getTextMap().excel_frequency"
                                    dense
                                    filled
                                    item-text="label"
                                    item-value="value"
                                    required
                                    card
                                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                       :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.notification_groups"
                                        :items="notificationGroupList"
                                        :label="$store.getters.getTextMap().notification_groups"
                                        dense
                                        filled
                                        item-text="label"
                                        item-value="value"
                                        multiple
                                        card
                                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.multiplier"
                                        :rules="numberRules"
                                        :label="$store.getters.getTextMap().multiplier"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor"  @click="onSubmit">
                                {{mode}}
                            </v-btn>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor"  @click="onReset">
                             {{   $store.getters.getTextMap().cancel}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateParameterSECReport',
    props:['report'],
    components:{
        InfoAlert
    },
    mounted(){
        //console.log(this.report.report_id)
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            
            this.form['multiplier']=Number(this.form['multiplier'])
            /*for(let i of this.form.list){
                let tmp=this.$store.getters.getParameterById(i)
                if(tmp){
                    list.push(tmp)
                }
                
            }
            this.form['list']=list*/
            this.api='updateEnergyUsageReport'
            this.mode='Edit'
        }else{
            this.api='createEnergyUsageReport'
        }
    },
     data(){
        return {
            api:'createEnergyUsageReport',
            mode:this.$store.getters.getTextMap().create,
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            selected:'',
            sendExcel:false,
            showExcelFreq:false,
            showDismissibleAlert:false,
            machine1:null,
            workspace1:null,
            machine2:null,
            workspace2:null,
            machine3:null,
            workspace3:null,
            energy_list:[],
            machineOptions1:[],
            machineOptions2:[],
            machineOptions3:[],
            paramOptions1:[],
            paramOptions2:[],
            paramOptions3:[],
            excelFrequency : [
            {label:this.$store.getters.getTextMap().daily,value:'day'},
            {label:this.$store.getters.getTextMap().weekly,value:'week'},
            {label:this.$store.getters.getTextMap().monthly,value:'month'},
            ],
            frequency:[
                {label:this.$store.getters.getTextMap().monthly,value:'monthly'},
                {label:this.$store.getters.getTextMap().daily,value:'daily'}                
            ],
            processing:[
              {label:this.$store.getters.getTextMap().average,value:'mean'},
                {label:this.$store.getters.getTextMap().addition,value:'sum'},
                {label:this.$store.getters.getTextMap().single, value:'single'}
            ],
            type:[
              {label:this.$store.getters.getTextMap().average,value:'mean'},
                {label:this.$store.getters.getTextMap().addition,value:'sum'}
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      form:{
                name:null,
                type:null,
                energy:null,
                apparent_energy:null,
                md:null,
                emails:[],
                multiplier:0
            }
        }
    },
    computed:{
        notificationGroupList(){
            let op=[]
            if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
                for(let i of this.$store.state.notificationGroups){
                    op.push({
                        label:i.name,
                        value:i.group_id
                    })
                }
            }
            return op
        },
      workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
    },
    methods:{
        showExcelFreqOption(){
        this.sendExcel = ! this.sendExcel
        this.showExcelFreq = ! this.showExcelFreq
      },
        getMachineList1(){
            console.log(this.workspace1)
            let op=[]
            if(this.workspace1){
                let payload={
                    workspace_id:this.workspace1.workspace_id
                }
                // console.log(payload,'payload')
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    //console.log(response.data);
                    let machines=response.data.machines
                    
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions1=op
        },
        getMachineList2(){
            let op=[]
            if(this.workspace2){
                let payload={
                    workspace_id:this.workspace2.workspace_id
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    // console.log(response.data,'response 2');
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions2=op
        },
        getMachineList3(){
            let op=[]
            if(this.workspace3){
                let payload={
                    workspace_id:this.workspace3.workspace_id
                }
                // console.log(payload)
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt 
            }})
                .then(response => {
                if (response.data.status == "success") {
                    // console.log(response.data,'response 3');
                    // console.log(response.data,'response.data3')
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions3=op
        },
        getParams1(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine1 ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine1},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions1=[]
                            for(let i of response.data.params){
                                this.paramOptions1.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParams2(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine2 ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine2},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions2=[]
                            for(let i of response.data.params){
                                this.paramOptions2.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParams3(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine3 ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine3},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions3=[]
                            for(let i of response.data.params){
                                this.paramOptions3.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
      close(param){
        //console.log(param)
        this.form.list=this.form.list.filter(
      function(data){ return data.parameter != param.parameter }
  );
      },
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
//console.log(d)
            this.form.send_excel=this.sendExcel
            // console.log(this.form.energy_list,'this.form.energy_list')
            // let list=[]
            // for(let i of this.form.energy_list){
            //   list.push(i)
            // }
            // d['energy_list']=list
            // // console.log(d.energy_list,'d.energy_list')
            // list=[]
            // // console.log(this.form.apparant_list,'this.form.apparant_list')
            // for(let i of this.form.apparent_list){
            //   list.push(i)
            // }
            // d['apparent_list']=list
            // // console.log(d.apparant_list,'d.apparant_list')
            // list=[]
            // // console.log(this.form.max_list,'this.form.max_list')
            // for(let i of this.form.max_list){
            //   list.push(i)
            // }
            // d['max_list']=list
            // // console.log(d.max_list,'d.max_list')
            console.log(d,'form')
            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshEnergyUsageReports')
        console.log("Report added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      console.log(err)
      });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.sendExcel
            this.showExcelFreq
            this.$emit('close')
        }

    },
    watch:{
        workspace1(){
            this.getMachineList1()
            
        },
        workspace2(){
            this.getMachineList2()
            
        },
        workspace3(){
            this.getMachineList3()
            
        },
        machine1(){
            this.getParams1()
        },
        machine2(){
            this.getParams2()
        },
        machine3(){
            this.getParams3()
        },
        selected(){
            this.showExcelFreqOption()
        }
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>