<template>
    <v-container fluid>
<InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row no-gutters>
            <v-col align-self="center">
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().energy_usage_trend }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="right" align-self="center" >
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>  
          <v-col  v-if="formType==='deviceForm'">
                <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
                </v-col> 
                <v-col v-else>
              <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
        </v-col>     
            <!-- <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
               :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.workspace"
          :items="workspaceOptions"
          label="Workspace"
          outlined          
          item-text="label"
          item-value="value"
          required
            :rules="objectRules" 
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          label="Device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.parameter"
          :items="paramOptions"
          label="Device Parameter"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col> -->
        </v-row>
        
        <v-row>
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
               :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
        
            <v-col v-if="!loading" align-self="center">
                <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            small
            
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().generate_report }}
          </v-btn>
          
            </v-col>
            <v-col v-if="!loading && graphData.length>0" align-self="center">
              <v-select  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="processing"
          :items="processingOptions"
          :label="$store.getters.getTextMap().data"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col v-if="!loading && graphData.length>0" align-self="center">
              
                <v-btn
          :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            outlined
            @click="downloadPDF"
          >
          {{ $store.getters.getTextMap().download_table }}
          </v-btn>
          
            </v-col>
            
        </v-row>
        </v-form>
        <v-btn-toggle
        v-if="graphData.length>0"
          v-model="graphType"
          mandatory
        >
          <v-btn small>
            Bar
          </v-btn>
          <v-btn small>
            Line
          </v-btn>
              </v-btn-toggle>
            
        <v-row v-if="graphData.length>0">
            <v-col v-if="graphType==0">
                <BarGraphByDate :label="label" :graphData="graphData" :dataKey="processing" />
            </v-col>
            <v-col v-if="graphType==1">
                <LineGraphByDate :label="label" :graphData="graphData" :dataKey="processing" />
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import BarGraphByDate from '@/components/graphs/BarGraphByDate'
import LineGraphByDate from '@/components/graphs/LineGraphByDate'
// import jsPDF from 'jspdf'
import moment from 'moment'
import axios from 'axios'
// import 'jspdf-autotable'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm.vue'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
// import { applyPlugin } from 'jspdf-autotable'
// applyPlugin(jsPDF)

export default {
    name:'ParameterUsageGraphByDays',
    components:{
        InfoAlert,
        BarGraphByDate,
        LineGraphByDate,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm
    },
    data(){
        return {
            form:{
                date:null,
                device:null,
                parameter:null
            },
            formType:'machineForm',
            //date: new Date().toISOString().substr(0, 10),
            report:{
                date:null,
                parameter:null,
                
                },
                valid:false,
                processing:'usage',
            graphData:[],
            graphType:0,
            menu1:null,
            menu2:null,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            objectRules:[
                v => !!v || this.$store.getters.getTextMap().required,
            ],
            ISODateRules:[
            v => !!v || this.$store.getters.getTextMap().required,
            v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_date,
            ]
        }
    },
    computed:{
        label(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            if(parameter.unit){
              if(this.report.device && this.report.device.name){
                return this.report.device.name+'_'+parameter.name + " ( "+parameter.unit+" )"
              }
              return parameter.name + " ( "+parameter.unit+" )"
            }else{
              if(this.report.device && this.report.device.name){
                return this.report.device.name+'_'+parameter.name
              }
              return parameter.name
            }
          }else{
            return ''
          }
        },
        unit(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            if(parameter.unit){
              return parameter.unit
            }else{
              return ''
            }
          }else{
            return ''
          }
        },
        processingOptions(){
            if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            if(parameter.processing && parameter.processing=='mean'){
              return [
                {label:this.$store.getters.getTextMap().usage,value:'usage'},
                {label:this.$store.getters.getTextMap().standard_deviation, value:'std'},
                {label:this.$store.getters.getTextMap().statistical_mode,value:'mode'},
                {label: this.$store.getters.getTextMap().peak_value, value:'max'},
                {label: this.$store.getters.getTextMap().min_value, value:'min'}
                ]
            }
          }
            return [{label:this.$store.getters.getTextMap().usage,value:'usage'}]
          
        },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  if(i.processing=='difference'){
                  if(i && i.name){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              }
          }
          }
          return op
        },
    },

    methods: {
      updateParamList(value){
          this.form.parameter=value.parameter['param_id']
          this.form.device=value.device
          },
        downloadPDF(){

          //let pdf = new jsPDF('landscape','pt',[512,600]);
            // let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Date',this.report.label]
            let body=[]
            for (let i of this.graphData){
                let temp=[]
                
                temp.push(moment(i.date.toString(),'YYYYMMDD').format("DD-MM-YYYY"))
                temp.push((Math.round(Number(i['usage'])*100)/100))
                
                body.push(temp)
            }
            // pdf.autoTable({
            //     head: [header],
            //     body: body
            //     })
            let param=this.$store.getters.getParameterById(this.report.parameter)
            // pdf.save(this.report.device.name+'_'+param.name+'_'+moment().format('YYYYMMDD')+'.pdf');

            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.report.device.name+'_'+param.name+'_'+'table'+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();

      },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.from_date=moment(d.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
            d.to_date=moment(d.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
            axios.post(this.$store.state.api+'getParameterUsageDataBetweenDate',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
        this.graphData=response.data.data
        this.report=d
        this.report.processing=this.processing
        this.report.label=this.label
        this.report.device=this.form.device
        //this.onReset()
        this.dialog=false
        this.loading=false
        //this.dialog=false
        //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.usageForm.reset()
            this.dialog=false
            this.loading=false
            //this.$emit('close')
        }

    },
}
</script>
<style >
.text-subtitle-1{
  color:#C5CAE9
}
</style>