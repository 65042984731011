<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
            ref="paramForm"
            v-model="valid"           
        >
         <v-card :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title >
          {{$store.getters.getTextMap().create_combo_param}}
        </v-card-title>
        <v-card-text>
        <v-row>
            <v-col><v-text-field :dark="$store.getters.getColorPalette().isDark" 
               :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.name"
      :counter="25"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field></v-col>
    <v-col>
        <v-select :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.operator"
                            :items="interOperators"
                            :label="$store.getters.getTextMap().inter_param_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
    </v-col>
    <v-col>
        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.correction_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().correction_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
    </v-col>
    <v-col>
        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.correction_operand"
      :counter="10"
      :rules="numberRules"
      :label="$store.getters.getTextMap().correction_operand"
      required
    ></v-text-field>
    </v-col>
        </v-row>
        <v-row>
            <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                 :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                 :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="dev"
                            :items="deviceOptions"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="param_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.params"
                            :items="paramOptions"
                            :label="$store.getters.getTextMap().params"
                            dense
                            filled
                            multiple
                            item-text="label"
                            item-value="value"
                            required
                            small-chips
                            :rules="listRules"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
        </v-row>
        <v-row >
            <v-col><v-text-field :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
      v-model="form.unit"
      :counter="8"
      
      :label="$store.getters.getTextMap().unit"
      
    ></v-text-field></v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                      
                        @click="onSubmit"
                    >
                       {{$store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col cols="2" align="right">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{$store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
          </v-card-text>
          </v-card>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateComboParam',
    props:['comboParam'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.comboParam){
            let d=Object.assign({},this.comboParam)
            this.mode='edit'
            this.initFlag=true
            this.param_type=d.param_type
            this.form=d
            
        }
    },
    data(){
        return {
            valid:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            workspace:null,
            dev:null,
            param_type:null,
            initFlag:false,
            mode:'create',
            form:{
                name:null,
                param_type:null,
                params:null,
                operator:null,
                correction_operator:null,
                correction_operand:null,
            },
            interOperators:[
                {label:'SUM',value:'+'},
                {label:'PRODUCT',value:'*'}
            ],
            operators:EntureStaticLists.operators,
            param_types:EntureStaticLists.paramTypes,
            objectRules:FormRules.objectRules,
            nameRules: FormRules.nameRules,
            numberRules: FormRules.numberRules,
            nonNegativeRules:FormRules.nonNegativeIntegerRules,
            listRules:FormRules.listRules,
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.dev && this.dev.device_id ){
              let g=[]
              if(this.param_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.param_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.dev.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.paramForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.param_type=this.param_type
            let d=Object.assign({}, this.form)
            let api='addComboParam'
            if(this.mode && this.mode=='edit'){
              api='updateComboParam'
            }
            axios.post(this.$store.state.api+api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$store.dispatch('refreshComboParams')
          this.loading=false
          this.close()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }
        },
        close(){
            this.$refs.paramForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    },
    watch:{
        param_type:  function (){
         if(this.initFlag){
           this.initFlag=false
         }else{
           this.form.params=[]
         }
                
            

        },
    }
}
</script>
