<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="12">
                <v-card class="px-2 my-2" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row>
                        <v-col cols="3">
                            <v-select
                                :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-if="!loading"
                                v-model="selectedDateRange"
                                :items="dateRange"
                                :label="$store.getters.getTextMap().date_range"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                                required
                                :rules="objectRules"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            ></v-select>
                        </v-col>
                        <v-col v-if="selectedDateRange==='custom'" cols="3" align="center" align-self="center">
                            <v-menu  
                                :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field  
                                        :dark="$store.getters.getColorPalette().isDark"
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-model="form.from_date"
                                        :label="$store.getters.getTextMap().from_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        clearable
                                        :loading="loading"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="ISODateRules"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.from_date" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col v-if="selectedDateRange==='custom'" cols="3" align="center" align-self="center">
                            <v-menu  
                                :dark="$store.getters.getColorPalette().isDark"
                                color="cyan lighten-1"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field  
                                        :dark="$store.getters.getColorPalette().isDark"
                                        color="cyan lighten-1"
                                        v-model="form.to_date"
                                        :label="$store.getters.getTextMap().to_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        dense
                                        outlined
                                        clearable
                                        :loading="loading"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.to_date" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn @click="getData"  outlined :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading">
                                {{ $store.getters.getTextMap().run_report }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col>
                            <v-card flat :dark="$store.getters.getColorPalette().isDark" >
                                <!-- {{tableData}} -->
                                <v-data-table
                                    :headers="headers"
                                    :items="tableData"
                                    item-key="timestamp"
                                    :loading="loading"
                                    :dark="$store.getters.getColorPalette().isDark"
                                    loading-text="Loading... Please wait"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <!-- <v-toolbar-title>From "{{formattedFromDate}}" To "{{ formattedToDate }}"</v-toolbar-title> -->
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-spacer></v-spacer>
                                            <v-icon @click="getData">mdi-refresh</v-icon>
                                        </v-toolbar>
                                    </template>
                                 </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment';
import axios from 'axios'
export default {
    name:'UsageByMachineBottomPanel',
    components:{
        InfoAlert,
    },
    data(){
        return{
            tagTypeMap:{
    'pf':'mean',
    'active_energy':'usage',
    'running_hours':'usage',
    'active_power':'mean'
},
            showDismissibleAlert:false,
            loading:false,
            info:'',
            ShowUsageByMachinePanel:false,
            form:{},
            selectedDateRange:null,
            tableData:[],
            machineUsageData:[],
            headers:[
                {text: 'Machine Name', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                {text:'Active Energy', value:'active_energy',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName},
                {text:'Running Hours', value:'running_hours',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName},
                { text: 'Power Factor', value: 'pf', class: "text-subtitle-1 text-capitalize " + this.$store.getters.getColorPalette().tableHeaderColorName }, 
            ],
            dateRange:[
                {label:this.$store.getters.getTextMap().this_year,value:'year'},
                {label:this.$store.getters.getTextMap().last_6_months,value:'half-year'},
                {label:this.$store.getters.getTextMap().last_quater,value:'quater'},
                {label:this.$store.getters.getTextMap().mtd,value:'mtd'},
                {label:this.$store.getters.getTextMap().yesterday,value:'yesterday'},
                {label:this.$store.getters.getTextMap().custom,value:'custom'},
            ],
            valid:false,
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            objectRules:[
                v => !!v || this.$store.getters.getTextMap().required ,
            ],
            ISODateRules:[
                v => !!v || this.$store.getters.getTextMap().required ,
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_date,
            ],
            
        }
    },
    mounted(){
        this.selectedDateRange = 'yesterday'
        this.getData()
    },
    computed:{
        formattedFromDate() {
            if (this.form.from_date) {
                return moment(this.form.from_date, 'YYYYMMDD').format('DD-MM-YYYY');
            }
            return '';
        },
        formattedToDate() {
            if (this.form.to_date) {
                return moment(this.form.to_date, 'YYYYMMDD').format('DD-MM-YYYY');
            }
            return '';
        },
        fetchDate(){
            let fromDate = null
            let toDate = null
            switch(this.selectedDateRange) {
            case 'year':
                fromDate = moment().startOf('year').format('YYYYMMDD');
                toDate = moment().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'half-year':
                fromDate = moment().subtract(6, 'months').startOf('month').format('YYYYMMDD');
                toDate = moment().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'quater':
                fromDate = (moment().subtract(1, 'quarter').startOf('quarter')).format('YYYYMMDD');
                toDate = (moment().subtract(1, 'quarter').endOf('quarter')).format('YYYYMMDD');
                break;
            case 'mtd':
                fromDate = moment().startOf('month').format('YYYYMMDD');
                toDate = moment().subtract(1, 'day').format('YYYYMMDD');
                break;
            case 'yesterday':
                fromDate = moment().subtract(1, 'day').format('YYYYMMDD');
                toDate = moment().subtract(1, 'day').format('YYYYMMDD');
                break;
            default:
                fromDate = moment().subtract(1, 'day').format('YYYYMMDD');
                toDate = moment().subtract(1, 'day').format('YYYYMMDD');
        }
        return {'from':fromDate,'to':toDate}
        },
    },
    methods:{
        getMachineTableData(){
            this.tableData=[]
            let temp=this.machineUsageData.reduce((prev,current)=>{
                if(!(current.machine_name in prev)){
                    prev[current.machine_name]={
                        machine_name:current.machine_name
                    }
                }
                // console.log(current.tag_type);
            prev[current.machine_name][current.tag_type] = current[this.tagTypeMap[current.tag_type]];
            // prev[current.machine_name][current.tag_type] = current['usage']
                 
                return prev
            },{})
            // console.log('Table Data:', Object.values(temp));
            this.tableData=Object.values(temp)
        },
        getData(){
            let d=Object.assign({}, this.form)
            if(this.selectedDateRange!=='custom'){
                let date = this.fetchDate
                d.from_date = date['from']
                d.to_date = date['to']
            }else{
                d.from_date=moment(d.from_date,'YYYY-MM-DD').format("YYYYMMDD")
                d.to_date=moment(d.to_date,'YYYY-MM-DD').format("YYYYMMDD")
            }
            d['tag_types']=["active_energy", "running_hours","pf"]
            axios.post(this.$store.state.api + "getUsageByMachines",d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                // console.log(response);
                if (response.data.status == "success") {
                    // console.log(response.data.data,"Response");
                    this.machineUsageData=response.data.data  
                    this.getMachineTableData()
                }
                else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
            })
            .catch(error=>{
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            })
        },
    }
}
</script>