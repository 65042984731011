<template>
        <v-container fluid>
<InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-dialog
      v-model="emailDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$store.getters.getTextMap().send_email_notification}}

        </v-card-title>
        <v-card-text>{{$store.getters.getTextMap().enture_send_email_notification}}
Let's Enture send email Notification to the selected user group.
            <v-form
            ref="notificationForm"
    v-model="notificationValid">
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-select
    v-if="!loading"
          v-model="notification.group_id"
          :items="notificationGroupOptions"       
          :label="$store.getters.getTextMap().value"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>

            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="emailDialog = false"
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="testSend"
          >
          {{$store.getters.getTextMap().agree_and_send}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<v-row>
            <v-col>
                <span class="text-caption">{{$store.getters.getTextMap().ebill}}
 - {{$store.getters.getTextMap().monthly_trend}}
</span>
            </v-col>
        </v-row>
        <v-form ref="form" v-model="valid">
    <v-row>
        
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-select
    v-if="!loading"
          v-model="form.by"
          :items="dateParamOptions"       
          :label="$store.getters.getTextMap().by"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>
        </v-col>
        <v-col align-self="center" align="center">
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        <v-checkbox
          v-if="!loading"
      v-model="dateRange"
      :label="$store.getters.getTextMap().date_range"
    ></v-checkbox>
    </v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        <v-col align-self="center">
            <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().generate_trend}}

          </v-btn>
        </v-col>
    </v-row>
   <v-row>
        <v-col v-if="dateRange">
            <v-select
    v-if="!loading"
          v-model="form.dateParam"
          :items="dateParamOptions"       
          :label="$store.getters.getTextMap().value"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>
        </v-col>
        <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    </v-row>
    
    <v-row v-if="graphData && graphData.length>0">
        <v-col>
            <v-select
    v-if="!loading"
          v-model="report.valueParam"
          :items="valueParamOptions"       
          :label="$store.getters.getTextMap().value"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>
        </v-col>
    </v-row>
    <v-row v-if="graphData && graphData.length>0">
        <v-col cols="12" align-self="center">
            
            <BarGraphByLabel v-on:canvasData="emailSend" :graphData="graphData" :label="report.valueParam" :dataKey="report.valueParam" :labelKey="report.by" :lineValue1="report.lower" lineLabel1="lower limit" :lineValue2="report.upper" lineLabel2="Upper limit"  />
            
        </v-col>
    </v-row>
        </v-form>
        <v-divider></v-divider>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import BarGraphByLabel from '@/components/graphs/BarGraphWithLineByLabel'
import axios from 'axios'
import moment from 'moment'
export default {
    name:'EbillMonthlyConsumptionPanel',
    components:{
        InfoAlert,
        BarGraphByLabel
    },
    mounted(){},
    data(){
        return {
            valid:false,
            notificationValid:false,
            emailDialog:false,
            imgData:null,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            graphData:[],
            dateRange:false,
            menu1:null,
            menu2:null,
            notification:{},
            form:{
                by:'bill_receive_date',
                valueParam:'total_unit'
            },
            report:{
                valueParam:'total_unit'
            },
            valueParamOptions:[
                //{label:'Billed Unit',value:'billed_unit'},
                {label:'Total Unit',value:'total_unit'},
                {label:'Meter Consumption Unit (KWH)',value:'meter_consumption_unit_kwh'},
                {label:'Bill Amount',value:'bill_amount'},
            ],
            byOptions:[
                {label:'Regions',value:'region'},
                {label:'Board Name',value:'board_name'},
            ],
            dateParamOptions:[
                //{label:'Billed Unit',value:'billed_unit'},
                {label:'Bill Due Date',value:'bill_due_date'},
                {label:'Bill Issue Date',value:'bill_issue_date'},
                {label:'Bill Receive Date',value:'bill_receive_date'},
            ],
            objectRules:[
                (v)=>!!v || 'required'
            ]
        }
    },
    computed:{
        notificationGroupOptions(){
            let op=[]
            if(this.$store.state.notificationGroups){
                for(let i of this.$store.state.notificationGroups){
                    op.push({
                        label:i.name, value:i.group_id
                    })
                }
            }
            
            return op
        }
    },
    methods:{
        emailSend(imgData){
            this.imageData=imgData
            this.emailDialog=true
        },
        testSend(){
        this.loading = true;
        this.$refs.notificationForm
        if(this.notificationValid){
            this.emailDialog = false
            let formData = new FormData();
            formData.append('imageData',this.imageData.imageData)
            formData.append('mailContent',this.report.valueParam+" by "+this.report.by)
            formData.append('subject',this.report.valueParam+" by "+this.report.by)
            formData.append('group_id',this.notification.group_id)
      axios.post(this.$store.state.api + "testEmail", formData,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt,
      'Content-Type': 'multipart/form-data'
    }})
        .then(response => {
          if (response.data.status == "success" && response.data.msg) {
            this.info = "email Sent";
          this.showDismissibleAlert = true;
          this.loading = false;
            //this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          //this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
        }
      },
        updateUpperAndLower(){
            if(this.graphData.length>1){
                        this.report.lower=0
                        this.report.upper=0
                        let avg=0
                        let i=0
                        for (i=0;i<this.graphData.length-1;i++){
                            avg+=Number(this.graphData[i][this.report.valueParam])
                        }
                        if(i>0){
                            avg/=i
                        }
                        this.report.lower=avg*0.8
                        this.report.upper=avg*1.2
                    }
        },
        onSubmit(){
            this.$refs.form.validate()
            if(this.valid){

                this.loading=true
                //let fromTimeStamp=this.fromTimeStamp
                //let toTimeStamp=this.toTimeStamp
                let payload={
                    by:this.form.by,
                    //valueParam:this.form.valueParam,
                    }
                let endpoint='ebillMonthlyConsumption'
                //console.log(fromTimeStamp)
                if(this.dateRange){
                    payload['to_date']=this.form.to_date
                    payload['from_date']=this.form.from_date
                    payload['dateParam']=this.form.dateParam
                }
                axios.post(this.$store.state.api+endpoint,payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                //console.log("report data received")
                //console.log(response.data)
                if(response.data.status==='success'){
                    this.report=Object.assign({},this.form)
                    this.graphData=response.data.data
                    
                    this.graphData.sort((a,b)=>{return moment(a[this.report.by],'MMM YYYY').unix()-moment(b[this.report.by],'MMM YYYY').unix()})
                    this.updateUpperAndLower()
                }else{
                    this.info = response.data.msg;
                //this.info = d;
                        this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                //this.loading=false
                }
                this.loading=false
                }).catch(err=>{
                    this.showDismissibleAlert=true
                    this.info=err
                    this.loading=false
                })
            }
        },
    },
    watch:{
        report:{
            deep:true,
            handler(){
                this.updateUpperAndLower()
            }
        }
    }
}
</script>