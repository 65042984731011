<template>
    <v-container fluid>
            
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
            
        <v-form
        ref="addForm"
        v-model="valid">
        <v-row>
          <v-col>
            <v-text-field
            dense
            outlined
                        v-if="!loading"
                        v-model="form.name"
                        counter="30"
                        :label="$store.getters.getTextMap().name" 
                        
                        
                    ></v-text-field>
          </v-col>
           
            <v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
     :dark="$store.getters.getColorPalette().isDark" 
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.from_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark" 
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          dense
          outlined
            v-model="form.from_time"
            :label="$store.getters.getTextMap().from_time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
           :dark="$store.getters.getColorPalette().isDark" 
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu1"
          v-model="form.from_time"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.from_time)"
         :dark="$store.getters.getColorPalette().isDark" 
        ></v-time-picker>
      </v-menu>
            </v-col>

            <v-col align-self="center">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu  :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.to_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  
          dense
          outlined
          :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_time"
            :label="$store.getters.getTextMap().to"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-time-picker  :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu2"
          v-model="form.to_time"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(form.to_time)"
        ></v-time-picker>
      </v-menu>
            </v-col>
            <v-col cols="auto" class="d-flex" align="right" align-self=center>
                <v-btn
              v-if="!loading"
              :color="$store.getters.getColorPalette().submitbtnColor"
                text
                @click="onSubmit"
              >
              {{ $store.getters.getTextMap().add}}
              </v-btn>
            </v-col>
        </v-row>
        </v-form>
    </v-container>
    </template>
    <script>
    import axios from 'axios';
    import InfoAlert from '@/components/InfoAlert'
    import FormRules from '@/utillities/FormRules'
    export default {
        name:'AddShiftData',
        props:['shift'],
        components:{
            InfoAlert,
            
        },
        data(){
            return {
                form:{
                    
                },
                    loading:false,
                    info:null,
                    showDismissibleAlert:false,
                    valid:false,
                    menu1:null,
                    menu2:null,
                    rules:FormRules.rules
            }
        },
        methods:{
            onSubmit(){
                //evt.preventDefault();
                this.$refs.addForm.validate()
                if(this.valid){
                this.loading = true;
                this.form.shift_id=this.shift.shift_id
          axios.post(this.$store.state.api + "addShiftData", this.form,{headers: {
          Authorization: 'Bearer '+ this.$store.state.jwt
        }})
            .then(response => {
              if (response.data.status == "success") {
                //this.$store.commit("addListData",response.data.tuple);  
                this.$store.dispatch("refreshShiftData")         
               
                this.info = "Data added";
              this.showDismissibleAlert = true;
              this.loading = false;
                this.onReset()
                this.$emit('close')
              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
              }
              //this.onReset()
              this.loading = false;
            })
            .catch(error => {
              this.onReset()
              this.info = error;
              this.showDismissibleAlert = true;
              this.loading = false;
            });
                }
            },
            onReset(){
                this.$refs.addForm.reset()
                this.$emit('close')
                
                
            }
        }
    
    }
    </script>