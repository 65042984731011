<template>

<v-container fluid>
<InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-row>
            <v-col >
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().historical_time_of_day_comparison_parameter }}</span>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
            
           
        
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          :label="$store.getters.getTextMap().device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.parameter"
          :items="paramOptions"
          :label="$store.getters.getTextMap().device_parameter"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
        </v-row>
        
        <v-row>
          
          <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        v-model="dateMenu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.date1"
            :label="$store.getters.getTextMap().date_1"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.date1"
          @input="dateMenu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
          <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        v-model="dateMenu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.date2"
            :label="$store.getters.getTextMap().date_2"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.date2"
          @input="dateMenu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
         {{ $store.getters.getTextMap().from }}
              <v-col align-self="center">
              <TimePicker v-model="fromTime"/>
            </v-col>
            {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 
<!-- <v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="fromTime"
            label="From"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu3"
          v-model="fromTime"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(fromTime)"
        ></v-time-picker>
      </v-menu>
            </v-col>

            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="toTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="toTime"
            label="To"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-if="menu2"
          v-model="toTime"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(toTime)"
        ></v-time-picker>
      </v-menu>
            </v-col> -->
            

            <v-col align-self="center">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            
            small
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().generate_report }}
          </v-btn>
          
            </v-col>
            
        </v-row>
        </v-form>
        
            
        <v-row v-if="graphData1.length>0 || graphData2.length>0">
            <v-col>
                <strong class=" white--text"> {{ $store.getters.getTextMap().tod_comparison_report }} - {{label}}</strong>
            </v-col>
            
        </v-row>
        <v-row v-if="graphData1.length>0 || graphData2.length>0">
            <v-col>
                 <SingleAxisLineGraphByTimestamp  :unit="unit" :timeUnit="timeUnit" :label1="form.date1" :label2="form.date2" :graphData1="graphData1" dataKey1="data" :graphData2="graphData2" dataKey2="data"  />
            </v-col>
        </v-row>
        
        <v-divider></v-divider>
        

</v-container>
    
</template>

<script>
//import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp';
import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
import TimePicker from '@/components/inputs/TimePicker'
applyPlugin(jsPDF)
export default {
    name:'ParameterHistoricalTODComparison',
    data(){
        return {
            form:{
                date1:null,
                date2:null,
                device:null,
                parameter:null
            },
            //date: new Date().toISOString().substr(0, 10),
            report:{
                date:null,
                parameter:null,
                
                },
                normalised:true,
                valid:false,
                dateMenu1:false,
                dateMenu2:false,
                menu:false,
                menu2:false,
                menu3:false,
            graphData1:[],
            graphData2:[],
            toTime:null,
            fromTime:null,
            showDismissibleAlert:false,
            loading:false,
            info:'',
             objectRules:[
        v => !!v || this.$store.getters.getTextMap().required,
      ],

        }
    },
    mounted(){

    },
    components:{
        SingleAxisLineGraphByTimestamp,
        InfoAlert,
        TimePicker
    },
    computed: {
      api(){
        return 'getNormalisedDataByParameterBetweenTimestamp'
        /*if(this.normalised){
          return 'getNormalisedDataByParameterBetweenTimestamp'
        }else{
          return 'getDataByParameterBetweenTimestamp'
        }*/
      },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
      fromTimeStamp1(){
        if(this.form.date1){
          
            let dt=this.form.date1.toString()
            if(this.fromTime){
              dt+=' '+this.fromTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp1(){
        if(this.form.date1){
          
            let dt=this.form.date1.toString()
            if(this.toTime){
              dt+=' '+this.toTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      fromTimeStamp2(){
        if(this.form.date2){
          
            let dt=this.form.date2.toString()
            if(this.fromTime){
              dt+=' '+this.fromTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp2(){
        if(this.form.date2){
          
            let dt=this.form.date2.toString()
            if(this.toTime){
              dt+=' '+this.toTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      
      label(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            if(parameter.name){
              return parameter.name
            }else{
              return this.report.parameter
            }
          }else{
            return ''
          }
        },
        parameter(){
            if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            return parameter
            }else{

                return null
            }
        },
        unit(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParameterById(this.report.parameter)
            if(parameter.unit){
              return parameter.unit
            }else{
              return null
            }
          }else{
            return ''
          }
        },
        timeUnit(){
          if( this.toTimeStamp1 && this.fromTimeStamp1 && (Number(this.toTimeStamp1) - Number(this.fromTimeStamp1))>(60*60*2)){
            return 'hour'
          }
          return 'minute'
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.snode_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    if(i.disabled!=true){
                      op.push({value:i.parameter, label: i.name })
                    }
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
        
        
    },
    methods: {
      
      onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  parameter:this.form.parameter,
      from_timestamp:this.fromTimeStamp1,
      to_timestamp:this.toTimeStamp1
    }
    
    //console.log(fromTimeStamp)
    axios.post(this.$store.state.api+this.api,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("Param data1 received")
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
let data=[]
for (let i of response.data.data){
  let d=Object.assign({}, i)
  d['timestamp']=moment(moment(Number(d['timestamp'])*1000).format('HH:mm:ss'),'HH:mm:ss').format('X')
    data.push(d)
  
}
this.graphData1=data
      //console.log(response.data.data)
      this.report['parameter']=this.form.parameter
      this.report['date1']=this.form.date1
      this.report['date2']=this.form.date2
      if(this.graphData1.length==0){
          this.info = "No data to show for date 1";
            this.showDismissibleAlert = true;
      }
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

    payload={
                  parameter:this.form.parameter,
      from_timestamp:this.fromTimeStamp2,
      to_timestamp:this.toTimeStamp2
    }
    axios.post(this.$store.state.api+this.api,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("Param data 2 received")
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
let data=[]
for (let i of response.data.data){
  let d=Object.assign({}, i)
  d['timestamp']=moment(moment(Number(d['timestamp'])*1000).format('HH:mm:ss'),'HH:mm:ss').format('X')
    data.push(d)
  
}
this.graphData2=data
      //console.log(response.data.data)
      this.report['parameter']=this.form.parameter
      this.report['date1']=this.form.date1
      this.report['date2']=this.form.date2
      if(this.graphData2.length==0){
          this.info = "No data to show for date 2";
            this.showDismissibleAlert = true;
      }
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })
    
    
    
            }
        }
    
    },

}
</script>