<template>
    <v-container>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-col align="right">
                <v-icon small :color="$store.getters.getColorPalette().successColor" @click="showEdit=!showEdit">mdi-pencil</v-icon>
                <DeleteConfirmation v-on:confirm="deleteSheets()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
               
                <v-icon small :color="$store.getters.getColorPalette().alertColor">mdi-delete</v-icon>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="showEdit">
            <v-col>
                <CreateSheets :sheet="sheet" v-on:close="showEdit=false" v-on:update="$emit('update')"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import CreateSheets from '@/components/crud_components/derived/CreateSheets.vue';
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default{
    name: "sheet",
    props: {
        "sheet": {
            type: Object,
            required: true
        },
    },
    components:{
        CreateSheets,
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
             showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            showEdit:false
        }
    },
    computed: {},
    methods: {
        
        deleteSheets() {
            axios.post(this.$store.state.api + "deleteSheets", this.sheet, { headers: {
                    Authorization: "Bearer " + this.$store.state.jwt
                } })
                .then(response => {
                if (response.data.status == "success") {
                    this.$emit("deleteSheets");
                    //this.info=response.data
                    //this.showDismissibleAlert=true
                    //this.$store.dispatch('refreshModules')
                }
                else {
                    //this.loading=false
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            });
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    
}
</script>