<template>
    <v-container fluid
      :style="{ background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode }">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
  
  
  
  
  
      <v-card :style="{ border: `1px solid ${$store.getters.getColorPalette().accentCode}`, borderRadius: '5px' }"
        class="mx-0 my-1" transition="scroll-x-reverse-transition" :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().background2ColorCode">
        <v-form ref="usageForm" v-model="valid">
          <v-row class="pt-2" no-gutters>
            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" :label="$store.getters.getTextMap().dates" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on" :rules="objectRules" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="form.date" range></v-date-picker>
              </v-menu>
            </v-col>
            <v-col align-self="center">
                <v-switch
          style="transform: scale(0.7);"
          inset
          color="primary"
          class="mt-n3"
          :dark="$store.getters.getColorPalette().isDark"
      v-model="showUnshipped"
      :label="$store.getters.getTextMap().show_unshipped_only"
    ></v-switch>
            </v-col>
            <v-col align-self="center">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-btn class="mt-n6" v-if="!loading" :color="$store.getters.getColorPalette().accentCode" outlined small
                @click="fetchBatches">
              {{ $store.getters.getTextMap().view_logs }}
              </v-btn>
  
            </v-col>
            <v-spacer></v-spacer>
            <v-col align-self="center" align="right">
               
                <v-icon  v-if="!loading"  class="mt-n6 pr-1" small :color="$store.getters.getColorPalette().accentCode" @click="showFilter=!showFilter">{{showFilter?'mdi-filter-off-outline':'mdi-filter-outline'}}</v-icon>
              
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="filterForm" v-model="filterValid" v-if="showFilter">
            <v-divider></v-divider>
            <br>
          <v-row class="px-8">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-select v-if="!filterLoading" v-model="filterForm.param" :items="filterParamOptions" :label="$store.getters.getTextMap().params"
                :rules="objectRules" outlined dense required item-text="label" item-value="value" clearable></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-select v-if="!filterLoading" v-model="filterForm.operator" :items="operators" item-text="label"
                item-value="value" :label="$store.getters.getTextMap().operator" :rules="objectRules" required
                :dark="$store.getters.getColorPalette().isDark" clearable
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense></v-select>
            </v-col>
  
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-text-field :loading="filterLoading" v-model="filterForm.value" :counter="11" :rules="objectRules"
              :label="$store.getters.getTextMap().target_value" required outlined dense></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-progress-circular indeter minate v-if="filterLoading" color="primary"></v-progress-circular>
              <v-btn v-if="!filterLoading" class="mt-n6" :color="$store.getters.getColorPalette().accentCode" outlined
                small @click="addFilter">
                {{ $store.getters.getTextMap().submit }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
  
  
      <v-row v-if="filters.length > 0">
        <v-col>
          <span><strong>{{ $store.getters.getTextMap().filters }}</strong></span>
          <v-chip small class="ma-2" close @click:close="deleteFilter(filter.key)" color="indigo" text-color="white"
            v-for="filter in filters" :key="filter.key">
            {{ filter.param }} {{ filter.operator }} {{ filter.value }}
          </v-chip>
        </v-col>
      </v-row>
  
      <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
  
      <v-row v-if="graphData.length > 0">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.getTextMap().search" single-line
                hide-details></v-text-field>
            </v-card-title>
  
            <v-data-table :headers="tableHeaders" :items="filteredData" item-key="batch_id" :search="search"
              :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
              loading-text="Loading... Please wait" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $store.getters.getTextMap().batches }} - {{ dateRangeText }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn v-if="graphData.length > 0" outlined :color="$store.getters.getColorPalette().downloadbtnColor"
                    small @click="downloadPDF">
                    {{ $store.getters.getTextMap().download_table }}
                  </v-btn>
                  <a ref="csvDownload" hidden></a>
                </v-toolbar>
              </template>
              <template v-slot:item.location_id="{ item }">
                <strong>{{ item.shipped?$store.getters.getTextMap().dispatched:getLocationNameById(item.location_id) }}</strong>
              </template>
              <template v-slot:item.from_timestamp="{ item }">
                <strong>{{ toReadableTime(item.production_start) }}</strong>
              </template>
              <template v-slot:item.production_time="{ item }">
                <strong>{{ toReadableTime(item.production_time) }}</strong>
              </template>
              <template v-slot:item.shipped="{ item }">
                <strong>{{ item.shipped?'Yes':'No' }}</strong>
              </template>
  
  
              <!-- template for editing the dynamic logs details -->
  
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <BatchDetails :batch="item" v-on:update="fetchBatches" />
  
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  
  </template>
  <script>
  import InfoAlert from '@/components/InfoAlert'
  import BatchDetails from '@/components/items/manufacturing/BatchDetails'
//   import DeleteConfirmation from '@/components/DeleteConfirmation'
//   import CreateBatchTracingLog from '@/components/crud_components/loggers/CreateBatchProductionLog'
//   import WriteBatchProductionLog from '@/components/crud_components/loggers/WriteBatchProductionLog'
//   import EditWrittenBatchLog from '@/components/crud_components/loggers/EditWrittenBatchLog'
  import moment from 'moment'
  import axios from 'axios'
  import jsPDF from 'jspdf'
  import 'jspdf-autotable'
  import { applyPlugin } from 'jspdf-autotable'
  applyPlugin(jsPDF)
  export default {
    name: 'BatchesTable',
    // props: ['batchLog'],
    components: {
    //   CreateBatchTracingLog,
      InfoAlert,
      BatchDetails,
    //   DeleteConfirmation,
    //   WriteBatchProductionLog,
    //   EditWrittenBatchLog
  
    },
    mounted() {
      this.init()
      this.form.date = []
      this.form.date[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
      this.form.date[1] = moment().add(1, 'd').format('YYYY-MM-DD')
      this.fetchBatches()
    },
    data() {
      return {
        sortBy: 'production_time',
        sortDesc: false,
        multiple: false,
        isActive: false,
        editDialog: false,
  
        form: {
          date: null,
          log_id: null
        },
        groupBy: null,
        //date: new Date().toISOString().substr(0, 10),
        expanded: [],
        search: '',
        valid: false,
        groupValid: false,
        menu: false,
        // meta: {},
        graphData: [],
        groupedData: [],
        showDismissibleAlert: false,
        loading: false,
        info: '',
        showFilter:false,
        filters: [],
        filterLoading: false,
        filterValid: false,
        selectedItem: null,
        dialog: false,
        showUnshipped:true,
        filterForm: {
          param: null,
          operator: '==',
          value: null
        },
        operators: [
          { label: 'Greater than', value: '>' },
          { label: 'Lesser than', value: '<' },
          { label: 'Equals to', value: '==' },
          { label: 'Greater than equal to', value: '>=' },
          { label: 'Lesser than equal to', value: '<=' },
        ],
        objectRules: [
          v => !!v || 'Required',
        ],
        headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
  
        ],
        groupByOptions: [
          { label: 'From Time', value: 'production_start' },
          { label: 'To Time', value: 'production_time' },
          { label: 'Quantity', value: 'quantity' },
        ],
        editBatchLogData: false,
        batchLogProp: null
      }
    },
    computed: {
  
      dateRangeText() {
        if (this.form.date == null) {
          return null
        }
        return this.form.date.join(' - ')
      },
  
      fromTimeStamp() {
        if (this.form.date) {
          if (this.form.date.length > 1) {
            let dt = this.form.date[0].toString()
            if (Number(moment(dt, 'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X"))) {
              dt = this.form.date[1].toString()
            }
            return Number(moment(dt, 'YYYY-MM-DD').format("X"));
          } else {
            return Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X"));
          }
        } else {
          return null
        }
      },
      toTimeStamp() {
        if (this.form.date) {
          if (this.form.date.length > 1) {
            let dt = this.form.date[1].toString()
            if (Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X")) > Number(moment(dt, 'YYYY-MM-DD').format("X"))) {
              dt = this.form.date[0].toString()
            }
            return Number(moment(dt, 'YYYY-MM-DD').add(1, 'd').format("X"));
          } else {
            return Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').add(1, 'd').format("X"));
          }
        } else {
          return null
        }
      },
  
      filteredData() {
        let filteredData = this.graphData
        for (let i of this.filters) {
          let filterFunc = () => {
          }
          switch (i.operator) {
            case '>=': filterFunc = (data) => { return data[i.param] >= i.value };
              break;
            case '>': filterFunc = (data) => { return data[i.param] > i.value };
              break;
            case '==': filterFunc = (data) => { return data[i.param] == i.value };
              break;
            case '<=': filterFunc = (data) => { return data[i.param] <= i.value };
              break;
            case '<': filterFunc = (data) => { return data[i.param] < i.value };
              break;
          }
          filteredData = filteredData.filter(filterFunc);
        }
        return filteredData
      },
   
  
      
      filterParamOptions() {
        let op = [{
          label:'Quantity',
          value:'quantity',
        },
        {
          label:'SKU ID',
          value:'sku_id',
        },
        {
          label:'Serial Number',
          value:'sl_no',
        },
        {
          label:'Code',
          value:'code',
        },
    ]
  
        return op
      },
      tableHeaders() {
        // console.log(this.batchLog);
  
  
  
        let headers = [
        {
          text: 'Batch ID',
          align: 'start',
          filterable: true,
          value: 'batch_id',
        },
          {
            text: 'Production Time',
            // align: 'start',
            filterable: true,
            value: 'production_time',
          },
          {
            text: 'Sl No',
            // align: 'start',
            filterable: true,
            value: 'sl_no',
          },
        //   {
        //     text: 'Production start Time',
        //     // align: 'start',
        //     filterable: true,
        //     value: 'production_start',
        //   },
        { text: "SKU ID", value: 'sku_id', filterable: true, },
        { text: "Quantity", value: 'quantity', filterable: true, },
          { text: "Date Code", value: 'code' },
          { text: "Location", value: 'location_id' },
          { text: this.$store.getters.getTextMap().dispatched, value: 'shipped' }
          
  
        ]
        return headers
      }
  
    },
    methods: {

      init() {
        if (this.batchLog) {
            // this.meta = Object.assign({}, this.batchLog.meta)
            // console.log(this.meta)
            this.updateTableHeader()
      }
    },
      refreshLogs() {
        this.fetchBatches();
      },
      closeDialog() {
        this.editBatchLogData = !this.editBatchLogData
      },
      getLocationNameById(location_id){
            let location=this.$store.getters.getLocationById(location_id)

            return location?location.location_name:location_id
        },
      toReadableTimeFromEpoch(timestamp) {
        if (isNaN(timestamp)) {
          return null
        }
        return moment.unix(Number(timestamp)).format('dddd, MMMM Do YYYY, h:mm:ss a')
  
      },
      toReadableTime(timestamp) {
        if(timestamp)
        return moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')
      return '-'
  
      },
      
      deleteBatchLog() {
        // console.log(this.batchLog);
        axios.post(this.$store.state.api + 'deleteBatchProductionLog', this.batchLog, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status === 'success') {
            this.info = 'Logger Deleted successfully';
  
            this.showDismissibleAlert = true;
            this.$store.dispatch('refreshBatchLogs')
          } else {
  
            this.info = response.data.msg;
  
            this.showDismissibleAlert = true;
  
          }
          this.loading = false
  
  
        }).catch(err => {
          console.error(err)
          this.showDismissibleAlert = true
          this.info = err
          this.loading = false
        })
  
      },
  
      editBatchLogDatas(item) {
        this.selectedItem = item;
        this.batchLogProp = { ...this.batchLog };
        this.editBatchLogData = !this.editBatchLogData
      },
      downloadPDF() {
  
        //let pdf = new jsPDF('landscape','pt',[512,600]);
        let pdf = new jsPDF();
        //pdf.table(20, 30, this.currentData);
        let header = []
        let keyMapping = {};
        this.headers.forEach(val => {
          header.push(val.text);
          keyMapping[val.text] = val.value;
        })
  
        // console.log(this.headers);
  
        let body = []
  
        for (let i of this.filteredData) {
          const values = header.map(heading => {
            const key = keyMapping[heading];
            let value = i[key];
  
            if (key === "production_start" || key === "production_time") {
              value = this.toReadableTime(value);
            }
            const escaped = ('' + value)
            return `"${escaped}"`;
          })
  
          body.push(values)
        }
  
        // console.log(body);
  
  
        pdf.autoTable({
          head: [header],
          body: body
        })
        pdf.save(this.batchLog.name + '_' + this.dateRangeText + '.pdf');
  
        let csvHeader = header.join(',') + "\n"
        let csv = csvHeader
        csv += body.map(e => e.join(",")).join("\n");
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        //let hiddenElement = document.createElement('a');
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.batchLog.name + '_' + this.dateRangeText + '.csv';
        hiddenElement.click();
  
      },
  
      updateTableHeader() {
        this.headers = [
        {
          text: 'Batch ID',
          align: 'start',
          filterable: true,
          value: 'batch_id',
        },
          {
            text: 'From',
            align: 'start',
            filterable: true,
            value: 'production_start',
          },
          {
            text: 'To',
            // align: 'start',
            filterable: true,
            value: 'production_time',
          },
          { text: "Quantity", value: 'quantity' },
          { text: "Entered By", value: 'logged_by' },
  
        ]
        
      },
  
      fetchBatches() {
        this.$refs.usageForm.validate()
        if (this.valid) {
          this.loading = true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload = {
            // log_id: this.batchLog.log_id,
            from_timestamp: this.fromTimeStamp,
            to_timestamp: this.toTimeStamp,
          }
          if(this.showUnshipped){
            payload["shipped"]=false
          }
          let endpoint = 'getbatchProductionDataBetweenTimestamp'
          //console.log(fromTimeStamp)
  
          axios.post(this.$store.state.api + endpoint, payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              // console.log(response);
              //console.log("report data received")
              //console.log(response.data)
              if (response.data.status === 'success') {
                this.updateTableHeader()
                // console.log(response.data.data)
                this.graphData=response.data.data
                // let data = []
                // for (let i of response.data.data) {
  
                //   //   i['timestamp']=moment(i['timestamp']).unix()
                //   let temp = {
                //     production_start: i.production_start,
                //     production_time: i.production_time,
                //     quantity: i.quantity,
                //     sku_id:i.sku_id,
                //     code:i.code,
                //     location_id:i.location_id,
                //     remarks:i.remarks,
                //     logged_by:i.logged_by,
                //     status_text:i.status_text,
                //     ...i.meta
                //   }
  
                //   data.push(temp)
  
                // }
                // this.graphData = data
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if (this.graphData.length == 0) {
                  this.info = "No data to show";
                  this.showDismissibleAlert = true;
                }
  
              } else {
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading = false
              }
              this.loading = false
  
            })
            .catch(err => {
              console.log(err)
              this.showDismissibleAlert = true
              this.info = err
              this.loading = false
            })
  
        }
      },
  
      addFilter() {
          this.$refs.filterForm.validate()
          if (this.filterValid) {
            let d = Object.assign({}, this.filterForm)
            d.key = Number(moment().format("X"))
            this.filters.push(d)
            this.$refs.filterForm.reset()
          }
        },
  
        deleteFilter(key) {
          this.filters = this.filters.filter(
            function (data) { return data.key != key }
          );
        },
  
        deleteLogData(logData) {
          this.loading = false
          let payload = Object.assign({ }, logData)
          axios.post(this.$store.state.api + 'deleteBatch', payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          }).then(response => {
            if (response.data.status === 'success') {
              this.info = 'Entry Deleted successfully';
  
              this.showDismissibleAlert = true;
              this.fetchBatches()
            } else {
  
              this.info = response.data.msg;
  
              this.showDismissibleAlert = true;
  
            }
            this.loading = false
  
  
          }).catch(err => {
            console.log(err)
            this.showDismissibleAlert = true
            this.info = err
            this.loading = false
          })
        },
  
    }
  
  }
  </script>
  
  
  <style scoped>
  .slide-fade-enter-active {
    transition: all .8s ease;
  }
  
  .slide-fade-leave-active {
    transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>
  