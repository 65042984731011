<template>
    <v-form ref="updateSheetForm" v-model="updateSheetValid">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" class="mt-2">
                <v-row>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-card-title>{{sheet && sheet.report_id && edit?'Edit Sheet':'Add New Sheet'}}</v-card-title>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-switch
                            v-if="!sheetForm.meta.multiMachineParam"
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="sheetForm.meta.shiftBasedSheet"
                            :label="$store.getters.getTextMap().shift_based_usage_sheet"
                        ></v-switch>
                        <v-switch
                            v-if="!sheetForm.meta.shiftBasedSheet"
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="sheetForm.meta.multiMachineParam"
                            :label="$store.getters.getTextMap().multiple_machine_param"
                        ></v-switch>
                        <v-switch
                           v-if="!sheetForm.meta.multiMachineParam"
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="sheetForm.meta.isTranspose"
                            :label="$store.getters.getTextMap().map_paramid_paramequation_to_row"
                        ></v-switch>
                        <v-switch
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="sheetForm.meta.addDateToSheet"
                            :label="$store.getters.getTextMap().add_current_date_to_sheet"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-2">
                    <v-col>
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.sheet_name"
                            :counter="30"
                            outlined
                            dense
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().sheet_name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="!sheetForm.meta.multiMachineParam">
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.row_start"
                            outlined
                            dense
                            :rules="numberRules"
                            :label="sheetForm.meta.isTranspose && !sheetForm.meta.multiMachineParam?'Column Start':'Row Start'"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="!sheetForm.meta.multiMachineParam && sheetForm.meta.shiftBasedSheet">
                        <v-select  
                            v-if="!loading"
                            v-model="sheetForm.meta.shifts"
                            :items="shiftOptions"
                            :label="$store.getters.getTextMap().shift"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            multiple
                            :dark="$store.getters.getColorPalette().isDark" 
                            :rules="objectRules"
                        ></v-select  >
                    </v-col>
                    <v-col v-if="!sheetForm.meta.multiMachineParam && !sheetForm.meta.shiftBasedSheet">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.data_source"
                            outlined
                            dense
                            :items="dataSourceOptions"
                            :label="$store.getters.getTextMap().data_source"
                            item-text="label"
                            item-value="value"
                            card
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="sheetForm.meta.multiMachineParam && !sheetForm.meta.shiftBasedSheet">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.meta.statistics"
                            outlined
                            dense
                            :items="statistics"
                            :label="$store.getters.getTextMap().statistics"
                            item-text="label"
                            item-value="value"
                            card
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="sheetForm.data_source=='param_daily_data'">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="time_frame"
                            outlined
                            dense
                            :items="timeframeOptions"
                            :label="$store.getters.getTextMap().timeFrame"
                            item-text="label"
                            item-value="value"
                            card
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="time_frame!=='yesterday' && !sheetForm.meta.multiMachineParam && !sheetForm.meta.shiftBasedSheet">
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.meta.index_col"
                            outlined
                            dense
                            :rules="numberRules"
                            :label="sheetForm.meta.isTranspose? $store.getters.getTextMap().date_row_index :$store.getters.getTextMap().date_column_index"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="sheetForm.data_source=='param_data'">
                        <v-row>
                            <v-col align="center" align-self="center">
                                <v-menu  
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    ref="menufrom"
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="from_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-model="from_time"
                                            :label="$store.getters.getTextMap().from"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            clearable
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="objectRules"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker   
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="menu3"
                                        v-model="from_time"
                                        full-width
                                        format="24hr"
                                        @click:minute="$refs.menufrom.save(from_time)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col align="center" align-self="center">
                                <v-menu  
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    ref="menuto"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="to_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-model="to_time"
                                            :label="$store.getters.getTextMap().to"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            clearable
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="objectRules"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker   
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="menu2"
                                        v-model="to_time"
                                        full-width
                                        format="24hr"
                                        @click:minute="$refs.menuto.save(to_time)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="sheetForm.meta.addDateToSheet" no-gutters>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-card-title>{{$store.getters.getTextMap().date_position}}</v-card-title>
                    </v-col>
                </v-row>
                <v-row class="mx-2" v-if="sheetForm.meta.addDateToSheet">
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.meta.date_row_no"
                            outlined
                            dense
                            :rules="numberRules"
                            :label="$store.getters.getTextMap().row_no"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="sheetForm.meta.date_col_no"
                            outlined
                            dense
                            :rules="numberRules"
                            :label="$store.getters.getTextMap().column_no"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col v-if="sheet && sheet.report_id && edit"  cols="auto" class="d-flex" align="right">
                        <v-btn class="ma-2" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="sheetSubmit">{{$store.getters.getTextMap().update}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-update</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-else cols="auto" class="d-flex" align="right">
                        <v-btn class="ma-2" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="sheetSubmit">{{$store.getters.getTextMap().add_sheet}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'AddSheetToExcelTemplateBasedReport',
    props:['sheet','edit'],
    components:{
        InfoAlert,
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            updateSheetValid:false,
            sheetForm:{
                data_source:'param_daily_data',
                row_start:1,
                col_start:1,
                meta:{
                    index_col:1,
                    isTranspose:false,
                    multiMachineParam:false,
                    shiftBasedSheet:false,
                    addDateToSheet:false,
                    shifts:null,
                    statistics:'sum',
                    date_row_no:1,
                    date_col_no:1
                }
            },
            time_frame:'mtd',
            from_time:null,
            to_time:null,
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            rules:FormRules.rules,
            old_sheet_name:'',
            isTranspose:false,
            shiftOptions:[],
            timeframeOptions:[
                {label:'last 30 days', value:'last30'},
                {label:'last 60 days', value:'last60'},
                {label:'last 90 days', value:'last90'},
                {label:'last 180 days', value:'last180'},
                {label:'last 365 days', value:'last365'},
                {label:'Yesterday', value:'yesterday'},
                {label:'MTD', value:'mtd'},
                {label:'YTD', value:'ytd'}
            ],
            dataSourceOptions:[
                {label:"Daily Data",value:"param_daily_data"},
                {label:"Time Series",value:"param_data"},
            ],
            statistics:[
                {label:"Sum",value:"sum"},
                {label:"Avg",value:"avg"},
                {label:"Max",value:"max"},
                {label:"Min",value:"min"},
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && !isNaN(v))|| 'It should be a valid number',
                v => (v && v > 0) || 'Number must be greater than 0',
            ],
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            if(this.sheet && this.sheet.sheet_name && this.sheet.report_id){
            this.old_sheet_name=this.sheet.sheet_name
            this.sheetForm=Object.assign({}, this.sheet)
            if(! (this.sheet && this.sheet.meta && this.sheet.meta.index_col)){
                this.sheetForm.meta['index_col']=1
            }
            if(! (this.sheet && this.sheet.meta && this.sheet.meta.statistics)){
                this.sheetForm.meta['statistics']='sum'
            }
            if(this.sheet.data_source ==='param_data'){
                this.from_time=this.sheet.time_frame.from_time
                this.to_time=this.sheet.time_frame.to_time
            }else{
                this.time_frame=this.sheet.time_frame.timeframe
            }
        }
        },
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        sheetSubmit(){
            this.$refs.updateSheetForm.validate()
            if(this.updateSheetValid){
                let api=this.edit?"updateExcelTemplateBasedReportSheet":"addSheetToExcelTemplateBasedReport"
                this.sheetForm['time_frame']=this.sheetForm.data_source==='param_daily_data'?{"timeframe":this.time_frame}:{"from_time":this.from_time,"to_time":this.to_time}
                this.sheetForm['report_id']=this.sheet.report_id
                this.sheetForm['old_sheet_name']=this.old_sheet_name
                axios.post(this.$store.state.api+api,this.sheetForm,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.report_id=response.data.data.report_id
                        this.$emit("sheetUpdated")
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    },
    watch:{
        'sheetForm.meta.shiftBasedSheet'(){
            this.getShifts()
        }
    }
}
</script>