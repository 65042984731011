<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-data-table
            :headers="headers"
            :items="tariffs"
            item-key="target_id"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :dark="$store.getters.getColorPalette().isDark"
        >
            <template v-slot:top>
            <v-toolbar
                flat
                :dark="$store.getters.getColorPalette().isDark"
            >
            <v-toolbar-title>{{ $store.getters.getTextMap().tariffs }} </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <CreateTariffModal v-on:update="getTariffs" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                <v-icon class="ml-2" small :color="$store.getters.getColorPalette().accentCode" @click="getTariffs">mdi-refresh</v-icon>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <Tariffs :tariff="item" v-on:deleteTariff="getTariffs"/>
            </td>
        </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Tariffs from '@/components/items/derived/Tariffs'
import CreateTariffModal from '@/components/modals/derived/CreateTariffModal'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'TariffsTable',
    components:{
        Tariffs,
        CreateTariffModal,
        InfoAlert
    },
    mounted(){
      this.getTariffs()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            tariffs:[],
            expanded: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    filterable: false,
                    value: 'tariff_id',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Tariff On', value: 'tariff_on', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Defaut Tariff Value', value: 'default_tariff_val', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Timezone', value: 'timezone', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    methods:{
        getTariffs(){
            this.loading=true
            axios.get(this.$store.state.api+'tariffs',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.tariffs=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true 
            })
        }
    },
}
</script>
