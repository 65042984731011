<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <v-row dense no-gutters>
            <v-col cols="12">
                <v-expansion-panels        
                    hover
                    
                    >
                        <v-expansion-panel 
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark"  >
                            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">1. Machine Wise Trends</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MachineWiseComparisonPanel/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel 
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark" v-if="$store.state.parameters.length>0" >
                            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">2. Timeframed Trend Comparison</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ParamTimeframedPanel/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">3. Time Of Day Trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterTODPanel />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">4. Energy Usage Trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterUsageGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">5. Basic Statistical Trends</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterStatGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel 
        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
        :dark="$store.getters.getColorPalette().isDark"  v-if="$store.state.parameters.length>0">
            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">6. Granular 24 Hrs Trend</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
                <ParameterGraphByDays/>
            </v-expansion-panel-content>
        </v-expansion-panel>
                        <v-expansion-panel 
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark" v-if="$store.state.parameters.length>0" >
                            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">7. Time Of Day Trend Comparison</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ParameterComparisonPanel />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        
                        <v-expansion-panel 
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark" v-if="$store.state.parameters.length>0" >
                            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">8. Dual Axis Trend Comparison</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <DualAxisParamComparisonPanel />
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel 
                        :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark" v-if="$store.state.parameters.length>0 && $store.state.user && $store.getters.getAccessByLevels(['betaaccess'])" >
                            <v-expansion-panel-header><h3   :color="$store.getters.getColorPalette().accentCode">9. Param Historical Comparisons</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ParameterHistoricalTODComparison />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
       
        
    </v-container>
</template>

<script>
import ParameterComparisonPanel from '@/components/panels/ParameterComparisonPanel'
// import SNodeComparisonPanel from '@/components/panels/SNodeComparisonPanel'
import ParameterHistoricalTODComparison from '@/components/panels/ParameterHistoricalTODComparison'
import ParamTimeframedPanel from '@/components/panels/ParamTimeframedPanel'
import MachineWiseComparisonPanel from '@/components/panels/MachineWiseComparisonPanel'
import DualAxisParamComparisonPanel from '@/components/panels/DualAxisParamComparisonPanel'
import ParameterTODPanel from '@/components/panels/ParameterTODPanel'
import ParameterUsageGraphByDays from '@/components/panels/ParameterUsageGraphByDays'
import ParameterStatGraphByDays from '@/components/panels/ParameterStatGraphByDays'
import ParameterGraphByDays from '@/components/panels/ParameterGraphByDays'
export default {
    name:'CompareParamsPage',
    components:{
        ParameterComparisonPanel,
        ParameterHistoricalTODComparison,
        // SNodeComparisonPanel,
        ParamTimeframedPanel,
        MachineWiseComparisonPanel,
        DualAxisParamComparisonPanel,
        ParameterTODPanel,
        ParameterStatGraphByDays,
        ParameterGraphByDays,
        ParameterUsageGraphByDays
    }
}
</script>