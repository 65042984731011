<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-data-table
        :headers="headers"
        :items="dbData"
        item-key="index"
        :search="search"
        :single-expand="true"
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
        loading-text="Loading... Please wait"
        :dark="$store.getters.getColorPalette().isDark"
    >
        <template v-slot:top>
            <v-toolbar flat :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar-title>{{$store.getters.getTextMap().esg_scope_logging}}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <CreateESGScopeConversionModal v-on:close="getData" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                    <v-icon small :color="$store.getters.getColorPalette().accentCode" @click="getData">mdi-refresh</v-icon>
            </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <ESGScopeConversion :esgData="item" v-on:deleteConversion="getData" v-on:close="getData"/>
            </td>
        </template>
        <template v-slot:item.param_id="{  item }">
            {{ $store.getters.getNameByParamId(item.param_id) }}
        </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import CreateESGScopeConversionModal from '@/components/modals/derived/CreateESGScopeConversionModal'
import ESGScopeConversion from '@/components/items/derived/ESGScopeConversion'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'ESGScopeConversionTable',
    components:{
        InfoAlert,
        CreateESGScopeConversionModal,
        ESGScopeConversion
    },
    mounted(){
      this.getData()
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            info:'',
            showDismissibleAlert:false,
            dbData:[],
            headers: [
                {
                    text: 'Scope',
                    align: 'start',
                    filterable: false,
                    value: 'scope',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Source', 
                    value: 'source', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Source Type', 
                    value: 'type', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Name', 
                    value: 'name', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'UOM', 
                    value: 'uom', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'Emission Factor', 
                    value: 'emission_factor', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'Automated', 
                    value: 'automated', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'Param', 
                    value: 'param_id', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
            ],
        }
    },
    methods:{
        getData(){
            this.loading=true
            axios.get(this.$store.state.api+'esg_scope_conversion',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.dbData=response.data.data
                    for(let i in this.dbData){
                       this.dbData[i]['index']=i
                    }
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true   
            })
        }
    },
}
</script>
