<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-form ref="dynamicForm" v-model="isFormValid">
            <v-row
                no-gutters
                v-for="(item, index) in formFields"
                :key="index"
            >
                <v-col
                    cols="12"
                >
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        dense
                        outlined
                        v-if="item.entry_type === 'text' && !loading"
                        v-model="formValues[item.key_text]"
                        :label="item.key_text"
                        :required="item.required"
                        :rules="item.required ? [rules.required] : []"
                    ></v-text-field>
                    <!-- 
                    <v-file-input
                        dense
                        outlined
                        v-else-if="item.entry_type === 'image'  && !loading"
                        v-model="formValues[item.key_text]"
                        :label="item.key_text"
                        :required="item.required"
                        :rules="item.required ? [rules.required] : []"
                    ></v-file-input> -->
                    <div v-if="item.entry_type === 'image' && !loading">
                        <v-file-input
                        dense
                        outlined
                        :label="`Upload ${item.key_text}`"
                        v-model="fieldFiles[item.key_text]"
                        accept="image/*"
                        @change="validateAndCompressImage(item.key_text)"
                        ></v-file-input>

                        <div v-if="base64Images[item.key_text]">
                        <h4>{{ item.key_text }} Preview:</h4>
                        <img :src="base64Images[item.key_text]" alt="Preview" style="max-width: 100%; height: auto;" />
                        </div>
                    </div>

                    <v-checkbox
                        dense
                        outlined
                        v-else-if="item.entry_type === 'check'  && !loading"
                        v-model="formValues[item.key_text]"
                        :label="item.key_text"
                        :required="item.required"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" class="mr-1"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="submitForm(true)">{{$store.getters.getTextMap().save_and_submit}}</v-btn>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().downloadbtnColor" small @click="submitForm(false)">{{$store.getters.getTextMap().save_as_draft}}</v-btn>
                </v-col>
            </v-row>
            </v-form>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert.vue'
import axios from 'axios';
export default {
    name: 'ChecklistMakerForm',
    props:['checklist'],
    components: {
        InfoAlert
    },
    data() { 
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            formFields: [],
            isFormValid: false,
            formValues: {},
            fieldFiles: {}, // Stores selected files
            base64Images: {}, // Stores base64 values
            maxFileSize: 100 * 1024, // Limit file size to 2MB
            maxImageWidth: 800, // Compress to max width
            maxImageHeight: 800, // Compress to max height
            rules: {
                required: (value) => !!value || "This field is required",
            },
        }
    },
    mounted() {
        if (this.checklist && this.checklist.checklist_id && this.checklist.template_id) {
            this.getChecklistTemplate()
        }
    },
    methods: {
        formInit() {
            this.formFields.forEach((field) => {
                if (field.entry_type === "check") {
                    this.formValues[field.key_text] = false;
                } else if (field.entry_type === "image") {
                    this.formValues[field.key_text] = null;
                } else {
                    this.formValues[field.key_text] = "";
                }
            });
            if (this.checklist.submitted_timestamp) {
                this.getChecklistData()
            }
        },
        getChecklistData() {
            this.loading = true
            this.checklistFormDetails=[]
            axios.post(this.$store.state.api + "getChecklistDataById",{checklist_id:this.checklist.checklist_id,timestamp:this.checklist.epoch_timestamp}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.checklistFormDetails = [...response.data.data]
                    for (let i of response.data.data) {
                        for (let j of this.formFields) {
                            if (i.key_text === j.key_text) {
                                if (j.entry_type === "check") { 
                                    i.data==='true'?this.formValues[j.key_text]=true:this.formValues[j.key_text]=false
                                } else {
                                    this.formValues[j.key_text] = i.data
                                }
                            }
                        }
                    }
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        getChecklistTemplate() {
            this.loading = true
            this.formFields=[]
            axios.post(this.$store.state.api + "checklistTemplateMetaById",{template_id:this.checklist.template_id}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.formFields = [...response.data.data]
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        // convertToBase64(keyText) {
        //     const file = this.fieldFiles[keyText];
        //     if (file) {
        //         const reader = new FileReader();

        //         reader.onload = (e) => {
        //         this.$set(this.base64Images, keyText, e.target.result); // Set Base64 value dynamically
        //         };

        //         reader.onerror = (error) => {
        //         console.error(`Error converting file for ${keyText} to Base64:`, error);
        //         };

        //         reader.readAsDataURL(file);
        //     } else {
        //         this.$delete(this.base64Images, keyText); // Remove Base64 value if no file
        //     }
        // },
        validateAndCompressImage(keyText) {
            const file = this.fieldFiles[keyText];
            if (file) {
                // Check file size
                if (file.size > this.maxFileSize) {
                alert(`File size exceeds ${this.maxFileSize / (1024)} KB limit`);
                this.$delete(this.fieldFiles, keyText);
                this.$delete(this.base64Images, keyText);
                return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                this.compressImage(e.target.result, keyText);
                };

                reader.onerror = (error) => {
                console.error(`Error reading file for ${keyText}:`, error);
                };

                reader.readAsDataURL(file);
            } else {
                this.$delete(this.base64Images, keyText);
            }
        },
        compressImage(imageSrc, keyText) {
            const img = new Image();
            img.src = imageSrc;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set the target dimensions
                let width = img.width;
                let height = img.height;

                // Scale down if necessary
                if (width > this.maxImageWidth || height > this.maxImageHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = this.maxImageWidth;
                    height = width / aspectRatio;
                } else {
                    height = this.maxImageHeight;
                    width = height * aspectRatio;
                }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Compress the image and save the Base64
                const compressedBase64 = canvas.toDataURL("image/jpeg", 0.8); // 80% quality
                this.$set(this.base64Images, keyText, compressedBase64);
            };

            img.onerror = (error) => {
                console.error(`Error loading image for ${keyText}:`, error);
            };
        },
        submitForm(submit) {
            if (this.$refs.dynamicForm.validate()) {
                this.loading = true

                let payload = {
                    checklist_id: this.checklist.checklist_id,
                    timestamp: Number(this.checklist.epoch_timestamp),
                    checklist_meta: {},
                    submit
                }
                let temp={}
                this.formFields.map((field) => {
                    if (field.entry_type === "image" && this.base64Images) {
                        temp[field.key_text]=this.base64Images[field.key_text] || null
                    } else {
                        temp[field.key_text]= this.formValues[field.key_text]
                    }
                });
                payload["checklist_meta"] = temp
                let endpoint = "writeChecklistData"
                if (this.checklist && this.checklist.status==="refill") {
                    endpoint="refillChecklistData"
                }
                axios.post(this.$store.state.api + endpoint,payload, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.$emit('submitted')
                        this.info = "Checklist submitted successfully!";
                        this.showDismissibleAlert = true;
                        this.loading = false
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
            }
        }
    },
    watch: {
        checklist: {
            handler() {
                if (this.checklist && this.checklist.checklist_id && this.checklist.template_id) {
                    this.getChecklistTemplate()
                }
            },
            deep:true
        },
        formFields:{
            handler() {
                if (this.formFields && this.formFields.length > 0 ) {
                    this.formInit()
                }
            },
            deep:true
        }
    }
}
</script>