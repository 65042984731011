<template>
    <v-container fluid
      :style="{ background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode }">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
  
  

  
      <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
  
      <v-row v-if="graphData.length > 0">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.getTextMap().search" single-line
                hide-details></v-text-field>
            </v-card-title>
  
            <v-data-table :headers="tableHeaders" :items="filteredData" item-key="event_id" :search="search"
              :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
              loading-text="Loading... Please wait" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{$store.getters.getTextMap().batch_movement}}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn v-if="graphData.length > 0" outlined :color="$store.getters.getColorPalette().downloadbtnColor"
                    small @click="downloadPDF">
                    Download Table
                  </v-btn>
                  <a ref="csvDownload" hidden></a>
                </v-toolbar>
              </template>
              <template v-slot:item.to_location="{ item }">
                <strong>{{ getLocationNameById(item.to_location) }}</strong>
              </template>
              <template v-slot:item.from_location="{ item }">
                <strong>{{ getLocationNameById(item.from_location) }}</strong>
              </template>
              <template v-slot:item.timestamp="{ item }">
                <strong>{{ toReadableTime(item.timestamp) }}</strong>
              </template>
              
  
  
              <!-- template for editing the dynamic logs details -->
  
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <ObjectTable :meta="item.meta" v-on:update="fetchBatchMovements" />
  
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  
  </template>
  <script>
  import InfoAlert from '@/components/InfoAlert'
  import ObjectTable from '@/components/tables/ObjectTable'
//   import DeleteConfirmation from '@/components/DeleteConfirmation'
//   import CreateBatchTracingLog from '@/components/crud_components/loggers/CreateBatchProductionLog'
//   import WriteBatchProductionLog from '@/components/crud_components/loggers/WriteBatchProductionLog'
//   import EditWrittenBatchLog from '@/components/crud_components/loggers/EditWrittenBatchLog'
  import moment from 'moment'
  import axios from 'axios'
  import jsPDF from 'jspdf'
  import 'jspdf-autotable'
  import { applyPlugin } from 'jspdf-autotable'
  applyPlugin(jsPDF)
  export default {
    name: 'BatchMovementTable',
    props: {
        'batch_id':{
            type:String,
            required:true
        }
    },
    components: {
    //   CreateBatchTracingLog,
      InfoAlert,
      ObjectTable,
    //   DeleteConfirmation,
    //   WriteBatchProductionLog,
    //   EditWrittenBatchLog
  
    },
    mounted() {
      this.init()
      this.fetchBatchMovements()
    },
    data() {
      return {
        sortBy: 'production_time',
        sortDesc: false,
        multiple: false,
        isActive: false,
        editDialog: false,
  
        form: {
          date: null,
          log_id: null
        },
        groupBy: null,
        //date: new Date().toISOString().substr(0, 10),
        expanded: [],
        search: '',
        valid: false,
        groupValid: false,
        menu: false,
        // meta: {},
        graphData: [],
        groupedData: [],
        showDismissibleAlert: false,
        loading: false,
        info: '',
        showFilter:false,
        filters: [],
        filterLoading: false,
        filterValid: false,
        selectedItem: null,
        dialog: false,
        showUnshipped:true,
        filterForm: {
          param: null,
          operator: '==',
          value: null
        },
        operators: [
          { label: 'Greater than', value: '>' },
          { label: 'Lesser than', value: '<' },
          { label: 'Equals to', value: '==' },
          { label: 'Greater than equal to', value: '>=' },
          { label: 'Lesser than equal to', value: '<=' },
        ],
        objectRules: [
          v => !!v || 'Required',
        ],
        headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
  
        ],
        groupByOptions: [
          { label: 'From Time', value: 'production_start' },
          { label: 'To Time', value: 'production_time' },
          { label: 'Quantity', value: 'quantity' },
        ],
        editBatchLogData: false,
        batchLogProp: null
      }
    },
    computed: {
  
      
  
      filteredData() {
        let filteredData = this.graphData
        for (let i of this.filters) {
          let filterFunc = () => {
          }
          switch (i.operator) {
            case '>=': filterFunc = (data) => { return data[i.param] >= i.value };
              break;
            case '>': filterFunc = (data) => { return data[i.param] > i.value };
              break;
            case '==': filterFunc = (data) => { return data[i.param] == i.value };
              break;
            case '<=': filterFunc = (data) => { return data[i.param] <= i.value };
              break;
            case '<': filterFunc = (data) => { return data[i.param] < i.value };
              break;
          }
          filteredData = filteredData.filter(filterFunc);
        }
        return filteredData
      },
   
  
      
      filterParamOptions() {
        let op = [{
          label:'Quantity',
          value:'quantity',
        },
        {
          label:'SKU ID',
          value:'sku_id',
        },
        {
          label:'Code',
          value:'code',
        },
    ]
  
        return op
      },
      tableHeaders() {
        // console.log(this.batchLog);
  
  
  
        let headers = [
        {
          text: 'Event ID',
          align: 'start',
          filterable: true,
          value: 'event_id',
        },
          {
            text: 'From Location',
            // align: 'start',
            filterable: true,
            value: 'from_location',
          },
          {
            text: 'to Location',
            // align: 'start',
            filterable: true,
            value: 'from_location',
          },
        //   {
        //     text: 'Production start Time',
        //     // align: 'start',
        //     filterable: true,
        //     value: 'production_start',
        //   },
          { text: "Event", value: 'event_type' },
          
  
        ]
        return headers
      }
  
    },
    methods: {

      init() {
        if (this.batchLog) {
            // this.meta = Object.assign({}, this.batchLog.meta)
            // console.log(this.meta)
            this.updateTableHeader()
      }
    },
      refreshLogs() {
        this.fetchBatchMovements();
      },
      closeDialog() {
        this.editBatchLogData = !this.editBatchLogData
      },
      getLocationNameById(location_id){
            let location=this.$store.getters.getLocationById(location_id)

            return location?location.location_name:location_id
        },
      toReadableTimeFromEpoch(timestamp) {
        if (isNaN(timestamp)) {
          return null
        }
        return moment.unix(Number(timestamp)).format('dddd, MMMM Do YYYY, h:mm:ss a')
  
      },
      toReadableTime(timestamp) {
        if(timestamp)
        return moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')
      return '-'
  
      },

      downloadPDF() {
  
        //let pdf = new jsPDF('landscape','pt',[512,600]);
        let pdf = new jsPDF();
        //pdf.table(20, 30, this.currentData);
        let header = []
        let keyMapping = {};
        this.headers.forEach(val => {
          header.push(val.text);
          keyMapping[val.text] = val.value;
        })
  
        // console.log(this.headers);
  
        let body = []
  
        for (let i of this.filteredData) {
          const values = header.map(heading => {
            const key = keyMapping[heading];
            let value = i[key];
  
            if (key === "production_start" || key === "production_time") {
              value = this.toReadableTime(value);
            }
            const escaped = ('' + value)
            return `"${escaped}"`;
          })
  
          body.push(values)
        }
  
        // console.log(body);
  
  
        pdf.autoTable({
          head: [header],
          body: body
        })
        pdf.save(this.batchLog.name + '.pdf');
  
        let csvHeader = header.join(',') + "\n"
        let csv = csvHeader
        csv += body.map(e => e.join(",")).join("\n");
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        //let hiddenElement = document.createElement('a');
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.batchLog.name +'.csv';
        hiddenElement.click();
  
      },
  
      updateTableHeader() {
        this.headers = [
        {
          text: 'Batch ID',
          align: 'start',
          filterable: true,
          value: 'batch_id',
        },
          {
            text: 'From',
            align: 'start',
            filterable: true,
            value: 'production_start',
          },
          {
            text: 'To',
            // align: 'start',
            filterable: true,
            value: 'production_time',
          },
          { text: "Quantity", value: 'quantity' },
          { text: "Entered By", value: 'logged_by' },
  
        ]
        
      },
  
      fetchBatchMovements() {
        if (this.batch_id) {
          this.loading = true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload = {
            // log_id: this.batchLog.log_id,
            batch_id:this.batch_id
          }
          
          let endpoint = 'getBatchMovements'
          //console.log(fromTimeStamp)
  
          axios.post(this.$store.state.api + endpoint, payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              // console.log(response);
              //console.log("report data received")
              //console.log(response.data)
              if (response.data.status === 'success') {
                this.updateTableHeader()
                // console.log(response.data.data)
                this.graphData=response.data.data
                
                if (this.graphData.length == 0) {
                  this.info = "No Movement Events";
                  this.showDismissibleAlert = true;
                }
  
              } else {
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading = false
              }
              this.loading = false
  
            })
            .catch(err => {
              console.log(err)
              this.showDismissibleAlert = true
              this.info = err
              this.loading = false
            })
  
        }
      },
  
      addFilter() {
          this.$refs.filterForm.validate()
          if (this.filterValid) {
            let d = Object.assign({}, this.filterForm)
            d.key = Number(moment().format("X"))
            this.filters.push(d)
            this.$refs.filterForm.reset()
          }
        },
  
        deleteFilter(key) {
          this.filters = this.filters.filter(
            function (data) { return data.key != key }
          );
        },
  
        deleteLogData(logData) {
          this.loading = false
          let payload = Object.assign({ }, logData)
          axios.post(this.$store.state.api + 'deleteBatch', payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          }).then(response => {
            if (response.data.status === 'success') {
              this.info = 'Entry Deleted successfully';
  
              this.showDismissibleAlert = true;
              this.fetchBatchMovements()
            } else {
  
              this.info = response.data.msg;
  
              this.showDismissibleAlert = true;
  
            }
            this.loading = false
  
  
          }).catch(err => {
            console.log(err)
            this.showDismissibleAlert = true
            this.info = err
            this.loading = false
          })
        },
  
    },
    watch:{
        batch_id(){
            this.fetchBatchMovements()
        }
    }
  
  }
  </script>
  
  
  <style scoped>
  .slide-fade-enter-active {
    transition: all .8s ease;
  }
  
  .slide-fade-leave-active {
    transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>
  