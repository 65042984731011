<template>
    <v-container fluid    :color="$store.getters.getColorPalette().backgroundColor">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().dual_axis_trend_comparison }}</span>
            </v-col>
        </v-row>
        <v-form ref="usageForm" v-model="valid">
            <v-row >
               <ProcessMachineParamForm :multiple="true" :removeParam="removeParam" v-on:value="updateParamList"/>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="axisLabels.y1AxisLabel"
                        outlined
                        dense
                        :counter="30"
                        :label="$store.getters.getTextMap().y1axis_label"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="axisLabels.y2AxisLabel"
                        outlined
                        dense
                        :counter="30"
                        :label="$store.getters.getTextMap().y2axis_label"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                
      <v-row>
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>

       
              {{$store.getters.getTextMap().from}}
              <v-col align-self="center">
               <TimePicker v-model="fromTime"/>
            </v-col>
       
          </v-row>
<v-row>
  
            <v-col>
                <v-menu :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            outlined
            dense
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
          ></v-text-field>
        </template>
        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>
         
            {{$store.getters.getTextMap().to}}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 

          
            
          </v-row>
                <!-- <v-row>
                    <v-col align="center" align-self="center"> 
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-menu  
                        :dark="$store.getters.getColorPalette().isDark"
                            color="cyan lighten-1"
                            v-if="!loading"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field  
                                :dark="$store.getters.getColorPalette().isDark"
                                    color="cyan lighten-1"
                                    v-model="form.date"
                                    label="Date"
                                    outlined
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="objectRules"
                                ></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.date" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>  
                    <v-col align="center" align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-menu  
                            :dark="$store.getters.getColorPalette().isDark"
                            color="cyan lighten-1"
                            v-if="!loading"
                            ref="menufrom"
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="fromTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field  
                                :dark="$store.getters.getColorPalette().isDark"
                                    color="cyan lighten-1"
                                    v-model="fromTime"
                                    label="From"
                                    outlined
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    dense
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="objectRules"
                                ></v-text-field>
                            </template>
                            <v-time-picker  
                                :dark="$store.getters.getColorPalette().isDark"
                                color="cyan lighten-1"
                                v-if="menu3"
                                v-model="fromTime"
                                full-width
                                format="24hr"
                                @click:minute="$refs.menufrom.save(fromTime)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col align="center" align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-menu  
                            :dark="$store.getters.getColorPalette().isDark"
                            color="cyan lighten-1"
                            v-if="!loading"
                            ref="menuto"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="toTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    color="cyan lighten-1"
                                    v-model="toTime"
                                    label="To"
                                    outlined
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    dense
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker  :dark="$store.getters.getColorPalette().isDark"
                                color="cyan lighten-1"
                                v-if="menu2"
                                v-model="toTime"
                                full-width
                                format="24hr"
                                @click:minute="$refs.menuto.save(toTime)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row> -->
            </v-row>
        </v-form>
        <v-row>
            <v-col cols="12">
            <v-row>
            <v-col align="center" align-self="center">
            <v-card class="px-2 py-2 fixed-height-row" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
            <v-container class="card-container" fluid>
                <v-row>
                <v-col cols="5">
                    <v-row>
                    <v-col>
                        <h3>{{ $store.getters.getTextMap().parameters }}</h3>
                        <v-card class="my-1 py-1 px-1 fixed-height-row-sub" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="parameter-card-container" fluid>
                        <v-row no-gutters v-bind:key="param.param_id" v-for="param in parameters">
                            <v-col>
                            <v-checkbox dense v-if="!(form.y1.some(obj => Object.values(obj).includes(param.param_id))||form.y2.some(obj => Object.values(obj).includes(param.param_id)))" :dark="$store.getters.getColorPalette().isDark" v-model="selected" :value="param" :label="param.name"></v-checkbox>
                            </v-col>
                        </v-row>
                        </v-container>
                        </v-card>
                    </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-14" cols="2">
                    <v-row>
                    <v-col>
                        <v-btn @click="addToY1"  outlined :color="$store.getters.getColorPalette().accentCode" v-if="!loading">
                        Y1<v-icon>mdi-greater-than</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                        <v-btn @click="addToY2"  outlined :color="$store.getters.getColorPalette().accentCode" v-if="!loading">
                        Y2<v-icon>mdi-greater-than</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                        <v-btn @click="addBack"  outlined :color="$store.getters.getColorPalette().accentCode" v-if="!loading">
                        <v-icon>mdi-less-than</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="deleteParams"  :color="$store.getters.getColorPalette().cancelbtnColor" v-if="!loading">
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                        <v-btn @click="resetBack" outlined :color="$store.getters.getColorPalette().accentCode" v-if="!loading">
                        <v-icon>mdi-less-than</v-icon><v-icon>mdi-less-than</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <v-row>
                    <v-col>
                        <h3>{{ $store.getters.getTextMap().y1axis }}</h3>
                        <v-card class="my-1 py-1 px-1 fixed-height-row-sub" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="axis-card-container" fluid>
                        <v-row no-gutters v-bind:key="param.param_id" v-for="param in form.y1">
                            <v-col>
                            <v-checkbox dense v-if="form.y1.some(obj => Object.values(obj).includes(param.param_id))" :dark="$store.getters.getColorPalette().isDark" v-model="selected" :value="param" :label="param.name"></v-checkbox>
                            </v-col>
                        </v-row>
                        </v-container>
                        </v-card>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                        <h3>{{ $store.getters.getTextMap().y2axis }}</h3>
                        <v-card class="my-1 py-1 px-1 fixed-height-row-sub" flat :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" :dark="$store.getters.getColorPalette().isDark">
                            <v-container class="axis-card-container" fluid>
                        <v-row  dense no-gutters >
                            <v-col>
                                <div v-bind:key="param.param_id" v-for="param in form.y2">
                                    <v-checkbox  dense v-if="form.y2.some(obj => Object.values(obj).includes(param.param_id))" v-model="selected" :value="param" :label="param.name"></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                        </v-container>
                        </v-card>
                    </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-container>
            </v-card>
        </v-col>
        </v-row>
        <v-row>
            <v-col align="center" align-self="center">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">
                    Generate Report
                </v-btn>
            </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <v-row v-if="showGraph">
            <v-col>
                <DualAxisParameterTrendPanel :y1ParamDetails="y1ParamDetails" :y2ParamDetails="y2ParamDetails" :fromTimestamp="from_time" :toTimestamp="to_time" :axisLabels="axisLabels"/>
            </v-col>
        </v-row>
    </v-col>
</v-row>
</v-container>
</template>
<script>
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import ProcessMachineParamForm from '@/components/forms/ProcessMachineParamForm'
import DualAxisParameterTrendPanel from '@/components/panels/DualAxisParameterTrendPanel'
import TimePicker from '@/components/inputs/TimePicker'
export default {
    name:'DualAxisParamComparisonPanel',
    components:{
        InfoAlert,
        ProcessMachineParamForm,
        DualAxisParameterTrendPanel,
        TimePicker
    },
    data(){
        return {
            showGraph:false,
            y1ParamDetails:[],
            y2ParamDetails:[],
            removeParam:[],
            axisLabels:{
                y1AxisLabel:null,
                y2AxisLabel:null,
            },
            from_time:null,
            to_time:null,
            fromTime:null,
            selected:[],
            form:{
                date:null,
                y1:[],
                y2:[],
            },
            valid:false,
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            toTime:null,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            parameters:[],
            objectRules:[
                v => !!v || 'Required',
            ],
            rules:FormRules.rules,
            ISODateRules:[
                v => !!v || 'Required',
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
                ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            }
    },
    computed: {
        fromTimeStamp(){
        if(this.form.from_date){
            let dt=this.form.from_date.toString()
            if(this.fromTime){
                dt+=' '+this.fromTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        }else{
            return null
        }
        },
        toTimeStamp(){
        if(this.form.to_date){
            let dt=this.form.to_date.toString()
            if(this.toTime){
                dt+=' '+this.toTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        }else{
            return null
        }
        },
    },
    methods: {
        updateParamList(value){
            this.parameters=value.parameter
        },
        remove(m){
            this.parameters=this.parameters.filter(x=>x.parameters!=m.param_id)
        },
        deleteParams(){
            for(let i of this.selected){
                this.form.y1=this.form.y1.filter(x=>x!=i) 
                this.form.y2=this.form.y2.filter(x=>x!=i)
                this.removeParam.push(i)
            }
            this.selected = []
        },
        resetBack(){
            this.form.y1=[]
            this.form.y2=[]
            this.selected=[]
        },
        addToY1(){
            for(let i of this.selected){
                if(!this.form.y1.some(obj => Object.values(obj).includes(i.param_id))){
                    this.form.y1.push(i)
                    this.form.y2=this.form.y2.filter(x=>x!=i)
                }
            }
               this.selected = []
        },
        addToY2(){
            for(let i of this.selected){
                if(!this.form.y2.some(obj => Object.values(obj).includes(i.param_id))){
                    this.form.y2.push(i)
                    this.form.y1=this.form.y1.filter(x=>x!=i)
                }
            }
               this.selected = []
        },
        addBack(){
            for(let i of this.selected){
                if(this.form.y1.some(obj => Object.values(obj).includes(i.param_id))){
                    this.form.y1=this.form.y1.filter(x=>x!=i)
                }else{
                    this.form.y2=this.form.y2.filter(x=>x!=i)
                }
            }
            this.selected = []
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.showGraph=false
                this.loading=true
                this.y1ParamDetails = []
                this.y2ParamDetails = []
                this.from_time=this.fromTimeStamp
                this.to_time=this.toTimeStamp
                this.y1ParamDetails = [...this.form.y1]
                this.y2ParamDetails = [...this.form.y2]
                const timeDifference = Math.abs(this.to_time - this.from_time);
                console.log(timeDifference,'timedifference')
                const maxAllowedDifference = 48 * 60 * 60;
                console.log(maxAllowedDifference,'maxAllowedDifference')
                if (timeDifference <= maxAllowedDifference) {
                    this.showGraph=true
                                    
                this.loading=false
                } else {
                    this.showDismissibleAlert=true
                    this.info="You cannot select date range for more than 48 hours"
                    this.loading=false
                }
                // console.log(this.y1ParamDetails,'y1')
                // console.log(this.y2ParamDetails,'y2')

            }
        }
    },
    watch:{
    }
}
</script>
<style scoped>
.card-container {
  max-height: 400px; /* Set the desired maximum height */
  /* overflow-y: auto;   */
}
.parameter-card-container {
    height: 300px;
  max-height: 300px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
.axis-card-container {
    height: 120px;
  max-height: 120px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a scroll bar when content overflows */
}
</style>