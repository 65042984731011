<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-container fluid>
            <v-card-title class="text-subtitle-2" :dark="$store.getters.getColorPalette().isDark" :style="{color:$store.getters.getColorPalette().textColor}">{{report.name ?report.name : report.report_id}}
                <v-spacer></v-spacer>
                <span v-if="$store.state.user && $store.state.settingMode "><EditExcelTemplateBasedReportBottomSheet :report="report"/></span>
                <span> 
                    <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular> 
                    <v-btn
                        v-if="!loading && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
                        class="mx-1"
                        icon
                        color="pink"
                    >
                        <DeleteConfirmation v-on:confirm="deleteParameterReport()"  title="Delete Confirmation"  description="Are you sure you want to delete this?"> 
                            <v-icon small  :dark="$store.getters.getColorPalette().isDark">mdi-trash-can</v-icon>
                        </DeleteConfirmation>
                    </v-btn>
                </span>
            </v-card-title>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-divider color="grey"></v-divider>
            <v-hover v-slot="{ hover }">
                <v-card-subtitle :style="{'border-radius': '5px '}" @click="overlay=!overlay" class="">
                    <v-row>
                        <v-col>
                            <strong :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().notification_groups}}</strong>
                            <strong :color="$store.getters.getColorPalette().accentCode">{{report.notification_group?1:0}}</strong>
                        </v-col>
                    </v-row>
                    <v-overlay absolute :value="hover">
                        <v-row justify="center" align="center" align-content="center">
                            <v-col justify="center" align="stretch" align-content="center">
                                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="dialog = true">{{$store.getters.getTextMap().view_report}}
                                    <v-icon>mdi-chart-line</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-overlay>
                </v-card-subtitle>
            </v-hover>
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorName">
                    <v-toolbar :color="$store.getters.getColorPalette().foregroundColorName">
                        <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{report.name ?report.name : report.report_id}} - {{$store.getters.getTextMap().excel_template_based_report}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <ExcelTemplateBasedReportDetails :report="report" />
                </v-card>
            </v-dialog>
        </v-container>
    </v-card>
</template>
<script>
import axios from 'axios'
import ExcelTemplateBasedReportDetails from '@/components/reports/ExcelTemplateBasedReportDetails'
import EditExcelTemplateBasedReportBottomSheet from '@/components/modals/reports/EditExcelTemplateBasedReportBottomSheet'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'ExcelTemplateBasedReport',
    props:['report'],
    components:{
        ExcelTemplateBasedReportDetails,
        EditExcelTemplateBasedReportBottomSheet,
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
    },
    data(){
        return {
            showDialog:false,
            overlay:false,
            dialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:''
        }
    },
    methods:{
        deleteParameterReport(){
            this.loading=true
            this.$emit('deleteReport',this.report)
            axios.post(this.$store.state.api+'deleteExcelTemplateBasedReport',this.report,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.$store.dispatch('refreshExcelTemplateBasedReports')
                    this.loading=false
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                console.log(err)
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        } 
    }
}
</script>