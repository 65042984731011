<template>
    <div class="text-center">
      <v-btn
        class="devc"
         small
         outlined
      :background-color="$store.getters.getColorPalette().background1ColorCode"
    :color="$store.getters.getColorPalette().btnborderColorCode"

       
        
        
        
        @click="dialog = !dialog"
      >
        {{$store.getters.getTextMap().create_param_equation}} <v-icon
          right
    :dark="$store.getters.getColorPalette().isDark"
       :color="$store.getters.getColorPalette().deletebtnColor"
        >mdi-clipboard-text-outline
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
      <v-card :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-toolbar
           :dark="$store.getters.getColorPalette().isDark"
         :color="$store.getters.getColorPalette().foregroundColorCode"
          >
            <v-btn
              icon
            :dark="$store.getters.getColorPalette().isDark"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{$store.getters.getTextMap().create_param_equation}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
              :dark="$store.getters.getColorPalette().isDark"
                text
                @click="dialog = false"
              >
                close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        <CreateParamEquation v-on:close="update()" v-on:update="update()"/>
      </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import CreateParamEquation from '@/components/crud_components/derived/CreateParamEquation'
  export default {
      name:'CreateParamEquationSheet',
      components:{
        CreateParamEquation  
      },
      data(){
          return {
              dialog:false
          }
      },
      methods:{
        update(){
          console.log('update 2')
            this.$emit('update')
            this.dialog=false
            console.log('update received')
        }

      }
  }
  </script>
  <style scoped>
  .devc{
     border:3px solid;
    border-radius:20px;

  }
  .theme--light.v-sheet {
     background-color: #263238;
  }
  .theme--light.v-card{
     background-color: #263238;
  }
  </style>