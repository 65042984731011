<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
      <v-autocomplete
                    v-if="!loading"
            v-model="logType"
            :items="logTypeOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().log_type"
            item-text="label"
                    item-value="value"
          ></v-autocomplete></v-col>
          <v-col>
            <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
      <v-autocomplete
                    v-if="!loading"
            v-model="log"
            :items="logOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().choose_Log"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <DynamicLogDetails :dynamicLog="log" v-if="log && logType=='dynamicLogs'"/>
                <ProductionLogDetails :productionLog="log" v-if="log && logType=='productionLogs'"/>
                <CapacityLogDetails :capacityLog="log" v-if="log && logType=='capacityLogs'"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script >
import DynamicLogDetails from '@/components/panels/loggers/DynamicLogDetails';
import CapacityLogDetails from '@/components/panels/loggers/CapacityLogDetails';
import ProductionLogDetails from '@/components/panels/ProductionLogDetails';
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'LogPanel',
    props:[],
    components:{
    DynamicLogDetails,
    CapacityLogDetails,
    ProductionLogDetails,
    InfoAlert,
},
    mounted(){},
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            log:null,
            logTypeOptions:[
                {label:'Production Logs', value:'productionLogs'},
                {label:'Capacity Logs', value:'capacityLogs'},
                {label:'Dynamic Logs', value:'dynamicLogs'},
            ],
            logType:null,
        }
    },
    computed:{
        logOptions(){
            let op=[]
            if(this.logType){
                for(let l of this.$store.state[this.logType]){
                    op.push({ label:l['name'], value:l})
                }
            }
            return op

        },
    },
    methods:{

    },
    watch:{
        logType(){
            this.log=null
        }
    }

}
</script>