<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}"> 
        <v-row>
            <v-col>
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mx-0 my-0">
               <BatchCountSummaryTable />
                </v-card>
            </v-col>
            <v-col  align="center">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mx-0 my-0">
                     <BatchProductionTrendPanel />
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col  align="center">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mx-0 my-0">
               <BatchBalanceTable />
                </v-card>
            </v-col>
            <v-col>
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mx-0 my-0">
               <BatchShippingCountTable />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import BatchProductionTrendPanel from '@/components/panels/manufacturing/BatchProductionTrendPanel'
import BatchBalanceTable from '@/components/panels/manufacturing/BatchBalanceTable'
import BatchShippingCountTable from '@/components/panels/manufacturing/BatchShippingCountTable'
import BatchCountSummaryTable from '@/components/panels/manufacturing/BatchCountSummaryTable'
export default {
    name:'BatchTracking',
    components:{
        BatchProductionTrendPanel,
        BatchBalanceTable,
        BatchShippingCountTable,
        BatchCountSummaryTable
    }
}
</script>