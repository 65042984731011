<template>
    <div>
        <v-btn 
            :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  
            @click="dialog = !dialog"
        >
        {{ mode }} {{ $store.getters.getTextMap().activity }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-file-tree</v-icon>
        </v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ mode }} {{ $store.getters.getTextMap().activity }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{ $store.getters.getTextMap().close }}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <CreateActivity :activity="activity" v-on:close="dialog=false" v-on:update="$emit('update')"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CreateActivity from '@/components/crud_components/application/CreateActivity'
export default {
    name:'CreateActivityModal',
    props:['activity'],
    components:{
        CreateActivity  
    },
    data(){
        return {
            dialog:false,
            mode:this.$store.getters.getTextMap().add
        }
    },
    mounted(){
        if(this.activity && this.activity.activity_id){
            this.mode = this.$store.getters.getTextMap().edit
        }
    }
}
</script>

  