<template>
    <v-container fluid    :color="$store.getters.getColorPalette().backgroundColor">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().machinewise_comparison }}</span>
            </v-col>
        </v-row>
        <v-form ref="usageForm" v-model="valid">
            <v-row >
               <WorkspaceMachineForm :multiple="true" v-on:value="updateMachineList"/>
                <!-- <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                      :dark="$store.getters.getColorPalette().isDark"
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="workspace"
                        :items="workspaceOptions"
                        label="Workspace"
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                      :dark="$store.getters.getColorPalette().isDark"
                     :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="machine"
                        :items="machineOptions"
                        label="Machine"
                        outlined
                        item-text="label"
                        item-value="value"
                        required
                        multiple
                        :rules="[rules.required,rules.nonZeroLength]"
                    ></v-select>
                </v-col> -->
                <!-- <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  dark
                        color="cyan lighten-1"
                        v-if="!loading"
                        v-model="form.parameters"
                        :items="paramOptions"
                        label="Device Parameter"
                        outlined
                        multiple
                        clearable
                        item-text="label"
                        item-value="value"
                        required
                        :rules="objectRules"  
                        :menu-props="{dark: true}"
                    ></v-select>
                </v-col> -->
            </v-row>
            <v-row>
                <v-col align="center" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                    :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="timeFrame"
                        :items="timeFrameOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        item-text="label"
                        item-value="value"
                        required
                        dense
                        outlined
                        :rules="objectRules"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col align-self="center" v-if="timeFrame==='timewise'">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-radio-group v-model="normalised" row :dark="$store.getters.getColorPalette().isDark">
                        <v-radio value="raw"><template v-slot:label>  {{ $store.getters.getTextMap().raw }} <InfoComponent hint="This is Raw data collected from the assets. This data is collected based on change or is collected after a minute from the last recorded data" /></template></v-radio>
                        <v-radio value="trend"><template v-slot:label>{{ $store.getters.getTextMap().trend }} <InfoComponent hint="This is trend data of a parameter. This showcases trend in any parameter. In case of counter parameters (such as kWH), it will show the minute wise change." /></template></v-radio>
                        <v-radio value="normalised"><template v-slot:label>{{ $store.getters.getTextMap().normalised }} <InfoComponent hint="This is minute wise data and will have start of a minute timestamp. This doesn’t depend on the change." /></template></v-radio>
                    </v-radio-group>
                </v-col> 
                <v-row v-if="showTimeWiseClock===false">
                <v-col align="center" align-self="center">
                    <v-menu  
                      :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field  
                              :dark="$store.getters.getColorPalette().isDark"
                              :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="form.from_date"
                                :label="$store.getters.getTextMap().from_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                clearable
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="ISODateRules"
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.from_date" @input="menu1 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col align="center" align-self="center">
                    <v-menu  
                      :dark="$store.getters.getColorPalette().isDark"
                        color="cyan lighten-1"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field  
                              :dark="$store.getters.getColorPalette().isDark"
                                color="cyan lighten-1"
                                v-model="form.to_date"
                                :label="$store.getters.getTextMap().to_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                dense
                                clearable
                                outlined
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="ISODateRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.to_date" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                </v-row>
                <v-row v-if="showTimeWiseClock===true">
                <v-col align="center" align-self="center"> 
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu  
                       :dark="$store.getters.getColorPalette().isDark"
                        color="cyan lighten-1"
                        v-if="!loading"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field  
                              :dark="$store.getters.getColorPalette().isDark"
                                color="cyan lighten-1"
                                v-model="form.date"
                                :label="$store.getters.getTextMap().date"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                clearable
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark" color="cyan lighten-1" v-model="form.date" @input="menu = false"></v-date-picker>
                </v-menu>
            </v-col> 
         {{ $store.getters.getTextMap().from }}
              <v-col align-self="center">
              <TimePicker v-model="fromTime"/>
            </v-col>
            {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 
            <!-- <v-col align="center" align-self="center">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-menu  
                :dark="$store.getters.getColorPalette().isDark"
                    color="cyan lighten-1"
                    v-if="!loading"
                    ref="menufrom"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="fromTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field  
                         :dark="$store.getters.getColorPalette().isDark"
                            color="cyan lighten-1"
                            v-model="fromTime"
                            label="From"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            :rules="objectRules"
                        ></v-text-field>
                    </template>
                    <v-time-picker  
                     :dark="$store.getters.getColorPalette().isDark"
                        olor="cyan lighten-1"
                        v-if="menu3"
                        v-model="fromTime"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menufrom.save(fromTime)"
                    ></v-time-picker>
                </v-menu>
            </v-col>
            <v-col align="center" align-self="center">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-menu  
                :dark="$store.getters.getColorPalette().isDark"
                    color="cyan lighten-1"
                    v-if="!loading"
                    ref="menuto"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="toTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :dark="$store.getters.getColorPalette().isDark"
                        color="cyan lighten-1"
                        v-model="toTime"
                        label="To"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        dense
                        outlined
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker  :dark="$store.getters.getColorPalette().isDark"
                    color="cyan lighten-1"
                    v-if="menu2"
                    v-model="toTime"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menuto.save(toTime)"
                ></v-time-picker>
            </v-menu>
        </v-col> -->
        </v-row>
                <v-col align="center" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
            v-model="form.tagType"
            :items="tagTypeOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            clearable
            :label="$store.getters.getTextMap().tag_type"
            item-text="label"
                    item-value="value"
                    :rules="objectRules"
          ></v-autocomplete>
                    <!-- <v-select  


                      :dark="$store.getters.getColorPalette().isDark"
                        color="cyan lighten-1"
                        v-if="!loading"
                        v-model="form.tagType"
                        :items="tagTypeOptions"
                        label="Tag Type"
                        item-text="label"
                        item-value="value"
                        required
                        dense
                        :rules="objectRules"
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select> -->
                </v-col>

            </v-row>
            <v-row>
                <v-col align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">
                      {{ $store.getters.getTextMap().generate_report }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row no-gutters>
            <v-col>
                <v-col>    
                    <v-chip
                        class="ma-2"
                        close
                        small
                        color="teal"
                        text-color="white"
                        @click:close="remove(machine)" 
                        v-bind:key="machine.machine_id" v-for="machine in machine"
                    >
                        {{$store.getters.getWorkspaceById(machine['workspace_id'])['name']}} - {{machine.machine_name}}
                    </v-chip>
                </v-col>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="this.tableData.length>0  && timeFrame==='daily' " no-gutters>
            <v-col align-self="center">
                <strong class=" white--text">{{report.from_date}}</strong> - <strong class=" white--text">{{report.to_date}}</strong>
            </v-col>
            <v-col v-if="!loading" align-self="center">
              <v-select 
                     :dark="$store.getters.getColorPalette().isDark"  color="cyan lighten-1"
                    v-if="!loading"
                    v-model="processing"
                    :items="processingOptions"
                    :label="$store.getters.getTextMap().data"
                    outlined
                    small
                    item-text="label"
                    item-value="value"
                    required
                    :rules="objectRules"
                  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                ></v-select>
            </v-col>
            <v-col align="center" align-self="center">
              <v-btn 
                small
             :color="$store.getters.getColorPalette().downloadbtnColor"
                outlined
                @click="download">
                {{ $store.getters.getTextMap().download_table }}
                </v-btn>
                <a ref="csvDownload" hidden></a>
            </v-col>
        </v-row>
        <v-row v-if="tableData && tableData.length>0" no-gutters>
                <v-col  align="right">
                    <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                        <v-btn small>
                            Bar
                        </v-btn>
                        <v-btn small>
                            Line
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
        <v-row no-gutters>
            
            <v-col>
                 <div ref="graph"></div>
            </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <strong v-if="untaggedMachines && untaggedMachines.length">Untagged Machines</strong>
        <v-row v-if="untaggedMachines && untaggedMachines.length">
            <v-col>
                <v-chip
                        class="ma-2"
                        close
                        small
                        color="teal"
                        text-color="white"
                        
                        v-bind:key="machine.machine_id" v-for="machine in untaggedMachines"
                    >
                        {{$store.getters.getWorkspaceById(machine['workspace_id'])['name']}} - {{machine.machine_name}}
                    </v-chip>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
//import DualAxisLineGraphByTimestamp from '@/components/graphs/DualAxisLineGraphByTimestamp';
//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import InfoAlert from '@/components/InfoAlert'
// import ColorUtility from '@/utillities/ColorUtility'
import FormRules from '@/utillities/FormRules'
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm'
import TimePicker from '@/components/inputs/TimePicker'
import InfoComponent from '@/components/InfoComponent'
export default {
    name:'MachineWiseComparisonPanel',
    components:{
        InfoAlert,
        WorkspaceMachineForm,
        TimePicker,
        InfoComponent
    },
    data(){
        return {
            normalised:'raw',
            graphType:'scatter',
            showGraphType:true,
            workspace:null,
            machine:[],
            machineOptions:[],
            untaggedMachines:[],
            timeFrame:'daily',
            showTimeWiseClock:false,
            fromTime:null,
                colorCode:this.$store.getters.getColorPalette().colorTypesCodeArray,
                form:{
                    date:null,
                    tagType:'active_energy',
                    timeFrame:'daily'
                },
                report:{
                from_date:null,
                to_date:null,
                machine:null,
                //parameter2:null
                },
                valid:false,
                menu:false,
                menu1:false,
                menu2:false,
                menu3:false,
                toTime:null,
                layout:{
                    showlegend:true,
                    font:{
                        color:this.$store.getters.getColorPalette().accentCode
                    },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },       
                    yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                    uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                },
                processing:'usage',
                graphData2:[],
                graphData:{},
                tableData:[],
                showDismissibleAlert:false,
                loading:false,
                info:'',
                processingOptions:[
                    {label: this.$store.getters.getTextMap().usage_or_average,value:'usage'},
                    {label: this.$store.getters.getTextMap().peak_value, value:'max'},
                    {label: this.$store.getters.getTextMap().min_value, value:'min'}
                ],
                timeFrameOptions:[
                    {label:this.$store.getters.getTextMap().daily,value:'daily'},
                    {label: this.$store.getters.getTextMap().time_of_day, value:'timewise'},
                ],
                // tagTypeOptions:[
                //     {label:'Active Energy',value:'active_energy'},
                //     {label:'Apparent Energy',value:'apparent_energy'},
                //     {label:'PF',value:'pf'},
                // ],
                objectRules:[
                    v => !!v || this.$store.getters.getTextMap().required,
                ],
                rules:FormRules.rules,
                ISODateRules:[
                    v => !!v || this.$store.getters.getTextMap().required ,
                    v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) ||this.$store.getters.getTextMap().required
                 ]
            }
    },
    mounted(){

    },
    computed: {
        tableName(){
        switch(this.normalised){
          case 'normalised':
          return 'param_normalised_data';
          
          case 'trend':
            return 'param_usage_data'
          default:
          return 'param_data'
        }

      },
        // workspaceOptions(){
        //     let op=[]
          
        //       let g=this.$store.state.workspaces
        //       for(let i of g){
        //           //op.push({value:i, text: i.name })
        //           op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
        //         }
        //   return op
        // },
        tagTypeOptions(){
            let op=[]
          
              let g=this.$store.state.tagTypes
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:{tag_type:i.tag_type, unit:i.unit}, label: i.name  })
                }
          return op
        },
        
         
          fromTimeStamp(){
            if(this.form.date){
              
                let dt=this.form.date.toString()
                if(this.fromTime){
                  dt+=' '+this.fromTime.toString()
                  return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
              
            }else{
              return null
            }
          },
          toTimeStamp(){
            if(this.form.date){
              
                let dt=this.form.date.toString()
                if(this.toTime){
                  dt+=' '+this.toTime.toString()
                  return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return null;
                // return Number(moment(dt,'YYYY-MM-DD').format("X"));
              
            }else{
              return null
            }
          },
    },
    methods: {
        updateMachineList(value){
            this.machine=value.machine
        },
        // getMachineList(){
        //     let op=[]
        //     if(this.workspace){
        //         let payload={
        //             workspace_id:this.workspace.workspace_id
        //         }
        //         // console.log(payload)
        //         this.loading = true;
        //         axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
        //     Authorization: 'Bearer '+ this.$store.state.jwt
        //     }})
        //         .then(response => {
        //         if (response.data.status == "success") {
                    
                    
        //             //console.log(response.data);
                    
        //             let machines=response.data.machines
        //         //this.loading = false;
        //         for(let i of machines){
                  
        //           if(i && i.name){
        //             op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
        //           }else{
        //             op.push({value:i.machine_id, label: i.machine_id })
        //           }
        //         }
                    
        //         } else {
        //             this.info = response.data.msg;
        //             this.showDismissibleAlert = true;
        //         }
        //         //this.onReset()
        //         this.loading = false;
        //         })
        //         .catch(error => {
        //         this.info = error;
        //         this.showDismissibleAlert = true;
        //         this.loading = false;
        //         });
        //     }
        //     this.machineOptions=op
        // },
      remove(m){
        this.machine=this.machine.filter(x=>x.machine_id!=m.machine_id)
      },
      download() {
        let machines = Array.from(new Set(this.tableData.map(item => item.machine_name)));
        let header = ['Date', ...machines];
        let body = [];
        let usageDataByDate = {};
        for (let i of this.tableData) {
            const date = moment(i.date, 'YYYYMMDD').format('YYYY-MM-DD');
            if (!usageDataByDate[date]) {
            usageDataByDate[date] = {};
            }
            usageDataByDate[date][i.machine_name] = i[this.processing];
        }
        for (let date in usageDataByDate) {
            let temp = [date];
            for (let machine of machines) {
            temp.push(usageDataByDate[date][machine] || '');
            }
            body.push(temp);
        }
        let csvHeader = header.join(',') + '\n';
        let csv = csvHeader;
        csv += body.map(e => e.join(',')).join('\n');
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.report.from_date + '_' + this.report.to_date + '.csv';
        hiddenElement.click();
    },

      createGraph(timeFrame){
        let plotData = {}
        if(!timeFrame){
            timeFrame=this.report.timeFrame
        }
        if(!this.processing){
              this.processing='usage'
            }
        //console.log(typeof(this.tableData))
        // this.layout.colorway = this.colorCode
        let type=this.graphType==1?'scatter':'bar'
        for(let i of  this.tableData){
            // console.log(i)
            if(!(i.machine_id in plotData)){
                let temp=this.$store.getters.getWorkspaceById(i['workspace_id'])
                // console.log(i['workspace_id'])
                // console.log(temp)
                if(temp){
                    temp=temp['name']+'-'+i.machine_name
                }else{
                    temp=i.machine_name
                }
                plotData[i.machine_id]={
                    x: [],
                    y: [],
                    name:temp,
                    type: type
                }
            }
            if(timeFrame=='daily'){
                plotData[i.machine_id]['x'].push(moment(i.date).format('YYYY-MM-DD'))
                plotData[i.machine_id]['y'].push(Number(i[this.processing]))
            }else{
                plotData[i.machine_id]['x'].push(moment.unix(i.date).format('YYYY-MM-DD HH:mm:ss'))
                plotData[i.machine_id]['y'].push(Number(i.data))
                //console.log(moment.unix(i.date).format('YYYY-MM-DD HH:mm:ss'))
            }
        }
        //let ar=Object.values(plotData)
        //this.graphData=plotData
        // console.log(Object.values(plotData),'this.graphData')
        Plotly.newPlot(this.$refs.graph, Object.values(plotData), this.layout,{displaylogo: false});
      },
      onSubmit(){
        this.$refs.usageForm.validate()
        if(this.valid){
            this.loading=true
            this.tableData=[]
            let d=Object.assign({}, this.form)
            d.timeFrame=this.timeFrame
            this.layout.yaxis.title = {text:d.tagType.unit,font:{color:this.$store.getters.getColorPalette().accentCode}}
            if(d.timeFrame==='daily'){
                d.from_date=moment(d.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
                d.to_date=moment(d.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD")
                d.from_timestamp = null
                d.to_timestamp = null
                d.tableName = this.tableName
                this.report['from_date']=moment(d.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
                this.report['to_date']=moment(d.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
                this.report['timeFrame']=d.timeFrame
                this.graphType=0
            }else{
                d.from_timestamp=this.fromTimeStamp,
                d.to_timestamp=this.toTimeStamp
                d.from_date=null
                d.to_date=null
                d.tableName = this.tableName
                this.report['from_date']=d.from_timestamp
                this.report['to_date']=d.to_timestamp
                this.report['timeFrame']=d.timeFrame
                this.graphType=1
            }
            try{
                this.untaggedMachines=[]
                for(let i = 0; i < this.machine.length; i++){
                    let name = this.machine[i].machine_name
                    let machine_id = this.machine[i].machine_id
                    let workspace_id = this.machine[i].workspace_id
                    let payload={
                        machine_id:this.machine[i].machine_id,
                        from_date:d.from_date,
                        to_date:d.to_date,
                        from_timestamp:d.from_timestamp,
                        to_timestamp:d.to_timestamp,
                        tag_type:d.tagType.tag_type,
                        timeFrame:d.timeFrame,
                        tableForTimewiseApi:d.tableName
                    }
                    axios.post(this.$store.state.api+'getUsageByMachineTagType',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }}).then(response=>{
                        if(response.data.status==='success'){
                            // console.log(response.data.data)
                        for (let i of response.data.data){
                        let temp ={}
                        if(this.report.timeFrame=='daily'){
                            temp['date']=i.date
                            temp['usage']=i.usage
                            temp['max']=i.max
                            temp['min']=i.min
                            
                        }else{
                            temp['date']=i.timestamp
                            temp['data']=i.data
                        }
                        temp['machine_name']=name
                        temp['machine_id']=machine_id
                        temp['workspace_id']=workspace_id
                        this.tableData.push(temp)
                        }
                        // console.log(this.tableData,'this.tableData')
                        }else{
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                            this.loading=false
                            this.untaggedMachines.push({
                                machine_name:name,
                                machine_id:machine_id,
                                workspace_id:workspace_id
                            })
                            
                        }
                        this.createGraph(d.timeFrame)
                            this.loading=false
                        }).catch(err=>{
                            this.showDismissibleAlert=true
                            this.info=err
                            this.loading=false
                            this.untaggedMachines.push({
                                machine_name:name,
                                machine_id:machine_id,
                                workspace_id:workspace_id
                            })

                        })
                    }
                    
                

                    // this.report['machine']=this.machine
                  //this.report['parameter2']=this.form.parameter2

            }catch(err){
                console.log(err)
            }
            
            }
        }
    
    },
    watch:{
        // workspace(){
        //     this.getMachineList()
        // },
        timeFrame(){
            if(this.timeFrame==="daily"){
                this.showTimeWiseClock = false
            }else{
                this.showTimeWiseClock = true
            }
        },
        graphType: function (){
            // this.init()
            this.createGraph()
        },
        processing(){
        this.createGraph()
      }
    }
}
</script>