<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-spacer/>
            <v-col cols="auto" class="d-flex" align="right">
                <v-btn v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])" @click="showFilter = !showFilter" :dark="$store.getters.getColorPalette().isDark" outlined text :color="$store.getters.getColorPalette().accentCode" small>{{$store.getters.getTextMap().filters}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-filter-outline</v-icon></v-btn>
                <CheckSafetyInspectionLogModal v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])" class="mx-1"/>
                <WriteSafetyInspectionModal v-on:success="refreshData()"/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-dialog
                    v-model="showFilter"
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <SafetyIncidentFilter :filter_for="'safety_inspection'" v-on:filters="applyFilters" v-on:close="showFilter=false"/>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <SafetyInspectionTable :timeFilter="timeFilter" :locations="locations" :inspectors="inspectors" :inspection_types="inspection_types" :refresh="refresh" v-on:refreshed="refreshData()"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WriteSafetyInspectionModal from '@/components/modals/manufacturing/WriteSafetyInspectionModal'
import CheckSafetyInspectionLogModal from '@/components/modals/manufacturing/CheckSafetyInspectionLogModal'
import SafetyInspectionTable from '@/components/lists/manufacturing/SafetyInspectionTable'
import SafetyIncidentFilter from '@/components/filters/manufacturing/SafetyIncidentFilter'
export default {
    name:'SafetyInspectionPanel',
    components:{
        WriteSafetyInspectionModal,
        SafetyInspectionTable,
        SafetyIncidentFilter,
        CheckSafetyInspectionLogModal
    },
    data(){
        return {
            refresh:false,
            showFilter:false,
            timeFilter:"month",
            locations:[],
            inspectors:[],
            inspection_types:[]
        }
    },
    methods:{
        refreshData(){
            this.refresh=!this.refresh
        },
        applyFilters(value){
            this.refresh=true
            this.showFilter=false
            this.timeFilter=value.timeFilter
            this.locations=value.locations
            this.inspectors=value.inspectors,
            this.inspection_types=value.inspection_types
        }
    }

}
</script>