<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-divider></v-divider>
        <v-card class="my-3" flat :dark="$store.getters.getColorPalette().isDark"> 
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess']) ">
            <v-col>
       <v-chip
      class="ma-2"
      small
      outlined
      color="teal"
      text-color="green" v-for="param in report.list"
        :key="param">
        {{getParamName(param)}}
        </v-chip>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
             <v-col>
                <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
 :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
      :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.from_date"
          @input="menu1 = false"
    :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
     :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            :loading="loading"
            v-bind="attrs"
            v-on="on"
            :rules="ISODateRules"
       :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu2 = false"
     :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col align-self="center">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
  :dark="$store.getters.getColorPalette().isDark"
    ></v-skeleton-loader>
                <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().foregroundColorCode"
            small
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().view_report}}

          </v-btn>
            </v-col>
        </v-row>
        </v-form>
        
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
        <v-row v-if="tableData.length>0">
            <v-col align-self="center">
                <strong   :color="$store.getters.getColorPalette().accentCode">{{report.name ?report.name : report.report_id}} - {{dateRangeText}}</strong>
            
                <strong   :color="$store.getters.getColorPalette().accentCode">{{report.from_date}}</strong> - <strong>{{report.to_date}}</strong>
            </v-col>
            <v-col v-if="!loading " align-self="center">
              <v-select
                v-if="!loading"
              v-model="processing"
          :items="processingOptions"
          :label="$store.getters.getTextMap().date"
          outlined
          small
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
       :dark="$store.getters.getColorPalette().isDark"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        </v-col>
            <v-col align="right">
                <v-btn-toggle
        v-if="tableData.length>0"
          v-model="graphType"
          mandatory
     :dark="$store.getters.getColorPalette().isDark"
        >
          <v-btn small>
            {{$store.getters.getTextMap().bar}}

          </v-btn>
          <v-btn small>
            {{$store.getters.getTextMap().line}}

          </v-btn>
              </v-btn-toggle>
            </v-col>
        </v-row>
        
            
        
        <v-row>
          <v-col>
            <div ref="graph"></div>
          </v-col>
        </v-row>
      </v-card>
        <v-row>
          <v-col>
            <v-data-table
      :headers="headers"
      :items="tableData"
      item-key="date"
      :search="search"
      :loading="loading"
    loading-text="Loading... Please wait"
:dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
   :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{$store.getters.getTextMap().usage}}
 - {{dateRangeText}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn 
                v-if="tableData.length>0"
                outlined 
                small
               :color="$store.getters.getColorPalette().downloadbtnColor"
                @click="downloadTable">
                {{$store.getters.getTextMap().download_table}}

                </v-btn>
        
      </v-toolbar>
          </template>
          
          
    </v-data-table>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
        </v-container>
</template>

<script>
//import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
//import BarGraphByDate from '@/components/graphs/BarGraphByDate'
//import LineGraphByDate from '@/components/graphs/LineGraphByDate'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import Plotly from 'plotly.js'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
import ColorUtility from '@/utillities/ColorUtility'

applyPlugin(jsPDF)

export default {
    name:'UsageReportDetails',
    props:['report'],
    components:{
        InfoAlert,
        //LineGraphByTimeStamp,
        //LineGraphByDate,
        //BarGraphByDate
    },
    mounted(){
        this.form['from_date']=moment().subtract(15,'d').format('YYYY-MM-DD')
        this.form['to_date']=moment().subtract(1,'d').format('YYYY-MM-DD')
        this.onSubmit()
        this.headers=[
                {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'date',
            class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName
            
          },
          ]
          /*if(this.report.unit){
          this.headers.push({text:'Usage-'+this.report.unit, value:'usage',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "})
          }else{
            this.headers.push({text:'Usage', value:'usage',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "})
          }*/
    },
    data(){
        return {
          colorCode:ColorUtility.colorTypesCodeArray,
            form:{
                from_date:null,
                to_date:null,
                
            },
            //date: new Date().toISOString().substr(0, 10),
            label:null,
            dateRangeText:null,
            unit:null,
                valid:false,
                menu:false,
                menu1:null,
            menu2:null,
            graphData:{},
            processing:'usage',
            graphType:0,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            search:null,
            rawData:[],
            tableData:[],
            layout:{
              
              showlegend:false,
              colorway:this.$store.getters.colorTypesCodeArray,
              font:{
                         color:this.$store.getters.getColorPalette().accentCode
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
            },
            headers:[
                {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'date',
            class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          //{text:'Usage', value:'usage',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "},
          //{text:'<10% of prev month', value:'less',class: "text-subtitle-1 text-capitalize indigo lighten-3 font-weight-black "},
            ],
            processingOptions:[
                {label:'Usage or Average',value:'usage'},
                //{label:'Standard Deviation',value:'std'},
                //{label:'Statistical Mode',value:'mode'},
                {label: 'Peak Value', value:'max'},
                {label: 'Min Value', value:'min'}
                ],
            ISODateRules:[
            v => !!v || 'Required',
            v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ],
             objectRules:[
        v => !!v || 'Required',
      ],
        }
    },
    computed:{
        
    },
    methods:{
      init(){
        this.headers=[
                {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'date',
            class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName
            
          },
          ]
      },
      downloadTable(){
        //let pdf = new jsPDF('landscape','pt',[512,600]);
            let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=this.headers.map(x=>x['text'])
            let headerVal=this.headers.map(x=>x['value'])
            
            let body=[]
            for (let i of this.tableData){
                let temp=[]
                for(let j of headerVal){
                  temp.push(i[j])
                }
                
                body.push(temp)
            }
            pdf.autoTable({
                head: [header],
                body: body
                })
            // pdf.save('usage.pdf');
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Usage.csv';
            hiddenElement.click();
      },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        createTable(){
          this.init()
          if(this.rawData && this.rawData.length>0){
            this.tableData=[]
            let temp=this.rawData.reduce((prev,current)=>{
              
              if(!(current.date in prev)){
                prev[current.date]={
                  date:current.date
                }
                
              }
              
              prev[current.date][current.param_id]=current[this.processing]
              return prev
            },{})
            this.tableData=Object.values(temp)
            for(let h of this.report.list){
              this.headers.push({text:this.getParamName(h), value:h,class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
            }
          }
        },
        createGraph(){
        if(this.rawData && this.rawData.length>0){
            this.graphData={}
            if(!this.processing){
              this.processing='usage'
            }
            
            for (let i of this.rawData){
                //console.log(i)
                        if(i.param_id && i.param_id in this.graphData){
                            this.graphData[i.param_id]['x'].push(moment(i.date,'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }else{
                          
                            let name=this.getParamName(i.param_id)
                            let type=this.graphType==1?'scatter':'bar'
                            this.graphData[i.param_id]={
                                x: [],
                                y: [],
                                name:name,
                                type: type
                            }
                            this.graphData[i.param_id]['x'].push(moment(i.date,'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'))
                            this.graphData[i.param_id]['y'].push(Number(i[this.processing]))
                        }
                    
                }
                if(Object.keys(this.graphData).length>0){
                  this.layout['showlegend']=true
                }else{
                  this.layout['showlegend']=false
                }
                //this.graphData.push(data)
                this.layout.colorway = this.colorCode
            Plotly.newPlot(this.$refs.graph, Object.values(this.graphData), this.layout,{displaylogo: false, displayModeBar:true,});
        }

      },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  report_id:this.report.report_id,
      from_date:moment(this.form.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
      to_date:moment(this.form.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
      discrete:true
    }
    this.dateRangeText=moment(this.form.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")+' - '+moment(this.form.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
    let endpoint='getDataByUsageReportBetweenDate'
    
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      console.log("report data received")
      //console.log(response.data)
      if(response.data.status==='success'){
let data=[]
this.rawData=[]
for (let i of response.data.data){
  //i['usage']=Number(i['usage'])*Number(this.report.correction_factor)
  //console.log(i)
    data.push(i)
  let tmp=Object.assign({},i)
  tmp['date']=moment(tmp['date'].toString(),'YYYYMMDD').format("DD-MM-YYYY")
  this.rawData.push(tmp)
}
//this.graphData=data
this.createGraph()
this.createTable()
      //console.log(response.data.data)
      //this.report['parameter']=this.form.parameter
      //this.report['date']=this.form.date
      if(this.graphData.length==0){
          this.info = "No data to show";
            this.showDismissibleAlert = true;
      }

    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        }
    },
    },
    watch:{
      processing(){
        this.createGraph()
        this.createTable()
      },
      graphType(){
        this.createGraph()
      }
    }
}
</script>