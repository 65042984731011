<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />  
      <v-card  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
        <v-form ref="usageForm" v-model="valid">
          <v-row class="mx-2 pt-2">
            <v-col>
              <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :dark="$store.getters.getColorPalette().isDark">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.from_date"
                    :label="$store.getters.getTextMap().from_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
                    :rules="ISODateRules"
                    :dark="$store.getters.getColorPalette().isDark"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.from_date" @input="menu1 = false" :dark="$store.getters.getColorPalette().isDark"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  :dark="$store.getters.getColorPalette().isDark"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.to_date"
                      :label="$store.getters.getTextMap().to_date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :loading="loading"
                      v-bind="attrs"
                      v-on="on"
                      :rules="ISODateRules"
                      :dark="$store.getters.getColorPalette().isDark"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.to_date" @input="menu2 = false" :dark="$store.getters.getColorPalette().isDark"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-btn v-if="!loading"  small :color="$store.getters.getColorPalette().foregroundColorCode" filled  @click="fetchReport">{{$store.getters.getTextMap().view_report}}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-card  class="mt-5" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
          <v-row class="my-2 px-2">
            <v-col align="right">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-btn
                v-if="!loading"
                :color="$store.getters.getColorPalette().accentCode"
                outlined
                small
                @click="openForm"
                >{{$store.getters.getTextMap().generate_report_manually}}

              </v-btn>
            </v-col>
          </v-row>
        <v-divider></v-divider>
        <v-form ref="listForm" v-model="listValid" v-if="showListForm===true">
          <v-row class="mx-2 mt-2">
            <v-col>
              <v-menu
                v-model="menu6"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :dark="$store.getters.getColorPalette().isDark"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="from_date_list"
                  :label="$store.getters.getTextMap().from_date"
                  prepend-icon="mdi-calendar"
                  readonly
                  :loading="loading"
                  v-bind="attrs"
                  v-on="on"
                  :rules="ISODateRules"
                  :dark="$store.getters.getColorPalette().isDark"
                ></v-text-field>
              </template>
              <v-date-picker v-model="from_date_list" @input="menu6 = false" :dark="$store.getters.getColorPalette().isDark"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu
                v-if="!loading"
                ref="menufrom"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :dark="$store.getters.getColorPalette().isDark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.from_time"
                    :label="$store.getters.getTextMap().from_time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="objectRules"
                    :dark="$store.getters.getColorPalette().isDark"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="form.from_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menufrom.save(form.from_time)"
                  :dark="$store.getters.getColorPalette().isDark"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu7"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :dark="$store.getters.getColorPalette().isDark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="to_date_list"
                    :label="$store.getters.getTextMap().to_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
                    :rules="ISODateRules"
                    :dark="$store.getters.getColorPalette().isDark"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="to_date_list" @input="menu7 = false" :dark="$store.getters.getColorPalette().isDark"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu
                v-if="!loading"
                ref="menuto"
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :dark="$store.getters.getColorPalette().isDark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.to_time"
                    :label="$store.getters.getTextMap().to_time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="objectRules"
                    :dark="$store.getters.getColorPalette().isDark"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu4"
                    v-model="form.to_time"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menuto.save(form.to_time)"
                  :dark="$store.getters.getColorPalette().isDark"
                ></v-time-picker>
              </v-menu>
            </v-col> 
            <v-col>
              <v-text-field
                outlined
                dense
                v-if="!loading"
                v-model="form.bill_amount"
                :rules="numberRules"
                :label="$store.getters.getTextMap().bill_amount"
                required
                :dark="$store.getters.getColorPalette().isDark"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-2">
            <v-col align-self="center">
                <v-checkbox 
                  :dark="$store.getters.getColorPalette().isDark" 
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-model="selectedIncludeOther" 
                  :label="$store.getters.getTextMap().include_others" value="include" 
                ></v-checkbox>
            </v-col>
            <v-col align-self="center">
              <v-checkbox
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-model="manualApparentEnergy"
                :label="$store.getters.getTextMap().enter_apparent_energy_manually"
              ></v-checkbox>
            </v-col>
            <v-col align-self="center" v-if="manualApparentEnergy">
              <v-text-field
                outlined
                dense
                v-if="!loading"
                v-model="form.manual_apparent_energy"
                :rules="numberRules"
                :label="$store.getters.getTextMap().apparent_energy"
                required
                :dark="$store.getters.getColorPalette().isDark"
              ></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-checkbox
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-model="manualActiveEnergy"
                :label="$store.getters.getTextMap().enter_active_energy_manually"
              ></v-checkbox>
            </v-col>
            <v-col align-self="center" v-if="manualActiveEnergy">
              <v-text-field
                outlined
                dense
                v-if="!loading"
                v-model="form.manual_active_energy"
                :rules="numberRules"
                :label="$store.getters.getTextMap().active_energy"
                required
                :dark="$store.getters.getColorPalette().isDark"
              ></v-text-field>
            </v-col>
            <v-col align="right" align-self="center">
              <v-btn class="mr-2" v-if="!loading" small :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().generate}}</v-btn>
              <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().deletebtnColor" @click="onReset">{{$store.getters.getTextMap().cancel}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="indexedItems"
          item-key="id"
          :search="search"
          :loading="loading"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          loading-text="Loading... Please wait"
          :dark="$store.getters.getColorPalette().isDark"
          class="elevation-4"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$store.getters.getTextMap().energy_bill_distribution_detailed_report}}</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="item.status==='processing'" :colspan="headers.length">
              {{$store.getters.getTextMap().the_report_is_processing}}
            </td>
            <td v-else :colspan="headers.length">
              <EnergyBillDistDetailedReport :energyBillDistReport="item" />
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <a ref="csvDownload" hidden></a>
  </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import EnergyBillDistDetailedReport from '@/components/items/EnergyBillDistDetailedReport'
import axios from 'axios'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
export default {
    name:'EnergyBillDistReportDetails',
    props:['report'],
    components:{
      InfoAlert,
      EnergyBillDistDetailedReport
    },
    mounted(){
      this.form['from_date']=moment().subtract(180,'d').format('YYYY-MM-DD')
      this.form['to_date']=moment().format('YYYY-MM-DD')
      this.fetchReport()  
    },
    data(){
        return {
          form:{
              from_date:null,
              to_date:null,
              from_time:null,
              to_time:null,
              bill_amount:0,
              manual_apparent_energy:0,
              manual_active_energy:0
            },
          manualApparentEnergy:false,
          manualActiveEnergy:false,
          label:null,
          dateRangeText:null,
          unit:null,
          valid:false,
          listValid:false,
          menu:false,
          menu1:null,
          menu2:null,
          menu6:null,
          menu7:null,
          expanded: [],
          graphData:{},
          graphType:0,
          selectedIncludeOther:'',
          include_other:false,
          showDismissibleAlert:false,
          from_date_list:null,
          to_date_list:null,
          from_time:null,
          to_time:null,
          showListForm:false,
          loading:false,
          info:'',
          menu3:null,
          menu4:null,
          search:null,
          Data:[],
          tableData:[],
          numberRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && v>0)|| 'It should be a valid number',
            v => (v && v.length <= 10 && v.length > 0) || 'Name must be less than 5 digits',
          ],
          headers:[
              {text:'From Time', value:'from_time',align:'start',filterable: false,class: "text-subtitle-1 text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName   },
              { text: 'To Time', value: 'to_time', class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Status', value: 'status', class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Bill Amount', value: 'bill_amount',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
              // { text: 'File Name', value: 'excel_loc',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName },
          ],
          ISODateRules:[
            v => !!v || 'Required',
            v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
          ],
            objectRules:[
            v => !!v || 'Required',
          ],
        }
    },
    computed:{
      fromTimeStamp(){
        if(this.from_date_list){
          let dt=this.from_date_list.toString()
          if(this.form.from_time){
            dt+=' '+this.form.from_time.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').format("X"));
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.to_date_list){
          let dt=this.to_date_list.toString()
          if(this.form.to_time){
            dt+=' '+this.form.to_time.toString()
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt,'YYYY-MM-DD').add('days',1).format("X"));
        }else{
          return null
        }
      },
      indexedItems () {
        return this.tableData.map((item, index) => ({
          id: index,
          ...item
        }))
      }
    },
    methods:{
        openForm(){
          this.showListForm = true
        },
        updateTableHeaders(){
          if(this.tableData.report_loc && this.tableData.excel_loc){
            this.headers.push({ text: 'File Name', value: 'excel_loc',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName })
          }else{
            this.headers.push({ text: 'File Name', value: 'zip_loc',class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName })
          }
        },
        fetchReport(){
          this.$refs.usageForm.validate()
          if(this.valid){
            this.loading=true
            let payload={
                  report_id:this.report.report_id,
                  from_date:moment(this.form.from_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
                  to_date:moment(this.form.to_date.toString(),'YYYY-MM-DD').format("YYYYMMDD"),
                  discrete:true
                }
              this.dateRangeText=moment(this.form.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")+' - '+moment(this.form.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
              let endpoint='getDataByEnergyBillDistReportBetweenDate'
              axios.post(this.$store.state.api+endpoint,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response=>{
                if(response.data.status==='success'){
                  this.rawData=[]
                  for(let i of response.data.data){
                      i.from_time = moment(i.from_time).format("YYYY/MM/DD - HH:mm");
                      i.to_time = moment(i.to_time).format("YYYY/MM/DD - HH:mm");
                      let temp = ({'from_time':i.from_time,'to_time':i.to_time,'status':i.status,'bill_amount':i.bill_amount,'report_loc':i.report_loc,'excel_loc':i.excel_loc,'zip_loc':i.zip_loc})
                      this.rawData.push(temp)
                  }
                  this.tableData=Object.values(this.rawData)
                  if(this.graphData.length==0){
                    this.info = "No data to show";
                    this.showDismissibleAlert = true;
                  }
                }else{
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                  this.loading=false
                }
                this.loading=false
              })
            .catch(err=>{
              this.showDismissibleAlert=true
              this.info=err
              this.loading=false
            })
          }
        },
        onSubmit(){
            this.$refs.listForm.validate()
            if(this.listValid){
              this.loading=true
              this.from_time=this.fromTimeStamp
              this.to_time=this.toTimeStamp
              let payload={
                    report_id:this.report.report_id,
                    bill_amount:this.form.bill_amount,
                    from_time:this.from_time,
                    to_time:this.to_time,
                    include_other:this.include_other,
                    manual_apparent_energy:this.manualApparentEnergy,
                    apparent_energy_value:this.form.manual_apparent_energy,
                    manual_active_energy:this.manualActiveEnergy,
                    active_energy_value:this.form.manual_active_energy,
                  }
              this.dateRangeText=moment(this.form.from_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")+' - '+moment(this.form.to_date.toString(),'YYYY-MM-DD').format("DD-MM-YYYY")
              let endpoint='createEnergyBillDistList'
              axios.post(this.$store.state.api+endpoint,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response=>{
              if(response.data.status==='success'){
                this.fetchReport()
                  if(this.graphData.length==0){
                    this.info = "No data to show";
                    this.showDismissibleAlert = true;
                  }
              }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
              }
              this.loading=false
              })
              .catch(err=>{
                this.showDismissibleAlert=true
                this.info=err
                this.loading=false
              })
            }
          },
          onReset(){
            this.showListForm=false
            this.$refs.listForm.reset()
          }
        },
        watch:{
          selectedIncludeOther(){
            this.include_other = ! this.include_other
          },
          // tableData(){
          //   this.updateTableHeaders()
          // }
        }
}
</script>