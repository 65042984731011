<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-autocomplete
                            v-if="!loading"
                            v-model="location_event_type"
                            :items="locationEventTypes"
                            :label="$store.getters.getTextMap().location_event_type"
                            outlined   
                            dense       
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="[rules.required]"
                        >
                        <template v-slot:selection="{ item }">
                            <strong>
                                <table>
                                <tr>
                                    <td>{{item.name}}</td>:
                                    <td>{{getLocationNameById(item.from_location)}}</td>-
                                    <td>{{getLocationNameById(item.to_location)}}</td>:
                                    <td>{{item.event_type}}</td>
                                </tr>
                            </table>
                            </strong>
      
   </template>
   <template v-slot:item="{ item }">
    <table>
                                <tr>
                                    <td>{{item.name}}</td>:
                                    <td>{{getLocationNameById(item.from_location)}}</td>-
                                    <td>{{getLocationNameById(item.to_location)}}</td>:
                                    <td>{{item.event_type}}</td>
                                </tr>
                            </table>
   </template>
</v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="location_event_type">
            <v-col>
                <MoveBatch :location_event_type="location_event_type" />
            </v-col>
        </v-row>
    </v-container>
        

</template>

<script>
import FormRules from '@/utillities/FormRules'
import MoveBatch from '@/components/crud_components/manufacturing/MoveBatch'
export default{
    name:'BatchMovementPanel',
    components:{
        MoveBatch
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            location_event_type:null,
            rules:FormRules.rules,
            
      }
    },
    computed:{
        locationEventTypes(){
            // let op=[]
            // if(this.$store.state.locationEventTypes){
            //     for(let i of this.$store.state.locationEventTypes){
            //         op.push({
            //             label:i,
            //             value:i
            //         })

            //     }

            // }
            return this.$store.state.locationEventTypes
        }
    },
    methods:{
        getLocationNameById(location_id){
            let location=this.$store.getters.getLocationById(location_id)

            return location?location.location_name:location_id
        }
    }
}</script>