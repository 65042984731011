<template>
    <v-container fluid>
  <InfoAlert
    :showDismissibleAlert="showDismissibleAlert"
    v-on:close="showDismissibleAlert = false"
    :info="info"
  />
  <v-card flat  :color="$store.getters.getColorPalette().backgroundColorCode"   >
    <v-card-title
      >
      </v-card-title>
      <v-card-subtitle>
        <v-row v-if="voucher.stage=='approved'">
            <v-col>
                <IssueStoreVoucherBottomSheet v-on:success="update" :voucher="voucher" />
            </v-col>
        </v-row>
          <v-simple-table v-if="!loading" >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <strong>Voucher ID</strong>
                  </th>
                  <th class="text-left">
                    <strong>item code</strong>
                  </th>
                  <th class="text-left">
                    <strong>Intended Qty</strong>
                  </th>
                  <th class="text-left">
                    <strong>Issued Qty</strong>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                  
                <tr v-for="(item,index) in itemList" :key="index">

                  <td>{{ item.voucher_id }}</td>
                  <td>{{ item.item_code }}</td>
                  <td>{{ item.intended_qty }}</td>
                  <td>{{ item.issued_qty }}</td>

                 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-card-subtitle>
      </v-card>
      </v-container>

</template>
<script >
import axios from 'axios'
import InfoAlert from "@/components/InfoAlert";
import IssueStoreVoucherBottomSheet from "@/components/modals/manufacturing/stores/IssueStoreVoucherBottomSheet";
export default{
  name:'StoreVoucher',
  props:{
      voucher:{
          type:Object,
          required:true
      }
  },
  mounted(){
    this.getVoucherItems()
  },
  components:{
      InfoAlert,
      IssueStoreVoucherBottomSheet
  },
  data(){
      return {
        loading: false,
        info: "",
        showDismissibleAlert: false,
        itemList:[]

      }
  },
  computed:{

  },
  methods:{
    update(){
        this.getVoucherItems()
        this.$emit('update')
    },
    getVoucherItems(){
        this.loading=true
                // this.form.process_id=this.process.process_id

                axios.post(this.$store.state.api+'getIssueVoucherDetailsByID',this.voucher,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.itemList=response.data.data
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
    }
  }

}
</script>