<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-data-table
            :headers="headers"
            :items="locations"
            item-key="location_id"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :dark="$store.getters.getColorPalette().isDark"
        >
            <template v-slot:top>
            <v-toolbar
                flat
                :dark="$store.getters.getColorPalette().isDark"
            >
            <v-toolbar-title>{{ $store.getters.getTextMap().locations }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <CreateLocationModal v-on:update="getLocations" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                <v-icon class="ml-2" small :color="$store.getters.getColorPalette().accentCode" @click="getLocations">mdi-refresh</v-icon>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-container fluid>
            <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-col align="right">
                
                <DeleteConfirmation v-on:confirm="deleteLocation(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
               
                <v-icon small :color="$store.getters.getColorPalette().alertColor">mdi-delete</v-icon>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        </v-container>
      </td>
    </template>
          
    </v-data-table>
  </v-card>
</template>
<script>
import CreateLocationModal from '@/components/modals/derived/CreateLocationModal'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'LocationTable',
    components:{
        CreateLocationModal,
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
      this.getLocations()
    },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            expanded: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    filterable: false,
                    value: 'location_id',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Name', value: 'location_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                { text: 'Type', value: 'location_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    computed:{
        locations(){
            return this.$store.state.locations
        }
    },
    methods:{
        getLocations(){
            this.$store.dispatch('refreshLocations')
        },
        deleteLocation(item){
            this.loading=true
            axios.post(this.$store.state.api+'deleteLocation',item,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.getLocations()
                    this.$store.dispatch('refreshLocations')
                    
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true 
            })
        },
    },
}
</script>
