<template>
    <div class="py-5 px-auto">
      <span :style="{color:$store.getters.getColorPalette().accentCode}"  :dark="$store.getters.getColorPalette().isDark" ><strong>{{label}}</strong></span>
      <v-btn
               :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
              outlined
             small
                @click="downLoadPDF()">
            Download PDF
      <v-icon
        right
     :dark="$store.getters.getColorPalette().isDark" 
      >mdi-download
      </v-icon>
        </v-btn>
        <canvas  ref="canvas"></canvas>
    </div>
</template>

<script>
import {Line } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'
// import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'LineGraphByDate',
    extends: Line ,
    props:['label','graphData','dataKey','dates','unit','beginAtZero'],
    components:{
        
    },
    computed:{
      graphName(){
        if(this.label){
              return this.label
          }
          return 'Graph'
      },
        dateLabels(){
            let l=[]

            if(this.dates){
              for(let i of this.dates){
                l.push(new Date(Number(moment(i.toString(),'YYYYMMDD').format("x"))))
              }
            }else{
            for (let i of this.graphData){
                //console.log(moment(this.$store.state.last7Days[i].date.toString(),'YYYYMMDD').format('dddd'));
                //l.push(moment(Number(i.timestamp)*1000).format('HH:mm'));
                
                l.push(new Date(Number(moment(i.date.toString(),'YYYYMMDD').format("x")))); //upper for seconds lower x for millis
                //console.log(i)
            }
            }
            return l
            //return ['1','2','3','4']
        },
        consumed(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey])
            }
            
            return l
        },
        yAxisLabel(){
          if(this.unit && this.yLabel){
            return this.yLabel +" - "+ this.unit
          }else if(this.unit){
            return this.unit
          }else if(this.label){
            return this.label
          }else{
            return ''
          }
        }
    },
    data(){
        return {
          colorCode:this.$store.getters.getColorPalette().colorTypesCodeArray,
             
        }
    },
    methods: {
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);

                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  
                  pdf.text(this.graphName, 40,40)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.graphName+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
      },
        createChart(){
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dateLabels,
          datasets: [
            {
                label:this.label,
               backgroundColor: this.colorCode[0],//'#00BFA5', '#90A4AE',
               borderColor:this.colorCode[1],//'rgba(20, 25, 100,0.8)',
               pointBorderColor: this.colorCode[2],//'rgba(20, 25, 100,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 5,
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed,
                fill:true,
                lineTension:0
            }
          ]},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{display: false},
          scales: {
        xAxes: [ {
          type: 'time',
          distribution: 'linear', //series
          time: {
                    unit: 'day',
                    /*displayFormats: {
                        day: 'MM Do hh:mm a'
                    }*/
                },
          display: true,
          gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
          scaleLabel: {
            display: false,
            labelString: 'Date',
             fontColor:this.$store.getters.getColorPalette().tickfontColor,
            
          },
          ticks: {  
             fontColor:this.$store.getters.getColorPalette().tickfontColor,
            major: {
              fontStyle: 'bold',
               fontColor:this.$store.getters.getColorPalette().tickfontColor,
            },
            source:'auto'
          }
        } ],
        yAxes: [ {
          display: true,
           gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
          scaleLabel: {
            display: true,
            labelString: this.yAxisLabel,
              fontColor:this.$store.getters.getColorPalette().tickfontColor,
          },
          ticks:{ 
            
           fontColor:this.$store.getters.getColorPalette().tickfontColor,
            beginAtZero: this.beginAtZero
          }
        } ]
      }})
        }
    },
    mounted() {

        this.createChart()
        
    },
    watch: {
        consumed:function(){
      
      this.createChart()

        }
    },
}
</script>
<style >

</style>