<template>
    <v-container fluid
      :style="{ background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode }">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
  
  
  
  
  
      <v-card 
        class="mx-0 my-1" transition="scroll-x-reverse-transition" :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().background2ColorCode">
        <v-form ref="usageForm" v-model="valid">
          <v-row class="pt-2" no-gutters>
            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              
                  <v-text-field v-model="batch_id" :label="$store.getters.getTextMap().batch_id" prepend-icon="mdi-abacus"  
                  :rules="[rules.required, rules.noSpace]" outlined dense></v-text-field>
                
            </v-col>
            <v-col align-self="center">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-btn class="mt-n6" v-if="!loading" :color="$store.getters.getColorPalette().accentCode" outlined small
                @click="fetchBatchDetails">
               {{ $store.getters.getTextMap().show_details }}
              </v-btn>
  
            </v-col>
            
            <v-col align-self="center" align="right">
               
                <v-icon  v-if="!loading"  class="mt-n6 pr-1" small :color="$store.getters.getColorPalette().accentCode" @click="showFilter=!showFilter">{{showFilter?'mdi-filter-off-outline':'mdi-filter-outline'}}</v-icon>
              
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="filterForm" v-model="filterValid" v-if="showFilter">
            <v-divider></v-divider>
            <br>
          <v-row class="px-8">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-select v-if="!filterLoading" v-model="filterForm.param" :items="filterParamOptions" :label="$store.getters.getTextMap().params"
                :rules="objectRules" outlined dense required item-text="label" item-value="value" clearable></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-select v-if="!filterLoading" v-model="filterForm.operator" :items="operators" item-text="label"
                item-value="value"  :label="$store.getters.getTextMap().operator" :rules="objectRules" required
                :dark="$store.getters.getColorPalette().isDark" clearable
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }" outlined dense></v-select>
            </v-col>
  
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-text-field :loading="filterLoading" v-model="filterForm.value" :counter="11" :rules="objectRules"
              :label="$store.getters.getTextMap().target_value" required outlined dense></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-progress-circular indeter minate v-if="filterLoading" color="primary"></v-progress-circular>
              <v-btn v-if="!filterLoading" class="mt-n6" :color="$store.getters.getColorPalette().accentCode" outlined
                small @click="addFilter">
                {{ $store.getters.getTextMap().add_filter }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
  
  
      <v-row v-if="filters.length > 0">
        <v-col>
          <span><strong>Filters:</strong></span>
          <v-chip small class="ma-2" close @click:close="deleteFilter(filter.key)" color="indigo" text-color="white"
            v-for="filter in filters" :key="filter.key">
            {{ filter.param }} {{ filter.operator }} {{ filter.value }}
          </v-chip>
        </v-col>
      </v-row>
  
      <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
      <v-tabs
          :dark="$store.getters.getColorPalette().isDark"
          :background-color="$store.getters.getColorPalette().backgroundColorCode"
          v-model="tab"
          show-arrows
        >
          <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
          <v-tab
          v-if="batch"
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
            
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-notebook</v-icon>{{ $store.getters.getTextMap().details }}
          </v-tab>
          <v-tab
          v-if="batch"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().btnborderColorCode"
          
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-map-marker-path</v-icon>{{ $store.getters.getTextMap().trace_logs }}
        </v-tab>
        <v-tab
        v-if="batch"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().btnborderColorCode"
          
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-arrow-collapse-right</v-icon>{{ $store.getters.getTextMap().movement_events }}
        </v-tab>
        
        </v-tabs>
        <v-divider v-if="batch"  :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-tabs-items v-model="tab" v-if="batch"  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
          >
          <BatchDetails :batch="batch" />
          </v-tab-item>
          
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
          >
          <v-row v-if="graphData.length > 0">
        <v-col cols="12">
          <v-card :dark="$store.getters.getColorPalette().isDark">
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.getTextMap().search" single-line
                hide-details></v-text-field>
            </v-card-title>
  
            <v-data-table :headers="tableHeaders" :items="filteredData" item-key="timestamp" :search="search"
              :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
              loading-text="Loading... Please wait" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $store.getters.getTextMap().tracing_logs }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn v-if="graphData.length > 0" outlined :color="$store.getters.getColorPalette().downloadbtnColor"
                    small @click="downloadPDF">
                    {{ $store.getters.getTextMap().download_table }}
                  </v-btn>
                  <a ref="csvDownload" hidden></a>
                </v-toolbar>
              </template>
              <template v-slot:item.location_id="{ item }">
                <strong>{{ getLocationNameById(item.location_id) }}</strong>
              </template>
              <template v-slot:item.timestamp="{ item }">
                <strong>{{ toReadableTime(item.timestamp) }}</strong>
              </template>
              <template v-slot:item.from_location_id="{ item }">
                <strong>{{ getLocationNameById(item.production_time) }}</strong>
              </template>
              
  
  
              <!-- template for editing the dynamic logs details -->
  
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <BatchDetails :batch="item" v-on:update="fetchBatchTraceLogs" />
  
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
          </v-tab-item>
          <v-tab-item
            :dark="$store.getters.getColorPalette().isDark"
          >
          <BatchMovementTable :batch_id="batch.batch_id" />
          </v-tab-item>
        </v-tabs-items>
      <v-card v-if="batch" :dark="$store.getters.getColorPalette().isDark">
        <v-row >
          <v-col>
              
          </v-col>
        </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-row v-if="batch && batch.batch_id">
      <v-col>
        
      </v-col>
    </v-row>
      <v-divider></v-divider>
      
    </v-container>
  
  </template>
  <script>
  import InfoAlert from '@/components/InfoAlert'
  import BatchDetails from '@/components/items/manufacturing/BatchDetails'
  import BatchMovementTable from '@/components/lists/manufacturing/BatchMovementTable'
//   import DeleteConfirmation from '@/components/DeleteConfirmation'
//   import CreateBatchTracingLog from '@/components/crud_components/loggers/CreateBatchProductionLog'
//   import WriteBatchProductionLog from '@/components/crud_components/loggers/WriteBatchProductionLog'
//   import EditWrittenBatchLog from '@/components/crud_components/loggers/EditWrittenBatchLog'
import FormRules from '@/utillities/FormRules'
  import moment from 'moment'
  import axios from 'axios'
  import jsPDF from 'jspdf'
  import 'jspdf-autotable'
  import { applyPlugin } from 'jspdf-autotable'
  applyPlugin(jsPDF)
  export default {
    name: 'BatchesTable',
    // props: ['batchLog'],
    components: {
    //   CreateBatchTracingLog,
      InfoAlert,
      BatchDetails,
      BatchMovementTable,
    //   DeleteConfirmation,
    //   WriteBatchProductionLog,
    //   EditWrittenBatchLog
  
    },
    mounted() {
    //   this.init()
      this.form.date = []
      this.form.date[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
      this.form.date[1] = moment().add(1, 'd').format('YYYY-MM-DD')
      this.fetchBatchDetails()
    },
    data() {
      return {
        sortBy: 'timestamp',
        sortDesc: false,
        multiple: false,
        isActive: false,
        editDialog: false,
        batch_id:null,
        batch:null,
        tab:null,
        form: {
          date: null,
          log_id: null
        },
        groupBy: null,
        //date: new Date().toISOString().substr(0, 10),
        expanded: [],
        search: '',
        valid: false,
        groupValid: false,
        menu: false,
        // meta: {},
        graphData: [],
        groupedData: [],
        showDismissibleAlert: false,
        loading: false,
        info: '',
        showFilter:false,
        filters: [],
        filterLoading: false,
        filterValid: false,
        selectedItem: null,
        dialog: false,
        showUnshipped:true,
        filterForm: {
          param: null,
          operator: '==',
          value: null
        },
        operators: [
          { label:this.$store.getters.getTextMap().greater_than, value: '>' },
          { label:this.$store.getters.getTextMap().lesser_than, value: '<' },
          { label:this.$store.getters.getTextMap().equals_to, value: '==' },
          { label:this.$store.getters.getTextMap().greater_than_equal_to, value: '>=' },
          { label:this.$store.getters.getTextMap().lesser_than_equal_to, value: '<=' },
        ],
        objectRules: [
          FormRules.rules.required
        ],
        rules:FormRules.rules,
        headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
  
        ],
        groupByOptions: [
          { label: this.$store.getters.getTextMap().from_time, value: 'production_start' },
          { label: this.$store.getters.getTextMap().to_time, value: 'production_time' },
          { label: this.$store.getters.getTextMap().quantity, value: 'quantity' },
        ],
        editBatchLogData: false,
        batchLogProp: null
      }
    },
    computed: {
  

      filteredData() {
        let filteredData = this.graphData
        for (let i of this.filters) {
          let filterFunc = () => {
          }
          switch (i.operator) {
            case '>=': filterFunc = (data) => { return data[i.param] >= i.value };
              break;
            case '>': filterFunc = (data) => { return data[i.param] > i.value };
              break;
            case '==': filterFunc = (data) => { return data[i.param] == i.value };
              break;
            case '<=': filterFunc = (data) => { return data[i.param] <= i.value };
              break;
            case '<': filterFunc = (data) => { return data[i.param] < i.value };
              break;
          }
          filteredData = filteredData.filter(filterFunc);
        }
        return filteredData
      },
   
  
      
      filterParamOptions() {
        let op = [{
          label:'Status',
          value:'status_text',
        },
        
    ]
  
        return op
      },
      tableHeaders() {
        // console.log(this.batchLog);
  
  
  
        let headers = [
          {
            text: 'Time',
            align: 'start',
            filterable: true,
            value: 'timestamp',
          },
        //   {
        //     text: 'Production start Time',
        //     // align: 'start',
        //     filterable: true,
        //     value: 'production_start',
        //   },
          { text: "Code", value: 'log_text' },
          { text: "From Location", value: 'from_location_id' },
          { text: "To Location", value: 'location_id' },
          { text: "Status", value: 'status_text' }
          
  
        ]
        return headers
      }
  
    },
    methods: {

      
      refreshLogs() {
        this.fetchBatchTraceLogs();
      },
      closeDialog() {
        this.editBatchLogData = !this.editBatchLogData
      },
      getLocationNameById(location_id){
            let location=this.$store.getters.getLocationById(location_id)

            return location?location.location_name:location_id
        },
      toReadableTimeFromEpoch(timestamp) {
        if (isNaN(timestamp)) {
          return null
        }
        return moment.unix(Number(timestamp)).format('dddd, MMMM Do YYYY, h:mm:ss a')
  
      },
      toReadableTime(timestamp) {
        if(timestamp)
        return moment(timestamp).format('YYYY-MM-DD, HH:mm:ss (dddd, MMMM Do )')
      return '-'
  
      },
      
      deleteBatchLog() {
        // console.log(this.batchLog);
        axios.post(this.$store.state.api + 'deleteBatchProductionLog', this.batchLog, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status === 'success') {
            this.info = 'Logger Deleted successfully';
  
            this.showDismissibleAlert = true;
            this.$store.dispatch('refreshBatchLogs')
          } else {
  
            this.info = response.data.msg;
  
            this.showDismissibleAlert = true;
  
          }
          this.loading = false
  
  
        }).catch(err => {
          console.error(err)
          this.showDismissibleAlert = true
          this.info = err
          this.loading = false
        })
  
      },
  
      editBatchLogDatas(item) {
        this.selectedItem = item;
        this.batchLogProp = { ...this.batchLog };
        this.editBatchLogData = !this.editBatchLogData
      },
      downloadPDF() {
  
        //let pdf = new jsPDF('landscape','pt',[512,600]);
        let pdf = new jsPDF();
        //pdf.table(20, 30, this.currentData);
        let header = []
        let keyMapping = {};
        this.headers.forEach(val => {
          header.push(val.text);
          keyMapping[val.text] = val.value;
        })
  
        // console.log(this.headers);
  
        let body = []
  
        for (let i of this.filteredData) {
          const values = header.map(heading => {
            const key = keyMapping[heading];
            let value = i[key];
  
            if (key === "production_start" || key === "production_time") {
              value = this.toReadableTime(value);
            }
            const escaped = ('' + value)
            return `"${escaped}"`;
          })
  
          body.push(values)
        }
  
        // console.log(body);
  
  
        pdf.autoTable({
          head: [header],
          body: body
        })
        pdf.save(this.batchLog.name + '_' + this.dateRangeText + '.pdf');
  
        let csvHeader = header.join(',') + "\n"
        let csv = csvHeader
        csv += body.map(e => e.join(",")).join("\n");
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        //let hiddenElement = document.createElement('a');
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.batchLog.name + '_' + this.dateRangeText + '.csv';
        hiddenElement.click();
  
      },
  
  
      fetchBatchDetails() {
        this.$refs.usageForm.validate()
        if (this.valid && this.batch_id) {
            
          this.loading = true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload = {
            // log_id: this.batchLog.log_id,
            batch_id:this.batch_id
          }
          
  
          axios.post(this.$store.state.api + 'getDetailsByBatchID', payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              // console.log(response);
              //console.log("report data received")
              //console.log(response.data)
              if (response.data.status === 'success') {
                
                this.batch=response.data.data
                this.fetchBatchTraceLogs()
                
                if (!this.batch) {
                  this.info = "No batch Details";
                  this.showDismissibleAlert = true;
                }
  
              } else {
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading = false
              }
              this.loading = false
  
            })
            .catch(err => {
              console.log(err)
              this.showDismissibleAlert = true
              this.info = err
              this.loading = false
            })
  
        }
      },
  
      fetchBatchTraceLogs() {
        this.$refs.usageForm.validate()
        if (this.valid && this.batch_id) {
          this.loading = true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload = {
            // log_id: this.batchLog.log_id,
            batch_id:this.batch_id
          }
          
          let endpoint = 'getBatchTraceLogs'
          //console.log(fromTimeStamp)
  
          axios.post(this.$store.state.api + endpoint, payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              // console.log(response);
              //console.log("report data received")
              //console.log(response.data)
              if (response.data.status === 'success') {
                
                this.graphData=response.data.data
                // let data = []
                // for (let i of response.data.data) {
  
                //   //   i['timestamp']=moment(i['timestamp']).unix()
                //   let temp = {
                //     production_start: i.production_start,
                //     production_time: i.production_time,
                //     quantity: i.quantity,
                //     sku_id:i.sku_id,
                //     code:i.code,
                //     location_id:i.location_id,
                //     remarks:i.remarks,
                //     logged_by:i.logged_by,
                //     status_text:i.status_text,
                //     ...i.meta
                //   }
  
                //   data.push(temp)
  
                // }
                // this.graphData = data
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if (this.graphData.length == 0) {
                  this.info = "No Trace Logs";
                  this.showDismissibleAlert = true;
                }
  
              } else {
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading = false
              }
              this.loading = false
  
            })
            .catch(err => {
              console.log(err)
              this.showDismissibleAlert = true
              this.info = err
              this.loading = false
            })
  
        }
      },
  
      addFilter() {
          this.$refs.filterForm.validate()
          if (this.filterValid) {
            let d = Object.assign({}, this.filterForm)
            d.key = Number(moment().format("X"))
            this.filters.push(d)
            this.$refs.filterForm.reset()
          }
        },
  
        deleteFilter(key) {
          this.filters = this.filters.filter(
            function (data) { return data.key != key }
          );
        },
  
        deleteLogData(logData) {
          this.loading = false
          let payload = Object.assign({ }, logData)
          axios.post(this.$store.state.api + 'deleteBatch', payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          }).then(response => {
            if (response.data.status === 'success') {
              this.info = 'Entry Deleted successfully';
  
              this.showDismissibleAlert = true;
              this.fetchBatchTraceLogs()
            } else {
  
              this.info = response.data.msg;
  
              this.showDismissibleAlert = true;
  
            }
            this.loading = false
  
  
          }).catch(err => {
            console.log(err)
            this.showDismissibleAlert = true
            this.info = err
            this.loading = false
          })
        },
  
    }
  
  }
  </script>
  
  
  <style scoped>
  .slide-fade-enter-active {
    transition: all .8s ease;
  }
  
  .slide-fade-leave-active {
    transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>
  