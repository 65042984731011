<template>
    <v-container fluid>
        <span v-if="reports && reports.length">
            <v-subheader>{{$store.getters.getTextMap().parameter_report}}</v-subheader><v-divider></v-divider>
        <v-row >
            
            <v-col cols="4" v-for="report in reports"
        :key="report.report_id" >
                <ParameterReport  :report="report" />
            </v-col>
            </v-row>
        </span>
        <span v-if="usageReports && usageReports.length">
            <v-subheader>{{$store.getters.getTextMap().usage_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in usageReports"
        :key="report.report_id" >
                <UsageReport :report="report" />
            </v-col>
            </v-row>
            </span>
            <span v-if="energyReports && energyReports.length">
            <v-subheader>{{$store.getters.getTextMap().energy_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in energyReports"
        :key="report.report_id" >
                <EnergyReport :report="report" />
            </v-col>
            </v-row>
            </span>
            <span v-if="energyUsageReports && energyUsageReports.length">
            <v-subheader>{{$store.getters.getTextMap().energy_usage_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in energyUsageReports"
        :key="report.report_id" >
                <EnergyUsageReport :report="report" />
            </v-col>
            </v-row>
            </span>
            <span v-if="energyDistributionReports && energyDistributionReports.length">
            <v-subheader>{{$store.getters.getTextMap().energy_distribution_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in energyDistributionReports"
        :key="report.report_id" >
                <EnergyDistributionReport :report="report" />
            </v-col>
            </v-row>
            </span>
            <span v-if="energyBillDistReports && energyBillDistReports.length">
            <v-subheader>{{$store.getters.getTextMap().ebill_distribution_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in energyBillDistReports"
        :key="report.report_id" >
                <EnergyBillDistReport :report="report" />
            </v-col>
        </v-row>
        </span>
              

        <!-- <span v-if="secReports && secReports.length">
            <v-subheader>Specific Energy Consumption Reports</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in secReports"
        :key="report.report_id" >
                <ParameterSECReport :report="report" />
            </v-col>
            </v-row>
            </span> -->
            <span v-if="secTimeFrameReports && secTimeFrameReports.length">
            <v-subheader>{{$store.getters.getTextMap().timeframed_specific_energy_consumption_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in secTimeFrameReports"
        :key="report.report_id" >
                <SECTimeFrameReport :report="report" />
            </v-col>
            </v-row>
            </span>
            <!-- <span v-if="granularSECReports && granularSECReports.length">
            <v-subheader>Granular Specific Energy Consumption Reports</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in granularSECReports"
        :key="report.report_id" >
                <GranularSECReport :report="report" />
            </v-col>
            </v-row>
            </span> -->
            <span v-if="consolidatedReports && consolidatedReports.length">
            <v-subheader>{{$store.getters.getTextMap().consolidated_report}}</v-subheader><v-divider></v-divider>
            <v-row>
            <v-col cols="4" v-for="report in consolidatedReports"
        :key="report.report_id" ><ConsolidatedReport :report="report" /></v-col>
        </v-row>
        </span>
        <span v-if="dynamicExcelReports && dynamicExcelReports.length>0">
            
            <v-subheader>{{$store.getters.getTextMap().dynamic_excel_report}}</v-subheader>
        <v-divider></v-divider>
        <v-row > 
            
            <v-col cols="4" v-for="report in dynamicExcelReports"
        :key="report.report_id" ><DynamicExcelReport :report="report" /></v-col>
        </v-row>
    </span>
        <span v-if="excelTemplateBasedReports && excelTemplateBasedReports.length>0">
            
            <v-subheader>{{$store.getters.getTextMap().excel_template_based_report}}</v-subheader>
        <v-divider></v-divider>
        <v-row > 
            
            <v-col cols="4" v-for="report in excelTemplateBasedReports"
        :key="report.report_id" ><ExcelTemplateBasedReport :report="report" /></v-col>
        </v-row>
    </span>
    </v-container>
</template>

<script>
import ParameterReport from '@/components/items/ParameterReport'
import UsageReport from '@/components/items/UsageReport'
import EnergyUsageReport from '@/components/items/EnergyUsageReport'
import EnergyReport from '@/components/items/EnergyReport'
import EnergyDistributionReport from '@/components/items/EnergyDistributionReport'
import EnergyBillDistReport from '@/components/items/EnergyBillDistReport'
// import ParameterSECReport from '@/components/items/ParameterSECReport'
import SECTimeFrameReport from '@/components/items/SECTimeFrameReport'
// import GranularSECReport from '@/components/items/GranularSECReport'
import ConsolidatedReport from '@/components/items/ConsolidatedReport'
import DynamicExcelReport from '@/components/items/DynamicExcelReport'
import ExcelTemplateBasedReport from '@/components/items/reports/ExcelTemplateBasedReport'
export default {
    name:'ReportList',
    components:{
        ParameterReport,
        UsageReport,
        // ParameterSECReport,
        SECTimeFrameReport,
        // GranularSECReport,
        ConsolidatedReport,
        DynamicExcelReport,
        ExcelTemplateBasedReport,
        EnergyUsageReport,
        EnergyReport,
        EnergyDistributionReport,
        EnergyBillDistReport,
    },
    data(){
        return {}
    },
    computed:{
        reports(){
            return this.$store.state.reports
        },
        usageReports(){
            return this.$store.state.usageReports
        },
        energyUsageReports(){
            return this.$store.state.energyUsageReports
        },
        energyReports(){
            return this.$store.state.energyReports
        },
        energyDistributionReports(){
            return this.$store.state.energyDistributionReports
        },
        energyBillDistReports(){
            return this.$store.state.energyBillDistReports
        },
        secReports(){
            return this.$store.state.secReports
        },
        secTimeFrameReports(){
            return this.$store.state.secTimeFrameReports
        },
        // granularSECReports(){
        //     return this.$store.state.granularSECReports
        // },
        consolidatedReports(){
            return this.$store.state.consolidatedReports
        },
        dynamicExcelReports(){
            return this.$store.state.dynamicExcelReports
        },
        excelTemplateBasedReports(){
            return this.$store.state.excelTemplateBasedReports
        }
    }
}
</script>