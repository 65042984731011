<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="safetyIncidentForm" v-model="valid">
            <v-row no-gutters>
                <v-col  cols="auto" class="d-flex" align="right">
                    <v-checkbox
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading && !editMode"
                        v-model="form.accident"
                        :label="$store.getters.getTextMap().accident"
                    ></v-checkbox>
                    <v-checkbox
                    class="mx-5"
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.accident_with_loss"
                        :label="$store.getters.getTextMap().accident_with_loss"
                    ></v-checkbox>
                    <v-checkbox
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.closed"
                        :label="$store.getters.getTextMap().closed"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <v-textarea
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.description"
                        :counter="300"
                        :rules="[formRules.rules.required,formRules.rules.validString,formRules.rules.size(300)]"
                        :label="$store.getters.getTextMap().incident_description"
                        outlined
                        dense
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.location_id"
                        :items="locationOptions"
                        :label="$store.getters.getTextMap().location"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <v-menu :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="date"
                                :label="$store.getters.getTextMap().date"
                                outlined
                                dense
                                readonly
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="formRules.ISODateRules"
                            ></v-text-field>
                        </template>
                        <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="date"
                            @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().time }}</div>
                    <TimePicker v-model="time"/>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.employee_id"
                        :counter="25"
                        :rules="[formRules.rules.required,formRules.rules.validString,formRules.rules.size(25)]"
                        :label="$store.getters.getTextMap().employee_id"
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="!editMode" no-gutters>
                <v-col>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.severity"
                        :items="severityOption"
                        :label="$store.getters.getTextMap().severity"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="[formRules.rules.required]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-textarea
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.corrective_action"
                        :counter="300"
                        :rules="[formRules.rules.validString,formRules.rules.size(300)]"
                        :label="$store.getters.getTextMap().corrective_action"
                        outlined
                        dense
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import TimePicker from '@/components/inputs/TimePicker'
import axios from 'axios'
import moment from "moment";
export default {
    name:'WriteSafetyIncident',
    props:['safetyIncidentDetails'],
    components:{
        InfoAlert,
        TimePicker
    },
    data(){
        return {
            valid:false,
            loading:false,
            info:'',
            api:'writeSafetyIncident',
            editMode:false,
            showDismissibleAlert:false,
            menu2:false,
            date:null,
            time:null,
            severityOption:[
                {label:"High",value:"High"},
                {label:"Medium",value:"Medium"},
                {label:"Low",value:"Low"},
                {label:"Near Miss",value:"Near Miss"},
            ],
            form:{
                description:null,
                accident:false,
                accident_with_loss:false,
                closed:false,
                corrective_action:null,
                severity:"High",
                location_id:null,
                employee_id:null
            },
            formRules: FormRules,
        }
    },
    mounted(){
        if(this.safetyIncidentDetails && this.safetyIncidentDetails.incident_id){
            this.api='updateSafetyIncidentLog'
            this.editMode=true
            this.form=Object.assign({},this.safetyIncidentDetails)
        }else{
            this.api='writeSafetyIncident'
            this.editMode=false
        }
    },
    computed:{
        locationOptions(){
            let op=[]
            for(let i of this.$store.state.locations){
                op.push({
                    label:i.location_name,
                    value:i.location_id
                })
            }
            return op
        }
    },
    methods:{
        getTimestamp(time){
            let dt=moment().format("YYYYMMDD")
            if(this.date){
                dt=this.date.toString()
            }
            if(time){
                dt+=' '+time.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        onSubmit(){
            this.$refs.safetyIncidentForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['incident_id']=this.safetyIncidentDetails.incident_id
                }
                d['timestamp']=this.getTimestamp(this.time)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        // this.$store.dispatch('refreshTariffs')
                        this.loading=false
                        this.$emit('success')
                        if(!this.editMode){
                            this.$refs.safetyIncidentForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit("close")
        }
    }
}
</script>