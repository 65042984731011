<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <div class="py-0">
            <v-form ref="parameterForm" v-model="valid" >
                <v-card flat :color="$store.getters.getColorPalette(). background1ColorCode" :dark="$store.getters.getColorPalette().isDark">
            <v-card-title class="headline">{{mode}}{{$store.getters.getTextMap().energy_distribution_report}}</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-text-field
                                          :dark="$store.getters.getColorPalette().isDark" 
                                           :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.name"
                                            :counter="25"
                                            :rules="nameRules"
                                            :label="$store.getters.getTextMap().name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col>
                                        <v-chip
                                            class="ma-2"
                                            close
                                            color="teal"
                                            text-color="white"
                                            @click:close="close(param)" 
                                            v-bind:key="param.parameter" v-for="param in form.energy_list"
                                            >
                                            {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col>
                                    <v-col>    
                                        <v-chip
                                             class="ma-2"
                                             close
                                             color="blue"
                                             text-color="white"
                                             @click:close="close(param)" 
                                             v-bind:key="param.parameter" v-for="param in form.reactive_list"
                                            >                                   
                                            {{$store.getters.getDeviceById(param['device_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col>
                                    <v-col>    
                                        <v-chip
                                             class="ma-2"
                                             close
                                             color="blue"
                                             text-color="white"
                                             @click:close="close(param)" 
                                             v-bind:key="param.parameter" v-for="param in form.max_list"
                                            >                                   
                                            {{$store.getters.getMachineById(param['machine_id'])['name']}} - {{param.name}}
                                        </v-chip>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <!-- <v-col>
                                        <v-text-field
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="form.tariff_rate"
                                            :rules="numberRules"
                                            label="Tariff Rate"
                                            required
                                        ></v-text-field>
                                    </v-col> -->
                                    <!-- <v-col>
                                        <v-select
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="form.tariff_on"
                                            :items="tariff_on_options"
                                            label="Tariff On"
                                            dense
                                            filled
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                            :menu-props="{dark: true}"
                                        ></v-select>
                                    </v-col>  -->
                                    <!-- <v-col v-if="showMachine===true">
                                        <v-text-field
                                            dark
                                            color="cyan lighten-1"
                                            v-if="!loading"
                                            v-model="form.label"
                                            label="Label"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col v-if="showMachine===true">
                                    <v-select
                                        dark
                                        color="cyan lighten-1"
                                        :menu-props="{dark: true}"
                                        v-if="!loading"
                                        v-model="workspace"
                                        :items="workspaceOptions"
                                        label="Workspace"
                                        outlined          
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                </v-col>
                                <v-col v-if="showMachine===true">
                                    <v-select
                                        dark
                                        color="cyan lighten-1"
                                        :menu-props="{dark: true}"
                                        v-if="!loading"
                                        v-model="form.machine"
                                        :items="machineOptions"
                                        label="Machine"
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                        multiple
                                    ></v-select>
                                </v-col>  -->
                                    <v-col>
                                    <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="selected" :label="$store.getters.getTextMap().automate_report" value="automate" ></v-checkbox>
                                </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox  :dark="$store.getters.getColorPalette().isDark"  v-model="selectedRootMachine" :label="$store.getters.getTextMap().add_root_machine" value="root_machine" ></v-checkbox>
                                        <!-- <h2>Add Root Machine</h2> -->
                                    </v-col>
                                </v-row>
                                <v-row v-if="show_root_machine===true">
                                    <v-col>
                                        <v-select
                                       :dark="$store.getters.getColorPalette().isDark" 
                                       :color="$store.getters.getColorPalette().inputtextColor"
                                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="rootWorkspace"
                                        :items="rootWorkspaceOptions"
                                        :label="$store.getters.getTextMap().workspace"
                                        outlined          
                                        item-text="label"
                                        item-value="value"
                                    ></v-select>
                                    </v-col>
                                    <v-col>
                                    <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                       :color="$store.getters.getColorPalette().inputtextColor"
                                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        v-if="!loading"
                                        v-model="form.root_machine"
                                        :items="rootMachineOptions"
                                        :label="$store.getters.getTextMap().root_machine"
                                        outlined
                                        item-text="label"
                                        item-value="value"
                                        required
                                        :rules="objectRules"
                                    ></v-select>
                                </v-col> 
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-select
                                      :dark="$store.getters.getColorPalette().isDark" 
                                       :color="$store.getters.getColorPalette().inputtextColor"
                                        v-if="!loading"
                                        v-model="form.notification_groups"
                                        :items="notificationGroupList"
                                        :label="$store.getters.getTextMap().notification_groups"
                                        dense
                                        filled
                                        item-text="label"
                                        item-value="value"
                                        multiple
                                        card
                                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor"  @click="onSubmit">
                                {{mode}}
                            </v-btn>
                            <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor"  @click="onReset">
                               {{ $store.getters.getTextMap().cancel}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background1ColorCode">
                    <v-btn v-if="this.showMachine===false" style="margin-bottom:10px;" color="blue lighten-1" text @click="addMachine = !addMachine">
                       {{ $store.getters.getTextMap().add_machines_to_list}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-form ref="machineListForm" v-model="machineListvalid" v-if="addMachine===true">
                        <v-row >
                            <v-col>
                                <v-text-field
                                   :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="label"
                                    :rules="nameRules"
                                    :label="$store.getters.getTextMap().label"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="showMachine===false">
                                <v-select
                                  :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    outlined          
                                    item-text="label"
                                    item-value="value"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col v-if="showMachine===false">
                                <v-select
                                   :dark="$store.getters.getColorPalette().isDark" 
                                   :color="$store.getters.getColorPalette().inputtextColor"
                                  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine_form.machine_id"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                    required
                                    :rules="objectRules"
                                ></v-select>
                            </v-col> 
                        </v-row>
                        <v-btn style="margin-bottom:10px;" v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="addMachineToList">
                              {{$store.getters.getTextMap().add}}
                        </v-btn>
                        <v-btn style="margin-bottom:10px;" v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="resetMachineList">
                            {{$store.getters.getTextMap().cancel}}
                        </v-btn>
                    </v-form>
                    <v-spacer></v-spacer>
                    <v-data-table
                        :headers="headers"
                        :items="allMachineList"
                        item-key="machine_id"
                        :search="search"
                        :single-expand="true"
                        show-expand
                        :expanded.sync="expanded"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                       :dark="$store.getters.getColorPalette().isDark" 
                        >
                        <template v-slot:top>
                            <v-toolbar flat  :dark="$store.getters.getColorPalette().isDark" >
                                <v-toolbar-title>{{ $store.getters.getTextMap().machine_list }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <EDMachineListItem :machine="item" v-on:delete="allMachines"/>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import EDMachineListItem from '@/components/items/EDMachineListItem'
export default {
    name:'CreateEnergyDistributionReport',
    props:['report','edit'],
    components:{
        InfoAlert,
        EDMachineListItem
    },
    mounted(){
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            this.form['tariff_rate']=Number(this.form['tariff_rate'])
            this.api='updateEnergyDistributionReport'
            this.mode='Edit'
            this.showMachine=false
            this.allMachines()
        }else{
            this.api='createEnergyDistributionReport'
        }
    },
     data(){
        return {
            api:'createEnergyDistributionReport',
            mode:this.$store.getters.getTextMap().create,
            dialog:false,
            showMachine:true,
            valid:false,
            machineListvalid:false,
            loading:false,
            label:'',
            info:'',
            selected:'',
            selectedRootMachine:'',
            expanded: [],
            search:'',
            automate:false,
            show_root_machine:false,
            showDismissibleAlert:false,
            workspace:null,
            rootWorkspace:null,
            machine:null,
            addMachine:false,
            machine_form:{
                machine_id:null
            },
            machineOptions:[],
            rootMachineOptions:[],
            allMachineList:[],
            tariff_on_options : [
            {label:this.$store.getters.getTextMap().active_energy,value:'active_energy'},
            {label:this.$store.getters.getTextMap().apparent_energy,value:'apparent_energy'},
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      form:{
            name:null,
            tariff_rate:0,
            tariff_on:null,
            automate:false,
            machine:null,
            root_machine:null
            },
        headers: [
        {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'machine_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        },
        {
            text: 'Name',
            value: 'name',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        }
    ],
        }
    },
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },
    rootWorkspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },

    notificationGroupList(){
        let op=[]
        if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
        }
        return op
        },
    },
methods:{
    allMachines(){
        let op=[]
        let payload={
                report_id:this.report.report_id
            }
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByReportID", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
                for(let i of machines){
                op.push({'machine_id':i.machine_id,'name':i.name,report_id:this.report.report_id})
            }
            this.allMachineList=op
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        },
    getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.machineOptions=op
        },
    getRootMachineList(){
        let op=[]
        if(this.rootWorkspace){
            let payload={
                workspace_id:this.rootWorkspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.rootMachineOptions=op
        },
    automate_report(){
        if(this.selected === 'automate'){
            this.automate = ! this.automate
        }else{
            this.automate = ! this.automate
        }
      },
    close(param){
            this.form.list=this.form.list.filter(
            function(data){ return data.parameter != param.parameter }
        );
      },
    addMachineToList(){
        this.$refs.machineListForm.validate()
            if(this.machineListvalid){
                this.loading=true
                this.machine_form.report_id=this.report.report_id
                this.machine_form.label=this.label
                let d=Object.assign({}, this.machine_form)

                axios.post(this.$store.state.api+'addEDMachineToList',d,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$store.dispatch('refreshEnergyDistributionReports')
                        this.allMachines()
                        this.resetMachineList()
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                    }})
                .catch(err=>{
                    this.loading=false
                    console.log(err)
                });
            }
        },
    onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.automated=this.automate
            let d=Object.assign({}, this.form)
            axios.post(this.$store.state.api+this.api,d,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshEnergyDistributionReports')
                // console.log("Report added "+d)
                this.onReset()
                    //this.dialog=false
                    //this.$emit('close')
            }else{
                //console.log(response.data.msg)
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading=false
            }})
            .catch(err=>{
                this.loading=false
                console.log(err)
            });
                //this.$store.dispatch('addDevice', d)
            }
        },
    onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.automate=false
            this.$emit('close')
        },
    resetMachineList(){
        this.addMachine=!this.addMachine
        this.machine_form = {}
        this.$refs.machineListForm.reset()


        }
    },
    watch:{
        selected(){
            this.automate_report()
        },
        selectedRootMachine(){
            this.show_root_machine = ! this.show_root_machine
            if(this.show_root_machine===false){
                this.form.root_machine = null
            }
        },
        workspace(){
            this.getMachineList()
        },
        rootWorkspace(){
            this.getRootMachineList()
        },
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>