<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-form ref="addForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text" :dark="$store.getters.getColorPalette().isDark" ></v-skeleton-loader>
                    <!-- <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="month"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="month"
                            label="Select Month"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            dense 
                            outlined
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="month" type="month" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(month)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog> -->
                    <v-select 
                        v-if="!loading"
                        dense 
                        outlined 
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputTextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                        v-model="form.month" 
                        :items="monthOptions" 
                        :label="$store.getters.getTextMap().month" 
                        item-text="label"
                        item-value="value" 
                        :rules="[rules.required]">
                    </v-select>
                </v-col>
                <v-col>
                    <v-select 
                        v-if="!loading"
                        dense 
                        outlined 
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputTextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}" 
                        v-model="form.day_of_week" 
                        :items="weekOptions" 
                        :label="$store.getters.getTextMap().day_of_week" 
                        item-text="label"
                        item-value="value" 
                        :rules="[rules.unum]">
                    </v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.tariff_val"
                        :counter="3"
                        :label="$store.getters.getTextMap().tariff_value" 
                        dense
                        outlined
                        :rules="[rules.required,rules.unum]"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().from_time }}</div>
                    <TimePicker v-model="form.from_time"/>
                </v-col>
                <v-col>
                    <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().to_time }}</div>
                    <TimePicker v-model="form.to_time"/>
                </v-col>
            </v-row>
            <v-row no-gutters >
                <v-col align="right">
                    <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{$store.getters.getTextMap().add}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import TimePicker from '@/components/inputs/TimePicker'
export default {
    name:'AddTariffData',
    props:['tariff'],
    components:{
        InfoAlert,  
        TimePicker 
    },
    data(){
        return {
            form:{
                from_time:'00:00',
                to_time:'23:59',
            },
            loading:false,
            info:null,
            showDismissibleAlert:false,
            valid:false,
            menu: false,
            modal: false,
            monthOptions:[
                { label: 'January', value: 1 },
                { label: 'February', value: 2 },
                { label: 'March', value: 3 },
                { label: 'April', value: 4 },
                { label: 'May', value: 5 },
                { label: 'June', value: 6},
                { label: 'July', value: 7},
                { label: 'August', value: 6},
                { label: 'September', value: 9},
                { label: 'October', value: 10},
                { label: 'November', value: 11},
                { label: 'December', value: 12},
            ],
            weekOptions:[
                { label: 'Sunday', value: 6 },
                { label: 'Monday', value: 0 },
                { label: 'Tuesday', value: 1 },
                { label: 'Wednesday', value: 2 },
                { label: 'Thursday', value: 3 },
                { label: 'Friday', value: 4},
                { label: 'Saturday', value: 5},
            ],
            rules:FormRules.rules
        }
    },
    methods:{
        onSubmit(){
            this.$refs.addForm.validate()
            if(this.valid){
                this.loading = true
                this.form.tariff_id=this.tariff.tariff_id
                axios.post(this.$store.state.api + "addTariffData", this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                if (response.data.status == "success") {
                    this.$store.dispatch("refreshTariffData")
                    this.$emit('add')       
                    this.info = "Data added";
                    this.showDismissibleAlert = true;
                    this.loading = false;
                    this.onReset()
                    this.$emit('close')
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
                .catch(error => {
                    this.onReset()
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.addForm.reset()
            this.$emit('close')                
        }
    }
}
</script>