<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col>
                <ItemCodeTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ItemCodeTable from '@/components/lists/derived/ItemCodeTable'
export default {
    name:'ItemCodePage',
    components:{
        ItemCodeTable
    }
}
</script>