<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row class="ma-3">
            <v-form ref="aqForm" v-model="valid">
                <!-- <v-card class="pa-3 ma-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark"> -->
                <v-row v-if="edit">
                        <v-col align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="hub_id"
                            :items="deviceOption"
                            :label="$store.getters.getTextMap().choose_air_quality_device"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="selectRules"
                        ></v-select>
                    </v-col>
                    <v-col align="right">
                        <DeleteConfirmation v-on:confirm="deleteAQDevice"  title="Delete Confirmation"  description="Are you sure you want to delete this Air Quality Device?">
                            <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().deletebtnColor" small filled >{{$store.getters.getTextMap().delete_this_device}}<v-icon small :color="$store.getters.getColorPalette().accentCode">mdi-delete</v-icon></v-btn>
                        </DeleteConfirmation>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align-self="center">
                        <v-text-field dense outlined v-if="!loading" v-model="form.label" :counter="30" :rules="nameRules" :label="$store.getters.getTextMap().air_quality_label" required></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="param_type"
                            :items="paramTypeOptions"
                            :label="$store.getters.getTextMap().param_type"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="selectRules"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="hub_id"
                            :items="hubOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            :rules="[rules.required]"  
                            :label="$store.getters.getTextMap().hub"

                            item-text="label"
                            item-value="value"
                        ></v-autocomplete>
                    </v-col>
                    <HubWorkspaceMachineParamForm :multiple="false" v-if="hub_id" :hub_id="hub_id" v-on:value="updateParamList" :eliminate="eliminate"/>
                    <v-col align="right">
                        <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().downloadbtnColor" small filled @click="addDataToTable">{{ $store.getters.getTextMap().add }}</v-btn>
                    </v-col>
                </v-row>
                <!-- </v-card> -->
            </v-form>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-3" flat outlined :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" :dark="$store.getters.getColorPalette().isDark">
                    <v-row v-if="!loading && tableData" style="margin-top:20px;">
                        <v-col>
                            <v-simple-table height="200px" :style="{background: $store.getters.getColorPalette().background1ColorCode}">
                                <template v-slot:default>
                                    <thead :style="{background: $store.getters.getColorPalette().tableHeaderColorCode}">
                                        <tr>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().param_type}}</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().param_name}}</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>{{$store.getters.getTextMap().param_id}}</strong>
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item.param_type" v-for="item in tableData">
                                            <td>{{ item.param_type }}</td>
                                            <td>{{ item.param_name }}</td>
                                            <td>{{ item.param_id }}</td>
                                            <td>
                                                <DeleteConfirmation v-on:confirm="deleteFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Pie TD widget?">
                                                    <v-icon small color="red">mdi-delete</v-icon>
                                                </DeleteConfirmation>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="right">
                <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                <v-btn style="margin-left:20px;"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
            </v-col>
        </v-row>
    </v-container> 
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import HubWorkspaceMachineParamForm from '../../../forms/HubWorkspaceMachineParamForm.vue'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'CreateAirQuality',
    props:['area','air_quality'],
    components:{
        InfoAlert,
        HubWorkspaceMachineParamForm,
        DeleteConfirmation
    },
    mounted(){
        if(this.area && this.area.area_id && this.air_quality && this.air_quality.length>0){
            this.form=Object.assign({},this.area)
            this.edit=true
            this.api='updateAirQuality'
            for(let i of this.air_quality){
                let temp = {}
                temp['aq_id']=i.aq_id
                temp['area_id']=i.area_id
                temp['hub_id']=i.hub_id
                temp['label']=i.label
                temp['value']=i.hub_id
                this.deviceOption.push(temp)
            }
        }else{
            this.edit=false
            this.api='createAirQuality'
        }
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'createAirQuality',
            param_type:null,
            tableData:[],
            eliminate:[],
            hub_id:null,
            param_id:null,
            param_name:null,
            deviceOption:[],
            form:{
                hub_id:null,
                pm10:null,
                pm25:null,
                co2:null,
                voc:null,
                so2:null,
                co:null,
                o3:null,
                no2:null,
                humidity:null,
                temp:null,
                label:null,
                meta:null,
            },
            paramTypeOptions:[
                {label:'PM 10', value:'pm10'},
                {label:'PM 2.5', value:'pm2.5'},
                {label:'CO₂', value:'co2'},
                {label:'VOC', value:'voc'},
                {label:'SO₂', value:'so2'},
                {label:'CO', value:'co'},
                {label:'O3', value:'o3'},
                {label:'NO₂', value:'no2'},
                {label:this.$store.getters.getTextMap().humidity, value:'humidity'},
                {label:this.$store.getters.getTextMap().temperature, value:'temperature'},
            ],
            paramTypeReference:{
                'pm10':{label:'PM 10', value:'pm10'},
                'pm2.5':{label:'PM 2.5', value:'pm2.5'},
                'co2':{label:'CO₂', value:'co2'},
                'voc': {label:'VOC', value:'voc'},
                'so2': {label:'SO₂', value:'so2'},
                'co': {label:'CO', value:'co'},
                'o3': {label:'O3', value:'o3'},
                'no2': {label:'NO₂', value:'no2'},
                'humidity': {label:this.$store.getters.getTextMap().humidity, value:'humidity'},
                'temperature':{label:this.$store.getters.getTextMap().temperature, value:'temperature'}
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6),
            rules:FormRules.rules
        }
    },
    computed:{
        hubOptions(){
        let op =[] 
        let g=this.$store.state.hubs
        for(let i of g){
            op.push({value:i.hub_id,label:i.hub_id})
        }
        return op
        },
    },
    methods:{
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        createTabledata(){
            this.tableData=[]
            for(let i of this.air_quality){
                if(this.hub_id===i.hub_id){
                    this.form.hub_id = this.hub_id
                    this.form.aq_id = i.aq_id
                    let temp = {}
                    this.form.label = i.label
                    if(i.pm10){
                        this.form.pm10=i.pm10
                        temp['param_id']=i.pm10
                        temp['param_name']=this.getParamName(i.pm10)
                        temp['param_type']='pm10'
                        this.tableData.push(temp)
                        this.eliminate.push(i.pm10)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='pm10' )
                        temp = {}
                    }
                    if(i.pm25){
                        this.form.pm25=i.pm25
                        temp['param_id']=i.pm25
                        temp['param_name']=this.getParamName(i.pm25)
                        temp['param_type']='pm2.5' 
                        this.tableData.push(temp)
                        this.eliminate.push(i.pm25)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='pm2.5' )
                        temp = {}
                    }
                    if(i.co2){
                        this.form.co2=i.co2
                        temp['param_id']=i.co2
                        temp['param_name']=this.getParamName(i.co2)
                        temp['param_type']='co2'  
                        this.tableData.push(temp)
                        this.eliminate.push(i.co2)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='co2' )
                        temp = {}
                    }
                    if(i.voc){
                        this.form.voc=i.voc
                        temp['param_id']=i.voc
                        temp['param_name']=this.getParamName(i.voc)
                        temp['param_type']='voc'
                        this.tableData.push(temp)
                        this.eliminate.push(i.voc)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='voc' )
                        temp = {}
                    }
                    if(i.so2){
                        this.form.so2=i.so2
                        temp['param_id']=i.so2
                        temp['param_name']=this.getParamName(i.so2)
                        temp['param_type']='so2'
                        this.tableData.push(temp)
                        this.eliminate.push(i.so2)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='so2' )
                        temp = {}
                    }
                    if(i.co){
                        this.form.co=i.co
                        temp['param_id']=i.co
                        temp['param_name']=this.getParamName(i.co)
                        temp['param_type']='co'
                        this.tableData.push(temp)
                        this.eliminate.push(i.co)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='co' )
                        temp = {}
                    }
                    if(i.o3){
                        this.form.o3=i.o3
                        temp['param_id']=i.o3
                        temp['param_name']=this.getParamName(i.o3)
                        temp['param_type']='o3'
                        this.tableData.push(temp)
                        this.eliminate.push(i.o3)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='o3' )
                        temp = {}
                    }
                    if(i.no2){
                        this.form.no2=i.no2
                        temp['param_id']=i.no2
                        temp['param_name']=this.getParamName(i.no2)
                        temp['param_type']='no2'
                        this.tableData.push(temp)
                        this.eliminate.push(i.no2)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='no2' )
                        temp = {}
                    }
                    if(i.humidity){
                        this.form.humidity=i.humidity
                        temp['param_id']=i.humidity
                        temp['param_name']=this.getParamName(i.humidity)
                        temp['param_type']='humidity'
                        this.tableData.push(temp)
                        this.eliminate.push(i.humidity)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='humidity' )
                        temp = {}
                    }
                    if(i.temp){
                        this.form.temp=i.temp
                        temp['param_id']=i.temp
                        temp['param_name']=this.getParamName(i.temp)
                        temp['param_type']='temperature'
                        this.tableData.push(temp)
                        this.eliminate.push(i.temp)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!='temperature' )
                        temp = {}
                    }
                }
            }
        },
        updateParamList(value){
            this.form.hub_id=this.hub_id
            if(value.parameter && value.parameter.param_id){
                this.param_id = value.parameter.param_id
                this.param_name=value.parameter.name
            }
        },
        addDataToTable(){
            this.$refs.aqForm.validate()
            if(this.valid){
                if(this.param_type && this.param_id){
                    switch (this.param_type){
                        case 'pm10':
                            this.form.pm10=this.param_id
                            break;
                        case 'pm2.5':
                            this.form.pm25=this.param_id
                            break;
                        case 'co2':
                            this.form.co2=this.param_id
                            break;
                        case 'voc':
                            this.form.voc=this.param_id
                            break;
                        case 'so2':
                            this.form.so2=this.param_id
                            break;
                        case 'co':
                            this.form.co=this.param_id
                            break;
                        case 'o3':
                            this.form.o3=this.param_id
                            break;
                        case 'no2':
                            this.form.no2=this.param_id
                            break;
                        case 'humidity':
                            this.form.humidity=this.param_id
                            break;
                        case 'temperature':
                            this.form.temp=this.param_id
                            break;
                        default:
                            break;
                        }
                        let temp = {'param_type':this.param_type,'param_id':this.param_id,'param_name':this.param_name}
                        this.tableData.push(temp)
                        this.eliminate.push(this.param_id)
                        this.paramTypeOptions=this.paramTypeOptions.filter(x=>x.value!=this.param_type )
                        this.param_id = null
                        this.param_type=null
                        this.param_name=null
                }else{
                    this.loading=false
                    this.info="Please select Param Type"
                    this.showDismissibleAlert=true
                }
            }
        },
        deleteFromTable(value){
            this.eliminate=this.eliminate.filter(x=>x!=value.param_id )
            this.tableData=this.tableData.filter(x=>x.param_id!=value.param_id)
            this.paramTypeOptions.push(this.paramTypeReference[value.param_type])
        },
        deleteAQDevice(){
            if(this.hub_id && this.edit){
                let aq_id = null
                for(let i of this.air_quality){
                    if(this.hub_id===this.hub_id){
                        aq_id = i.aq_id
                    }
                }
                let payload={aq_id:aq_id}
                this.loading=true
                axios.post(this.$store.state.api+'deleteAirQualityDevice',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.edit=false
                        this.$emit('success')
                        this.close()
                    }
                    })
                .catch(err=>{
                    this.loading=false
                    this.info=err.message
                    this.showDismissibleAlert=true
                });
            }else{
                this.loading=false
                this.info="Please select Air Quality device"
                this.showDismissibleAlert=true
            }
        },
        onSubmit(){
            // this.$refs.aqForm.validate()
            if(this.form && this.form.hub_id && this.area && this.area.area_id){
                this.loading=true
                this.form.area_id = this.area.area_id
                axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$emit('success')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit('close')
        }
    },
    watch:{
        hub_id:{
        handler(){
            this.tableData=[]
            this.eliminate=[]
            this.paramTypeOptions = [
                {label:'PM 10', value:'pm10'},
                {label:'PM 2.5', value:'pm2.5'},
                {label:'CO₂', value:'co2'},
                {label:'VOC', value:'voc'},
                {label:'SO₂', value:'so2'},
                {label:'CO', value:'co'},
                {label:'O3', value:'o3'},
                {label:'NO₂', value:'no2'},
                {label:'Humidity', value:'humidity'},
                {label:'Temperature', value:'temperature'},
            ]
            if(this.edit){
                this.createTabledata()
            }
        },
        deep:true
    }
    }
}
</script>