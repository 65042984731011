<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="generalSheetForm" v-model="generalSheetValid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>{{$store.getters.getTextMap().parameter_equation_based_sheet}}</v-card-title>
                </v-col>
            </v-row>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row>
                <v-col>
                    <v-text-field
                        v-if="!loading"
                        v-model="form.name"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().sheet_name"
                        dense
                        outlined
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().time_frame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-select  
                        v-if="!loading"
                        v-model="form.meta.dataSource"
                        :items="dataSourceOptions"
                        :label="$store.getters.getTextMap().data_source"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-select  >
                </v-col>
                <v-col>
                    <v-checkbox  :label="$store.getters.getTextMap().absolute_values" v-model="getAbsolute"></v-checkbox>
                </v-col>
                <v-col>
                    <v-checkbox :label="$store.getters.getTextMap().transpose"  v-model="isTranspose"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-card  :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" elevation="4" class="my-2" style="padding:15px 15px 15px 15px; margin-top:20px; margin-bottom:20px;">
            <v-row >
                <v-col cols="auto" class="d-flex">
                    <v-col>
                        <v-text-field
                            v-if="!loading"
                            v-model="eq_label"
                            :label="$store.getters.getTextMap().equation_label"
                            dense
                            outlined
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="eq_id"
                            outlined
                            :items="paramEquationOptions"
                            :label="$store.getters.getTextMap().param_equation"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                    <v-btn class="mt-4 mx-5"  color="gray darken-1" small outlined @click="addParamEqToList">{{$store.getters.getTextMap().add_equation_to_list}}</v-btn>
                    <v-btn class="mt-4 mx-5" :color="$store.getters.getColorPalette().deletebtnColor" outlined small @click="onParamEqReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="'transparent'" flat>
                        <v-row v-if="!loading && paramEqTableDetails " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().param_equation}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.param_id" v-for="item in paramEqTableDetails">
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.eq_id }}</td>
                                                <td>
                                                <DeleteConfirmation v-on:confirm="deleteFromParamEqTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                                    <v-icon  small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                                </DeleteConfirmation>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card>   
                </v-col>
            </v-row>
            </v-card>
            <v-row>
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
                <v-col align="left">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" outlined small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form> 
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'ParamEquationBasedSheet',
    props: {
    "sheet": {
        type: Object,
    },
    },
    components:{
        InfoAlert,
        DeleteConfirmation,
    },
    mounted(){
        if(this.sheet && this.sheet.meta){
            this.paramEqTableDetails = this.sheet.meta.eq_list
            this.form.name = this.sheet.name
            this.api = 'updateSheets'
            this.form.sheet_id = this.sheet.sheet_id
            this.isTranspose=this.sheet.meta.isTranspose
            this.getAbsolute=this.sheet.meta.getAbsolute
            this.form.meta.dataSource=this.sheet.meta.dataSource
        }
        this.getParamEquations()
        this.form.sheet_type = 'paramEquationBasedSheet'
    },
    data(){ 
        return {
            showDialog:false,
            api:'createSheets',
            eqTableDetails:[],
            paramEqTableDetails:[],
            eq_id:null,
            eq_label:null,
            paramEqOptions:[],
            generalSheetValid:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            isTranspose:false,
            getAbsolute:false,
            timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
            ],
            dataSourceOptions:[
                {label:'Trend', value:'trend'},
                {label:'Usage', value:'usage'},
            ],
            form:{
                name:null,
                sheet_type:'paramEquationBasedSheet',
                meta:{
                    eq_list:{},
                    timeframe:'mtd',
                    isTranspose:false,
                    getAbsolute:false,
                    dataSource:'trend'
                }
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
            ],
            numberRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
            nonNegativeRules:[
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && v>0 && v%1==0)|| 'It should be a valid number',
                v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
            ],
        }
    },
    computed:{
        paramEquationOptions(){
            let op=[]
            for(let i of this.paramEqOptions){
                op.push( {value:i.eq_id, label: i.label })
            }
            return op
        },
    },
    methods:{
        getParamEquations(){
        this.loading=true
            axios.get(this.$store.state.api+'paramEquations',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.paramEqOptions=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },
        close(){
            this.$emit("close")
        },
        addParamEqToList(){
            if(!this.paramEqTableDetails){
                this.paramEqTableDetails = []
            }
            if(this.eq_id){

                this.paramEqTableDetails.push(
                    {label:this.eq_label,eq_id:this.eq_id}
                )
            }else{
                this.loading=false
                this.info="Please select parameter"
                this.showDismissibleAlert=true
            }
            this.eq_id=null
            this.eq_label=null

        },
        sheetSubmit(){
            this.$refs.generalSheetForm.validate()
            if(this.generalSheetValid && this.paramEqTableDetails && this.paramEqTableDetails.length>0){
                this.loading=true
                this.form.meta.eq_list = this.paramEqTableDetails
                this.form.meta.isTranspose = this.isTranspose
                this.form.meta.getAbsolute = this.getAbsolute
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$emit('update')
                            this.$store.dispatch('refreshSheets')
                            this.$refs.generalSheetForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }else{
                this.loading=false
                this.info="Please add parameter"
                this.showDismissibleAlert=true       
            }
        },
        deleteFromParamEqTable(item){
            this.paramEqTableDetails=this.paramEqTableDetails.filter(x=>x.eq_id!=item.eq_id)
        },
        onParamEqReset(){
            this.eq_id=null
            this.eq_label=null
            this.loading=false
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        }
    },
    watch:{
        isTranspose(){
            if(this.isTranspose===null){
                this.isTranspose=false
            }
        }
}

}
</script>