<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col cols="12" align="center">
                <ParamEquationTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ParamEquationTable from '@/components/lists/derived/ParamEquationTable'
export default {
    name:'ParamEquationsPage',
    components:{
        ParamEquationTable
    }
}
</script>