<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-row>
                <v-col>
                    <v-card-title>{{ $store.getters.getTextMap().issue_items }}</v-card-title>
                </v-col>
            </v-row>
            <v-form ref="usageForm" v-model="valid">
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col><v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="getItems">{{ $store.getters.getTextMap().refresh  }}</v-btn></v-col>
                </v-row>
                
               
                    <v-row>
                        <v-col>

                            <v-simple-table height="350px">
                                <template :dark="$store.getters.getColorPalette().isDark"  v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().item_code}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().intended_qty}}</strong>
                            </th>
                            <th class="text-left">
                                <strong>{{$store.getters.getTextMap().issued_qty}}</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in itemList"
                            :key="item.item_code"
                            >
                            
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.intended_qty }}</td>
                            <td>
                                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                :color="$store.getters.getColorPalette().inputtextColor"
                                    :loading="loading"
                                    v-model="item.issued_qty"
                                    :counter="5"
                                    :label="$store.getters.getTextMap().issued_qty"
                                    
                                    ></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="!loading">
                            <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" text small outlined @click="onSubmit">{{ $store.getters.getTextMap().submit  }}</v-btn>
                        </v-col>
                    </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
// import ItemCodeSelectorTable from '@/components/forms/ItemCodeSelectorTable.vue'
import axios from 'axios'
// import moment from 'moment'
export default {
    name:'IssueItemVoucher',
    props:{
        voucher:{
          type:Object,
          required:true
      }
    },
    components:{
        InfoAlert,
        // ItemCodeSelectorTable
    },
    mounted(){
        this.getItems()
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            departments:[],
            departmentID:null,
            itemList:[],
            selectedItems:[],
            // filteredItemList:[],
            searchBy:'item_code',
            searchString:null,
            menu:null,
            menu1:null,
            menu2:null,
            menu3:null,
            timeForm:{},
            form:{},
            // showSKUID:false,
            rules:FormRules.rules,
            objectRules:[FormRules.rules.required],
            // searchByOptions:[
            //     {label:"Item Code",value:'item_code'},
            //     {label:"material",value:'material'},
            //     {label:"material Description",value:'material_desc'},
            //     {label:"material Group",value:'material_group'},
            // ]
        }
    },
    computed:{
        // departmentOptions(){
        //     let op=[]

        //     for(let d of this.departments){
        //         op.push({
        //             label:d.department_name,
        //             value:d.department_id
        //         })
        //     }

        //     return op

        // },
        // filteredItemList(){
        //     let lst=[]
        //     if(this.searchBy && this.searchString && this.searchString!=''){
        //         lst=this.itemList.filter(item=>{
        //             if(!item[this.searchBy])
        //                 return false
        //             let iterItem=item[this.searchBy].toString().toLowerCase()
        //             let itemCode=item['item_code']
        //             let fnd=this.selectedItems.find(i=>{
        //                 return i['item_code']==itemCode
        //             })
        //             if(fnd){
        //                 return false

        //             }
        //             return iterItem.includes(this.searchString.toString().toLowerCase())
        //         })

        //     }

        //     return lst

        // },
        // skuOptions(){
        //     let op=[]
        //     let g=this.$store.state.skus
        //     for(let i of g){
        //         if(this.showSKUID){
        //             op.push( {value:i.sku_id, label: i.sku_id })

        //         }else{

        //             op.push( {value:i.sku_id, label: i.sku_name })
        //         }
        //     }
        //   return op
        // },
    },
    methods:{

        getPayload(){
            let payload={
                voucher_id:this.voucher.voucher_id,
                department_id:this.voucher.department_id,
                items:[]
            }
            if(this.itemList && this.itemList.length>0){
                for(let item of this.itemList){
                    if(isNaN(item['issued_qty'])){
                        throw "Missing issue Qty in One of the items";
                    }
                    payload.items.push(item)
                }
                
            }else{
                throw "No Items selected"
            }
            
            return payload
            

        },
        
        getItems(){
            // this.$refs.usageForm.validate()
            if(this.voucher){
                this.loading=true
                // this.form.process_id=this.process.process_id
                // let payload={
                //     department_id:this.departmentID
                // }
                axios.post(this.$store.state.api+'getIssueVoucherDetailsByID',this.voucher,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.itemList=response.data.data
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
                this.loading=true
                // this.form.process_id=this.process.process_id
                try{

                    let payload=this.getPayload()
                    axios.post(this.$store.state.api+'issueItem',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$emit('success')
                        this.$emit('close')
                     }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                }})
                .catch(err=>{console.error(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
                });
            }catch(e){
                this.info=e
                this.showDismissibleAlert = true;
                        this.loading=false
            }
            }
        },
        onReset(){
            this.$refs.usageForm.reset()
            this.loading=false
        }
    },
    watch:{

        departmentID(){
            this.getItems()
        }

    }
}
</script>