var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({background: _vm.$store.getters.getColorPalette().backgroundColorCode}),attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert=false}}}),(_vm.$store.state.user && _vm.$store.getters.getAccessByLevels(['engineeraccess']))?_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[_c('v-icon',{on:{"click":function($event){_vm.edit=!_vm.edit}}},[_vm._v("mdi-pencil")])],1)],1):_vm._e(),(_vm.edit)?_c('v-row',[_c('v-col',[_c('CreateConsolidatedReport',{attrs:{"report":_vm.report}})],1)],1):_vm._e(),_c('v-divider'),_c('v-form',{ref:"usageForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"label":_vm.$store.getters.getTextMap().dates,"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.objectRules},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,1684130857),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor,"text":""},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().view_report)+" ")]):_vm._e()],1)],1)],1),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reportData,"item-key":"timestamp","search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-class":"font-weight-black text-center"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().report_list)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.timestamp))+" ")]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.downloadReport(item)}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().download_report))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }