<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" /> 
        <v-form ref="machineShiftUsageBasedSheetForm" v-model="valid">
            <v-row no-gutters>
                <v-col>
                    <v-card-title>{{$store.getters.getTextMap().shift_based_machine_usage_log}}</v-card-title>
                    <v-card :dark="$store.getters.getColorPalette().isDark" outlined flat :color="$store.getters.getColorPalette(). background2ColorCode" class="pa-4" >
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-if="!loading"
                                    v-model="form.name"
                                    :rules="nameRules"
                                    :label="$store.getters.getTextMap().sheet_name"
                                    dense
                                    outlined
                                    required
                                :dark="$store.getters.getColorPalette().isDark" 
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select  
                                    v-if="!loading"
                                    v-model="form.meta.timeframe"
                                    :items="timeframeOptions"
                                    :label="$store.getters.getTextMap().time_frame"
                                    dense
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    required
                                    :dark="$store.getters.getColorPalette().isDark" 
                                ></v-select  >
                            </v-col>
                            <!-- <v-col>
                                <v-checkbox label="Transpose"  v-model="isTranspose"></v-checkbox>
                            </v-col> -->
                        </v-row>
                        <v-divider  class="mb-5"></v-divider>
                        <v-row >
                            <v-col>
                                <v-text-field
                                    v-if="!loading"
                                    v-model="log_book_label"
                                    :label="$store.getters.getTextMap().log_book+' '+$store.getters.getTextMap().label"
                                    :rules="nameRules"
                                    dense
                                    outlined
                                    required
                                :dark="$store.getters.getColorPalette().isDark" 
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select  
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="form.meta.logDetails"
                                    :items="shiftMachineUsageLogBookOptions"
                                    :label="$store.getters.getTextMap().shift_based_machine_usage_log"
                                    dense
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    required
                                    :rules="objectRules"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col align="right">
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small @click="sheetSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-btn class="ml-2" v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" outlined text small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'MachineShiftUsageLogBasedSheet',
    props: {
        "sheet": {
            type: Object,
        },
    },
    components:{
        InfoAlert,
        // DeleteConfirmation
    },
    data(){ 
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            valid:false,
            isTranspose:false,
            log_book_label:null,
            api:'createSheets',
            form:{
                name:null,
                sheet_type:'machineShiftUsageLogBasedSheet',
                meta:{
                    logDetails:{},
                    isTranspose:false,
                    timeframe:'mtd'
                }
            },
            timeframeOptions:[
                    {label:'MTD', value:'mtd'},
                    {label:'YTD', value:'ytd'},
                    {label:'Last 30 Days', value:'last30'},
                    {label:'Last 60 Days', value:'last60'},
                    {label:'Last 90 Days', value:'last90'},
                    {label:'Last 180 Days', value:'last180'},
                ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    mounted(){
        this.form.sheet_type = 'machineShiftUsageLogBasedSheet'
        if(this.sheet && this.sheet.meta){
            this.form.meta.logDetails = this.sheet.meta.logDetails
            this.form.meta.timeframe=this.sheet.meta.timeframe
            this.log_book_label=this.sheet.meta.logDetails.log_label
            this.form.name = this.sheet.name
            this.api = 'updateSheets'
            this.form.sheet_id = this.sheet.sheet_id
            this.isTranspose=this.sheet.meta.isTranspose
        }
    },
    computed:{
    shiftMachineUsageLogBookOptions(){
        let op=[]
          let g=this.$store.state.shiftMachineUsageLogs
          for(let i of g){
              op.push( {value:{shift_id:i.shift_id,log_label:i.name,meta:i.meta}, label: i.name })
            }
      return op
    },
    },
    methods:{
        sheetSubmit(){
            this.$refs.machineShiftUsageBasedSheetForm.validate()
            if(this.valid){
                this.loading=true
                this.form.meta.isTranspose = this.isTranspose
                let d=Object.assign({}, this.form)
                d['hide_index']=true
                //d.target=this.emails.replaceAll(' ','').split(',')
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$emit('update')
                            this.$store.dispatch('refreshSheets')
                            this.$refs.machineShiftUsageBasedSheetForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit("close")
        },
    },
    watch:{
        isTranspose(){
            if(this.isTranspose===null){
                this.isTranspose=false
            }
        }
}
}
</script>