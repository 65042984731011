<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <v-form ref="locationForm" v-model="valid">
            <v-row>
                <v-col v-if="!editMode">
                    <v-autocomplete
            v-model="form.from_location"
            :items="locationOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().from_location"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
                </v-col>
                <v-col v-if="!editMode">
                    <v-autocomplete
            v-model="form.to_location"
            :items="locationOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().to_location"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.name"
                        :counter="45"
                        :rules="[rules.required, rules.nonZeroLength, rules.validString ]"
                        :label="$store.getters.getTextMap().name"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col v-if="!editMode">
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.event_type"
                        :counter="35"
                        outlined
                        dense
                        :rules="[rules.required, rules.nonZeroLength, rules.validString, rules.noSpace ]"
                        :label="$store.getters.getTextMap().event_type"
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.msg"
                        :counter="45"
                        outlined
                        dense
                        :label="$store.getters.getTextMap().description_optional"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
        :headers="fieldHeaders"
        :items="metaItems"
        item-key="label"
        :search="search"        
        :loading="loading"
        :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{$store.getters.getTextMap().additional_fields}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            
            <v-form
                    ref="fieldForm"
                    v-model="fieldValid" >
            <v-row dense >
                
            
                

                                    
                                        <v-col align-self="center">
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="fieldForm.label"
                    :counter="25"
                    :rules="[rules.required]"
                    :label="$store.getters.getTextMap().field_label"
                    required
                    ></v-text-field>
                </v-col>
                                    <v-col align-self="center">
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
           v-if="!loading"
                            v-model="fieldForm.field_type"
                            :items="fieldTypes"
                            :label="$store.getters.getTextMap().field_type"
                            
                            
                            item-text="label"
                            item-value="value"
                            required
                            :rules="[rules.required]"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                                    </v-col>
                <v-col align-self="center" v-if="!loading && fieldForm.field_type && fieldForm.field_type =='list'">
                      
                        <v-autocomplete  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading && fieldForm.field_type && fieldForm.field_type =='list'"
                            v-model="fieldForm.field"
                            :items="fieldMetas"
                            :label="$store.getters.getTextMap().choose_list"
                            
                            item-text="label"
                            item-value="value"
                            :rules="[rules.required]"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-autocomplete>
                </v-col>
                <v-col  align-self="center">
                    <v-btn small @click="addField" :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().submitbtnColor" >
                                         {{$store.getters.getTextMap().add}} <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                </v-col>
                                
                
                
            </v-row>
        </v-form>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon small @click="removeField(item)" :dark="$store.getters.getColorPalette().isDark" color="red">mdi-delete</v-icon>
            
        </template>
        
      </v-data-table>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'CreateLocation',
    props:['locationEventType'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.locationEventType){
            this.api='updateLocationEventType'
            this.editMode=true
            this.form=Object.assign({},this.locationEventType)
            this.meta=Object.assign({},this.locationEventType.meta)
        }else{
            this.api='createLocationEventType'
            this.editMode=false
        }
    },
    data(){
        return {
            api:'createLocationEventType',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            search:null,
            fieldValid:false,
            form:{
                name:null,
                msg:null,
                meta:null
            },
            rules:FormRules.rules,
            fieldHeaders:[
                    {
                text: "Field Name",
                value: "label",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Field Type",
                value: "type",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "ID",
                value: "field",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Action",
                value: "action",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
            ],
            fieldForm:{},
            meta:{},
            fieldTypes:[
              {label:'List',value:'list'},
              {label:'Text',value:'text'},
            ],
            
        }
    },
    computed:{
        locationOptions(){
            let op=[]
            if(this.$store.state.locations){
                for(let i of this.$store.state.locations){
                    op.push({
                        label:i.location_name, value:i.location_id
                    })
                }
            }
            return op
        },
        fieldMetas(){
          let op=[]
          
            let lsts=this.$store.state.lists
            for(let i of lsts){
              op.push({
                label:i.name,
                value:i.list_id
              })
            }
          return op
        },
        metaItems(){
            if(this.meta){
                return Object.values(this.meta)
            }
            return []
        }
    },
    methods:{
        addField(){
            this.$refs.fieldForm.validate()
            if(this.fieldValid){
                // console.log('gh')

                this.meta[this.fieldForm.label]={
                    label:this.fieldForm.label,
                    type:this.fieldForm.field_type,
                    field:this.fieldForm.field
                }
                this.meta=Object.assign({},this.meta)
                // console.log(this.meta)

                this.$refs.fieldForm.reset()
                this.fieldValid=true
            }
            
        },
        removeField(field){
            if(field && field.label){
                delete this.meta[field.label]
                this.meta=Object.assign({},this.meta)
            }
        },
        close(){
            this.$emit("close")
        },
        onSubmit(){
            this.$refs.locationForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                d.meta=this.meta
                if(this.editMode){
                    d['from_location']=this.locationEventType.from_location
                    d['to_location']=this.locationEventType.to_location
                    d['event_type']=this.locationEventType.event_type
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.$store.dispatch('refreshLocationEventTypes')
                        this.loading=false
                        this.$emit('update')
                        if(!this.editMode){
                            this.$refs.locationForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    }
}
</script>