<template>
    <v-row justify-end>
        <v-col align="right">
            <v-btn
            class="list"
     :background-color="$store.getters.getColorPalette().background1ColorCode"
     :color="$store.getters.getColorPalette().btnborderColorCode"

      :dark="$store.getters.getColorPalette().isDark"
        outlined
        @click="dialog = !dialog"
        small
      >
       {{$store.getters.getTextMap().create_shift}} <v-icon
          right
        :dark="$store.getters.getColorPalette().isDark"
       :color="$store.getters.getColorPalette().deletebtnColor"
        >mdi-plus
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialog"
        
        max-width="80%"
      >
        <v-card :color="$store.getters.getColorPalette().backgroundColorCode" ><CreateShift v-on:close="onClose" /></v-card>
        
      </v-dialog>
        </v-col>
    </v-row>
  </template>
  
  <script>
  import CreateShift from '@/components/crud_components/derived/CreateShift'
    export default {
        name:'CreateShiftModal',
        components:{
            CreateShift
        },
      data () {
        return {
          dialog: false,
          valid:false,
        }
      },
      methods:{
          onClose(){
              this.dialog=false
              this.$emit('close')
              }
  
      }
    }
  </script>
  <style scoped>
  .list{
     border:3px solid;
    border-radius:20px;
 
  }
  .v-application .headline{
    color:white;
  }
 
  </style>