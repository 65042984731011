<template>
    <v-row justify-end>
        <v-col align="right">
            <v-btn
            :color="$store.getters.getColorPalette().accentCode" small text outlined
        @click="dialog = !dialog"
      >
      {{$store.getters.getTextMap().add_day_wise_target}} <v-icon
          right
         :dark="$store.getters.getColorPalette().isDark"
       :color="$store.getters.getColorPalette().deletebtnColor"
        >mdi-plus
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialog"
        
        max-width="490"
      >
        
        <AddTargetDayWiseData :target="target" v-on:success="$emit('success')" v-on:close="onClose" />
      </v-dialog>
        </v-col>
    </v-row>
  </template>
  
  <script>
  import AddTargetDayWiseData from '@/components/crud_components/derived/AddTargetDayWiseData'
    export default {
        name:'AddTargetDayWiseDataModal',
        props:{
            target:{
                type:Object,
                required:true
            }
        },
        components:{
            AddTargetDayWiseData
        },
      data () {
        return {
          dialog: false,
          valid:false,
        }
      },
      methods:{
          onClose(){
              this.dialog=false
              this.$emit('close')
              }
  
      }
    }
  </script>
  <style scoped>
  .list{
     border:3px solid;
    border-radius:20px;
   
  }
  .v-application .headline{
    color:white;
  }
  .theme--light.v-card {
     background-color: #263238;
  }
  </style>