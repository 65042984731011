<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card
        v-if="dbData && dbData.length>0"
            flat
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}" 
        >
            <v-row  class="mt-2 mx-2" >
                <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="timeframe"
                        :items="timeframeOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"   
                    ></v-select>  
                </v-col>
            </v-row>
            <v-row  class="mb-2 mx-2"  no-gutters>
                <v-col>
                    <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <tr>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Remark</th>
                                    <th v-for="(item,ind) in pollutants"  :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{referenceNames[item]}}</th>
                                </tr>
                            </thead>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                <tr  v-for="(item,ind) in remarks"  :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <td  :class="getRemarksColorClass(item)+' black--text  pa-1 text-center text-subtitle-2'">
                                        <span >{{item}}</span>
                                    </td>
                                    <td v-for="(pollutant,ind) in pollutants"  :key="ind" class="text-center">{{AQIData[item][pollutant]+' Days'}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
export default {
    name:'AirQualityInsights',
    props:['area','air_quality'],
    components:{
        InfoAlert,
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            tableData:[],
            dbData:[],
            pollutants:[],
            timeframe:'6',
            timeframeOptions:[
                {label:'last 7 days', value:'6'},
                {label:'last 30 days', value:'29'},
            ],
            AQIData:{
                'Good':{},
                'Moderate':{},
                'Unhealthy for Sensitive Groups':{},
                'Unhealthy':{},
                'Very Unhealthy':{},
                'Hazardous':{},
            },
            references:[
                'pm10',
                'pm25',
                'co',
                'co2',
                'so2',
                'no2',
                'voc',
                'o3',
                'no',
                'humidity',
                'temp',
                'aqi'
            ],
            referenceNames:{
                'pm10':'PM10',
                'pm25':'PM2.5',
                'co':'CO',
                'co2':'CO2',
                'so2':'SO2',
                'no2':'NO2',
                'voc':'VOC',
                'o3':'O3',
                'no':'NO',
                'humidity':'Humidity',
                'temp':'Temperature',
                'aqi':'AQI'
            },
            remarks:[
                'Good',
                'Moderate',
                'Unhealthy for Sensitive Groups',
                'Unhealthy',
                'Very Unhealthy',
                'Hazardous'
            ]
        }
    },
    mounted(){
        this.timeframe='6'
        this.fetchData()
    },
    methods:{
        calculateInsights(){
            this.AQIData={
                'Good':{},
                'Moderate':{},
                'Unhealthy for Sensitive Groups':{},
                'Unhealthy':{},
                'Very Unhealthy':{},
                'Hazardous':{},
            }
            this.pollutants=[]
            let pollutants=new Set([])
            for(let i of this.dbData){
                for(let j of this.references){
                    if(i[j]!==null){
                        pollutants.add(j)
                        for(let k of this.remarks){
                            if(!this.AQIData[k][j]){
                                this.AQIData[k][j]=0
                            }         
                        }
                    }
                    if(i[j] && i[j]<=50){
                        this.AQIData['Good'][j]=this.AQIData['Good'][j]+1
                    }else if(i[j] && (i[j]>=51 && i[j]<=100) ){
                        this.AQIData['Moderate'][j]=this.AQIData['Moderate'][j]+1
                    }else if(i[j] && (i[j]>=101 && i[j]<=150 )){
                        this.AQIData['Unhealthy for Sensitive Groups'][j]=this.AQIData['Unhealthy for Sensitive Groups'][j]+1
                        // this.AQIData['Unhealthy for Sensitive Groups'][j]=this.AQIData['Unhealthy for Sensitive Groups'][j]=1
                    }else if(i[j] && (i[j]>=151 && i[j]<=200) ){
                        this.AQIData['Unhealthy'][j]=this.AQIData['Unhealthy'][j]+1
                    }else if(i[j] && (i[j]>=201 && i[j]<=300) ){
                        this.AQIData['Very Unhealthy'][j]=this.AQIData['Very Unhealthy'][j]+1
                    }else if(i[j] && i[j]>=301){
                        this.AQIData['Hazardous'][j]=this.AQIData['Hazardous'][j]+1
                    }
                }
            }
            this.pollutants=[...pollutants]
        },
        fetchData(){
            this.dbData=[]
            let from_date=moment().subtract(6,'days').format("YYYYMMDD")
            switch( this.timeframe){
                case '29':
                    from_date=moment().subtract(29, 'days').format('YYYYMMDD')
                    break;
                case '6':
                    from_date=moment().subtract(6, 'days').format('YYYYMMDD')
                    break;
            }
            let aq_ids=[]
            for(let i of this.air_quality){
                aq_ids.push(i.aq_id)
            }
            let payload={
                aq_ids:aq_ids,
                from_date:from_date
            }
            axios.post(this.$store.state.api+'getAQIDataGroupedByDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.dbData=response.data.data
                    this.calculateInsights()
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            })
            .catch(err=>{
                console.error(err)
                this.loading=false
            });
        },
        getRemarksColorClass(remark) {
            switch(remark){
                case "Good":
                    return 'green accent-3'
                case "Moderate":
                    return 'lime accent-3'
                case "Unhealthy for Sensitive Groups":
                    return 'yellow lighten-2'
                case "Unhealthy":
                    return 'yellow darken-3'
                case "Very Unhealthy":
                    return 'orange darken-4'
                case "Hazardous":
                    return 'red darken-3'
            }
      },
    },
    watch:{
        timeframe(){
            this.fetchData()
        },
    }
}
</script>