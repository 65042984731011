<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col>
                <TariffsTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TariffsTable from '@/components/lists/derived/TariffsTable'
export default {
    name:'TariffsPage',
    components:{
        TariffsTable
    }
}
</script>