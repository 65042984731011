<template>
    <v-container>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-col align="right">
                <v-icon small :color="$store.getters.getColorPalette().successColor" @click="showEdit=!showEdit">mdi-pencil</v-icon>
                <DeleteConfirmation v-on:confirm="deleteLocationEventType()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
               
                <v-icon small :color="$store.getters.getColorPalette().alertColor">mdi-delete</v-icon>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="showEdit">
            <v-col>
                <CreateLocationEventType :locationEventType="locationEventType" v-on:close="showEdit=false" v-on:update="$emit('update')"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
        :headers="fieldHeaders"
        :items="metaItems"
        item-key="label"
        :search="search"        
        :loading="loading"
        :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
      >
      <template v-slot:top>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
      ></v-text-field>
      </template>
        
      </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import CreateLocationEventType from '@/components/crud_components/derived/CreateLocationEventType.vue';
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default{
    name: "LocationEventType",
    props: {
        "locationEventType": {
            type: Object,
            required: true
        },
    },
    components:{
        CreateLocationEventType,
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
             showDialog:false,
             search:null,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            showEdit:false,
            fieldHeaders:[
                    {
                text: "Field Name",
                value: "label",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Field Type",
                value: "type",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "ID",
                value: "field",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
            ],
        }
    },
    computed: {
        metaItems(){
            if(this.locationEventType && this.locationEventType.meta){
                return Object.values(this.locationEventType.meta)
            }
            return []
        }
    },
    methods: {
        
        deleteLocationEventType() {
            axios.post(this.$store.state.api + "deleteLocationEventType", this.locationEventType, { headers: {
                    Authorization: "Bearer " + this.$store.state.jwt
                } })
                .then(response => {
                if (response.data.status == "success") {
                    this.$emit("deleteLocationEventType");
                    //this.info=response.data
                    //this.showDismissibleAlert=true
                    //this.$store.dispatch('refreshModules')
                }
                else {
                    //this.loading=false
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            });
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    
}
</script>