<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-form ref="statisticalAnalysisBasedSheetForm" v-model="valid" >
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2">
                <v-card-title>{{$store.getters.getTextMap().statistical_analysis_based_sheet}}</v-card-title>
                <v-row class="mt-2" no-gutters>
                    <v-col>
                        <v-text-field
                            v-if="!loading"
                            v-model="form.name"
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().sheet_name"
                            dense
                            outlined
                            required
                        :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <v-col class="ml-2">
                        <v-checkbox  :label="$store.getters.getTextMap().transpose"  v-model="form.meta.isTranspose"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2 my-2">
                <v-card-title class="sub-heading">{{$store.getters.getTextMap().create_machine_list}}</v-card-title>
                <v-row no-gutters>
                    <v-col cols="auto" align-self="center">
                        <v-text-field
                            v-if="!loading"
                            v-model="machine_label"
                            :label="$store.getters.getTextMap().machine_label"
                            dense
                            outlined
                        :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <WorkspaceMachineForm :multiple="false" v-on:value="updateMachineList"/>
                    </v-col>
                    <v-col cols="auto" class="d-flex mt-3" align="right">
                        <v-btn class="ma-2" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addMachineToList">{{$store.getters.getTextMap().add}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="form.meta.machine_list && form.meta.machine_list.length>0">
                    <v-col>
                        <v-simple-table max-height="300px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().machine_label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().machine_id}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().delete}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="item.machine_id" v-for="item in form.meta.machine_list">
                                                <td>{{ item.machine_label }}</td>
                                                <td>{{ item.machine_id }}</td>
                                                <td>
                                                    <DeleteConfirmation v-on:confirm="deleteFromMachineList(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this machine?">
                                                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>Delete    
                                                            <v-icon small class="ml-1"  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </DeleteConfirmation>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2">
                <v-card-title class="sub-heading">{{$store.getters.getTextMap().create_sheet_column}}</v-card-title>
                <v-row >
                    <v-col align-self="center">
                        <v-text-field
                            v-if="!loading"
                            v-model="column_label"
                            :label="$store.getters.getTextMap().column_label"
                            dense
                            outlined
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select  
                            v-if="!loading"
                            v-model="column_timeframe"
                            :items="timeframeOptions"
                            :label="$store.getters.getTextMap().time_frame"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                    <v-col>
                        <v-select  
                            v-if="!loading"
                            v-model="column_statistics"
                            :items="statisticOptions"
                            :label="$store.getters.getTextMap().statistics"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select  >
                    </v-col>
                    <v-col>
                        <v-autocomplete  
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="column_tagtypes"
                            :items="tagTypeOptions"
                            :label="$store.getters.getTextMap().tag_type"
                            item-text="label"
                            item-value="value"
                            multiple
                            dense
                            outlined
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="auto" class="d-flex mt-2" align="right">
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addColumn">{{$store.getters.getTextMap().add}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="form.meta.cols && form.meta.cols.length>0">
                    <v-col>
                        <v-simple-table max-height="300px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().column_label}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().time_frame}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().statistics}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().tag_type}}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{$store.getters.getTextMap().delete}}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="index" v-for="(item,index) in form.meta.cols">
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.timeframe }}</td>
                                                <td>{{ item.stat }}</td>
                                                <td>{{ item.tags }}</td>
                                                <td>
                                                    <DeleteConfirmation v-on:confirm="deleteColumn(index)"  title="Delete Confirmation"  description="Are you sure you want to delete this machine?">
                                                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete}}  
                                                            <v-icon small class="ml-1"  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </DeleteConfirmation>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().submitbtnColor"  @click="sheetSubmit">
                        {{sheet && sheet.sheet_id?$store.getters.getTextMap().update:$store.getters.getTextMap().create}}
                    </v-btn>
                    <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor"  @click="close">
                       {{$store.getters.getTextMap().cancel}}
                    </v-btn>
            </v-card-actions>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm.vue'
export default {
    name:'StatisticalAnalysisBasedSheet',
    props: {
        "sheet": {
            type: Object,
        },
    },
    components:{
        InfoAlert,
        DeleteConfirmation,
        WorkspaceMachineForm
    },
    data(){ 
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            valid:false,
            machine_label:null,
            machine_id:null,
            column_label:null,
            column_statistics:null,
            column_tagtypes:[],
            column_timeframe:'mtd',
            api:'createSheets',
            form:{
                name:null,
                sheet_type:'statisticalAnalysisBasedSheet',
                meta:{
                    machine_list:[],
                    cols:[],
                    isTranspose:false
                }
            },
            timeframeOptions:[
                {label:'MTD', value:'mtd'},
                {label:'LMTD', value:'lmtd'},
                {label:'YTD', value:'ytd'},
                {label:'Yesterday', value:'yesterday'},
                {label:'Last 30 Days', value:'last30'},
                {label:'Last 60 Days', value:'last60'},
                {label:'Last 90 Days', value:'last90'},
                {label:'Last 180 Days', value:'last180'},
                {label:'Last 365 Days', value:'last365'},
            ],
            statisticOptions:[
                {label:'Max', value:'max'},
                {label:'Min', value:'min'},
                {label:'Average', value:'avg'},
                {label:'Usage', value:'usage'},
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /[0-9]+$/.test(v) || 'It should be a number',
                v => (v && !isNaN(v))|| 'It should be a valid number',
                v => (v && v > 0) || 'Number must be greater than 0',
            ],
        }
    },
    mounted(){
        if(this.sheet && this.sheet.meta){
            this.form.name = this.sheet.name
            this.form.meta.machine_list = this.sheet.meta.machine_list
            this.form.meta.cols = this.sheet.meta.cols
            this.form.sheet_id = this.sheet.sheet_id
            this.isTranspose=this.sheet.meta.isTranspose
            this.api = 'updateSheets'
        }
        this.form.sheet_type = 'statisticalAnalysisBasedSheet'
    },
    computed:{
        tagTypeOptions(){
            let op=[]
            let g=this.$store.state.tagTypes
            for(let i of g){
                op.push( {value:i.tag_type, label: i.name  })
                }
            return op
        },
    },
    methods:{
        updateMachineList(value){
           this.machine_id=value.machine.machine_id
        },
        addMachineToList(){
            let temp=this.form.meta.machine_list
            temp.push({"machine_label":this.machine_label,"machine_id":this.machine_id})
            this.form.meta.machine_list=temp
            this.machine_id=null
            this.machine_label=null
        },
        addColumn(){
            let temp = this.form.meta.cols
            temp.push({"label":this.column_label,"tags":this.column_tagtypes,"stat":this.column_statistics,"timeframe":this.column_timeframe})
            this.form.meta.cols=temp
            this.column_label=null
            this.column_tagtypes=[]
            this.column_statistics=null
            this.column_timeframe=null
        },
        deleteFromMachineList(item){
            this.form.meta.machine_list=this.form.meta.machine_list.filter(x=>x.machine_id!=item.machine_id)
        },
        deleteColumn(index){
            this.form.meta.cols=this.form.meta.cols.filter((x,i)=>i!=index)
        },
        sheetSubmit(){
            this.$refs.statisticalAnalysisBasedSheetForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$emit('update')
                        this.$store.dispatch('refreshSheets')
                        this.$refs.statisticalAnalysisBasedSheetForm.reset()
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                    
                })
            }
        },
        close(){
            this.$emit("close")
         },
    }
}
</script>