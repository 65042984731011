<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col v-if="!hub_id">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="hub"
            :items="hubOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            
            :label="$store.getters.getTextMap().hub"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="workspace"
            :items="workspaceOptions()"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            :rules="objectRules"
            
            :label="$store.getters.getTextMap().workspace"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col>
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                    <v-autocomplete
                    v-if="!loading"
            v-model="machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().machine_name"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-if="multiple">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            multiple
            :label="$store.getters.getTextMap().param_name"
            item-text="label"
                    item-value="value"
                    :rules="objectRules"
          ></v-autocomplete>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().parameter"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
name:'HubWorkspaceMachineParamForm',
// props:['multiple'],
props:{multiple:{
    type:Boolean,
    default:false
},
hub_id:{
    type:String,
},
eliminate:{
    type:Array,
    default:()=>[]
}
},
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        workspace:null,
        hub:null,
        machine:null,
        parameter:null,
        form:{},
        machineOptions:[],
        paramOptionsUnfiltered:[],
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
computed:{
    hubOptions(){
        let op =[] 
        let g=this.$store.state.hubs
        for(let i of g){
            op.push({value:{hub_id:i.hub_id,hub_name:i.hub_id},label:i.hub_id})
        }
        return op
    },
    paramOptions(){
        return this.paramOptionsUnfiltered.filter(u=>!(this.eliminate.includes(u.value.param_id)))
    }
},
methods:{
    workspaceOptions(){
        let hub={}
        let op=[]
        if(this.hub_id){
            hub={hub_id:this.hub_id,hub_name:this.hub_id}
        }else if(this.hub){
            hub=this.hub
            
        }
        let g=this.$store.getters.getWorkspacesByHubId(hub.hub_id)
        if(!g || g.length==0){
            return op
        }
                for(let i of g){
                    op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
                }
            
        return op
    },
    getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    let machines=response.data.machines
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
    getParams(){
        if(this.machine ){
            this.loading=true
            axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine.machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.paramOptionsUnfiltered=[]
                    for(let i of response.data.params){
                        this.paramOptionsUnfiltered.push({label:i.name,value:{param_id:i.param_id,name:i.name}})
                    }
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
    send(){
        this.form.hub=this.hub
        this.form.workspace=this.parameter
        this.form.machine=this.machine
        this.form.parameter=this.parameter
        this.$emit('value',this.form)
    }
},
watch:{
    hub(){
        // this.workspace=null
        // this.machine=null
        // this.parameter=null
        this.workspaceOptions()
    },
    workspace(){
        this.getMachineList()
    },
    machine(){
        this.getParams()
    },
    parameter:{
        handler(){
            this.send()
        },
        deep:true
    }
}
}
</script>