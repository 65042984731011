<template>
    <v-container fluid >
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="shiftForm"
            v-model="valid"          
        >
    <v-row>
        <v-col>
            <v-text-field
            dense
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
        </v-col>
        <v-col>
            <v-text-field
            dense
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.msg"
                        :counter="45"
                        
                        :label="$store.getters.getTextMap().description_optional"
                        required
                    ></v-text-field>
        </v-col>
        
    </v-row>
    <v-row no-gutters>
        <v-col align="right">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    small
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().submitbtnColor"
                    
                        @click="onSubmit"
                    >{{$store.getters.getTextMap().submit}}
                    </v-btn>
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    class="ml-1"
                    small
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                        :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{$store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
    </v-row>
    </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'CreateShift',
    props:['shift'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.shift && this.shift.shift_id){
            this.api='updateShift'
            this.editMode=true
            this.form=Object.assign({},this.shift)
            
        }else{
            this.api='createShift'
            this.editMode=false
        }

    },
    data(){
        return {
            api:'createShift',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                name:null,
                operator:null,
                value:null,
                actions:[],
                timeout:0
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    computed:{
        
    },
    methods:{
        close(){
            this.$emit("close")
        },
        onSubmit(){
            this.$refs.shiftForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['shift_id']=this.shift.shift_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshShifts')
                            if(!this.editMode){
                                this.$refs.shiftForm.reset()
                            }
                            
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
            }
    }
}
</script>