<template>
    <v-container  >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="activityForm" v-model="valid"          >
            <v-row>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.name" :counter="30" :rules="nameRules" :label="$store.getters.getTextMap().activity_name" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.msg" :counter="200"  :label="$store.getters.getTextMap().description" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="form.activity_type"
                        :items="activityTypeOptions"
                        :label="$store.getters.getTextMap().activity_type"
                        outlined
                        dense
                        item-text="label"
                        item-value="value"
                        required
                        :rules="selectRules"
                    ></v-select>
                </v-col>
                <v-col align-self="center">
                
<v-select
  v-if="!loading"
  v-model="form.em_id"
  :label="$store.getters.getTextMap().escalation_matrix"
  outlined
  dense
  :items="escalation_matrix" 
  item-text="label" 
  item-value="value" 
  required
  :rules="[rules.required,rules.validString,rules.size(45)]"
></v-select>
                    </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.default_assignee"
                        :items="users"
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        outlined
                        dense
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().assignee"
                        item-text="label"
                        item-value="value"
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.default_manager"
                        :items="users"
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        outlined
                        dense
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().manager"
                        item-text="label"
                        item-value="value"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                    <v-btn style="margin-left:20px;"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{ $store.getters.getTextMap().cancel }}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container> 
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateActivity',
    props:['activity'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.activity && this.activity.activity_id){
            this.form=Object.assign({},this.activity)
            this.edit=true
            this.api='updateActivity'
        }else{
            this.edit=false
            this.api='createActivity'
        }
          
        this.getEscalationMatrix()
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            escalation_matrix: [],
            api:'createActivity',
            rules:FormRules.rules,
            form:{
                name:null,
                activity_type:'maintenance_activity',
                em_id:null,
                default_assignee:null,
                default_manager:null,
                meta:null,
                msg:null, 
            },
            activityTypeOptions:[
                {label:this.$store.getters.getTextMap().maintenance_activity, value:'maintenance_activity'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 200 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    computed:{
        users(){
            let op=[]
            let g=this.$store.state.UAMUsers
            for(let i of g){
                op.push( {value:i.userid, label: i.name })
            }
            return op
        }
    },
    methods:{
  getEscalationMatrix() {
  this.escalation_matrix = []; 
  this.loading = true;
  axios.get(this.$store.state.api + 'escalationMatrix', { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
    .then(response => { 
      if (response.data.status === 'success') {
        this.escalation_matrix = response.data.escalation_matrix.map(matrix => ({
          label: matrix.name, 
          value: matrix.em_id
        }));
       // console.log(response.data.escalation_matrix,"matrix.id")
      } else {
        this.info = response.data.msg;
        this.showDismissibleAlert = true;
      }
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.info = err;
      this.showDismissibleAlert = true;
    });
},

onSubmit() {
  this.$refs.activityForm.validate();
  if (this.valid) {
    this.loading = true;
    //console.log(this.form,'df')
    axios.post(this.$store.state.api + this.api, this.form, {headers: { Authorization: 'Bearer ' + this.$store.state.jwt }
      })
      .then(response => {
       if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshActivities')
                        this.$emit('update')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
      })
      .catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
},

        close(){
            this.$emit('close')
        }
        
    }
}
</script>