<template>

  <v-container fluid >
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
  <v-row>
              <v-col>
                  <span  :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().time_of_day_parameter }}</span>
              </v-col>
          </v-row>
          <v-form
      ref="usageForm"
      v-model="valid"
      
    >
          <v-row>
              
             
            <v-col>
                <WorkspaceDeviceParamForm v-on:value="updateParamList"/>
                </v-col>
              <!-- <v-col>
                  <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
                  <v-select :dark="$store.getters.getColorPalette().isDark"
  :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                v-model="form.workspace"
            :items="workspaceOptions"
            label="Workspace"
            outlined          
            item-text="label"
            item-value="value"
            required
              :rules="objectRules"
          ></v-select>
              </v-col>
              <v-col>
                  <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
                  <v-select :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                v-model="form.device"
            :items="deviceOptions"
            label="Device"
            outlined
            item-text="label"
            item-value="value"
            required
              :rules="objectRules"
          ></v-select>
              </v-col>
              <v-col>
                  <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
                  <v-select :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                v-model="form.parameter"
            :items="paramOptions"
            label="Device Parameter"
            outlined
            item-text="label"
            item-value="value"
            required
              :rules="objectRules"
          ></v-select>
              </v-col> -->
          </v-row>
          <v-row>
            <v-col align-self="center">
                <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
                  <v-menu :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
              v-model="form.date"
              :label="$store.getters.getTextMap().date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="objectRules"
            ></v-text-field>
          </template>
          <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.date"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
              </v-col >
              {{ $store.getters.getTextMap().from }}
              <v-col align-self="center">
              <TimePicker v-model="fromTime"/>
            </v-col>
            {{ $store.getters.getTextMap().to }}
            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col>
              <!-- <v-col align-self="center">
                <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
      <v-card :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode}" class="pa-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" outlined flat>
        From Time<input id="appt" :style="{color:$store.getters.getColorPalette().accentCode}" type="time" v-model="fromTime" name="appt"/>
      </v-card>
      </v-col> -->
      <!-- <v-col align-self="center">
        <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
      <v-card :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode}" class="pa-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" outlined flat>
        To Time<input id="appt" :style="{color:$store.getters.getColorPalette().accentCode}" type="time" v-model="toTime" name="appt"/>
      </v-card>
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" outlined flat>
        <v-input   type="time" v-model="toTime" name="appt"/>
      </v-card>
              </v-col> -->
  <!-- <v-col align-self="center">
      <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
      <v-menu :dark="$store.getters.getColorPalette().isDark"
               :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
          ref="menufrom"
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="fromTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
              v-model="fromTime"
              label="From"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="objectRules"
            ></v-text-field>
          </template>
          <v-time-picker  :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
            v-if="menu3"
            v-model="fromTime"
            full-width
            format="24hr"
            @click:minute="$refs.menufrom.save(fromTime)"
          ></v-time-picker>
        </v-menu>
              </v-col>
  
              <v-col align-self="center">
                  <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
  <v-menu  :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
  v-if="!loading"
          ref="menuto"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="toTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
              v-model="toTime"
              label="To"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="objectRules"
            ></v-text-field>
          </template>
          <v-time-picker :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
            v-if="menu2"
            v-model="toTime"
            full-width
            format="24hr"
            @click:minute="$refs.menuto.save(toTime)"
          ></v-time-picker>
        </v-menu>
              </v-col> -->
              <v-col align-self="center">
                <v-skeleton-loader
                  v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-radio-group
        v-model="normalised"
        row :dark="$store.getters.getColorPalette().isDark"
      >
        <v-radio
          :label="$store.getters.getTextMap().raw"
          value="raw"
        ></v-radio>
        <v-radio
          :label="$store.getters.getTextMap().trend"
          value="trend"
        ></v-radio>
        <v-radio
          :label="$store.getters.getTextMap().normalised"
          value="normalised"
        ></v-radio>
      </v-radio-group>
              </v-col> 
          </v-row>
          <v-row>
              <v-col align-self="center" >
                  <v-skeleton-loader
          v-if="loading"
        class="mx-auto"
        type="text"
      ></v-skeleton-loader>
                  <v-btn
                  v-if="!loading"
             :color="$store.getters.getColorPalette().submitbtnColor"
              small
              @click="onSubmit"
            >
            {{ $store.getters.getTextMap().generate_report }}
            </v-btn>
            
              </v-col>
              
          </v-row>
          </v-form>
          
              
          <v-row v-if="graphData.length>0">
              <v-col>
                  <strong    :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().tod_report }} - {{form.date}}</strong>
              </v-col>
              <v-col v-if="!loading && graphData.length>0"  align-self="center">
                  <v-btn
           :color="$store.getters.getColorPalette().downloadbtnColor"
              small
              outlined
              @click="downloadPDF"
            >
            {{ $store.getters.getTextMap().download_table}}
            </v-btn>
            
              </v-col>
          </v-row>
          <div v-if="graphData.length>0" >
          <v-divider></v-divider>
          <v-row v-if="processing == 'difference'">
            <v-col >
              <strong class="text-subtitle-2">in this timeframe:</strong>
             
              <span class="text-h3"> {{usage}}</span>{{unit}} 
            </v-col>
            
            <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
            <v-col>
              <strong class="text-subtitle-2">Average:</strong>
             
              <span class="text-h3"> {{graphData.length?Math.round((usage/graphData.length)*100)/100:0}}</span>{{unit}} 
            </v-col>
          </v-row>
          <v-row v-if="processing=='mean'">
            <v-col>
              <strong class="text-subtitle-2">
                Maximum:
              </strong>
              <span class="text-h3"> {{max}}</span>{{unit}} 
            </v-col>
            <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
            <v-col>
              <strong class="text-subtitle-2">
                Minimum:
              </strong>
              <span class="text-h3"> {{min}}</span>{{unit}} 
            </v-col>
          </v-row>
          
          <v-divider></v-divider>
          
          <v-row v-if="graphData.length>0">
              <v-col>
                   <LineGraphByTimeStamp  :timeUnit="timeUnit" :unit="unit" :label="label"  :graphData="graphData" dataKey="data"  />
              </v-col>
          </v-row>
          </div>
          <v-divider></v-divider>
          <a ref="csvDownload" hidden></a>
  
  </v-container>
      
  </template>
  
  <script>
  import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp';
  //import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
  import InfoAlert from '@/components/InfoAlert'
  import axios from 'axios'
  import moment from 'moment'
  // import jsPDF from 'jspdf'
  // import 'jspdf-autotable'
  // import { applyPlugin } from 'jspdf-autotable'
  import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm.vue'
  import TimePicker from '@/components/inputs/TimePicker'
  // applyPlugin(jsPDF)
  export default {
      name:'ParameterTODPanel',
      data(){
          return {
              form:{
                  date:null,
                  device:null,
                  parameter:null
              },
              //date: new Date().toISOString().substr(0, 10),
              report:{
                  date:null,
                  parameter:null,
                  
                  },
                  normalised:'trend',
                  valid:false,
                  menu:false,
                  menu2:false,
                  menu3:false,
              graphData:[],
              toTime:null,
              fromTime:null,
              showDismissibleAlert:false,
              loading:false,
              info:'',
               objectRules:[
                v => !!v || 'Required',
              ],
  
          }
      },
      mounted(){
  
      },
      components:{
          LineGraphByTimeStamp,
          InfoAlert,
          WorkspaceDeviceParamForm,
          TimePicker,
      },
      computed: {
        api(){
          switch(this.normalised){
            case 'normalised':
            return 'getNormalisedDataByParameterBetweenTimestamp';
            
            case 'trend':
              return 'getUsageDataByParamBetweenTimestamp'
            default:
            return 'getDataByParameterBetweenTimestamp'
          }
  
        },
          workspaceOptions(){
              let op=[]
            
                let g=this.$store.state.workspaces
                for(let i of g){
                    //op.push({value:i, text: i.name })
                    op.push( {value:i, label: i.name })
                  }
            return op
          },
        fromTimeStamp(){
          if(this.form.date){
            
              let dt=this.form.date.toString()
              if(this.fromTime){
                dt+=' '+this.fromTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              }
              return Number(moment(dt,'YYYY-MM-DD').format("X"));
            
          }else{
            return null
          }
        },
        toTimeStamp(){
          if(this.form.date){
            
              let dt=this.form.date.toString()
              if(this.toTime){
                dt+=' '+this.toTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              }
              return Number(moment(dt,'YYYY-MM-DD').add('days',1).format("X"));
            
          }else{
            return null
          }
        },
        
        label(){
            if(this.report.parameter){
              let parameter=this.$store.getters.getParameterById(this.report.parameter)
              if(parameter.name){
                return parameter.name
              }else{
                return this.report.parameter
              }
            }else{
              return ''
            }
          },
          parameter(){
              if(this.report.parameter){
              let parameter=this.$store.getters.getParameterById(this.report.parameter)
              return parameter
              }else{
  
                  return null
              }
          },
          processing(){
            if(this.parameter && this.parameter.processing)
            return this.parameter.processing
            return null
          },
          usage(){
            if(this.graphData && this.graphData.length>1){
            if(this.processing && this.processing=='difference'){
              if(this.normalised=='trend'){
                let total=0
              for(let i of this.graphData){
                //console.log(i)
                if(!isNaN(Number(i['data']))){
                  total+=Number(i['data'])
                }
                
              }
              return Math.round(total*1000)/1000
  
              }
              return Math.round((Number(this.graphData[this.graphData.length-1]['data'])-Number(this.graphData[0]['data']))*1000)/1000
            }else{
              let c=0
              let total=0
              for(let i of this.graphData){
                //console.log(i)
                if(!isNaN(Number(i['data']))){
                  total+=Number(i['data'])
                c++
                }
                
              }
              console.log(total)
              return isNaN(total/c)?0:Math.round((total/c)*100)/100
            }
            }else{
              return 0
            }
  
          },
          max(){
            if(this.graphData && this.graphData.length>0){
              let p=[]
              for(let i of this.graphData){
                if(!isNaN(Number(i['data']))){
                  p.push(Number(i['data']))
                }
              }
              if(p.length>0){
                return Math.round(Math.max(...p)*100)/100
              }
            }
            return 0
          },
          min(){
            if(this.graphData && this.graphData.length>0){
              let p=[]
              for(let i of this.graphData){
                if(!isNaN(Number(i['data']))){
                  p.push(Number(i['data']))
                }
              }
              if(p.length>0){
                return Math.round(Math.min(...p)*100)/100
              }
            }
            return 0
          },
          unit(){
            if(this.report.parameter){
              let parameter=this.$store.getters.getParameterById(this.report.parameter)
              if(parameter.unit){
                return parameter.unit
              }else{
                return null
              }
            }else{
              return ''
            }
          },
          deviceOptions(){
               
              let op=[]
            
            if(this.form.workspace && this.form.workspace.workspace_id ){
                let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
                for(let i of g){
                    
                    if(i && i.name ){
                      
                      op.push({value:i, label: i.name })
                    }else{
                      op.push({value:i, label: i.device_id })
                    }
                    
                
            }
            }
            return op
          },
          paramOptions(){
               
              let op=[]
            
            if(this.form.device && this.form.device.device_id ){
                let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
                for(let i of g){
                    
                    if(i && i.name ){
                      
                      op.push({value:i.parameter, label: i.name })
                    }else{
                      op.push({value:i.parameter, label: i.parameter })
                    }
                    
                
            }
            }
            return op
          },
          timeUnit(){
            if( this.toTimeStamp && this.fromTimeStamp && (Number(this.toTimeStamp) - Number(this.fromTimeStamp))>(60*60*3)){
              return 'hour'
            }
            return 'minute'
          }
          
          
      },
      methods: {
        updateParamList(value){
          this.form.parameter=value.parameter['param_id']
          this.form.device=value.device
          },
        downloadPDF(){
  
            //let pdf = new jsPDF('landscape','pt',[512,600]);
              // let pdf = new jsPDF();
              //pdf.table(20, 30, this.currentData);
              let header=['Date',this.label]
              let body=[]
              for (let i of this.graphData){
                  let temp=[]
                  temp.push(moment.unix(Number(i.timestamp)).format('MMMM Do YYYY HH:mm:ss'))
                  if(this.unit){
                  temp.push((Math.round(Number(i['data'])*100)/100)+this.unit)
                  }else{
                      temp.push((Math.round(Number(i['data'])*100)/100))
                  }
                  body.push(temp)
              }
              // pdf.autoTable({
              //     head: [header],
              //     body: body
              //     })
              // pdf.save(this.report.device.name+'_'+this.parameter.name+'_'+moment().format('YYYYMMDD')+'.pdf');
  
              let csvHeader=header.join(',')+"\n"
              let csv=csvHeader
              csv+=body.map(e => e.join(",")).join("\n");
              let csvData = new Blob([csv], { type: 'text/csv' });  
              let csvUrl = URL.createObjectURL(csvData);
              //let hiddenElement = document.createElement('a');
              let hiddenElement = this.$refs.csvDownload;
              hiddenElement.href = csvUrl;
              hiddenElement.target = '_blank';
              hiddenElement.download = this.report.device.name+'_'+this.parameter.name+'_'+'table'+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
              hiddenElement.click();
  
        },
        onSubmit(){
              this.$refs.usageForm.validate()
              if(this.valid){
                this.loading=true
                //let fromTimeStamp=this.fromTimeStamp
                //let toTimeStamp=this.toTimeStamp
                let payload={
                    parameter:this.form.parameter,
        from_timestamp:this.fromTimeStamp,
        to_timestamp:this.toTimeStamp
      }
      
      axios.post(this.$store.state.api+this.api,payload,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }})
      .then(response=>{
        console.log("Param data received")
        //console.log(response.data.monthlyData)
        //context.state.monthlyData=response.data.monthlyData
  //console.log(response.data.data)
  //console.log('something')
  
  
  if(response.data.status==='success'){
  let data=[]
  for (let i of response.data.data){
    
      data.push(i)
    
  }
  this.graphData=data
        //console.log(response.data.data)
        this.report['parameter']=this.form.parameter
        this.report['date']=this.form.date
        this.report['device']=this.form.device
        if(this.graphData.length==0){
            this.info = "No data to show";
              this.showDismissibleAlert = true;
        }
      }else{
        this.info = response.data.msg;
        //this.info = d;
              this.showDismissibleAlert = true;
        //console.log(response.data.msg)
        this.loading=false
      }
        this.loading=false
  
      })
      .catch(err=>{
      this.showDismissibleAlert=true
              this.info=err
              this.loading=false
              })
  
      
      
      
      
              }
          }
      
      },
  
  }
  </script>
  <style scoped>
  .text-subtitle-2{
    color: #fff;
  }
  .text-h3{
    color:#fff;
  }

  input[type="time"]::-webkit-calendar-picker-indicator{
  filter: invert(80%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  }
  </style>