<template>
    <div class="text-center">
        <v-btn
            class="devc"
            small
            outlined
            :background-color="$store.getters.getColorPalette().background1ColorCode"
            :color="$store.getters.getColorPalette().btnborderColorCode"
            @click="dialog = !dialog"
        >
       {{$store.getters.getTextMap().create_location}} <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor">mdi-map-marker</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title> {{$store.getters.getTextMap().create_location}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <CreateLocation v-on:close="dialog=false" v-on:update="$emit('update')"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CreateLocation from '@/components/crud_components/derived/CreateLocation'
export default {
    name:'CreateLocationModal',
    // props:['sheet_id'],
    components:{
        CreateLocation  
    },
    data(){
        return {
            dialog:false
        }
      },
    methods:{
        update(){
            this.$emit('update')
        }
    }
  }
  </script>
  <style scoped>
  .devc{
    border:3px solid;
    border-radius:20px;
  }
  .theme--light.v-sheet {
     background-color: #263238;
  }
  .theme--light.v-card{
     background-color: #263238;
  }
  </style>