<template>
    <v-container fluid>
       <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
       <div v-if="dbData && dbData.length>0">
            <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                <template v-slot:default>
                    <thead :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                        <tr>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{headerMap[timeFilter]?headerMap[timeFilter]:headerMap['month']}}</th>
                            <th v-for="(item,ind) in headers"  :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':getSeverityColorClass(item)}">{{item}}</th>
                            <th v-for="(item,ind) in headers2"  :key="ind+item" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':item==='% of Compliances'?'#29B6F6':$store.getters.getColorPalette().accentCode}">{{item}}</th>
                        </tr>
                    </thead>
                    <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                        <tr  v-for="(item,ind) in dbData"  :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                            <td  :class="' pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item.period}}</span>
                            </td>
                            <td  :class="'pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item.High}}</span>
                            </td>
                            <td  :class="'pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item.Medium}}</span>
                            </td>
                            <td  :class="'pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item.Low}}</span>
                            </td>
                            <td  :class="' pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item.Findings}}</span>
                            </td>
                            <td  :class="' pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item && item.Open?item.Open:0}}</span>
                            </td>
                            <td  :class="' pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span >{{item && item.Closed?item.Closed:0}}</span>
                            </td>
                            <td  :class="' pa-1 text-center text-subtitle-2'" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <span>{{Number(item.Findings) > 0 ? ((Number((item && item.Closed?item.Closed:0) / item.Findings))) * 100 : 0}}%</span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else  :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
            <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
        </div>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
export default {
   name:'SafetyInspectionTable',
   props:['refresh','timeFilter','locations','inspection_types'],
   components:{
       InfoAlert
   },
   data(){
       return {
           loading:false,
           info:'',
           showDismissibleAlert:false,
           api:'getSafetyInspectionCountGroupedBySeverityAndTimeFilter',
           dbData:[],
           headers:[
               "High",
               "Medium",
               "Low",
           ],
           headers2:[     
                "Findings",
                "Open",
                "Closed",
                "% of Compliances",
            ],
           headerMap:{
               "day":"Date",
               "week":"Week",
               "month":"Month",
               "quarter":"Quarter",
           }
       }
   },
   mounted(){
       this.getData()
   },
   methods:{
       getData(){
           axios.post(this.$store.state.api+this.api,{timeFilter:this.timeFilter,locations:this.locations && this.locations.length>0?this.locations:[],inspection_types:this.inspection_types && this.inspection_types.length>0?this.inspection_types:[]},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
           .then(response=>{
               if(response.data.status=='success'){
                   this.dbData=response.data.data
                   this.loading=false
                   this.$emit('refreshed')
               }else{
                   this.loading=false
                   this.info=response.data.msg
                   this.showDismissibleAlert=true
               }
           }).catch(err=>{
               this.loading=false
               this.info=err
               this.showDismissibleAlert=true
           })
       },
       getSeverityColorClass(severity) {
        switch(severity){
                case "High":
                    return '#D32F2F'
                case "Medium":
                    return '#FFF176'
                case "Low":
                    return '#00E676'
                case "Findings":
                    return '#FFF176'
                case "Open":
                    return '#FFF176'
                case "Closed":
                    return '#00E676'
            }
     },
   },
   watch:{
       refresh(){
           if(this.refresh){
               this.getData()
           }
       },
       timeFilter(){
           this.getData()
       },
       locations(){
           this.getData()
       },
       inspection_types(){
           this.getData()
       }
   }

}
</script>