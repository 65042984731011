<template>
    <v-container  fluid  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col align="right"><v-icon @click="edit=!edit">mdi-pencil</v-icon></v-col>
        </v-row>
        <v-row v-if="edit">
            <v-col><CreateDynamicExcelReport :report="report" /></v-col>
        </v-row> -->
       <v-divider v-if="$store.state.user && $store.state.settingMode "></v-divider>
       
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row class="mt-2">
            <v-col cols="auto" class="d-flex">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
                v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
         :dark="$store.getters.getColorPalette().isDark"
            v-model="dateRangeText"
            :label="$store.getters.getTextMap().dates"
            prepend-icon="mdi-calendar"
            readonly
            dense
            outlined
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          range
          
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().accentCode" small outlined text @click="onSubmit">
      {{$store.getters.getTextMap().view_report}}

                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                            mdi-chart-box-multiple-outline
                        </v-icon>
                    </v-btn>
            </v-col>
        </v-row>
        </v-form>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
        <v-row>
            <v-col>
                <v-data-table
      :headers="headers"
      :items="reportData"
      item-key="timestamp"
      :search="search"
      
      :loading="loading"
    loading-text="Loading... Please wait"
    item-class="font-weight-black text-center"
    >
    <template v-slot:top>
      <v-toolbar
        flat 
    :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{$store.getters.getTextMap().report_list}}
</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        
      </v-toolbar>
          </template>
          <template v-slot:item.timestamp="{ item }">
              {{formatTimestamp(item.timestamp)}}
              
    </template>
          <template v-slot:item.filename="{ item }">
            <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="downloadReport(item)">
              {{$store.getters.getTextMap().download_report}}

                            <!-- <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                                mdi-download-circle-outline
                            </v-icon> -->
                        </v-btn>
    </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        
       {{item}}
      </td>
    </template>
          
    </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import fileDownload from 'js-file-download'
// import CreateDynamicExcelReport from '@/components/crud_components/CreateDynamicExcelReport'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'DynamicExcelReportDetails',
    props:['report'],
    components:{
        // CreateDynamicExcelReport,
        InfoAlert
    },
    data(){
        return {
            edit:false,
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            search:null,
            menu:null,
            reportData:[],
            objectRules:[
                v => !!v || 'Required',
            ],
            expanded:[],
            headers:[
                    {
                        text: 'Date  (DD-MM-YYYY)',
                        align: 'start',
                        filterable: false,
                        value: 'date',
                        // class:"text-capitalize indigo lighten-3 font-weight-black"
                        
                    },
                    { text: 'Report', value: 'filename', },
                    
            ],
            form:{
                date:[]
            }    

        }
    },
    mounted() {
        this.form.date=[]
        this.form.date[0]=moment().subtract(31,'d').format('YYYY-MM-DD')
        this.form.date[1]=moment().format('YYYY-MM-DD')
        this.onSubmit()

    },
    computed:{
        dateRangeText () {
          if(this.form.date==null){
              console.log('nl')
            return null
          }
          //console.log('fsdf')
            return this.form.date.join(' - ')
        },
        fromTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[0].toString()
            if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
              dt=this.form.date[1].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
          }
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[1].toString()
            if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
              dt=this.form.date[0].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
          }
        }else{
          return null
        }
      },
    },
    methods:{
      formatTimestamp(item){
        return moment.unix(item).format('DD-MM-YYYY')
      },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  report_id:this.report.report_id,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    let endpoint='getDynamicExcelReportBetweenTimestamp'
    //console.log(fromTimeStamp)
    
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("report data received")
      //console.log(response.data)
      if(response.data.status==='success'){
          //this.setTableHeaders()
          //this.reportData=[]
            
            this.reportData=response.data.data

            if(this.reportData.length>0){
              this.reportData.sort(function(a, b) {
                  return new Date(b.date) - new Date(a.date);
              });
                for(let i of this.reportData){
                    i['date']=moment(i['date']).format('DD-MM-YYYY')
                }
            }
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if(this.reportData.length==0){
                    this.info = "No data to show";
                        this.showDismissibleAlert = true;
                }

    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        }
    },
    downloadReport(item){
            if(item){
                //this.subTableData=item
                console.log()
                this.loading=true
                axios.post(this.$store.state.api+'downloadDynamicExcelReport',{
             statement_id:item.report_loc
            // file_list:['k.txt','9327a2de-a9dc-4daf-a5fc-6c9a96ab7704_20230405.xlsx']
           },{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    },
    responseType: 'blob'}).then((response) => {
      this.loading=false
    /*const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item+'.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();*/
    if(response.data.status && response.data.status=='error'){
      this.showDismissibleAlert=true
      this.info=response.data.msg
    }else{
      fileDownload(response.data,item.report_loc)
    }
    
}).catch(err=>{
  this.loading=false
  this.showDismissibleAlert=true
  this.info=err
});
            }else{
              
                this.showDismissibleAlert=!this.showDismissibleAlert
                this.info='Report file name not specified'
            }

        },
    }
}
</script>