<template>
    <v-container fluid>
  <InfoAlert
    :showDismissibleAlert="showDismissibleAlert"
    v-on:close="showDismissibleAlert = false"
    :info="info"
  />
  <v-card flat  :color="$store.getters.getColorPalette().backgroundColorCode"   >
    <v-card-title
      >
      </v-card-title>
      <v-card-subtitle>
          <v-simple-table v-if="!loading && metaItems.length" >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <strong>{{$store.getters.getTextMap().prop}}
</strong>
                  </th>
                  <th class="text-left">
                    <strong>{{$store.getters.getTextMap().value}}
</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                  
                <tr v-for="(item,index) in metaItems" :key="index">

                  <td>{{ item.label }}</td>
                  <td>{{ item.value }}</td>

                 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-card-subtitle>
      </v-card>
      </v-container>

</template>
<script >
import InfoAlert from "@/components/InfoAlert";
export default{
  name:'ObjectTable',
  props:{
      meta:{
          type:Object,
          required:true
      }
  },
  components:{
      InfoAlert
  },
  data(){
      return {
          loading: false,
    info: "",
    showDismissibleAlert: false,

      }
  },
  computed:{
      metaItems(){
          let result=[]
          if(this.meta){
              let meta=this.meta
              result = Object.keys(meta).map((key) => {return {'label':key,'value':meta[key]}})
          }
          return result
      }
  }

}
</script>