<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="logForm" v-model="valid"  >
            <v-row class="mt-1">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu
                        v-if="!loading"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                outlined
                                dense
                                v-model="form.from_date"
                                :label="$store.getters.getTextMap().from_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                                :dark="$store.getters.getColorPalette().isDark" 
                            ></v-text-field>
                        </template>
                        <v-date-picker   
                            :dark="$store.getters.getColorPalette().isDark" 
                            v-model="form.from_date"
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu
                        v-if="!loading"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="form.to_date"
                                :label="$store.getters.getTextMap().to_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :rules="objectRules"
                                :dark="$store.getters.getColorPalette().isDark" 
                            ></v-text-field>
                        </template>
                        <v-date-picker   
                            :dark="$store.getters.getColorPalette().isDark" 
                            v-model="form.to_date"
                            @input="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text" ></v-skeleton-loader>
                    <v-btn
                        v-if="!loading"
                        small
                        text
                        :color="$store.getters.getColorPalette().submitbtnColor"
                        outlined
                        @click="onSubmit"
                    >
                        {{ $store.getters.getTextMap().fetch_logs }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row no-gutters>
            <v-col cols="12">
                <v-card  :dark="$store.getters.getColorPalette().isDark" >
                    <v-data-table
                        :headers="headers"
                        :items="logs"
                        item-key="inspection_id"
                        show-expand
                        :expanded.sync="expanded"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        :dark="$store.getters.getColorPalette().isDark" 
                    >
                    <template v-slot:expanded-item="{ headers, item }">
                            <!-- <td  v-if="!editMode">
                                <v-btn
                                    v-if="!loading"
                                    small
                                    text
                                    :color="$store.getters.getColorPalette().accentCode"
                                    outlined
                                    @click="editMode=!editMode"
                                >
                                    {{ $store.getters.getTextMap().edit }}
                                </v-btn>
                            </td> -->
                            <td v-if="editMode" :colspan="headers.length">
                                <WriteSafetyInspectionLog :safetyInspectionDetails="item" v-on:success="onSubmit"/>
                            </td>
                        </template>
                </v-data-table>
                </v-card>
            </v-col>
        </v-row>    
    </v-container>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import WriteSafetyInspectionLog from '@/components/crud_components/manufacturing/WriteSafetyInspectionLog'
export default {
    name:'SafetyInspectionLogDetails',
    components:{
        InfoAlert,
        WriteSafetyInspectionLog
    },
    mounted(){
        this.form.from_date=moment().subtract(30,'days').format('YYYY-MM-DD')
        this.form.to_date=moment().add(3,'days').format('YYYY-MM-DD')
        this.onSubmit()
    },
        data(){
            return {
                editMode:true,
                search:'',
                valid:false,
                menu:false,
                menu1:false,
                menu2:false,
                menu3:false,
                graphData:[],
                showDismissibleAlert:false,
                loading:false,
                info:'',
                expanded: [],
                logs:[],
                form:{
                    from_date:null,
                    to_date:null,
                },
                headers: [
                    {
                        text: 'Date',
                        align: 'start',
                        filterable: false,
                        value: 'inspection_date',
                        class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                    },
                    { text: 'Location', value: 'location_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Inspector Id', value: 'inspector_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Severity', value: 'severity', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Inspection Type', value: 'inspection_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Findings', value: 'findings', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Action Required', value: 'actions_required', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Follow Up Date', value: 'followup_date', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Status', value: 'status', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                    { text: 'Logged By', value: 'logged_by', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                ],
                objectRules:[
                    v => !!v || 'Required',
                ],
            }
        },
        methods: {
            onSubmit(){
                this.$refs.logForm.validate()
                if(this.valid){
                  this.loading=true
                  let payload={
                    from_date:this.form.from_date,
                    to_date:this.form.to_date
                    }
                    axios.post(this.$store.state.api+'getSafetyInspectionLogDataBetweenTimestamp',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                        if(response.data.status==='success'){
                            let data=[]
                            let index=0
                            for (let i of response.data.data){
                                let location_details=this.$store.getters.getLocationById(i.location_id)
                                i['inspection_date'] = moment(i['inspection_date']).format('YYYY-MM-DD');
                                i['location_id'] = location_details.location_name;
                                i['index']=index++
                                data.push(i)
                            }
                            this.logs=data
                            if(this.logs.length==0){
                                this.info = "No data to show";
                                this.showDismissibleAlert = true;
                            }
                        }else{
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                    })
                }
            }
        },
    }
</script>