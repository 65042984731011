<template>
    <v-container fluid>
        
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  class="mx-1 my-1" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
<v-row>
    <v-col>
 <v-form
    ref="orgForm"
    v-model="valid"
    
  >
      <v-card flat outlined  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
        <v-card-title class="headline">
          {{ $store.getters.getTextMap().add_hub }}
        </v-card-title>
        <v-card-text>
            <v-text-field
      v-model="form.hub_id"
      :counter="4"
      :rules="nameRules"
      :label="$store.getters.getTextMap().add_hub"
      :loading="loading"
      required
    ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().cancelbtnColor"
         :dark="$store.getters.getColorPalette().isDark" 
            @click="onReset"
          >
           {{ $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-col>
</v-row>
</v-card>
    </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'AddHub',
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                hub_id:null
            },
                loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
        nameRules: [
        v => !!v || this.$store.getters.getTextMap().id_is_required,
        v => /\S+/.test(v) ||this.$store.getters.getTextMap().id_is_required,
        v => (v && v.length <= 4 && v.length > 0) || this.$store.getters.getTextMap().id_must_be_less_than_4_characters,
      ],
        }
    },
    methods:{
        onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "addHub", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshHubs");
            
           
            this.info = "Hub added";
          this.showDismissibleAlert = true;
          this.loading = false;
            this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            
            
            
        }
    }
}
</script>
<style scoped>
.v-sheet.v-card{
  background-color:#B2DFDB;
}
</style>