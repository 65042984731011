<template>
    <v-container fluid    :color="$store.getters.getColorPalette().backgroundColor">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col align-self="center">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-radio-group v-model="normalised" row :dark="$store.getters.getColorPalette().isDark">
                    <v-radio
                    :label="$store.getters.getTextMap().raw"
                        value="raw"
                    ></v-radio>
                    <v-radio
                    :label="$store.getters.getTextMap().trend"
                        value="trend"
                    ></v-radio>
                    <v-radio
                    :label="$store.getters.getTextMap().normalised"
                        value="normalised"
                    ></v-radio>
                </v-radio-group>
            </v-col>  
        </v-row>
        <v-row no-gutters>
          <v-col>
               <div ref="graph"></div>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import Plotly from 'plotly.js'
import moment from 'moment'
export default {
    name:'DualAxisParamTrendPanel',
    components:{
        InfoAlert,
    },
    props:['y1ParamDetails','y2ParamDetails','fromTimestamp','toTimestamp','axisLabels'],
    data(){
        return{
            showDismissibleAlert:false,
            loading:false,
            info:'',
            graphY1Data:[],
            graphY2Data:[],
            graphData:[],
            normalised:'trend',
            y1AxisTitle:'Y1 Axis',
            y2AxisTitle:'Y2 Axis',
            layout:{
                // title: 'Double Y Axis Example',
                    showlegend:true,
                    font:{
                        color:this.$store.getters.getColorPalette().accentCode
                    },
                    plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },       
                    yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                        title: 'Y1 axis'
                      },
                      yaxis2: {
                        title: 'Y2 Axis',
                        titlefont: this.$store.getters.getColorPalette().accentCode,
                        tickfont: this.$store.getters.getColorPalette().accentCode,
                        overlaying: 'y',
                        side: 'right'
                    },
                    uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                },
        }
    },
    mounted(){
        if(this.y1ParamDetails && this.y2ParamDetails && this.fromTimestamp && this.toTimestamp){
            this.getData()
        }
    },
    computed: {
        api(){
        switch(this.normalised){
          case 'normalised':
          return 'getNormalisedDataByParameterBetweenTimestamp';
          
          case 'trend':
            return 'getUsageDataByParamBetweenTimestamp'
          default:
          return 'getDataByParameterBetweenTimestamp'
        }

      },
    },
    methods:{
        createGraph(){
            // let trace1 = {}
            // let trace2 = {}
            // this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
            // let type='scatter'
            // let traces=[]
            // var trace1 = {
            //     x:[],
            //     y:[],
            //     name: 'y1axis data',
            //     type: type
            // };
            // for(let i of this.graphY1Data){
            //     let temp={
            //         x:[],y:[],type:type,name:i.param_name,
            //         yaxis:'y1'
            //     }
            //     temp['x'].push(i.date)
            //     temp['y'].push(i.usage)
            //     traces.push(temp)
            // }
            // for(let i of this.graphY2Data){
            //     let temp={
            //         x:[],y:[],type:type,name:i.param_name,
            //         yaxis:'y2'
            //     }
            //     temp['x'].push(i.date)
            //     temp['y'].push(i.usage)
            //     traces.push(temp)
            // }
            // var data = [trace1, trace2];
            Plotly.newPlot(this.$refs.graph, this.graphData, this.layout,{displaylogo: false});
        },
        getData(){
            this.layout.yaxis.title = 'Y1 Axis'
            this.layout.yaxis2.title = 'Y2 Axis'
            if(this.axisLabels.y1AxisLabel && this.axisLabels.y2AxisLabel){
                this.layout.yaxis.title = this.axisLabels.y1AxisLabel
                this.layout.yaxis2.title = this.axisLabels.y2AxisLabel
            }
            this.graphData=[]
            let payload={
                parameter:null,
                from_timestamp:this.fromTimestamp,
                to_timestamp:this.toTimestamp
            }
            let type='scatter'
            try{
                for(let p of this.y1ParamDetails){
                    payload['parameter']=p['param_id']
                    axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                        if(response.data.status==='success'){
                            let temp ={}
                                temp={
                                            x:[],y:[],type:type,name:p.name,
                                            yaxis:'y1'
                                        }
                            for (let i of response.data.data){
                                
                                        temp['param_id']=p.param_id
                                temp.x.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                                temp.y.push(Number(i.data))
                                        
                            }
                            this.graphData.push(temp)
                            this.createGraph()
                        }
                        
                    })
                    .catch(err=>{
                        this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                    })

                }
                for(let p of this.y2ParamDetails){
                    payload['parameter']=p['param_id']
                    axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                        if(response.data.status==='success'){
                            let temp ={}
                                temp={
                                            x:[],y:[],type:type,name:p.name,
                                            yaxis:'y2'
                                        }
                                        temp['param_id']=p.param_id
                            for (let i of response.data.data){
                                
                                temp.x.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                                temp.y.push(Number(i.data))
                                        
                                
                            }
                            this.graphData.push(temp)
                            this.createGraph()
                            
                        }
                        
                    })
                    .catch(err=>{
                        this.showDismissibleAlert=true
                        this.info=err
                        this.loading=false
                    })
                }
            }catch(error){
                console.log(error)
            }
        }
    },
    watch:{
        y1ParamDetails:{
            handler(){
                // console.log('y1 watch')
                this.getData()
        },
        deep:true
            
        },
        // y2ParamDetails:{
        //     handler(){
        //         console.log('y2 watch')
        //         this.getData()
        // },
        // deep:true
            
        // },
        // fromTimestamp:{
        //     handler(){
        //         // console.log('from watch')
        //         this.getData()
        // },
        // deep:true
            
        // },
        // toTimestamp:{
        //     handler(){
        //         // console.log('to watch')
        //         this.getData()
        // },
        // deep:true
            
        // },
        // axisLabels:{
        //     handler(){
        //         this.getData()
        // },
        // deep:true
            
        // }
        normalised:{
            handler(){
                this.getData()
        },
        deep:true
            
        },
    }
}
</script>