<template>
    <v-container fluid>
      
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
      <div  ref="vis"  >
          
      </div>
    </v-container>
    </template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
// import InfoAlert from '@/components/InfoAlert'
export default{
    name:'BatchProductionTrendPanel',
    props:[],
    compoenents:{
      // InfoAlert
    },
    mounted(){
      this.init()
    },
    data(){
        return {
            loading:false,
          info:'',
          showDismissibleAlert:false,
          graphData:{},
          productionData:[],
          productionPlanData:[],
          graphType:'bar',
          showGraphType:true,
          layout : {
                  //height: 300,
                  //width: 500,
                  // coloraxis:{
                  //   colorbar:{
                  //     bordercolor:'#FFF',
                  //     outlinecolor:'#FFF',
                  //     tickcolor:'#FFF'
                  //   }
                  // },
                  barmode: 'group',
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                  plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  showlegend:true,
                  autosize: true,
                  xaxis: {
                   
                      tickangle: -12,
                      zeroline: false,
                      showgrid: true,
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },       
                    yaxis: {
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },
                    uniformtext: {
                      "mode": "hide",
                      "minsize": 1
                    }
                  }
        }
    },
    methods:{
      getSKUName(sku_id){
        let temp=this.$store.getters.getSKUById(sku_id)
        if(temp){
          return temp.sku_name
        }
        return sku_id

      },
        init(){
            
        this.graphData={}
        this.getData()
        this.getPlanData()
        // this.createChart()
          
    },
    createChart(){
        // this.init()
        this.graphData={}
          this.layout['title']= 'Production Trend'
          //let textInfo='label+percent'

          
          this.layout.yaxis['title'] = 'Quantity'
          
          
            this.layout['showlegend']=true
            //textInfo='percent'
          
          // this.layout={barmode: 'group'}
          // let data = [];
          for(let i of this.productionData){
            //console.log(i,'i')
            if(!('Production' in this.graphData)){
            //   console.log('Upcateing label',i.label)
              let type=this.graphType==1?'line':'bar'
              this.graphData['Production']={
              x: [],
              y: [],
              name:'Production',
              type: type,
              //width:0.9,
              opacity:0.6,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
            }
            //console.log(moment(i.date,'YYYYMMDD'))
            // this.graphData[i.sku_id]['x'].push(moment(i.date).toISOString())
            this.graphData['Production']['x'].push(this.getSKUName(i.sku_id))
            //this.graphData[i.label]['x'].push(i.date)
            this.graphData['Production']['y'].push(i.quantity)
          }
          let temp=Object.values(this.graphData)
          let planData={}
          for(let i of this.productionPlanData){
            //console.log(i,'i')
            if(!('Planned' in planData)){
            //   console.log('Upcateing label',i.label)
              let type=this.graphType==1?'line':'bar'
              planData['Planned']={
              x: [],
              y: [],
              name:'Planned',
              type: type,
              //width:0.9,
              opacity:0.6,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
            }
            //console.log(moment(i.date,'YYYYMMDD'))
            // this.graphData[i.sku_id]['x'].push(moment(i.date).toISOString())
            planData['Planned']['x'].push(this.getSKUName(i.sku_id))
            //this.graphData[i.label]['x'].push(i.date)
            planData['Planned']['y'].push(i.quantity)
          }
          temp=[...temp,...Object.values(planData)]
          // console.log(temp)
          Plotly.newPlot(this.$refs.vis, temp, this.layout, {displaylogo: false});
      },
      getPlanData(){
    
          // this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
          this.graphData={}
          let from_timestamp=moment().subtract(30,'days').unix()
          let to_timestamp=moment().add(1,'days').unix()
          
              this.productionData=[]
              
              
                
                
                this.tempData={}
                let payload={
                    from_timestamp:from_timestamp,
                    to_timestamp:to_timestamp
                      }
                
                  axios.post(this.$store.state.api+'getProductionPlanTotalSKUWise',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response.data)
                  if(response.data.status==='success'){
                  
                      this.productionPlanData=response.data.data
                      this.createChart()
                  }else{
                      this.info=response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                  })
                  .catch(err=>{
                      console.error(err)
                      this.loading=false});

      },
      getData(){
    
          // this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray
          this.graphData={}
          let from_timestamp=moment().subtract(30,'days').unix()
          let to_timestamp=moment().unix()
          
              this.productionData=[]
              
              
                
                
                this.tempData={}
                let payload={
                    from_timestamp:from_timestamp,
                    to_timestamp:to_timestamp
                      }
                
                  axios.post(this.$store.state.api+'getProductionTotalSKUWise',payload,{headers: {
                  Authorization: 'Bearer '+ this.$store.state.jwt
                  }}).then(response=>{
                    // console.log(response.data)
                  if(response.data.status==='success'){
                  
                      this.productionData=response.data.data
                      this.createChart()
                  }else{
                      this.info=response.data.msg
                      this.showDismissibleAlert=true
                  }
                      this.loading=false
                  })
                  .catch(err=>{
                      console.error(err)
                      this.loading=false});

      },
    }
}

</script>