<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-card-title>{{target.name?target.name:target.target_id}} <v-spacer></v-spacer>
              <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
              <TargetDayWiseSheet :target="target" />
                <v-row justify-end="end" v-if="$store.state.user && $store.state.settingMode  && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <v-col cols="auto" class="d-flex ml-auto" align="right">
                        <v-btn @click="addDialog=!addDialog" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().add_target_data}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-plus</v-icon></v-btn>
                        <v-btn class="mx-1"  @click="editMode=!editMode" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().edit_target}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <DeleteConfirmation v-on:confirm="deleteTarget()"  title="Delete Confirmation"  description="Are you sure you want to delete this Target?"> 
                            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_target}}
                                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                            </v-btn>
                        </DeleteConfirmation>
                    </v-col>
                </v-row>
            </v-card-title>
            
            <v-card-subtitle >
                <v-card v-if="editMode" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :color="$store.getters.getColorPalette().backgroundColorCode"  :dark="$store.getters.getColorPalette().isDark">
                    <v-row>
                        <v-col>
                            <CreateTarget :target="target" v-on:close="editMode=false" v-on:update="$emit('update')"/>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="my-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                    <v-row>
                        <v-col>
                           <AddTargetData :target="target" v-if="addDialog"/>
                        </v-col> 
                    </v-row>
                </v-card>
                <v-row>
                    <v-col>
                        <v-simple-table height="200px" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().month}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().day_of_week}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().target_value}}</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in targetData" :key="index">
                                        <td>{{ monthValueMap[item.month] }}</td>
                                        <td>{{ weekValueMap[item.day_of_week] }}</td>
                                        <td>{{ item.target_val }}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteTargetData(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation> 
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-card-title>
              <v-row>

                <v-col align-self="center">Actions attached to {{target.name?target.name:target.target_id}} </v-col>
                <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
                <v-spacer></v-spacer>
                <v-col align-self="center" align="right" cols="1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" >
                  <span>
                    <v-btn
                      small
                      fab
                      :dark="$store.getters.getColorPalette().isDark"
                      @click="addActionDialog = !addActionDialog"
                      color="orange"
                      >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </span>
                </v-col>
                
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
        <v-row>
          <v-col v-if="addActionDialog">
            <v-form ref="addForm" v-model="valid">
              <v-row>
                <v-col
                  ><v-select
                    v-if="!loading"
                    v-model="form.action_id"
                    :items="actionList"
                    :label="$store.getters.getTextMap().action"
                    dense
                    filled
                    item-text="label"
                    item-value="value"
                    required
                    :rules="[rules.required]"
                  ></v-select
                ></v-col>
                <v-col
                  ><v-btn
                    color="teal"
                    :dark="$store.getters.getColorPalette().isDark"
                    @click="addActionToTarget"
                    >{{$store.getters.getTextMap().add_action}}
                    </v-btn>
                  </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
                <v-row>
          <v-col>
            <v-simple-table v-if="!loading" height="250px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().action_id}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().name}}</strong>
                    </th>
                    <th class="text-left">
                      <strong>{{$store.getters.getTextMap().type}}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in actions" :key="item.action_id">
                    <td>{{ item.action_id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>

                    <td>
                      <DeleteConfirmation
                        v-on:confirm="removeActionFromTarget(item)"
                        title="Delete Confirmation"
                        description="Are you sure you want to remove this action from the Target?"
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </DeleteConfirmation>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
            </v-card-subtitle>
            </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddTargetData from '@/components/crud_components/derived/AddTargetData'
import CreateTarget from '@/components/crud_components/derived/CreateTarget';
import TargetDayWiseSheet from "@/components/modals/derived/TargetDayWiseSheet"
import FormRules from '@/utillities/FormRules'
export default {
name:'Target',
props:['target'],
components:{
    InfoAlert,
    AddTargetData,
    DeleteConfirmation,
    CreateTarget,
    TargetDayWiseSheet
},
mounted(){
  this.getActions()
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        addActionDialog:false,
        editMode:false,
        valid:false,
        actions:[],
        // targetData:null,
        rules:FormRules.rules,
        form:{},
        monthValueMap:{
            1:"January",
            2:"February",
            3:"March",
            4:"April",
            5:"May",
            6:"June",
            7:"July",
            8:"August",
            9:"September",
            10:"October",
            11:"November",
            12:"December",
        },
        weekValueMap:{
            6:"Sunday",
            0:"Monday",
            1:"Tuesday",
            2:"wednesday",
            3:"Thursday",
            4:"Friday",
            5:"Saturday",
        },
    }
},
computed:{
    targetData(){
        return this.$store.getters.getTargetDataById(this.target.target_id)
    },
    actionList() {
      let op = [];
      for (let i of this.$store.state.actions) {
        if (!this.actions.find((x) => x.action_id == i.action_id)) {
          op.push({
            label: i.name,
            value: i.action_id,
          });
        }
      }
      return op;
    },
    
},
methods:{
    // getTargetDetails(){
    //     axios.post(this.$store.state.api+'getTargetDataByID',{target_id:this.target.target_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
    //     .then(response=>{
    //         if(response.data.status=='success'){
    //             this.targetData=response.data.data
    //             this.loading=false
    //         }else{
    //             this.loading=false
    //             this.info=response.data.msg
    //             this.showDismissibleAlert=true
    //         }
    //     }).catch(err=>{
    //         this.loading=false
    //         this.info=err
    //         this.showDismissibleAlert=true
    //     })
    // },
    getActions(){
        if(this.target && this.target.target_id){

            axios
            .post(this.$store.state.api + "targetActionMap", {target_id:this.target.target_id}, {
            headers: {
                Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
            //   this.$store.dispatch("refreshActionMappings");

              this.actions=response.data.data
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
        })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
        }

    },
    addActionToTarget() {
      this.$refs.addForm.validate();
      if (this.valid) {
        this.loading = true;
        this.form["target_id"] = this.target.target_id;

        axios
          .post(this.$store.state.api + "attachActionToTarget", this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
            //   this.$store.dispatch("refreshActionMappings");
            this.getActions()
              this.info = "The Action has been added to the target";
              this.showDismissibleAlert = true;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
    removeActionFromTarget(item) {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "removeActionFromTarget",
          { target_id: this.target.target_id, action_id: item.action_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            // this.$store.dispatch("refreshActionMappings");
            this.loading = false;
            this.info = "The Action has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    deleteTarget(){
        axios.post(this.$store.state.api+'deleteTarget',{target_id:this.target.target_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                this.$store.dispatch("refreshTargetData")
                this.$emit('deleteTarget')
                this.loading=false
                this.info='The target has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    deleteTargetData(tuple){
        axios.post(this.$store.state.api+'deleteTargetData',tuple,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status=='success'){
                // this.getTargetDetails()
                this.$store.dispatch("refreshTargetData")
                this.loading=false
                this.info='The target Data has been deleted'
                this.showDismissibleAlert=true
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        })
    },
    cancel(){
        this.showDialog=false
    },
    confirm(){
        this.showDialog=false
    }
},
// watch:{
//     target(){
//         if(this.target&& this.target.target_id){
//             this.getTargetDetails()
//         }
//     }
// }
}
</script>
<style scoped>
.theme--light.v-tabs-items{
    background-color: #263238;
}
</style>