var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:({'border-radius':'5px','border':'1px solid'+_vm.$store.getters.getColorPalette().panelBorderColorCode}),attrs:{"flat":"","color":_vm.$store.getters.getColorPalette().background2ColorCode,"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"text-subtitle-2",style:({color:_vm.$store.getters.getColorPalette().textColor}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_vm._v(_vm._s(_vm.report.name ?_vm.report.name : _vm.report.report_id)+" "),_c('v-spacer'),(_vm.$store.state.user && _vm.$store.state.settingMode )?_c('span',[_c('EditDynamicExcelReportBottomSheet',{attrs:{"report":_vm.report}})],1):_vm._e(),_c('span',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.loading && _vm.$store.state.user && _vm.$store.state.settingMode && _vm.$store.getters.getAccessByLevels(['engineeraccess']))?_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","color":"pink"}},[_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this?"},on:{"confirm":function($event){return _vm.deleteParameterReport()}}},[_c('v-icon',{attrs:{"small":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_vm._v(" mdi-trash-can ")])],1)],1):_vm._e()],1)],1),_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert=false}}}),_c('v-divider',{attrs:{"color":"grey"}}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card-subtitle',{style:({'border-radius': '5px '}),on:{"click":function($event){_vm.overlay=!_vm.overlay}}},[_c('v-row',[_c('v-col',[_c('strong',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().notification_groups)+":")]),_c('strong',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}},[_vm._v(_vm._s(_vm.report.notification_group?1:0))])])],1),_c('v-overlay',{attrs:{"absolute":"","value":hover}},[_c('v-row',{attrs:{"justify":"center","align":"center","align-content":"center"}},[_c('v-col',{attrs:{"justify":"center","align":"stretch","align-content":"center"}},[_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().view_report)+" "),_c('v-icon',[_vm._v("mdi-chart-line")])],1)],1)],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorName}},[_c('v-toolbar',{attrs:{"color":_vm.$store.getters.getColorPalette().foregroundColorName}},[_c('v-btn',{attrs:{"icon":"","dark":_vm.$store.getters.getColorPalette().isDark},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.report.name ?_vm.report.name : _vm.report.report_id)+" - Dynamic Excel Report")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().close)+" ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[(
            _vm.$store.state.user &&
            _vm.$store.state.settingMode
          )?_c('v-chip',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode}},[_c('v-avatar',{class:_vm.$store.getters.getColorPalette().foregroundColorName +
              ' mx-1 px-0',style:({
              color: _vm.$store.getters.getColorPalette().background2ColorCode,
            }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-icon',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-file-chart-outline")])],1),_vm._v(_vm._s(_vm.report.report_id))],1):_vm._e()],1),_c('DynamicExcelReportDetails',{attrs:{"report":_vm.report}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }