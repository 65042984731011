<template>
 
    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn   icon v-bind="attrs"
          v-on="on" color="blue darken-2" >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      </template>
      <v-form
    ref="groupForm"
    v-model="valid"
  >
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-card-title class="headline">
          {{$store.getters.getTextMap().edit}}

        </v-card-title>
        <v-card-text>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
           

    {{info}}





<v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="form.name"
      :counter="35"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
            v-if="!loading"
      v-model="form.emails"
      :rules="emailRules"
      :label="$store.getters.getTextMap().emails"
      hint="Email IDs separated by comma"
      required
    ></v-text-field>
                    </v-col>
                </v-row>
    </v-container>




        </v-card-text>
        <v-card-actions>
          
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
          <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}

          </v-btn>
          <v-btn
        :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
      
</template>

<script>
import axios from 'axios';

  export default {
      name:'EditSECReportModal',
      props:['report'],
    data () {
      return {
        dialog: false,
        valid:false,
        info:'',
        showDismissibleAlert:false,
        loading:false,
        nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Name must be less than 35 characters',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+,?/.test(v) || 'E-mail must be valid',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
        form:{
                name:null,
                type:null,
                processing:null,
                list:[],
                emails:[]
                
            }
      }
    },
    mounted(){
        this.form=Object.assign({}, this.report)
        if(this.form.emails){
            this.emails= this.form.emails.join(',')
          }else{
            this.emails= ''
          }

    },
    
    methods:{
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.report_id=this.report.report_id
            if(typeof(d['emails'])==typeof('emails')){
              d.emails=d['emails'].replaceAll(' ','').split(',')
            }
            
                
                axios.post(this.$store.state.api+'updateSECReport',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          
        this.$store.dispatch('refreshSECReports')
        this.onReset()
            this.dialog=false
            this.$emit('close')
    }else{
        this.info=response.data.msg
        this.loading=false
      //console.log(response.data.msg)
    }})
    .catch(err=>{//console.log(err)
    this.info=err
    this.loading=false});
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
    }
  }
</script>