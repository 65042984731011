<template>
    <v-container fluid :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <v-row>
            <v-col cols="12">
                <ListsTable />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ListsTable from '@/components/lists/derived/ListsTable'
export default {
    name:'ListsPage',
    components:{
        ListsTable
    }
}
</script>