<template>
    <v-form ref="groupForm" v-model="valid">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-card class="pb-10 pt-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-card-title class="headline">
              {{ $store.getters.getTextMap().move_batch }}<v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-container>
                    

                    <v-row>
                        <v-col align-self="center">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="batch_id"
                            :items="batchOptions"
                            :label="$store.getters.getTextMap().batch"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col v-for="field in metaItems" :key="field.label">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select v-if="!loading && field.type == 'list'" v-model="metaData[field.label]"
                                :items="getListOptions(field.field)" :label="field.label" dense outlined item-text="label"
                                item-value="value" required :rules="objectRules"
                                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                            <v-text-field v-else :loading="loading" outlined dense v-model="metaData[field.label]"
                                :counter="10" :rules="[rules.required]" :label="field.label" required></v-text-field>
                        </v-col>
                    </v-row>


                </v-container>




            </v-card-text>
            <div>
                <div class="d-flex justify-center">
                    <v-btn @click="onSubmit" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading" class="mx-2">
                        {{$store.getters.getTextMap().submit }}<v-icon small class="pl-2">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="onReset" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" class="mx-2">
                        {{$store.getters.getTextMap().clear }}<v-icon small class="pl-2">mdi-eraser</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name: 'BatchMovementLog',
    props: {
        'location_event_type': {
            type: Object,
            required: true
        }

    },
    components: {
        InfoAlert
    },
    mounted() {
        this.init()

    },
    data() {
        return {
            loading: false,
            info: '',
            showDismissibleAlert: false,
            showSKUID:false,
            valid: false,
            menu: null,
            menu1: null,
            menu2: null,
            menu3: null,
            batchOptions:[],
            metaData: {},
            meta: {},
            batch_id:null,
            form: {
                fromTime: null,
                toTime: null,
                date: null,
            },
            rules: FormRules.rules,
            objectRules: [
                FormRules.rules.required,
            ],
            floatRules: [
                // v => !!v || 'Required',
                v => (!v || !isNaN(Number(v))) || 'It should be a number',
                v => (!v || (v && v > 0)) || 'It should be more than zero'
            ],
            logRules: [
                v => !!v || 'Required',
                v => /\S+/.test(v) || 'Required',
                v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /^\d+$/.test(v) || 'Input must contain only numbers'
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 40 && v.length > 0) || 'Name must be less than 40 characters',
                v => isNaN(parseFloat(v)) || 'Input cannot be a number' // New rule for checking if the input is not a number
            ],


        }
    },
    computed: {
        skuOptions(){
            let op=[]
            let g=this.$store.state.skus
            for(let i of g){
                if(this.showSKUID){
                    op.push( {value:i.sku_id, label: i.sku_id })

                }else{

                    op.push( {value:i.sku_id, label: i.sku_name })
                }
            }
          return op
        },

        metaItems() {
            if (this.meta) {
                return Object.values(this.meta)
            }
            return []
        }


    },
    methods: {
        init() {
            if (this.location_event_type) {
                this.meta = Object.assign({}, this.location_event_type.meta)
                this.getBatchOptions()
            }
            
        },
        getListOptions(list_id) {
            let op = []
            let lst = this.$store.getters.getListDataById(list_id)
            for (let i of lst) {
                op.push({
                    label: i.tag,
                    value: i.value
                })
            }
            return op
        },
        getBatchOptions(){
            if(this.location_event_type){

                let payload={
                    location_id:this.location_event_type.from_location
                }
                if(this.location_event_type && this.location_event_type.prev_status){
                    payload['status_text']=this.location_event_type.prev_status
                }
                console.log(payload)
                axios.post(this.$store.state.api + 'getBatchesByLocation', payload, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status === 'success') {
                            //this.$store.commit('addParameter',d)
                            //this.$store.dispatch('refreshParameters')
                            //console.log("Parameter added "+d)
                            console.log(response.data.data)
                            let op=[]
                            if(response.data.data){
                                for(let i of response.data.data){
                                    let label=i.batch_id+': '+moment(i.production_time).format('YYYY-MM-DD')+' : '+i.sku_id+' -- '+i.sl_no
                                    op.push({
                                        label:label,
                                        value:i.batch_id
                                    })
                                }
                            }
                            this.batchOptions=op

                        } else {
                            //console.log(response.data.msg)
                            this.info = response.data.msg;
                            //this.info = d;
                            this.showDismissibleAlert = true;
                            //console.log(response.data.msg)
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err
                        this.loading = false
                        this.showDismissibleAlert = true
                    });
            }

        },
        onSubmit() {
            this.$refs.groupForm.validate()
            if (this.valid) {
                //console.log("entered")
                this.loading = true
                //evt.preventDefault()
                // this.form.log_id = this.location_event_type.log_id
                // this.form.meta_data = this.metaData


                let temp = {
                    batch_id:this.batch_id,
                to_location: this.location_event_type.to_location,
                from_location: this.location_event_type.from_location,
                event_type: this.location_event_type.event_type,
                meta:this.metaData,
                }

                let d = Object.assign({}, temp)

                // console.log(d);

                axios.post(this.$store.state.api + 'moveBatch', d, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status === 'success') {
                            //this.$store.commit('addParameter',d)
                            //this.$store.dispatch('refreshParameters')
                            //console.log("Parameter added "+d)

                            this.loading = false
                            this.info = "Success"
                            this.showDismissibleAlert = true
                            //this.dialog=false
                            this.$refs.groupForm.reset()
                            this.getBatchOptions()
                            this.form={}
                            this.$emit('success')
                            this.$emit('close')

                        } else {
                            //console.log(response.data.msg)
                            this.info = response.data.msg;
                            //this.info = d;
                            this.showDismissibleAlert = true;
                            //console.log(response.data.msg)
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err
                        this.loading = false
                        this.showDismissibleAlert = true
                    });
                //this.$store.dispatch('addDevice', d)

            }
        },
        onReset() {
            this.$refs.groupForm.reset()
            this.$emit('close')
        }
    },
    watch: {
        // "location_event_type.meta": {
        //     handler() {

        //         this.init()
        //     },
        //     deep: true
        // },
        "location_event_type": {
            handler() {

                this.init()
            },
            deep: true
        },
    }
}
</script>
<style scoped>
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

}
</style>