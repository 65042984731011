<template>

    <v-container fluid    :color="$store.getters.getColorPalette().backgroundColor">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

        <v-row no-gutters>
            <v-col align-self="center">
                <span :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().granular24_hrs_trend}}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="right" align-self="center" v-if=" $store.state.user">
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
        </v-row>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
            
           
          <v-col v-if="formType==='deviceForm'">
            <WorkspaceDeviceParamForm :multiple="false" v-on:value="updateParamList"/>
          </v-col>
          <v-col v-else>
              <WorkspaceMachineParamForm :multiple="false" v-on:value="updateParamList"/>
          </v-col>
            <!-- <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.workspace"
          :items="workspaceOptions"
          label="Workspace"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules" 
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          label="Device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
               :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.parameter"
          :items="paramOptions"
          label="Device Parameter"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col> -->
        </v-row>
        
        <v-row>
          
          <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu  :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
            v-model="dateRangeText"
            :label="$store.getters.getTextMap().date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
          @input="menu=false"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col><v-checkbox
      v-model="showTOD"
      :label="$store.getters.getTextMap().time_of_day"
    ></v-checkbox></v-col>
    <span v-if="showTOD">
            {{$store.getters.getTextMap().from}}
              <v-col align-self="center">
              <TimePicker v-model="fromTime" :initiate="false" />
            </v-col>
            {{$store.getters.getTextMap().to}}
            <v-col align-self="center">
              <TimePicker v-model="toTime" :initiate="false"/>
            </v-col>
            </span>
            <v-col align-self="center">
              <v-skeleton-loader
                v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-radio-group
      v-model="normalised"
      row :dark="$store.getters.getColorPalette().isDark"
    >
                        <v-radio  value="raw"><template v-slot:label>{{$store.getters.getTextMap().raw}}<InfoComponent hint="This is Raw data collected from the assets. This data is collected based on change or is collected after a minute from the last recorded data" /></template></v-radio>
                        <v-radio  value="trend"><template v-slot:label>{{$store.getters.getTextMap().trend}}<InfoComponent hint="This is trend data of a parameter. This showcases trend in any parameter. In case of counter parameters (such as kWH), it will show the minute wise change." /></template></v-radio>
                        <v-radio value="normalised"><template v-slot:label>{{$store.getters.getTextMap().normalised}}<InfoComponent hint="This is minute wise data and will have start of a minute timestamp. This doesn’t depend on the change." /></template></v-radio>
    </v-radio-group></v-col>  
            <v-col align-self="center">
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            
            small
            @click="onSubmit"
          >
            {{$store.getters.getTextMap().generate_report}}
          </v-btn>
          
            </v-col>
            
        </v-row>
        </v-form>
            
        <v-row v-if="graphData.length>0" :style="{'color':$store.getters.getColorPalette().accentCode}">
            <v-col >
                <strong  >{{$store.getters.getTextMap().granular24_hrs_trend}} - {{dateRangeText}}</strong>
            </v-col>
            <v-col v-if="!loading && graphData.length>0" align-self="center">
                <v-btn
          :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            outlined
            @click="downloadPDF"
          >
            {{$store.getters.getTextMap().download_table}}
          </v-btn>
          
            </v-col>
        </v-row>
        <div v-if="graphData.length>0">
          <v-divider></v-divider>
        
        <v-row v-if="processing=='mean' || form.normalised=='trend'">
          
          
          <v-col >
            <span :style="{'color':$store.getters.getColorPalette().downloadbtnColor}">
            <strong class="text-subtitle-2" >
              {{$store.getters.getTextMap().maximum}}
            </strong>
            </span>
            <span :style="{'color':$store.getters.getColorPalette().accentCode}" class="text-h3"> {{max}}</span><span :style="{'color':$store.getters.getColorPalette().accentCode}" class="text-subtitle-2">{{unit}}</span> 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          <v-col>
            <span :style="{'color':$store.getters.getColorPalette().downloadbtnColor}">
            <strong class="text-subtitle-2">
              {{$store.getters.getTextMap().minimum}}
            </strong>
            </span>
           
            <span class="text-h3"  :style="{'color':$store.getters.getColorPalette().accentCode}"> {{min}}</span><span class="text-subtitle-2"  :style="{'color':$store.getters.getColorPalette().downloadbtnColor}">{{unit}}</span> 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          <v-col>
            <span :style="{'color':$store.getters.getColorPalette().downloadbtnColor}">
            <strong class="text-subtitle-2">
              {{$store.getters.getTextMap().average}}
            </strong>
            </span>
           
            <span class="text-h3"  :style="{'color':$store.getters.getColorPalette().accentCode}"> {{avg}}</span><span class="text-subtitle-2"  :style="{'color':$store.getters.getColorPalette().downloadbtnColor}">{{unit}}</span> 
          </v-col>
        </v-row>
        
        <v-divider></v-divider>
        
        <v-row v-if="graphData.length>0">
            <v-col>
                 <LineGraphByTimeStamp  timeUnit="minute" :unit="unit" :label="label"  :graphData="graphData" dataKey="data"  />
            </v-col>
        </v-row>
        </div>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
    </v-container>
</template>
<script>
import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp';
import InfoAlert from '@/components/InfoAlert'
//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import axios from 'axios'
import moment from 'moment'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
import WorkspaceDeviceParamForm from '@/components/forms/WorkspaceDeviceParamForm'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm.vue'
import TimePicker from '@/components/inputs/TimePicker'
// applyPlugin(jsPDF)
import InfoComponent from '@/components/InfoComponent'
//ANIK - DONOT REMOVE THE BELOW LINE
/* eslint-disable no-useless-escape */
export default {
    name:'ParameterGraphByDays',
    data(){
        return {
            form:{
                date:null,
                device:null,
                parameter:null
            },
            formType:'machineForm',
            fromTime:null,
            toTime:null,
            showTOD:false,
            //date: new Date().toISOString().substr(0, 10),
            report:{
                date:null,
                parameter:null,
                
                },
                normalised:'trend',
                valid:false,
                menu:false,
            graphData:[],
            
            showDismissibleAlert:false,
            loading:false,
            info:'',
             objectRules:[
                v => !!v || 'Required',
              ],

        }
    },
    mounted(){

    },
    components:{
        LineGraphByTimeStamp,
        InfoAlert,
        WorkspaceDeviceParamForm,
        WorkspaceMachineParamForm,
        TimePicker,
        InfoComponent
    },
    computed: {
      api(){
        switch(this.normalised){
          case 'normalised':
          return 'getNormalisedDataByParameterBetweenTimestamp';
          
          case 'trend':
            return 'getUsageDataByParamBetweenTimestamp'
          default:
          return 'getDataByParameterBetweenTimestamp'
        }

      },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        dateRangeText () {
          if(this.form.date==null){
            return null
          }
        return this.form.date//.join(' ~ ')
      },
      timeUnit(){
        if(this.form.date && this.form.date.length>1 ){
          return "day"
        }else{
          return 'hour'
        }
      },
      // fromTimeStamp(){
      //   if(this.form.date){
      //     if(this.form.date.length>1 ){
      //       let dt=this.form.date[1].toString()
      //       if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
      //         dt=this.form.date[0].toString()
      //       }
      //       return Number(moment(dt,'YYYY-MM-DD').format("X"));
      //     }else{
      //       return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
      //     }
      //   }else{
      //     return null
      //   }
      // },
      // toTimeStamp(){
      //   if(this.form.date){
      //     if(this.form.date.length>1 ){
      //       let dt=this.form.date[1].toString()
      //       if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
      //         dt=this.form.date[0].toString()
      //       }
      //       return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
      //     }else{
      //       return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
      //     }
      //   }else{
      //     return null
      //   }
      // },
      fromTimeStamp(){
          if(this.form.date){
            
              let dt=this.form.date.toString()
              if(this.showTOD && this.fromTime){
                dt+=' '+this.fromTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              }
              return Number(moment(dt,'YYYY-MM-DD').format("X"));
            
          }else{
            return null
          }
        },
        toTimeStamp(){
          if(this.form.date){
            
              let dt=this.form.date.toString()
              if(this.showTOD && this.toTime){
                dt+=' '+this.toTime.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
              }
              return Number(moment(dt,'YYYY-MM-DD').add(1,'days').format("X"));
            
          }else{
            return null
          }
        },
      label(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParamById(this.report.parameter)
            if(parameter && parameter.name){
              return parameter.name
            }else{
              return this.report.parameter
            }
          }else{
            return ''
          }
        },
        parameter(){
            if(this.report.parameter){
            let parameter=this.$store.getters.getParamById(this.report.parameter)
            return parameter
            }else{

                return null
            }
        },
        processing(){
          if(this.parameter && this.parameter.processing)
          return this.parameter.processing
          return null
        },
        usage(){
          if(this.graphData && this.graphData.length>1){
          if(this.processing && this.processing=='difference'){
            return Number(this.graphData[this.graphData.length-1]['data'])-Number(this.graphData[0]['data'])
          }else{
            let c=0
            let total=0
            for(let i of this.graphData){
              //console.log(i)
              if(!isNaN(Number(i['data']))){
                total+=Number(i['data'])
              c++
              }
              
            }
            console.log(total)
            return isNaN(total/c)?0:Math.round((total/c)*100)/100
          }
          }else{
            return 0
          }

        },
        max(){
          if(this.graphData && this.graphData.length>0){
            let p=[]
            for(let i of this.graphData){
              if(!isNaN(Number(i['data']))){
                p.push(Number(i['data']))
              }
            }
            if(p.length>0){
              return Math.round(Math.max(...p)*100)/100
            }
          }
          return 0
        },
        min(){
          if(this.graphData && this.graphData.length>0){
            let p=[]
            for(let i of this.graphData){
              if(!isNaN(Number(i['data']))){
                p.push(Number(i['data']))
              }
            }
            if(p.length>0){
              return Math.round(Math.min(...p)*100)/100
            }
          }
          return 0
        },
        avg(){
          if(this.graphData && this.graphData.length>0){
            let p=[]
            for(let i of this.graphData){
              if(!isNaN(Number(i['data']))){
                p.push(Number(i['data']))
              }
            }
            if(p.length>0){
              let avg=p.reduce((a,b) => a + b, 0) / p.length
              return Math.round(avg*100)/100
            }
          }
          return 0
        },
        unit(){
          if(this.report.parameter){
            let parameter=this.$store.getters.getParamById(this.report.parameter)
            if(parameter && parameter.unit){
              return parameter.unit.toString()
            }else{
              return null
            }
          }else{
            return ''
          }
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.snode_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
        
        
    },
    methods: {
      updateParamList(value){
          this.form.parameter=value.parameter.param_id
          this.form.device=value.device
      },
      downloadPDF(){

          //let pdf = new jsPDF('landscape','pt',[512,600]);
            // let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            console.log(this.unit)
            let unit = this.unit?this.unit.toString().replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, ''):''
            let header=['Date',this.label + ' ' +unit]
            let body=[]
            for (let i of this.graphData){
                let temp=[]
                temp.push(moment.unix(Number(i.timestamp)).format('MMMM Do YYYY HH:mm'))
                if(this.unit){
                temp.push((Math.round(Number(i['data'])*100)/100))
                }else{
                    temp.push((Math.round(Number(i['data'])*100)/100))
                }
                body.push(temp)
            }
            // pdf.autoTable({
            //     head: [header],
            //     body: body
            //     })
            // pdf.save(this.report.device.name+'_'+this.parameter.name+'_'+moment().format('YYYYMMDD')+'.pdf');

            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.report.device.name+'_'+this.parameter.name+'_table'+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();

      },
      onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  parameter:this.form.parameter,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    
    axios.post(this.$store.state.api+this.api,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("Param data received")
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
let data=[]
for (let i of response.data.data){
  
  data.push(i)
  
}
this.graphData=data
//console.log(response.data.data)
this.report['parameter']=this.form.parameter
this.report['date']=this.form.date
this.report['device']=this.form.device
this.report['normalised']=this.normalised
if(this.graphData && this.graphData.length==0){
  this.info="Device was off/no communication during this time frame"
        this.showDismissibleAlert=true
      }
    }else{
      console.log(payload)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
            //console.log(response.data.msg)
      
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

    
    
    
    
            }
        }
    
    },
}
</script>
<style scoped>

.text-h3{
  color:#fff;
}
</style>