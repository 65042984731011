var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert=false}}}),_c('v-form',{ref:"logForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":_vm.$store.getters.getTextMap().from_date,"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.objectRules,"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.form.from_date),callback:function ($$v) {_vm.$set(_vm.form, "from_date", $$v)},expression:"form.from_date"}},'v-text-field',attrs,false),on))]}}],null,false,1652212105),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.form.from_date),callback:function ($$v) {_vm.$set(_vm.form, "from_date", $$v)},expression:"form.from_date"}})],1):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-menu',{ref:"menufrom",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fromTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fromTime=$event},"update:return-value":function($event){_vm.fromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"outlined":"","dense":"","label":_vm.$store.getters.getTextMap().from,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":_vm.objectRules},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},'v-text-field',attrs,false),on))]}}],null,false,1188136156),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menufrom.save(_vm.fromTime)}},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}}):_vm._e()],1):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$store.getters.getTextMap().to_date,"prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","rules":_vm.objectRules,"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.form.to_date),callback:function ($$v) {_vm.$set(_vm.form, "to_date", $$v)},expression:"form.to_date"}},'v-text-field',attrs,false),on))]}}],null,false,1647508489),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.form.to_date),callback:function ($$v) {_vm.$set(_vm.form, "to_date", $$v)},expression:"form.to_date"}})],1):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-menu',{ref:"menuto",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"close-on-content-click":false,"nudge-right":40,"return-value":_vm.toTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.toTime=$event},"update:return-value":function($event){_vm.toTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"outlined":"","dense":"","label":_vm.$store.getters.getTextMap().to,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":_vm.objectRules},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},'v-text-field',attrs,false),on))]}}],null,false,357153532),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuto.save(_vm.toTime)}},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}}):_vm._e()],1):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-btn',{attrs:{"small":"","text":"","color":_vm.$store.getters.getColorPalette().submitbtnColor,"outlined":""},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().fetch_logs)+" ")]):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs,"item-key":"incident_id","show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","dark":_vm.$store.getters.getColorPalette().isDark},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.editMode)?_c('td',{attrs:{"colspan":headers.length}},[_c('WriteSafetyIncident',{attrs:{"safetyIncidentDetails":item},on:{"success":_vm.onSubmit}})],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }