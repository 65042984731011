<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
          <v-col align-self="center" align="right">
            <span>
              <v-icon :color="$store.getters.getColorPalette().accentCode" small @click="showEdit=!showEdit">
        mdi-pencil
      </v-icon>
            </span>
            </v-col>
        </v-row>
        <v-row v-if="showEdit">
          <v-col><CreateSECReport :report="report" v-on:close="showEdit=false" /></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <strong    :color="$store.getters.getColorPalette().accentCode">Energy Params:</strong><br>
       <v-chip
      class="ma-2"
   :dark="$store.getters.getColorPalette().isDark" small
      color="teal"
      text-color="white" v-for="param in report.energy_list"
        :key="param">
        {{paramName(param,report.energy_type)}}
        </v-chip>
            </v-col>
            <v-col>
                <strong    :color="$store.getters.getColorPalette().accentCode">Production Params:</strong><br>
       <v-chip
      class="ma-2"
      small
      color="indigo"
      text-color="white" v-for="param in report.prod_list"
        :key="param">
        {{paramName(param,report.prod_type)}}
        </v-chip>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
            <v-col align-self="center">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
                v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Dates"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          range
        :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col align-self="center">
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
     :dark="$store.getters.getColorPalette().isDark"
    ></v-skeleton-loader>
                <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().foregroundColorCode"
          :dark="$store.getters.getColorPalette().isDark"
            small
            @click="onSubmit"
          >
            View Report
          </v-btn>
            </v-col>
        </v-row>
        </v-form>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-form
    ref="filterForm"
    v-model="filterValid"
    
  >
  <v-row>
    <v-col align-self="center">
      <v-select
    v-if="!filterLoading"
          v-model="filterForm.param"
          :items="filterParamOptions"       
          label="Param"
         :rules="objectRules"
         required
         :dark="$store.getters.getColorPalette().isDark"
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
    </v-col>
    <v-col align-self="center">
      <v-select
    v-if="!filterLoading"
          v-model="filterForm.operator"
          :items="operators"
          item-text="label"
          item-value="value"          
          label="Operator"
         :rules="objectRules"
         required
        :dark="$store.getters.getColorPalette().isDark"
          clearable
       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
    </v-col>
    <v-col align-self="center">
      <v-text-field
                        :loading="filterLoading"
                        v-model="filterForm.value"
                        :counter="11"
                        :rules="objectRules"
                        label="Target Value"
                        required
                      :dark="$store.getters.getColorPalette().isDark"
                    ></v-text-field>
    </v-col>
    <v-col align-self="center">
      <v-progress-circular
      indeterminate
      v-if="filterLoading"
      color="primary"
    :dark="$store.getters.getColorPalette().isDark"
    ></v-progress-circular>
      <v-btn
          v-if="!filterLoading"
            :color="$store.getters.getColorPalette().foregroundColorCode"
            outlined
           :dark="$store.getters.getColorPalette().isDark"
            small
            @click="addFilter"
          >
            Add Filter
          </v-btn>
    </v-col>
  </v-row>
    </v-form>
    
    <v-row v-if="filters.length>0">
      <v-col>
        <span    :color="$store.getters.getColorPalette().accentCode"><strong>Filters:</strong></span>
        <v-chip
      class="ma-2"
      close
      @click:close="deleteFilter(filter.key)" 
      color="indigo"
      text-color="white" v-for="filter in filters"
        :key="filter.key">
        {{filter.param}} {{filter.operator}} {{filter.value}}
        </v-chip>
      </v-col>
    </v-row>
    <div ref="trend">
    <v-row v-if="graphData.length>0">
      <v-col >
            <strong class="text-subtitle-2 white--text">Max SEC</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.max_sec*100)/100}}</span><span    :color="$store.getters.getColorPalette().accentCode">{{report.unit}}</span> 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
      <v-col >
            <strong class="text-subtitle-2 white--text">Total Energy Consumed</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.energy*100)/100}}</span><span   :color="$store.getters.getColorPalette().accentCode">{{report.energy_unit}} </span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
      <v-col >
            <strong class="text-subtitle-2 white--text">Total Production</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.production*100)/100}}</span><span    :color="$store.getters.getColorPalette().accentCode">{{report.prod_unit}} </span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
          
    </v-row>
    <v-row v-if="graphData.length>0">
      <v-col >
            <strong class="text-subtitle-2 white--text">Average SEC</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.sec*100)/100}}</span><span    :color="$store.getters.getColorPalette().accentCode">{{report.unit}} </span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
      <v-col >
            <strong class="text-subtitle-2 white--text">Average Production</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.avg_prod*100)/100}}</span><span   :color="$store.getters.getColorPalette().accentCode">{{report.prod_unit}} </span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
        <v-col >
            <strong class="text-subtitle-2 white--text">Average Energy Consumed</strong>
           <br>
            <span class="text-h3 white--text"> {{Math.round(total.avg_energy*100)/100}}</span><span   :color="$store.getters.getColorPalette().accentCode">{{report.energy_unit}}</span> 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
    </v-row>
    <v-row>
        <v-col>
            <DualBarGraphWithLine v-on:imageData="downloadTrend" :lineValue1="total.sec" lineLabel1="Average SEC" :stopDownload="true" :graphName="label" label1="SEC" label2="production" :graphData="filteredData" dataKey1="sec" dataKey2="production" :unit1="report.unit" :unit2="report.prod_unit" :timeUnit="timeUnit" />
        </v-col>
    </v-row>
    </div>
    
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
   :dark="$store.getters.getColorPalette().isDark"
    ></v-progress-circular>
    <v-form
    ref="groupForm"
    v-model="groupValid">
    <v-row v-if="graphData.length>0">
      
      <v-col align-self="center">
      <v-select
          v-if="!loading"
          v-model="groupBy"
          :items="groupByOptions"
          :label="$store.getters.getTextMap().group_by"
          dense
          filled
          item-text="label"
          item-value="value"
          required
          :rules="objectRules"
       :dark="$store.getters.getColorPalette().isDark"
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      ></v-select>
      </v-col>
      <v-col align-self="center">
        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().foregroundColorCode"
            outlined
         :dark="$store.getters.getColorPalette().isDark"  small
            @click="groupData"
          >
            {{ $store.getters.getTextMap().generate_grouped_trends }}
          </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="groupedData && groupedData.length>0">
      <v-col cols="12">
        <MultiAxisBarGraphByLabel xLabelKey="label" :label="label +' Trend'" :graphData="groupedData" :dataKeys="['production','sec','energy']" />
      </v-col>
    </v-row>
    </v-form>
        <v-row v-if="graphData.length>0">
            <v-col>
                <strong    :color="$store.getters.getColorPalette().accentCode">{{report.name ?report.name : report.report_id}} - {{dateRangeText}}</strong>
            </v-col>
        </v-row>
        
        <v-row v-if="graphData.length>0">
            <v-col>
                <v-card :dark="$store.getters.getColorPalette().isDark">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
     :dark="$store.getters.getColorPalette().isDark"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="timestamp"
      :search="search"
      :single-expand="true"
      
      :loading="loading"
    loading-text="Loading... Please wait"
  :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Time framed SEC - {{dateRangeText}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn 
                v-if="filteredData.length>0"
                outlined 
                small
               :color="$store.getters.getColorPalette().downloadbtnColor"
                @click="downloadPDF">
                Download Table
                </v-btn>
        <a ref="csvDownload" hidden></a>
      </v-toolbar>
          </template>
          
          
    </v-data-table>
  </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>
        </v-container>
</template>

<script>
import MultiAxisBarGraphByLabel from '@/components/graphs/MultiAxisBarGraphByLabel'
import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'
//import DualBarGraphByTimestamp from '@/components/graphs/DualBarGraphByTimestamp'
import DualBarGraphWithLine from '@/components/graphs/DualBarGraphWithLine'
import CreateSECReport from '@/components/crud_components/CreateSECReport'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export default {
    name:'SECReportDetails',
    props:['report'],
    components:{
        InfoAlert,
        //BarGraphByTimestamp,
        //DualBarGraphByTimestamp,
        CreateSECReport,
        DualBarGraphWithLine,
        MultiAxisBarGraphByLabel
    },
    mounted() {
        this.form.date=[]
        this.form.date[0]=moment().subtract(15,'d').format('YYYY-MM-DD')
        this.form.date[1]=moment().subtract(1,'d').format('YYYY-MM-DD')
        this.onSubmit()

    },
    data(){
        return {
            form:{
                date:null,
                device:null,
                parameter:null
            },
            //date: new Date().toISOString().substr(0, 10),
            showEdit:false,
            groupBy:null,
            groupValid:false,
            groupedData:[],
            expanded: [],
            search:'',
            headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
          { text: 'Day', value: 'day' },
          { text: 'Time Frame', value: 'time_frame' },
          { text: 'Energy', value: 'energy' },
          { text: 'Production', value: 'production' },
          { text: 'SEC', value: 'sec' },
          
          
        ],
        groupByOptions:[
          {label:'Time Frame',value:'time_frame'},
          {label:'Date',value:'date'},
        ],
        filters:[],
            filterParamOptions:['sec','production','energy','time_frame'],
            operators:[
                {label:'Greater than', value:'>'},
                {label:'Lesser than', value:'<'},
                {label:'Equals to', value:'=='},
                {label:'Greater than equal to', value:'>='},
                {label:'Lesser than equal to', value:'<='},
            ],
            filterLoading:false,
            filterValid:false,
            filterForm:{
              param:null,
              operator:null,
              value:null
            },
                valid:false,
                menu:false,
            graphData:[],
            showDismissibleAlert:false,
            loading:false,
            info:'',
             objectRules:[
        v => !!v ||  this.$store.getters.getTextMap().required
      ],
        }
    },
    computed:{
      unit(){
        if(this.report && this.report.unit){
          return this.report.unit
        }
        return null
      },
      unit1(){
        if(this.report && this.report.unit){
          return this.report.unit
        }
        return null
      },
      label(){
        if(this.report && this.report.name){
          return this.report.name
        }
        return null
      },
        dateRangeText () {
          if(this.form.date==null){
            return null
          }
        return this.form.date.join(' - ')
      },
      timeUnit(){
        if(this.form.date && this.form.date.length>1 ){
          return "day_hour"
        }else{
          return 'hour'
        }
      },
      fromTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[0].toString()
            if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
              dt=this.form.date[1].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
          }
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[1].toString()
            if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
              dt=this.form.date[0].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
          }
        }else{
          return null
        }
      },
      energyHeader(){
        if(this.report.energy_unit){
              return 'Energy - '+this.report.energy_unit
          }else{
              return 'Energy'
          }
      },
      prodHeader(){
        if(this.report.prod_unit){
              return 'Production - '+this.report.prod_unit
          }else{
              return 'Production'
          }
      },
      secHeader(){
        if(this.unit){
          return  'SEC - '+this.unit
        
            }else{
                return 'SEC'
            }
      },
      filteredData(){
        let filteredData=this.graphData
        for(let i of this.filters){
          let filterFunc=()=>{
          }
          switch (i.operator) {
               case '>=': filterFunc=(data)=>{return data[i.param]>=i.value};
               break;
               case '>': filterFunc=(data)=>{return data[i.param]>i.value};
               break;
               case '==': filterFunc=(data)=>{return data[i.param]==i.value};
               break;
               case '<=': filterFunc=(data)=>{return data[i.param]<=i.value};
               break;
               case '<': filterFunc=(data)=>{return data[i.param]<=i.value};
               break;
          }
          filteredData=filteredData.filter(filterFunc);
        }
        return filteredData
      },
      total(){
        let a={
            'production':0,
            'energy':0,
            'sec':0,
            'avg_prod':0,
            'avg_energy':0,
            'max_sec':0,
          }
        if(this.filteredData && this.filteredData.length>0){
          
          let count=0
          for(let i of this.filteredData){
            if (Number(i['sec'])>0){
              a['production']+=Number(i['production'])
              a['energy']+=Number(i['energy'])
              a['sec']+=Number(i['sec'])
              if(a['max_sec']<Number(i['sec'])){
                a['max_sec']=Number(i['sec'])
              }
              count++
            }
          }
          if(count>0){
          a['sec']/=count
          a['avg_prod']=a['production']/count
          a['avg_energy']=a['energy']/count
          return a
          }
        }
          return a
        
      }
    },
    methods:{
      addFilter(){
        this.$refs.filterForm.validate()
            if(this.filterValid){
              let d=Object.assign({}, this.filterForm)
              d.key=Number(moment().format("X"))
              this.filters.push(d)
              this.$refs.filterForm.reset()
            }
      },
      deleteFilter(key){
        this.filters=this.filters.filter(
      function(data){ return data.key != key }
  );
      },
      downloadTrend(imgData){
        //let pdf = new jsPDF()
        //let d=pdf.fromHTML(this.$refs.trend)
        let pdf = new jsPDF('landscape','pt',[imgData.width+50, imgData.height+200]);
        pdf.setFontSize(35)
        pdf.text(30, 40, this.label +" - SEC VS Production  ")
        pdf.setFontSize(25)
        pdf.text(40,80,'Average SEC: '+this.total.sec.toString()+this.unit+", Total Energy Consumed: "+(Math.round(this.total.energy*100)/100).toString()+this.report.energy_unit+", Average Energy Consumed: "+(Math.round(this.total.avg_energy*100)/100).toString()+this.report.energy_unit)
        pdf.text(40,120,'Max SEC: '+this.total.max_sec.toString()+this.unit+", Total Production: "+(Math.round(this.total.energy*100)/100).toString()+", Average Production "+(Math.round(this.total.avg_prod*100)/100).toString())
        pdf.addImage(imgData.imageData, 'JPEG', 0, 200, imgData.width,imgData.height);
        pdf.save(this.label+"_"+this.dateRangeText+'.pdf')
      },
      testSend(imgData){
        this.loading = true;
            let formData = new FormData();
            formData.append('imageData',imgData.imageData)
      axios.post(this.$store.state.api + "testEmail", formData,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt,
      'Content-Type': 'multipart/form-data'
    }})
        .then(response => {
          if (response.data.status == "success" && response.data.list) {
            this.info = "email Sent";
          this.showDismissibleAlert = true;
          this.loading = false;
            //this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          //this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });

      },
      downloadPDF(){

          //let pdf = new jsPDF('landscape','pt',[512,600]);
            let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Date','Time Frame',this.energyHeader, this.prodHeader, this.secHeader]
            let body=[]
            for (let i of this.filteredData){
                let temp=[]
                temp.push(i.date)
                temp.push(i.time_frame)
                temp.push(i.energy)
                temp.push(i.production)
                temp.push(i.sec)
                body.push(temp)
            }
            pdf.autoTable({
                head: [header],
                body: body
                })
            pdf.save(this.report.name+'_'+moment().format('YYYYMMDD')+'.pdf');

            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.report.name+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();

      },
      groupData(){
        this.$refs.groupForm.validate()
        if(this.groupValid){
          this.groupedData=[]
          let g=DataProcessingUtillities.groupBy(this.filteredData,this.groupBy)
          for(let i in g){
            let energy=0
            let production=0
            let sec=0
            for(let j of g[i]){
              if(j['energy']){
                energy+=j['energy']
              }
              if(j['production']){
                production+=j['production']
              }
            }
            if(energy && production){
              sec=energy/production
            }
            this.groupedData.push({label:i,energy:energy, production:production, sec:sec})
          }
        }
        },
        paramName(param,type='param'){
          switch(type){
            case 'computed':
              return this.getComputedParamName(param)
              
            case 'derived':
              return this.getDerivedParamName(param)
              
            default:
              return this.getParamName(param)
          }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        getComputedParamName(param){
            let p=this.$store.getters.getComputedParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        getDerivedParamName(param){
            let p=this.$store.getters.getDerivedParametersById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with Report Param"
          }
        },
        setTableHeaders(){
            
            this.headers= [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
          { text: 'Day', value: 'day' },
          { text: 'Time Frame', value: 'time_frame' },
          { text: this.energyHeader, value: 'energy' },
          { text: this.prodHeader, value: 'production' }
          ]
          
          if(this.unit){
          this.headers.push({ text: 'SEC - '+this.unit, value: 'sec' })
        
            }else{
                this.headers.push({ text: 'SEC', value: 'sec' })
            }
            
        },
        onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  report_id:this.report.report_id,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    let endpoint='getDataBySECReportBetweenTimestamp'
    //console.log(fromTimeStamp)
    
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("report data received")
      //console.log(response.data)
      if(response.data.status==='success'){
          this.setTableHeaders()
          this.groupedData=[]
            let data=[]
            for (let i of response.data.data){
              i.sec=Math.round(i.sec*1000)/1000
              i.production=Math.round(i.production*1000)/1000
              i.energy=Math.round(i.energy*1000)/1000
              i.day=moment(i.date.toString(),'YYYYMMDD').format('dddd')
                data.push(i)
            
            }
            this.graphData=data
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if(this.graphData.length==0){
                    this.info = "No data to show";
                        this.showDismissibleAlert = true;
                }

    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        }
    },
    }
}
</script>