<template>
    <div class="py-5 px-auto">
      <span  :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{label}}</strong></span>
      <v-btn
              :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
              outlined
                small
                @click="downLoadPDF()">
            Download PDF
      <v-icon
        right
       :dark="$store.getters.getColorPalette().isDark" 
      >mdi-adobe-acrobat
      </v-icon>
        </v-btn>
        
      <v-btn
                color="blue-grey"
                class="ma-2 white--text"  
                small
                @click="emitData()">
            Email
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-email
      </v-icon>
        </v-btn>
        
        <canvas  ref="canvas"></canvas>
    </div>
</template>

<script>
import {Bar } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'
import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'BarGraphWithLineByLabel',
    extends: Bar ,
    props:['label','graphData','dataKey','labelKey','unit','timeUnit','stopDownload', 'emailButton','lineValue1','lineLabel1','lineValue2','lineLabel2'],
    components:{
        
    },
    computed:{
        dateLabels(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.labelKey])
            }
            return l
        },
        consumed(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey])
            }
            
            return l
        },
        lineData1(){
            if (!isNaN(this.lineValue1)){
              return Array(this.graphData.length).fill(this.lineValue1)
            }
            
            return []
        },
        lineData2(){
            if (!isNaN(this.lineValue2)){
              return Array(this.graphData.length).fill(this.lineValue2)
            }
            
            return []
        },
        yAxisLabel(){
          if(this.unit && this.label){
            return this.label +" - "+ this.unit
          }else if(this.unit){
            return this.unit
          }else if(this.label){
            return this.label
          }else{
            return ''
          }
        }
    },
    data(){
        return {
 colorCode:ColorUtility.colorTypesCodeArray,

        }
    },
    methods: {
        emitData(){
            let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);
        this.$emit('canvasData',{
          imageData:pageData,
          height:canvas.height,
          width:canvas.width
        })
        },
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);
        this.$emit('imageData',{
          imageData:pageData,
          height:canvas.height,
          width:canvas.width
        })
        if(this.stopDownload){
          return
        }

                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  pdf.text(40, 40, this.label)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.label+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
      },
        createChart(){
            let datasets= [
            {
                barPercentage: 0.9,
        //barThickness: 6,
        maxBarThickness: 81,
                label:this.label,
               backgroundColor: '#90A4AE',
               borderColor:'rgba(220, 25, 200,0.5)',
               pointBorderColor: 'rgba(20, 25, 100,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                //pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 4,
                
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed,
                fill:true,
                lineTension:0.1
            }
          ]
            if(this.lineData1.length>0){
            datasets.push(
              {
              type: 'line',
              //height: '300',
              label:this.lineLabel1,
              pointBackgroundColor: '#D65DB1',
              pointRadius:0.5,
              borderWidth: 2,
              hoverBorderWidth: 4,
              lineTension:0.1,
              backgroundColor: this.colorCode[0],
              fill:false,
              borderColor:this.colorCode[0],
              data: this.lineData1,
              //yAxisID: "y-axis-1"
            }
            )
          }
            if(this.lineData2.length>0){
            datasets.push(
              {
              type: 'line',
              //height: '300',
              label:this.lineLabel2,
              pointBackgroundColor: '#F65DB1',
              pointRadius:0.5,
              borderWidth: 2,
              hoverBorderWidth: 4,
              lineTension:0.1,
              fill:false,
              backgroundColor: this.colorCode[1] ,//'transparent',
              borderColor:this.colorCode[1],
              data: this.lineData2,
              //yAxisID: "y-axis-1"
            }
            )
          }
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dateLabels,
          datasets:datasets},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{display: true},
          scales: {
        xAxes: [ {
          //type: 'linear',
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Date',
            fontColor:this.$store.getters.getColorPalette().tickfontColor,
          },
          ticks: {
              fontColor:this.$store.getters.getColorPalette().tickfontColor,
            major: {
              fontStyle: 'bold',
                fontColor:this.$store.getters.getColorPalette().tickfontColor,
            },
            source:'auto'
          },
        gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        },
         ],
        yAxes: [ {
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.yAxisLabel,
             fontColor:this.$store.getters.getColorPalette().tickfontColor,

          },
          gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        } ]
      }})
        }
    },
    mounted() {

        this.createChart()
        
    },
    watch: {
        consumed:function(){
      
      this.createChart()

        }
    },
}
</script>
<style >
.v-application .text-capitalize{
  color:#fff;
}