<template>
    <v-container fluid>
        <v-card
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentName, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                >
        <v-row class="my-1 mx-1">
            <v-col>
                <v-simple-table
            :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" 
  >
    <template v-slot:default>
      <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
        <tr >
        <!-- <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Co2</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">PM10</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">PM2.5</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Temperature</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Humidity</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">VOC</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">SO₂</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">CO</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">O3</th>
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">NO₂</th> -->
        <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">Device</th>
        <th v-for="(pollutant,ind) in pollutants"    :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{referenceNames[pollutant]}}</th>
        </tr>
      </thead>
      <tbody :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode}">
        <tr  v-for="(item,ind) in air_quality"    :key="ind"   :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
       
          <!-- <td class="text-center text-subtitle-2">{{item.co2?streamData[item.co2]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.pm10?streamData[item.pm10]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.pm25?streamData[item.pm25]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.temp?streamData[item.temp]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.humidity?streamData[item.humidity]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.voc?streamData[item.voc]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.so2?streamData[item.so2]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.co?streamData[item.co]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.o3?streamData[item.o3]:'-'}}</td>
          <td class="text-center text-subtitle-2">{{item.no2?streamData[item.no2]:'-'}}</td> -->
          <td class="text-center text-subtitle-1" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{ item.label }}</td>
          <td v-for="(pollutant,ind) in pollutants"    :key="ind" class="text-center text-subtitle-2">{{ item[pollutant] ? streamData[item[pollutant]] : '-' }}</td>
          
          
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-col>
        </v-row>
</v-card>
    </v-container>
</template>
<script>
import {io} from 'socket.io-client';
export default {
    name:'AirQualityRealTimeTable',
    props:['air_quality'],
    components:{

    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    mounted(){
        if(this.air_quality && this.air_quality.length>0){

            this.stream.on("connect_error", (err) => {
            console.log(err.message); // prints the message associated with the error
            });
            this.stream.on('error',data=>{
            console.error(data)
            
            })

            this.stream.on('param',data=>{
                //console.log(typeof data)
                if(data.constructor==String){
                data=JSON.parse(data)
            }
            if(!('param_id' in data)){
        data.param_id=data.parameter
      }
            // console.log(data)
            let temp=Object.assign({},this.streamData)
            temp[data.param_id]=Math.round(Number(data.data)*1000)/1000
            this.streamData=temp
            //console.log(temp)
            this.pollutants=[]
            let pollutants=new Set([])
            for(let i of this.air_quality){
                for(let j of this.references){
                    if(this.streamData[i[j]]){
                        pollutants.add(j)
                    }
                }
            }
            this.pollutants=[...pollutants]
            })

            this.subscribe()
        }

    },
    data(){
        return {
            stream:null,
            streamData:{},
            pollutants:[],
            references:[
                'pm10',
                'pm25',
                'co',
                'co2',
                'so2',
                'no2',
                'voc',
                'o3',
                'no',
                'humidity',
                'temp',
                // 'aqi'
            ],
            referenceNames:{
                'pm10':'PM10',
                'pm25':'PM2.5',
                'co':'CO',
                'co2':'CO2',
                'so2':'SO2',
                'no2':'NO2',
                'voc':'VOC',
                'o3':'O3',
                'no':'NO',
                'humidity':'Humidity',
                'temp':'Temperature',
                // 'aqi':'AQI'
            },
        }
    },
    methods:{
        subscribe(){
            if(this.air_quality && this.air_quality.length>0){
                for(let aq_dev of this.air_quality){
                    // console.log(aq_dev)
                if(aq_dev.pm10){
                    this.stream.emit('getParam',aq_dev.pm10)
                }
                if(aq_dev.pm25){
                    this.stream.emit('getParam',aq_dev.pm25)
                }
                if(aq_dev.co2){
                    this.stream.emit('getParam',aq_dev.co2)
                }
                if(aq_dev.voc){
                    this.stream.emit('getParam',aq_dev.voc)
                }
                if(aq_dev.so2){
                    this.stream.emit('getParam',aq_dev.so2)
                }
                if(aq_dev.co){
                    this.stream.emit('getParam',aq_dev.co)
                }
                if(aq_dev.o3){
                    this.stream.emit('getParam',aq_dev.o3)
                }
                if(aq_dev.no2){
                    this.stream.emit('getParam',aq_dev.no2)
                }
                if(aq_dev.temp){
                    this.stream.emit('getParam',aq_dev.temp)
                }
                if(aq_dev.humidity){
                    this.stream.emit('getParam',aq_dev.humidity)
                }
            }
            }

        }
    },
    computed:{}
}
</script>