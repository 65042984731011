<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row><v-col><h2>{{ $store.getters.getTextMap().create_consolidated }}</h2></v-col></v-row>
        
        <v-form ref="metForm"
            v-model="metaValid">
            
            <v-row>
                <v-col>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
       :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="metaForm.name"
                    :counter="55"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().label"
                    required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="metaForm.type"
          :items="types"
          :label="$store.getters.getTextMap().report_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
                <v-col>
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="metaForm.id"
          :items="reportList"
          :label="$store.getters.getTextMap().choose_report"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules" 
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
                <v-col>
                    <v-btn outlined color="green" @click="addToMeta">{{$store.getters.getTextMap().add}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
        
        <v-row>
            <v-col>
                <v-simple-table v-if="!loading"  :dark="$store.getters.getColorPalette().isDark" >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" >
            <strong>{{$store.getters.getTextMap().id}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().name}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().type}}</strong>
          </th>
          
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in form.meta"
          :key="index"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{item.type}}</td>
          <td>
      <DeleteConfirmation  v-on:confirm="removeFromMeta(index)"  title="Delete Confirmation"  description="Are you sure you want to delete this report?">
         <v-icon small color="red">mdi-delete</v-icon>
     </DeleteConfirmation>
</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-form ref="reportForm"
        v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.name"
                    :counter="55"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.msg"
                    :counter="85"
                    
                    :label="$store.getters.getTextMap().description"
                    
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
               <v-col>
<v-select  :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.notification_groups"
          :items="notificationGroupList"
          :label="$store.getters.getTextMap().notification_groups"
          dense
          filled
          item-text="label"
          item-value="value"
          required
          multiple
          card
            :rules="listRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col> <v-col align="right"><v-btn color="teal "   :dark="$store.getters.getColorPalette().isDark"  @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn></v-col>
            </v-row>
            
            
        </v-form>
    </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'CreateConsolidatedReport',
    props:['report'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            this.api="updateConsolidatedReport"
        }else{
            this.api='createConsolidatedReport'
        }
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            metaValid:false,
            api:'createConsolidatedReport',
            form:{
                meta:[]
            },
            metaForm:{},
            types:[
                {label:this.$store.getters.getTextMap().timeframed_sec, value:'sec_report'},
                {label:this.$store.getters.getTextMap().usage, value:'usage_report'},
                
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Name must be less than 55 characters',
            ],
            listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{
        reportList(){
            let op=[]
            switch(this.metaForm.type){
                case 'sec_reports':
                    for(let i of this.$store.state.secTimeFrameReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
                case 'sec_report':
                    for(let i of this.$store.state.secTimeFrameReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
                case 'usage_report':
                    for(let i of this.$store.state.usageReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
            }
            return op
        },
        notificationGroupList(){
            let op=[]
            if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
                for(let i of this.$store.state.notificationGroups){
                    op.push({
                        label:i.name,
                        value:i.group_id
                    })
                }
            }
            return op
        }
    },
    methods:{
        addToMeta(){
            this.$refs.metForm.validate()
            if(this.metaValid){
                let payload=Object.assign({},this.metaForm)
                this.form.meta.push(payload)
            }
        },
        removeFromMeta(index){
            this.form.meta.splice(index,1)

        },
        onSubmit(){
            this.$refs.reportForm.validate()
            if(this.valid){
                if(this.form.meta && this.form.meta.length>0){
                    console.log("created")
                    this.loading=true
                    axios.post(this.$store.state.api+this.api,this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                    if(response.data.status==='success'){
                        
                        this.$store.dispatch('refreshConsolidatedReports')
                        this.onReset()
                        this.dialog=false
                        this.$emit('close')
                    }else{
                        this.info=response.data.msg
                        this.loading=false
                    //console.log(response.data.msg)
                    }
                    this.loading=false
                    })
                    .catch(err=>{//console.log(err)
                    this.info=err
                    this.loading=false});
                }else{
                    this.loading=false
                    this.info='Report list is empty'
                    this.showDismissibleAlert=true
                }
            }
        },
           cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }

    }
}
</script>
<style scoped >

.v-dialog{
transform: scale(0.85);
margin-bottom: 65px;
width:150%;
}
/* .theme--light.v-data-table{
       background-color: #263238;
} */


</style>
