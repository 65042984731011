<template>
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-data-table
        :headers="headers"
        :items="products"
        item-key="index"
        :search="search"
        :single-expand="true"
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
        loading-text="Loading... Please wait"
        :dark="$store.getters.getColorPalette().isDark"
    >
        <template v-slot:top>
            <v-toolbar flat :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar-title>{{$store.getters.getTextMap().products}}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <CreateProductModal v-on:close="getProducts" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
                    <v-icon small :color="$store.getters.getColorPalette().accentCode" @click="getProducts">mdi-refresh</v-icon>
            </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <ProductItem :product="item" v-on:deleteProduct="getProducts" v-on:close="getProducts"/>
            </td>
        </template>
        <template v-slot:item.param_id="{  item }">
            {{ $store.getters.getNameByParamId(item.param_id) }}
        </template>
    </v-data-table>
  </v-card>
</template>
<script>
import CreateProductModal from '@/components/modals/derived/CreateProductModal'
import ProductItem from '@/components/items/derived/ProductItem'
// import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'ProductsTable',
    components:{
        CreateProductModal,
        ProductItem,
        InfoAlert
    },
    // mounted(){
    // },
    data(){
        return {
            search:'',
            loading:false,
            info:'',
            showDismissibleAlert:false,
            // products:[],
            expanded: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    filterable: false,
                    value: 'product_id',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { text: 'Name', value: 'name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
        }
    },
    computed:{
        products(){
            let products=[]
            if(this.$store.state.products){
                products=this.$store.state.products
            }
            return products
        },

    },
    methods:{
        getProducts(){
            this.loading=true
            this.$store.dispatch('refreshProducts')
            this.loading=false
        }
    },
}
</script>
