<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <span v-if="energyBillDistReport.status=='processed'"><v-btn small text :style="{color:$store.getters.getColorPalette().foregroundColorCode}" @click="downloadReport()">{{$store.getters.getTextMap().download_report}}</v-btn></span>
    </v-container>
</template>
<script>
import axios from 'axios'
import fileDownload from 'js-file-download'
import InfoAlert from '@/components/InfoAlert'
export default {
name:'EnergyBillDistDetailedReport',
props:['energyBillDistReport'],
components:{
    InfoAlert,
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        valid:false,
    }
},
methods:{
    // downloadReport(){
    //     if(this.energyBillDistReport){
    //         const jsonStr = this.energyBillDistReport.report_loc.replace(/\\/g, "")
    //         let PDF_Excel_reports = jsonStr.slice(1, -1).split(',');
    //         PDF_Excel_reports.push(this.energyBillDistReport.excel_loc)
    //         this.loading=true
    //         axios.post(this.$store.state.api+'downloadEnergyBillDistReport',{file_list:PDF_Excel_reports},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
    //         .then((response) => {
    //             this.loading=false
    //             if(response.data.status && response.data.status=='error'){
    //                 this.showDismissibleAlert=true
    //                 this.info=response.data.msg
    //             }else{
    //                 fileDownload(response.data,'energyBillDistReport.zip')
    //             }
    //         }).catch(err=>{
    //             this.loading=false
    //             this.showDismissibleAlert=true
    //             this.info=err
    //         });
    //     }else{
    //         this.showDismissibleAlert=!this.showDismissibleAlert
    //         this.info='Report file name not specified'
    //     }
    // },
    async downloadReport(){
        try{
            if(this.energyBillDistReport){
                if(this.energyBillDistReport.report_loc && this.energyBillDistReport.excel_loc){
                    const jsonStr = this.energyBillDistReport.report_loc.replace(/\\/g, "")
                    let PDF_Excel_reports = jsonStr.slice(1, -1).split(',');
                    PDF_Excel_reports.push(this.energyBillDistReport.excel_loc)
                    this.loading=true
                    for(let i of PDF_Excel_reports){
                        let payload={
                            statement_id:i
                        }
                        let response=await axios.post(this.$store.state.api+'downloadEnergyBillDistReport',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                        if(response.data.status && response.data.status=='error'){
                            this.showDismissibleAlert=true
                            this.info=response.data.msg
                        }else{
                            fileDownload(response.data,i)
                        }
                    }
                }else{
                    let response=await axios.post(this.$store.state.api+'downloadEnergyBillDistReport',{statement_id:this.energyBillDistReport.zip_loc},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt},responseType: 'blob'})
                    if(response.data.status && response.data.status=='error'){
                        this.showDismissibleAlert=true
                        this.info=response.data.msg
                    }else{
                        fileDownload(response.data,this.energyBillDistReport.zip_loc)
                    }
                }
            }else{
                this.showDismissibleAlert=!this.showDismissibleAlert
                this.info='Report file name not specified'
            }
        }catch(error){
            this.loading=false
            this.showDismissibleAlert=true
            this.info=error
        }
    },
},
}
</script>