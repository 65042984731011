<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="form" v-model="valid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 mb-2">
                <div :style="[{'display':'flex','justify-content':'space-between'}]">
                    <div :style="[{'display':'flex'}]">
                        <v-card-title class="sub-heading">{{$store.getters.getTextMap().machine_status_timeline}}</v-card-title>
                    </div>
                    <div :style="[{'display':'flex','align_items':'center','cursor':'pointer'}]" @click="showForm=!showForm">
                        <v-icon v-if="showForm"  medium>mdi-arrow-up-drop-circle-outline</v-icon>
                        <v-icon v-else  medium>mdi-arrow-down-drop-circle-outline</v-icon>
                    </div>
                </div>
            </v-card>
            <v-card v-if="showForm" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 mb-2">
                <v-row class="px-3">
                    <v-card-title v-if="timeframeType==='custom'" class="sub-heading">{{$store.getters.getTextMap().select_timeframe}}</v-card-title>
                    <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().select_shift}}</v-card-title>
                    <v-spacer/>
                    <v-col cols="auto" class="d-flex" align="right">
                        <v-radio-group v-model="timeframeType" row :dark="$store.getters.getColorPalette().isDark">
                            <v-radio :label="$store.getters.getTextMap().timeframe_type_shift" value="shift"></v-radio>
                            <v-radio :label="$store.getters.getTextMap().timeframe_type_custom"  value="custom"></v-radio>
                            <!-- <v-radio :label="$store.getters.getTextMap().timeframe_type_yesterday"  value="yesterday"></v-radio> -->
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                <v-row class="mt-1">
                    <v-col>
                        <v-menu :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-model="date"
                                    :label="$store.getters.getTextMap().date"
                                    outlined
                                    dense
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    :loading="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="ISODateRules"
                                ></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="date"
                                @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row v-if="timeframeType==='custom'" no-gutters>
                    <v-col>
                        <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().from }}</div>
                        <TimePicker v-model="from_time"/>
                    </v-col>
                    <v-col>
                        <div :style="{'margin-bottom':'5px','margin-left':'10px'}">{{ $store.getters.getTextMap().to }}</div>
                        <TimePicker v-model="to_time"/>
                    </v-col>
                    <v-col cols="auto" class="d-flex">
                        <v-btn  v-if="!loading" class="mt-9 mx-1"  color="gray darken-1" small outlined text @click="addTimestampToList">{{$store.getters.getTextMap().add}}</v-btn>
                    </v-col>
                </v-row>
                <v-row v-else no-gutters>
                    <v-col>
                        <v-select  
                            v-if="!loading"
                            v-model="shift"
                            :items="shiftOptions"
                            :label="$store.getters.getTextMap().shift"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            required
                            :dark="$store.getters.getColorPalette().isDark" 
                        ></v-select>
                    </v-col>
                </v-row>
                <v-divider v-if="timeframeType==='shift'" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row class="pa-3" v-if="timeframeType==='shift' && form && form.meta && form.meta.shiftDetails && form.meta.shiftDetails.shift_id">
                    <v-col  align="center" align-self="center">
                        <h4 class="count-header">{{$store.getters.getTextMap().id}}</h4>
                        <span class="count">{{form.meta.shiftDetails.shift_id}}</span>
                    </v-col>
                    <v-divider vertical :dark="$store.getters.getColorPalette().isDark"/>
                    <v-col align="center" align-self="center">
                        <h4  class="count-header">{{$store.getters.getTextMap().name}}</h4>
                        <span class="count">{{form.meta.shiftDetails.name ?form.meta.shiftDetails.name : form.meta.shiftDetails.shift_id}}</span>
                    </v-col>
                    <v-divider vertical :dark="$store.getters.getColorPalette().isDark"/>
                    <v-col align="center" align-self="center">
                        <h4  class="count-header">{{$store.getters.getTextMap().date}}</h4>
                        <span class="count">{{formattedDate(date)}}</span>
                    </v-col>
                </v-row>
                <div v-if="timeframeType==='custom' && date" :style="[{'display':'flex','justify-content':'space-between'}]">
                    <div>
                        <v-card-title class="sub-heading">{{  $store.getters.getTextMap().date }}</v-card-title>
                    </div>
                    <div> 
                        <v-card-subtitle class="sub-heading">{{formattedDate(date)}}</v-card-subtitle>
                    </div>
                </div>
                <v-divider v-if="timeframeType==='shift'" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="!loading && form && form.meta && form.meta.timestamps" no-gutters class="mb-1 mt-2">
                    <v-col >
                        <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">                        
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left"><strong>{{  $store.getters.getTextMap().start_time }}</strong></th>
                                        <th class="text-left"><strong>{{  $store.getters.getTextMap().end_time }}</strong></th>
                                        <th class="text-left" v-if="timeframeType==='custom'"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="(item,index) in form.meta.timestamps">
                                        <td>{{ getformattedTime(item.start_time)}}</td>
                                        <td>{{ getformattedTime(item.end_time) }}</td>
                                        <td v-if="timeframeType==='custom'">
                                            <DeleteConfirmation v-on:confirm="deleteTimestampFromTable(item,index)"  title="Delete Confirmation"  description="Are you sure you want to delete this Timestamp?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-card v-if="showForm" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 pb-2 mb-2">
                <v-row class="px-3 mt-1">
                    <v-card-title class="sub-heading">{{$store.getters.getTextMap().choose_machine}}</v-card-title>
                    <v-spacer/>
                    <!-- <v-col v-if="form && form.meta && form.meta.machineList && form.meta.machineList['machine_id']" cols="auto" class="d-flex mt-1" align="right">
                        <v-btn v-if="!loading" small outlined text :color="$store.getters.getColorPalette().accentCode"  @click="clearMachineDetails">
                            {{$store.getters.getTextMap().remove_machine}}
                        </v-btn>
                    </v-col> -->
                </v-row>
                <v-row no-gutters>
                    <v-col align-self="center">
                        <WorkspaceMachineForm :multiple="false" v-on:value="updateMachineList"/>
                    </v-col>
                </v-row>
                <v-row v-if="!loading && form && form.meta && form.meta.machineList" no-gutters class="mb-1">
                    <v-col >
                        <v-simple-table maxHeight="200px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">                        
                            <template v-slot:default>
                                <thead >
                                    <tr>
                                        <th class="text-left"><strong>Machine</strong></th>
                                        <th class="text-left"><strong>ID</strong></th>
                                        <th class="text-left"><strong>{{$store.getters.getTextMap().action}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.label" v-for="item in form.meta.machineList">
                                        <td>{{ item.machine_name}}</td>
                                        <td>{{ item.machine_id}}</td>
                                        <td>
                                            <DeleteConfirmation v-on:confirm="deleteMachineFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Machine?">
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </DeleteConfirmation>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
            <v-row v-if="showForm" no-gutters>
                <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().generate}}</v-btn>
                    <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- <v-card v-if="showGraph && form && form.meta && form.meta.machineList && form.meta.machineList.length>0 && form.meta.timestamps && form.meta.timestamps.length>0" no-gutters dense :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-3 my-2">
                <div :style="[{'display':'flex','justify-content':'space-between'}]">
                    <div :style="[{'display':'flex'}]">
                        <v-card-title class="sub-heading">{{$store.getters.getTextMap().date}}</v-card-title>
                    </div>
                    <div :style="[{'display':'flex'}]">
                        <v-card-subtitle class="sub-heading">{{formattedDate(date)}}</v-card-subtitle>
                    </div>
                </div>
        </v-card> -->
        <v-row class="mt-2" v-if="showGraph && form && form.meta && form.meta.machineList && form.meta.machineList.length>0 && form.meta.timestamps && form.meta.timestamps.length>0" no-gutters dense>
            <v-col>
                <div v-for="machine in form.meta.machineList" :key="machine.machine_id">
                    <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="mb-1">
                        <div :style="[{'display':'flex','justify-content':'space-between'}]">
                            <div>
                                <v-card-subtitle class="sub-heading">{{machine.machine_name}}</v-card-subtitle>
                            </div>
                            <div v-if="form && form.meta && form.meta.timeframeType==='shift' && form.meta.shiftDetails && form.meta.shiftDetails.shift_id">
                                <v-card-subtitle class="sub-heading">{{form.meta.shiftDetails.name}}</v-card-subtitle>
                            </div>
                        </div>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        <div v-for="(item,index) in form.meta.timestamps" :key="index">
                            <div :style="[{'display':'flex','justify-content':'end'}]">
                               <v-card-subtitle class="sub-heading">{{  $store.getters.getTextMap().start_time }}: {{formattedTime(item.start_time)}},  {{  $store.getters.getTextMap().end_time }}:{{formattedTime(item.end_time)}}</v-card-subtitle>
                            </div>
                            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                            <MachineStatusTimelineGraph :machine_id="machine.machine_id" :start_time="item.start_time" :end_time="item.end_time"/>
                            <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                        </div>
                        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import WorkspaceMachineForm from '@/components/forms/WorkspaceMachineForm.vue'
import TimePicker from '@/components/inputs/TimePicker'
import axios from 'axios'
import moment from 'moment'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import MachineStatusTimelineGraph from '@/components/graphs/MachineStatusTimelineGraph.vue'
export default {
    name:'MachineStatusBasedTimelinePanel',
    props:['widget','seq'],
    components:{
        InfoAlert,
        WorkspaceMachineForm,
        TimePicker,
        DeleteConfirmation,
        MachineStatusTimelineGraph
    },
    data(){
        return {
            loading:false,
            valid:false,
            showDismissibleAlert:false,
            info:'',
            edit: false,
            api: 'createWidgetForView',
            timeframeType:'shift',
            from_time:null,
            to_time:null,
            menu2:false,
            date:null,
            shiftOptions:[],
            showForm:true,
            showGraph:false,
            shift:null,
            form: {
                meta:{
                    machineList:[],
                    timestamps:[],
                    timeframeType:'shift',
                    shiftDetails:{}
                }
            },
            widthOptions: [
                { label: 'Full screen', value: 12 },
                { label: 'Half screen', value: 6 },
                { label: '1/3 screen', value: 4 },
                { label: 'Quarter screen', value: 3 },
                { label: 'Auto', value: null }
            ],
            heightOptions: [
                { label: 'Fixed', value: 'fixed' },
                { label: 'Flex', value: 'flex' },
            ],
            rules: FormRules.rules,
            ISODateRules:[
                  v => !!v ||this.$store.getters.getTextMap().required,
                  v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
              ]
        }
    },
    mounted() {
        this.getShifts()
        this.init()
    },
    computed:{
        shiftData(){
            if(this.shift && this.shift.shift_id){
                return this.$store.getters.getShiftDataById(this.shift.shift_id)
            }
            return null
        },
    },
    methods: {
        init() {
 
        },
        formattedTime(timestamp){
            if(timestamp){
                return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
            }
            return null
        },
        formattedDate(date_to_format){
            if(date_to_format){
                return moment(date_to_format).format('DD-MM-YYYY');
            }
            return null
        },
        getTimestamp(time){
            let dt=moment().subtract(1, 'days').format("YYYYMMDD")
            if(this.date){
                dt=this.date.toString()
            }
            if(time){
                dt+=' '+time.toString()
                return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
        },
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let shifts=response.data.data
            for(let i of shifts){
                op.push({value:i, label: i.name })
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
          this.shiftOptions = op
        },
        getformattedTime(timestamp){
            if(timestamp){
                return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
            }
            return null
        },
        updateMachineList(value){
            if(!(this.form && this.form.meta && this.form.meta && this.form.meta.machineList && this.form.meta.machineList.length>0)){
                this.form.meta.machineList=[]
            }
            if(!this.form.meta.machineList.some(x=>x.machine_id ===value.machine.machine_id)){
                this.form.meta.machineList.push(value.machine)
            }
        },
        addTimestampToList(){
            let timestamps=[]
            timestamps=this.form.meta.timestamps
            timestamps.push({start_time:this.getTimestamp(this.from_time),end_time:this.getTimestamp(this.to_time)})
            this.form.meta.timestamps=timestamps
            timestamps=[]
        },
        addShiftToList(){
            if(this.shift && this.shift.shift_id){
                if(!(this.form && this.form.meta && this.form.meta && this.form.meta.shiftDetails && this.form.meta.shiftDetails.shift_id)){
                    this.form.meta.shiftDetails={}
                }
                let timestamps=[]
                let shiftDetails=this.shiftData
                this.form.meta.shiftDetails=this.shift
                for(let i of shiftDetails){
                    timestamps.push({start_time:this.getTimestamp(i.from_time),end_time:this.getTimestamp(i.to_time)})
                }
                this.form.meta.timestamps=timestamps
            }
        },
        onSubmit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.showGraph=true
                this.showForm=false
            }
        },
        deleteTimestampFromTable(item,index){
            this.form.meta.timestamps = this.form.meta.timestamps.filter((x, i) => i !== index);
        },
        deleteMachineFromTable(item){
            this.form.meta.machineList=this.form.meta.machineList.filter(x=>x.machine_id!=item.machine_id)
        },
        clearMachineDetails(){
            this.form.meta.machineList={}
        },
        onClose() {
            this.showGraph=false
            this.showForm=true
            this.$refs.form.reset()
        }
    },
    watch:{
        shift(){
            this.addShiftToList()
        },
        timeframeType(){
            this.form.meta.timestamps=[]
            if(this.form && this.form.meta && this.form.meta.shiftDetails && this.form.meta.shiftDetails.shift_id){
                this.form.meta.shiftDetails={}
            }
            if(this.shift && this.shift.shift_id){
                this.shift=null
            }
        }
    }
}
</script>