<template>
    <v-container fluid>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card flat>
        <v-card-title>
          <span v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.engineerAccessLevel">
          <DeleteConfirmation  v-on:confirm="deleteGroup()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
            <v-icon small>mdi-delete</v-icon>
            </DeleteConfirmation>
          </span>
        </v-card-title>
      </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
export default {
name:'MachineList',
props:['machine'],
components:{
    InfoAlert,
    DeleteConfirmation
},
mounted(){
},
data(){
    return {
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addDialog:false,
        valid:false,
        tuples:[],
        form:{
            machine_id:null
        },
        stringRules: [
          v => !!v || 'Required',
          v => /\S+/.test(v) || 'Required',
          v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
        ],
        bitRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && !isNaN(v))|| 'It should be a valid number',
            v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
        ],
        emailRules:FormRules.emailRules,
        nameRules:FormRules.nameRules
    }
},

computed:{
},

methods:{
    deleteGroup(){
        
        axios.post(this.$store.state.api+'deletefromEDMachineList',this.machine,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
            if(response.data.status=='success'){
                this.loading=false
                this.info='The Machine has been deleted'
                this.$store.dispatch('refreshEnergyDistributionReports')
                this.showDismissibleAlert=true
                this.$emit('delete')
            }else{
                this.loading=false
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
        }).catch(err=>{
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true

        })
    },
     cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }

},
watch:{
}
}
</script>